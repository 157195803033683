<template>
    <div class="clamped-text" :class="'color-' + color">
        <div class="clamped-wrapper" :style="contentStyle">
            <div class="clamped-content" ref="content">
                <slot />
            </div>
        </div>
        <div class="clamped-toggle" :class="{ noclamp, clamped }">
            <a href="javascript: void 0;" class="clamped-label" @click="toggle">
                {{ label }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "ClampedText",
    props: {
        maxHeight: {
            type: [Number, String],
            default: 60,
        },
        more: {
            type: String,
            default: "Show More",
        },
        less: {
            type: String,
            default: "Show Less",
        },
        color: {
            type: String,
            default: "light"
        }
    },
    data() {
        return {
            noclamp: true,
            clamped: true,
            observer: null,
            height: 60,
        };
    },
    computed: {
        label() {
            return this.clamped ? this.more : this.less;
        },
        contentStyle() {
            let style = {};
            if (this.noclamp) {
                style.paddingBottom = "0";
            } else {
                style.paddingBottom = "20px";
            }

            if (!this.noclamp && this.clamped) {
                style.maxHeight = this.maxHeight + "px";
            } else {
                style.maxHeight = "none";
            }

            return style;
        },
    },
    watch: {
        height(val) {
            let max;
            if (typeof this.maxHeight === "string") {
                max = parseInt(this.maxHeight);
            } else {
                max = this.maxHeight;
            }
            this.noclamp = val <= this.maxHeight;
        },
    },
    methods: {
        toggle() {
            this.clamped = !this.clamped;
        },
    },
    mounted() {
        this.height = this.$refs.content.scrollHeight;
        this.observer = new ResizeObserver((entries) => {
            const { height } = entries[0].contentRect;

            this.height = height;
        });

        this.observer.observe(this.$refs.content);
    },
    destroyed() {
        this.observer?.disconnect();
    },
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.clamped-text {
    position: relative;
}

.noclamp {
    display: none;
}

.clamped-wrapper {
    overflow-y: hidden;
}

.clamped-toggle {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 30px 0 0;
    margin-bottom: 12px;

    &.clamped {
        margin-bottom: 0;
    }
}

.color-light .clamped-toggle.clamped {
    background-image: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.8),
            rgba(255, 255, 255, 1)
    );
}
.color-dark {
    .clamped-label {
        color: #4476b6;
    }

    .clamped-toggle {
        padding: 15px 0 0;
    }

    .clamped-toggle.clamped {
        background-image: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 0.6),
                rgba(0, 0, 0, 0.8)
        );
    }
}
</style>
