export function createUrlBuilder(base) {
    return (...parts) => {
        if (parts.length > 0) {
            return base + "/" + parts.map(it => {
                if (it === null || it === undefined) {
                    return "null";
                }
                return encodeURIComponent(it.toString());
            }).join("/");
        }
        return base;
    };
}
