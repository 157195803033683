import Clipboard from "@/components/Clipboard.vue";
import GasEstimate from "@/components/dumbapp/GasEstimate.vue";
import SubmitMixin from "@/components/dumbapp/submit/SubmitMixin";
import LoadingButton from "@/components/LoadingButton.vue";
import { mapGetters } from "vuex";
import Vue from "vue";
import "vue-unique-id";
import { getChain } from "@blockwell/chains";
import { once } from "@/lib/interval";
export default Vue.extend({
    name: "MetamaskWalletView",
    components: { GasEstimate, Clipboard, LoadingButton },
    mixins: [SubmitMixin],
    data() {
        return {
            trustWalletError: false,
            networkChangeInterval: null,
        };
    },
    computed: {
        ...mapGetters("user", ["loggedIn", "api"]),
        noProvider() {
            return this.state === "not-installed";
        },
        network() {
            if (this.chainId) {
                return getChain(this.chainId);
            }
            return null;
        },
        networkName() {
            return this.network?.networkName;
        },
        networkChange() {
            if (this.network &&
                this.dumbappChainId &&
                this.dumbappChainId !== this.network.chainId) {
                return getChain(this.dumbappChainId);
            }
            return null;
        },
        unconnected() {
            return this.state === "ready";
        },
        connecting() {
            return this.state === "loading-accounts";
        },
    },
    watch: {
        networkChange: {
            immediate: true,
            handler(net) {
                if (net) {
                    // Using the interval will ensure a request isn't sent when the browser tab is
                    // hidden. It'll get sent when it becomes visible.
                    if (this.networkChangeInterval) {
                        // If there's an existing interval, but it's not locked, clear it out
                        if (!this.networkChangeInterval.locked) {
                            this.networkChangeInterval.clear();
                        }
                    }
                    this.networkChangeInterval = once(async () => {
                        try {
                            await this.wallet.requestNetwork(net);
                        }
                        finally {
                            this.networkChangeInterval = null;
                        }
                    }, 0);
                }
            },
        },
    },
    methods: {
        login() {
            this.$bus.emit("login_request", {
                message: "",
            });
        },
        connect() {
            this.wallet.connect();
        },
    },
    mounted() {
        if (this.provider) {
            let exec = this.provider.execution;
            exec.onResult(this, async (result) => {
                if (this.loggedIn && result.success === true) {
                    try {
                        await this.api.addDumbappSubmission(result.data.submission.data);
                    }
                    catch (err) {
                        // Log the error, but proceed anyway because we don't
                        // want this to depend on the server.
                        console.error("Failed to add submission to user account", err);
                    }
                }
            });
        }
    },
    destroyed() {
        if (this.provider) {
            let exec = this.provider.execution;
            exec.removeHandler(this);
        }
    },
});
