import accessibleAutocomplete from "accessible-autocomplete";
import moment from "moment";
import * as R from "rambdax";
import * as filter from "secure-filters";
import recentTemplate from "../templates/recent-contract.hbs";
import {errorText, loadContract} from "./util";
import * as util from "./util";
import {getNetwork, toChainId} from "./networks";
import Recent from '../../lib/Recent';
import Web3 from 'web3/dist/web3.esm';
import store from '@/store'

const recent = new Recent();
const web3Cache = {};

export default class Dapp {

    /**
     * Parses the input into an Ethereum network and returns info and a Web3 instance.
     *
     * @param network
     * @param useAlternative
     * @return {EthNetwork & {web3: Web3}}
     */
    static getNetwork(network, useAlternative = false) {
        const net = getNetwork(network);

        if (!net) {
            return null;
        }
        const cacheKey = `${net.networkId}-${useAlternative}`;
        if (web3Cache[cacheKey]) {
            return web3Cache[cacheKey];
        }

        if (net.nodeUrl) {
            if (useAlternative && net.altNodeUrl) {
                net.web3 = new Web3(net.altNodeUrl);
                net.nodeUrl = net.altNodeUrl;
            } else {
                net.web3 = new Web3(net.nodeUrl.getUrl ? net.nodeUrl.getUrl() : net.nodeUrl);
            }
            web3Cache[cacheKey] = net;
            return net;
        } else {
            return null;
        }
    }

    /**
     * Parses a Unix timestamp from Ethereum.
     *
     * @param {string|number} value
     * @return {null|moment.Moment}
     */
    static parseTimestamp(value) {
        let parsed = parseInt(value);
        if (parsed > 0) {
            return moment.unix(parsed);
        } else {
            return null;
        }
    }

    /**
     *
     * @param {Object} opt
     * @param {jQuery | null} opt.element
     * @param {Api} opt.api
     * @param {function (string): Promise<Object | null>} opt.process
     * @return {Promise<Object | string | null>}
     */
    static loadAddressFromInput(opt) {
        return new Promise((resolve, reject) => {
            const $element = opt.element || $('.contract-address-wrap');
            const api = opt.api;
            if (!api) {
                reject(new Error("loadAddressFromInput requires opt.api, no api found"));
                return;
            }
            let $input;

            const doResolve = (value) => {
                if (opt.process) {
                    opt.process.bind($input)(value).then(res => {
                        if (res) {
                            resolve(res);
                        }
                    });
                } else {
                    resolve(value);
                }
            };
            $element.empty();

            accessibleAutocomplete({
                element: $element[0],
                id: `contract-address`,
                displayMenu: 'overlay',
                name: 'address',
                source: function (query, populateResults) {
                    if (!util.addressRegex.test(query.trim())) {
                        api.tokenSearch(query).then(list => {
                            populateResults(list);
                        });
                    }
                },
                templates: {
                    inputValue: it => {
                        return it ? it.address : "";
                    },
                    suggestion: it => {
                        return filter.html(it.name);
                    }
                },
                onConfirm: value => {
                    if (value && util.addressRegex.test(value.address)) {
                        $input.val(value.address).trigger('input');
                    }
                }
            });

            // Remove ID after we get a reference to it, so we don't cause conflicts
            $input = $('#contract-address');
            $input.removeAttr('id');

            $input.on('input', function () {
                let value = $(this).val().trim();

                if (util.addressRegex.test(value)) {
                    $('.autocomplete__menu').hide();
                    $(this).addClass('is-valid');
                    doResolve(value);
                } else {
                    $('.autocomplete__menu').attr('style', '');
                }
            });
        });
    }

    static selectNetwork($wrapper, networks) {
        return new Promise((resolve, reject) => {
            let $description = $(`<p>A contract for this address was found on multiple networks, please select the
                        correct network.</p>`);
            let $sel = $('<div class="network-selection"></div>');
            $wrapper.append($description);
            $wrapper.append($sel);

            let i = 0;
            for (let it of networks) {
                let text = it.name || it;
                $sel.append(`
                    <button class="btn btn-sm btn-primary" type="button" data-network="${i}">${text}</button>
                `);
                ++i;
            }

            util.fadeIn($('.select-network'));

            $sel.find('button').click(async function () {
                await util.fadeOut($('.select-network'));
                let networkNum = parseInt($(this).data('network'));
                resolve(networks[networkNum]);
            });
        })
    }

    /**
     *
     * @param {Api} api
     * @param {function(Dapp)} loadContract
     * @param {function(Error, Dapp) | null} loadError
     */
    constructor(api, loadContract, loadError = null) {
        this.api = api;
        this.loadContract = loadContract;
        this.loadError = loadError;
        this.wallet = window.wallet;
    }

    init(useAlternative = false) {
        this.useAlternative = useAlternative;
        $('.change-contract').click(() => {
            $('.contract-container').show();
            $('.app-container').hide();

            return false;
        });

        window.onpopstate = () => {
            this.loadUrl();
        };
    }

    loadRecents(type, deployerAddress, load) {
        if (deployerAddress) {
            recent.getDeployerRecent(type, deployerAddress)
                .then(recents => {
                    let $deployed = $('.recently-deployed').empty();
                    if (recents.length > 0) {
                        for (let recent of recents) {
                            let $ele = $(recentTemplate(recent));
                            $ele.find('a').click(load);
                            $deployed.append($ele);
                        }
                    } else {
                        $deployed.append('<p>No recent contracts found.</p>');
                    }
                });
        }

        let recents = recent.getUserRecent(type);
        let $recent = $('.user-recent').empty();
        if (recents.length > 0) {
            for (let recent of recents) {
                let $ele = $(recentTemplate(recent));
                $ele.find('a').click(load);
                $recent.append($ele);
            }
        } else {
            $recent.append('<p>No recent contracts found.</p>');
        }
    }

    loadFromForm() {
        const $wrap = $('.contract-address-wrap').parents('.form-group');
        const $feedback = $wrap.find('.invalid-feedback');
        this.api.tryPromise('dapp_load_form', async () => {
            let contract = await Dapp.loadAddressFromInput({
                api: this.api,
                process: async address => {
                    let contractList = await util.loadContract(address);
                    let contract;
                    if (contractList.length > 1) {
                        $('.network-address').text(contractList[0].address);
                        let network = await Dapp.selectNetwork($wrap, contractList.map(it => it.network));
                        contract = contractList.find(it => it.network === network);
                    } else if (contractList.length === 1) {
                        contract = contractList[0];
                    } else {
                        $feedback.text('No contract found for that address.').show();
                    }
                    return contract;
                }
            });
            this.contractAddress = contract.address;
            this.network = contract.network;
            this.updateUrl(this.contractAddress, this.network);
            this.load(this.contractAddress, this.network);
        })
            .catch(err => {
                console.error(err);
                $feedback.text('There was a problem loading the contract: ' + errorText(err.message));
                $feedback.show();
                $(this).addClass('is-invalid');
                $(this).removeClass('is-valid');
            });
    }

    loadUrl(presetAddress = null, presetNetwork = null) {
        let urlParams = new URLSearchParams(window.location.search);
        if (presetAddress) {
            this.contractAddress = presetAddress;
            this.network = presetNetwork;
            this.load(presetAddress, presetNetwork);
        } else {
            let urlContractAddress = urlParams.get('contract');
            let urlNetwork = urlParams.get('net');
            if (urlContractAddress) {
                if (urlContractAddress.length === 36) {
                    this.api.getTokenData(urlContractAddress).then(data => {
                        this.contractAddress = data.address;
                        this.network = data.network;
                        this.updateUrl(this.contractAddress, this.network);
                        this.load(this.contractAddress, this.network, data.id);
                    }).catch(console.error);
                } else {
                    if (this.contractAddress === urlContractAddress && this.network === urlNetwork) {
                    } else {
                        this.contractAddress = urlContractAddress;
                        this.network = urlNetwork;
                        this.load(this.contractAddress, this.network);
                    }
                }
            } else if (!this.network || !this.contractAddress) {
                $('.action-buttons').empty();
                $('.contract-container').show();
                $('.app-container').hide();
                $('#contract-dropdown').hide();
                this.loadFromForm();
            }
        }
    }

    load(contractAddress, network, id = null) {
        console.log("dapp", contractAddress, network, id);
        this.api.tryPromise('dapp_load', async () => {
            $('.contract-container').hide();
            $('.app-container').show();
            let net = Dapp.getNetwork(network, this.useAlternative);
            this.web3 = net.web3;
            this.networkId = net.networkId;
            this.networkName = net.networkName;

            if (!id) {
                try {
                    this.contractData = await this.api.getContractId(contractAddress, network);
                    this.contractId = this.contractData.id;
                } catch (err) {
                    console.error('No contract ID found', err);
                }
            } else {
                this.contractId = id;
                this.contractData = await this.api.getContract(id);
            }

            await this.doLoadContract();
        }).catch(err => {
            console.error(err);
            if (this.loadError) {
                this.loadError(err, this);
            }
        });
    }

    async doLoadContract() {
        $('.contract-address').html(util.renderAddress(this.contractAddress, this.networkId));
        $('.contract-network').text(this.networkName);
        $('#contract-dropdown').show();
        await this.loadContract(this);

        store.dispatch("rolodex/saveUnknownAddress", {
            address: this.contractAddress,
            chainId: this.networkId
        }).catch(console.error);
    }

    updateUrl(address, network) {
        if (!window.suggestionsAddress || suggestionsAddress !== address) {
            if (history.pushState) {
                let urlParams = new URLSearchParams(window.location.search);
                if (urlParams.get('contract') !== address || urlParams.get('net') !== network) {
                    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname
                        + '?net=' + network + '&contract=' + address;
                    if (!urlParams.get('contract') || urlParams.get('contract').startsWith('0x')) {
                        window.history.pushState({path: newurl}, '', newurl);
                    } else {
                        window.history.replaceState({path: newurl}, '', newurl);
                    }
                }
            }
        }
    }

    renderQr(codes) {
        $('[data-qr]').each(function () {
            let qr = $(this).data('qr');
            let code = codes[qr];

            if (code) {
                $(this).attr('href', code.url);
            }
        }).addClass('show');
    }
}
