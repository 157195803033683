import Vue from "vue";
import Clipboard from "@/components/Clipboard.vue";
export default Vue.extend({
    name: "CodeBlock",
    components: { Clipboard },
    props: {
        embedCode: {
            type: String,
        },
        disableAutoSize: Boolean
    },
    data() {
        return {};
    },
    watch: {
        embedCode: {
            immediate: true,
            handler() {
                if (!this.disableAutoSize) {
                    this.$nextTick(() => {
                        let wrap = this.$el.querySelector(".formulate-input-element");
                        let area = wrap.querySelector("textarea");
                        wrap.style.height = `${wrap.scrollHeight}px`;
                        area.style.height = "auto";
                        if (area.scrollHeight > 0) {
                            area.style.height = `${area.scrollHeight + 5}px`;
                        }
                        wrap.style.height = null;
                    });
                }
            },
        },
    },
});
