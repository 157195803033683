<template>
    <div class="quick-exchange">
        <ProgressCircle v-if="!state"/>
        <div v-else>
            <div v-if="tooLittle" class="too-little">
                The requested amount {{quantity}} is less than the minimum, using minimum amount instead.
            </div>
            <div v-if="tooMuch" class="too-little">
                The requested amount {{quantity}} is more than what's currently available.
            </div>
            <div :class="state === 'quote' ? 'quote-buying' : 'funding-buying'">
                Buying
                <span class="funding-quantity">{{ quantityFormatted }}</span>
                <span class="funding-symbol">{{ assetSymbol }}</span>
                <img :src="icon" alt="" height="24" class="asset-icon"/>
            </div>
            <template v-if="state === 'quote'">
                <div class="asset-price">
                    <span class="value">{{ price }} </span>
                    <span class="label"> per {{ assetSymbol }} </span>
                    <span class="value asset-fee"> {{ fee }} </span>
                    <span class="label"> fees</span>
                </div>
                <div class="total-cost">
                    <span class="value">{{ cost }}</span>
                    <span class="label"> TOTAL</span>
                </div>
                <div class="currency">
                    <span>{{ paymentCurrency ? paymentCurrency.ccy_code : '' }}</span>
                </div>
                <div class="liquid-terms">
                    By continuing you agree to
                    <a href="https://help.liquid.com/en/articles/3969828-quick-exchange-by-liquid-end-user-terms-of-use"
                       target="_blank" rel="noopener noreferrer">
                        Liquid’s Terms of Use
                    </a>
                </div>
            </template>
        </div>
        <div class="quick-exchange-container" ref="qex">

        </div>
    </div>
</template>

<script>
import {getNetwork} from "@/assets/lib/networks";
import ProgressCircle from "@/components/ProgressCircle";
import {QexWidgetApi} from "@/lib/qex";
import axios from "axios";
import BigNumber from "bignumber.js";
import {BuySellHelperImmutable, QexClient} from "liquid-qex";
import {TapClient} from "liquid-tap";
import {mapGetters, mapState} from "vuex";
import * as R from 'rambdax';

export default {
    name: "QuickExchange",
    components: {ProgressCircle},
    props: {
        asset: String,
        network: [String, Number],
        amount: [String, BigNumber]
    },
    data() {
        const tap = new TapClient();
        let partnerKey;
        if (getNetwork(this.network).networkId === 1) {
            partnerKey = process.env.VUE_APP_LIQUID_KEY;
        } else {
            partnerKey = process.env.VUE_APP_LIQUID_KEY_SANDBOX;
        }

        return {
            quantity: this.amount.toString(),
            tap,
            qex: new QexClient(tap, {
                partnerKey
            }),
            quote: {
                quoted_quantity: null,
                dealable_quantity: null,
                client_ask: {
                    value: null
                },
                fee: {
                    quantity: null
                }
            },
            iframeUrl: null,
            transactionId: null,
            state: null
        };
    },
    watch: {
        helper: {
            immediate: true,
            handler() {
                this.updateStream();
            }
        },
        amount(val) {
            this.quantity = val?.toString();
        }
    },
    computed: {
        ...mapState('user', ['account']),
        ...mapState('dumbapp', ['paymentCurrencyCode']),
        ...mapGetters('user', ['api']),
        helper() {
            return new BuySellHelperImmutable({
                fixSide: "buy",
                payout_settlement: {
                    method: "BLOCKCHAIN_TRANSFER"
                },
                sellCcy: this.paymentCurrencyCode || "USD",
                buyCcy: this.asset,
                quantity: this.quantity
            });
        },
        tooLittle() {
            let asked = new BigNumber(this.quantity);
            let dealable = new BigNumber(this.quote?.dealable_quantity);

            return !dealable.isNaN() && dealable.gt(asked);
        },
        tooMuch() {
            let asked = new BigNumber(this.quantity);
            let dealable = new BigNumber(this.quote?.dealable_quantity);

            return !dealable.isNaN() && dealable.lt(asked);
        },
        quantityFormatted() {
            if (this.quote?.dealable_quantity) {
                return new BigNumber(this.quote?.dealable_quantity).toFormat();
            }
            if (this.quantity) {
                return new BigNumber(this.quantity).toFormat();
            }
            return '';
        },
        icon() {
            return this.currency?.icon;
        },
        assetName() {
            return this.currency?.label;
        },
        assetSymbol() {
            if (this.currency?.ccy_code === "ETH") {
                return "ETH";
            }
            return this.currency?.symbol;
        },
        price() {
            return this.formatCurrency(this.quote.client_ask.value);
        },
        fee() {
            return this.formatCurrency(this.quote.fee.quantity);
        },
        cost() {
            return this.formatCurrency(this.quote.quoted_quantity);
        },
        currency() {
            if (this.currencies) {
                return this.currencies.find(it => it.ccy_code === this.asset);
            }
        },
        paymentCurrencies() {
            if (this.currencies) {
                return this.currencies.filter(it => {
                    return it.settlement?.funding?.includes('CARD_PAYMENT');
                })
            }
        },
        paymentCurrency() {
            let code = this.paymentCurrencyCode || 'USD';

            return this.paymentCurrencies?.find(it => it.ccy_code === code);
        }
    },
    asyncComputed: {
        currencies() {
            return axios.get(`${process.env.VUE_APP_LIQUID_URL}/api/v1/settlement/currencies`, {
                params: {public_api_key: process.env.VUE_APP_LIQUID_KEY}
            })
                .then(res => {
                    return res.data.payload;
                })
        }
    },
    methods: {
        updateStream() {
            console.log('binding stream update');
            if (this.helper) {
                let stream = this.qex.quoteStreamFromHelper(this.helper);
                stream.bind('updated', this.quoteUpdated);
                if (this.stream) {
                    this.stream.unsubscribe();
                }
                if (this.qexw) {
                    this.qexw.destroy();
                    this.qexw = null;
                }
                this.stream = stream;
            }
        },
        quoteUpdated(quoteJson) {
            let quote = JSON.parse(quoteJson);

            if (quote && !this.qexw) {
                if (this.stream) {
                    this.stream.unsubscribe();
                    this.stream = null;
                }

                this.quote = quote;
                this.qexw = new QexWidgetApi(this.$refs.qex);
                this.qexw.init({
                    public_api_key: process.env.VUE_APP_LIQUID_KEY,
                    theme: "light",
                    quote,
                    side: "buy",
                    payout_settlement: {
                        method: 'BLOCKCHAIN_TRANSFER',
                        input_parameters: {
                            account_key: {
                                type: "WALLET_ADDRESS",
                                value: this.account
                            }
                        }
                    },
                    custom_styles: {
                        "cs-button": {
                            "color": "#fff",
                            "background": "#004FFF"
                        }
                    }
                });
                this.qexw.onSuccess(this.success);
                this.qexw.onError((errors) => {
                    console.log("Transaction Failed", errors);
                });
                this.qexw.onStepTransition(this.transition);
                this.state = 'quote';
            } else {
                // This doesn't work
                //this.qexw.updateQuote(R.clone(quote), "buy");
            }
        },
        loaded() {
            if (this.iframeUrl) {
                console.log('iframe loaded');
                /**
                 *
                 * @type {HTMLIFrameElement}
                 */
                let iframe = this.$refs.iframe;

                if (iframe.src.includes('api/v1/method/card')) {
                    iframe.contentWindow.postMessage({
                        event: 'initialize',
                        payload: {}
                    }, process.env.VUE_APP_LIQUID_URL);
                }
            }
        },
        formatCurrency(amount) {
            if (!amount) {
                return '';
            }
            return new Intl.NumberFormat(undefined, {
                style: 'currency',
                currency: this.paymentCurrencyCode || 'USD'
            }).format(parseFloat(amount));
        },
        transition(stepTransition) {
            console.log(
                `Step transition. Old Step: ${stepTransition.old_step}, New step: ${stepTransition.new_step}`,
                stepTransition
            );

            switch (stepTransition.new_step) {
                case 'funding':
                    this.state = 'funding';

                    this.$gtag.event('qex_funding', {
                        event_category: "dumbapps"
                    });
                    break;
                case 'success':
                    this.state = 'success';

                    this.$gtag.event('qex_success', {
                        event_category: "dumbapps"
                    });
                    break;
                case 'quoting':
                    this.$gtag.event('qex_quoting', {
                        event_category: "dumbapps"
                    });
                    if (stepTransition.old_step === 'failure') {
                        this.state = 'quote';
                        this.updateStream();
                    }
                    break;
            }
        },
        success(transaction) {
            console.log('success', transaction);
            this.$emit('success', transaction);

            if (getNetwork(this.network).networkId === 4) {
                this.api.testnetQex(this.account, '4', transaction.payout_settlement.currency, transaction.payout_settlement.quantity)
                    .catch(console.error);
            }
        }
    },
    created() {
        this.$gtag.event('qex_open', {
            event_category: "dumbapps"
        });
    },
    destroyed() {
        if (this.stream) {
            this.stream.unsubscribe();
            this.stream = null;
        }
        if (this.qexw) {
            this.qexw.destroy();
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";
@import "~@/assets/css/bootstrap/mixins/_forms.scss";

.quick-exchange {
    width: 420px;
    @media screen and (max-width: 520px) {
        width: 360px;
    }
    @media screen and (max-width: 420px) {
        width: calc(100vw - 50px);
    }
}

.quick-exchange-asset {
}

.quantity-wrapper {
    position: relative;
    font-size: 24px;
    line-height: 24px;
}

.quantity {
    flex: 1 0 auto;
    text-align: right;
    margin-right: 10px;
    font-weight: 600;
}

.asset-icon {
    margin-left: 15px;
}

.quantity-input {
    display: block;
    box-sizing: border-box;
    height: calc(1.5em + 1rem + 2px);
    padding: .5rem 70px .5rem 70px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d3d3d3;
    border-radius: 1px;
    text-align: center;

    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    max-width: 100%;

    &:focus {
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(#000, .075), 0 0 0 .2rem rgba($primary, .25);
    }
}

.asset-symbol {
    padding: 0;
    margin: 0;
    position: absolute;
    top: calc(50% - 12px);
    right: 10px;
}

.quick-exchange-iframe {
    ::v-deep( iframe ) {
        width: 100%;
        border: none;
    }
}

.asset-price {
    text-align: center;
    font-size: 14px;
    margin-top: 8px;

    ::v-deep( .label ) {
        display: inline-block;
        text-align: left;
        color: $secondary;
    }
}

.asset-fee {
    margin-left: 10px;
}

.total-cost {
    font-size: 28px;
    margin: 32px 0 0;
    text-align: center;

    .will-pay {
        color: $secondary;
    }

    .value {
        font-weight: 600;
    }

    .label {
        font-size: 16px;
    }
}

.currency {
    font-size: 14px;
    font-style: italic;
    text-align: center;
    margin-bottom: 32px;
    opacity: 0.6;

    ::v-deep( a ) {
        margin-left: 10px;
    }
}

.quick-exchange-cta {
    text-align: center;
    margin-top: 20px;
}

.liquid-terms {
    font-size: 18px;
}

.funding-buying, .quote-buying {
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .funding-quantity {
        font-weight: 600;
        color: $dark;
        margin: 0 5px 0 5px;
    }
}

.funding-buying {
    margin-bottom: 20px;
}

.quote-buying {
    margin-top: 32px;
}

.too-little {
    font-size: 0.9em;
    color: $text-muted;
    text-align: center;
}
</style>
