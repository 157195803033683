export function debounce(func, delay = 500) {
    let timeout;
    return function () {
        clearTimeout(timeout);
        let self = this;
        let args = arguments;
        timeout = setTimeout(() => {
            func.apply(self, args);
        }, delay);
    };
}
