import colorMixin from "./ColorMixin";
import Saturation from "@/components/color/Saturation.vue";
import Hue from "@/components/color/Hue.vue";
import Alpha from "@/components/color/Alpha.vue";
import Checkboard from "@/components/color/Checkboard.vue";
import ColorInput from "./ColorInput.vue";
const presetColors = [
    '#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321',
    '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2',
    '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF',
    'rgba(0,0,0,0)'
];
export default {
    name: 'ColorPicker',
    mixins: [colorMixin],
    components: {
        ColorInput,
        Checkboard,
        Alpha,
        Hue,
        Saturation
    },
    props: {
        presetColors: {
            type: Array,
            default() {
                return presetColors;
            }
        },
        theme: {
            type: Object
        },
        disableAlpha: {
            type: Boolean,
            default: false
        },
        disableFields: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hex() {
            let hex;
            if (this.colors.a < 1) {
                hex = this.colors.hex8;
            }
            else {
                hex = this.colors.hex;
            }
            return hex.replace('#', '');
        },
        activeColor() {
            let rgba = this.colors.rgba;
            return 'rgba(' + [rgba.r, rgba.g, rgba.b, rgba.a].join(',') + ')';
        },
        themeEntries() {
            const theme = this.theme;
            if (!theme || Object.keys(theme).length === 0) {
                return null;
            }
            return Object.values(theme);
        }
    },
    methods: {
        handlePreset(c) {
            this.colorChange({
                hex: c,
                source: 'hex'
            });
        },
        handleTheme(c, variation) {
            this.colorChangeNoEmit({
                hex: c[variation],
                source: 'hex'
            });
            let variable = `--` + c.name;
            if (variation !== "base") {
                variable += "-" + variation;
            }
            this.$emit("input", `var(${variable})`);
        },
        childChange(data) {
            this.colorChange(data);
        },
        inputChange(data) {
            if (!data) {
                return;
            }
            if ("hex" in data) {
                this.isValidHex(data.hex) && this.colorChange({
                    hex: data.hex,
                    source: 'hex'
                });
            }
            else if (data.source === "rgba") {
                this.colorChange({
                    r: data.r || this.colors.rgba.r,
                    g: data.g || this.colors.rgba.g,
                    b: data.b || this.colors.rgba.b,
                    a: data.a || this.colors.rgba.a,
                    source: 'rgba'
                });
            }
        }
    }
};
