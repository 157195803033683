import QRCode from "qrcode";

export async function renderQr(canvas, shortcode, data, color, logoUrl, overlay, label) {
    let url = process.env.VUE_APP_QR_URL + shortcode;
    let query = '';
    let keys = Object.keys(data).filter(it => data[it]);
    if (keys.length > 0) {
        query = keys
            .flatMap(it => {
                if (Array.isArray(data[it])) {
                    let arr = data[it];

                    let index = arr.length - 1;

                    while (arr[index] === "") {
                        arr = arr.slice(0, index);
                        --index;
                    }

                    return arr.map(item => encodeURIComponent(it) + '=' + encodeURIComponent(item));
                } else {
                    return encodeURIComponent(it) + '=' + encodeURIComponent(data[it]);
                }
            })
            .join('&');
    }

    let imageUrl = url + "/image";
    if (query.length > 0) {
        url += "?" + query;
        imageUrl += "?" + query;
    }

    let dataUrl = await QRCode.toDataURL(url, {
        scale: 16,
        errorCorrectionLevel: 'Q'
    });

    let ctx = canvas.getContext('2d');
    let img = new Image();
    img.src = dataUrl;
    await onLoad(img);
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;

    if (logoUrl && overlay) {
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        let logo = new Image();
        logo.src = logoUrl;
        await onLoad(logo);

        let centerWidth = Math.round(canvas.width - 128);
        let centerHeight = Math.round(canvas.height - 128);
        let logoSize = centerWidth;
        let margin = 64;
        let width;
        let height;
        if (logo.naturalWidth > logo.naturalHeight) {
            width = logoSize;
            height = Math.floor((logo.naturalHeight / logo.naturalWidth) * logoSize);
        } else {
            height = logoSize;
            width = Math.floor((logo.naturalWidth / logo.naturalHeight) * logoSize);
        }
        let x = margin + (logoSize - width) / 2;
        let y = margin + (logoSize - height) / 2;

        ctx.globalAlpha = 0.7;
        ctx.drawImage(logo, x, y, width, height);

        ctx.globalCompositeOperation = 'darken';
        ctx.globalAlpha = 0.7;
        ctx.drawImage(img, 0, 0);
        ctx.globalCompositeOperation = 'source-over';
        ctx.globalAlpha = 1;
    } else {
        if (color) {
            ctx.fillStyle = color;
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.globalCompositeOperation = 'lighten';
            ctx.drawImage(img, 0, 0);
            ctx.globalCompositeOperation = 'source-over';
        } else {
            ctx.drawImage(img, 0, 0);
        }

        if (logoUrl && logoUrl.length > 0) {
            let logo = new Image();
            logo.src = logoUrl;
            await onLoad(logo);

            let centerWidth = Math.round(canvas.width / 3);
            let centerHeight = Math.round(canvas.height / 3);
            let logoSize = Math.round(0.6 * centerWidth);
            let margin = (centerWidth - logoSize) / 2;
            let width;
            let height;
            if (logo.naturalWidth > logo.naturalHeight) {
                width = logoSize;
                height = Math.floor((logo.naturalHeight / logo.naturalWidth) * logoSize);
            } else {
                height = logoSize;
                width = Math.floor((logo.naturalWidth / logo.naturalHeight) * logoSize);
            }
            let x = margin + (logoSize - width) / 2;
            let y = margin + (logoSize - height) / 2;

            let tmpCanvas = document.createElement('canvas');
            tmpCanvas.width = centerWidth;
            tmpCanvas.height = centerHeight;
            let tmpCtx = tmpCanvas.getContext('2d');

            let tmpCanvas2 = document.createElement('canvas');
            tmpCanvas2.width = centerWidth;
            tmpCanvas2.height = centerHeight;
            let tmpCtx2 = tmpCanvas2.getContext('2d');
            for (let i = 0; i < 15; i++) {
                tmpCtx2.shadowColor = 'black';
                tmpCtx2.shadowBlur = 20;
                tmpCtx2.drawImage(logo, x, y, width, height);
            }


            tmpCtx.imageSmoothingQuality = 'high';
            tmpCtx.fillStyle = 'white';
            tmpCtx.fillRect(0, 0, tmpCanvas.width, tmpCanvas.height);
            tmpCtx.globalCompositeOperation = 'destination-in';
            tmpCtx.drawImage(tmpCanvas2, 0, 0);
            tmpCtx.globalCompositeOperation = 'source-over';

            tmpCtx.drawImage(logo, x, y, width, height);
            ctx.imageSmoothingQuality = 'high';
            for (let i = 0; i < 10; i++) {
                ctx.drawImage(tmpCanvas, (canvas.width - centerWidth) / 2, (canvas.height - centerHeight) / 2);
            }
        }
    }

    if (label) {
        ctx.fillStyle = color || '#000000';
        ctx.font = '30px sans-serif';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
        ctx.fillText(label, canvas.width / 2, canvas.height - 10);
    }
}

function onLoad(img) {
    return new Promise((resolve, reject) => {
        img.addEventListener('load', function () {
            resolve();
        });
    });
}
