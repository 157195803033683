import Vue from "vue";
import { Dumbapp } from "@blockwell/dumbapp";
import DumbappMixin from "@/components/dumbapp/DumbappMixin";
import DumbappWallet from "@/components/dumbapp/submit/DumbappWallet.vue";
import TokenContract from "@/components/TokenContract.vue";
import { every } from "@/lib/interval";
export default Vue.extend({
    name: "ApprovalDumbapp",
    components: { TokenContract, DumbappWallet },
    mixins: [DumbappMixin],
    props: {
        approve: {
            type: Object,
        },
        parentDumbapp: Dumbapp,
        showCancel: Boolean,
        requireWalletAddress: String,
        disableWallet: String,
        priority: String
    },
    data() {
        return {
            contract: null,
            spender: null,
            interval: null
        };
    },
    computed: {
        values() {
            return this.approve;
        },
        trackingId() {
            if (!this.approve) {
                return null;
            }
            return [
                "approve",
                this.approve.account,
                this.approve.chainId,
                this.approve.contractAddress,
                this.approve.spender,
            ]
                .join("-")
                .toLowerCase();
        },
        confirming() {
            return this.tracked?.status === "pending" || this.tracked?.status === "completed";
        },
        error() {
            if (this.tracked?.status === "error") {
                return this.tracked.submission.error();
            }
            return null;
        },
        tokenName() {
            return this.contract?.name;
        },
        spenderName() {
            return this.spender?.name;
        },
    },
    watch: {
        approve: {
            immediate: true,
            handler(val) {
                if (!val || val.type === "undetermined") {
                    this.contract = null;
                    this.shortcodeOverride = null;
                    return;
                }
                switch (val.type) {
                    case "erc721-all":
                        this.shortcodeOverride = "all721";
                        break;
                    case "erc20":
                        this.shortcodeOverride = "appr20";
                        break;
                    case "erc721":
                        this.shortcodeOverride = "app721";
                        break;
                }
                const api = this.$store.getters["user/api"];
                api.contracts
                    .getContract({
                    chainId: val.chainId,
                    address: val.contractAddress,
                })
                    .then((it) => (this.contract = it));
                api.contracts
                    .getContract({
                    chainId: val.chainId,
                    address: val.spender,
                })
                    .then((it) => (this.spender = it));
            },
        },
    },
    mounted() {
        this.hidden = false;
        const controller = this.provider;
        controller.execution.addHandler(this, "preExecute", async () => {
            let data = {
                extras: {
                    approval: {
                        approval: this.approve,
                        shortcode: this.parentDumbapp.shortcode,
                        dumbappName: this.parentDumbapp.getTitle(),
                    },
                },
            };
            return {
                success: true,
                data,
            };
        });
        this.interval = every(() => {
            this.$emit("resolve");
        }, 8000);
        this.$on("completed", (submission) => {
            this.$emit("resolve");
        });
    },
    beforeDestroy() {
        const controller = this.provider;
        controller.execution.removeHandler(this);
        this.interval?.clear();
    },
});
