<template>

    <div class="contrib-method" style="min-height: 390px">
        <h3>
            <img src="../../assets/images/logos/metamask-fox.svg" alt="" width="50"/>
            MetaMask
        </h3>
        <p>
            Contribute using a MetaMask wallet.
        </p>
        <div class="metamask-method">
            <transition name="fade">
                <div v-if="!token" :key="'picker'" class="metamask-picker">
                    <div class="metamask-tokens">
                        <div v-for="it in highlighted" class="metamask-token" @click="useToken(it)">
                            <div class="popular-logo">
                                <img
                                    :src="logo(it.address)"
                                    alt=""/>
                            </div>
                            <span>{{ it.name }}</span>
                        </div>
                    </div>
                    <ContractLoader
                        label="Other Token"
                        hide-recents
                        hide-deployed
                        class="other-token"
                        network="1"
                        features="erc20"
                        :load-click="useToken"
                    />
                </div>
                <div v-else :key="'picked'" class="metamask-picked">
                    <h4>
                        <span class="change-token">
                            <a href="javascript: void 0;" @click="clearToken"
                               class="material-icons">arrow_back_ios</a>
                        </span>
                        <img
                            :src="logo(token.address)"
                            alt=""/>
                        <span>{{ token.name }}</span>
                    </h4>
                    <template v-if="tokenPrice">
                        <div class="token-amount formulate-input" data-classification="text">
                            <label :for="$id('token')">Contribution Amount</label>
                            <div class="token-input-wrapper">
                                <input :id="$id('token')" type="number" step="any" v-model="tokenAmount"/>
                                <span class="token-symbol">{{ token.symbol }}</span>
                            </div>
                        </div>
                        <DumbappPopover
                            label="Contribute"
                            v-if="tokenValue"
                            :instance="metamaskDumbapp"
                            :values="{
                                    value: tokenAmount,
                                    i: reference
                                }"
                            @submitted="dumbappSubmitted"
                        />
                        <OfferMultiplier v-if="tokenAmount"
                                         :amount="tokenAmount"
                                         :value="tokenValue"
                                         :multiplier="multiplier"
                                         :symbol="token.symbol"/>
                    </template>
                    <template v-else>
                        <p>
                            We are unable to accept this token automatically at this time. Contact us
                            on <a href="https://t.me/blockwell" target="_blank">Telegram</a> to find out if we can
                            accept it manually.
                        </p>
                    </template>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { addressRegex, getAddress } from "@blockwell/chain-client";
import { DumbappSubmission } from "@blockwell/dumbapp";
import DumbappPopover from "@/components/DumbappPopover";
import OfferMultiplier from "@/components/offer/OfferMultiplier";
import {getPrice} from "@/lib/prices";
import ContractLoader from "@/views/happs/components/ContractLoader";
import BigNumber from "bignumber.js";
import {mapGetters} from "vuex";

export default {
    name: "MetaMaskOffer",
    components: {OfferMultiplier, ContractLoader, DumbappPopover},
    props: {
        reference: String,
        multiplier: Number
    },
    data() {
        return {
            highlighted: [
                {
                    name: "Ether",
                    address: "0x0000000000000000000000000000000000000000",
                    symbol: "ETH"
                },
                {
                    name: "Tether",
                    address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
                    symbol: "USDT"
                },
                {
                    name: "USD Coin",
                    address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
                    symbol: "USDC"
                },
                {
                    name: "Dai",
                    address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
                    symbol: "DAI"
                }
            ],
            token: null,
            tokenAmount: ''
        };
    },
    computed: {
        ...mapGetters('user', ['api']),
        ...mapGetters('dumbapp', ['submissions']),
        amountFormatted() {
            return new BigNumber(this.tokenAmount).toFormat();
        },
        tokenValue() {
            let value = this.tokenPrice?.times(this.tokenAmount);
            if (value && !value.isNaN()) {
                return value.toString(10);
            }
            return '';
        },
        prideValue() {
            let value = new BigNumber(this.tokenValue);
            if (value && !value.isNaN()) {
                return value.times(this.multiplier).toString(10);
            }
            return '';
        }
    },
    asyncComputed: {
        async tokenPrice() {
            if (this.token) {
                return getPrice(this.token.address);
            }
            return null;
        },
        metamaskDumbapp() {
            if (this.token && this.token.address !== "0x0000000000000000000000000000000000000000") {
                return this.api.offerDumbapp(this.token.address);
            }
            return this.api.getDumbapp("4hx4yo");
        }
    },
    methods: {
        logo(address) {
            if (address === '0x0000000000000000000000000000000000000000') {
                return require('../../assets/images/ethereum.png');
            }
            if (addressRegex.test(address)) {
                return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${getAddress(address)}/logo.png`;
            }
            return '';
        },
        useToken(data) {
            if (data.parameters?.symbol) {
                data.symbol = data.parameters.symbol;
            }
            this.token = data;
        },
        clearToken() {
            this.token = null;
            this.tokenAmount = '';
        },
        dumbappSubmitted(data) {
            console.log('dumbappSubmitted', data);

            setTimeout(() => {
                let updated = this.submissions[data.id];

                if (!updated.data.error()) {
                    this.api.offerReceipt(this.reference, this.token.address, this.tokenAmount, this.token.symbol, this.tokenValue, this.prideValue, data.data.from);
                } else {
                    console.log('Error! no receipt');
                }

                this.clearToken();
            }, 3000);
        }
    }
}
</script>

<style scoped lang=scss>
@use "sass:color";
@import "~@/assets/css/custom.scss";

.metamask-method {
    position: relative;
    margin-top: 30px;
}

.metamask-tokens {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
    justify-items: stretch;
}

.metamask-token {
    cursor: pointer;
    border: 1px solid rgba($primary, .1);
    padding: 10px 0 10px;
    text-align: center;

    .popular-logo {
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;

        ::v-deep( img ) {
            max-height: 25px;
            display: block;
        }
    }

    ::v-deep( span ) {
        color: $dark;
    }

    &:hover {
        box-shadow: 0 0 2px rgba($primary, .5);
    }
}

.metamask-picker {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.metamask-picked {

    ::v-deep( h4 ) {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        margin-bottom: 0;

        img {
            max-width: 30px;
            max-height: 30px;
            margin-right: 8px;
        }
    }

    ::v-deep( .dumbapp-popover ) {
        width: 220px;
        margin: 0 auto;

        .dumbapp-popover-trigger button {
            width: 220px;
        }
    }
}

.change-token {
    width: 0;
    position: relative;
    left: -40px;

    .material-icons {
        color: $secondary;
        text-decoration: none;
    }
}

.other-token {
    margin: 15px 0 0;
}

.token-input-wrapper {
    position: relative;
}

.token-symbol {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 22px;
    line-height: 40px;
}

.token-amount {
    width: 220px;
    margin: 15px auto 0;

    ::v-deep( input ) {
        padding-right: 70px;
    }
}


</style>
