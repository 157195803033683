import FuelMixin from "@/components/fuel/FuelMixin";
export default {
    name: "FuelWidget",
    mixins: [FuelMixin],
    methods: {
        click() {
            this.$router.push("/fuel");
        }
    },
};
