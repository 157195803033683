<template>
    <a
        v-if="linkOnly"
        href="javascript: void 0;"
        ref="button"
        :data-clipboard-text="textValue"
        v-tooltip="{
            content: 'Copied to Clipboard',
            trigger: 'manual',
            show: showTooltip,
            container: this.containerElement || $refs.wrap,
        }"
        >{{ displayText || "Copy To Clipboard" }}</a
    >
    <span v-else class="clipboard-wrap" ref="wrap">
        <a
            v-if="href"
            :href="href"
            target="_blank"
            class="clipboard-text"
            :class="{ ellipsize }"
            @click.stop
        >
            <slot v-if="hasDefaultSlot" />
            <span v-else>{{ displayText }}</span>
        </a>
        <component
            :is="element"
            class="clipboard-text"
            :class="{ ellipsize }"
            v-else-if="!linkOnly && displayText"
        >
            <slot v-if="hasDefaultSlot" />
            <span v-else>{{ displayText }}</span>
        </component>

        <button
            v-if="button"
            :data-clipboard-text="textValue"
            class="btn btn-primary btn-sm"
            type="button"
            ref="button"
        >
            Copy To Clipboard
        </button>
        <span
            v-else
            :data-clipboard-text="textValue"
            ref="button"
            @click.stop
            v-tooltip="{
                content: 'Copied to Clipboard',
                trigger: 'manual',
                show: showTooltip,
                container: this.containerElement || $refs.wrap,
            }"
            class="vue-clipboard-image"
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.12 12.93">
                <title>Copy To Clipboard</title>
                <path
                    class="clipboard-icon"
                    :class="{ 'white-icon': white }"
                    d="M193.57,80.28v-2.2h-8.41v11h2.71V91h8.41V83Zm0,1.3,1.2,1.2h-1.2Zm-7.5,6.58V79h6.58v1h-4.78v8.14Zm2.72,1.93V80.93h3.86v2.76h2.72v6.4Z"
                    transform="translate(-185.16 -78.08)"
                />
            </svg>
        </span>
    </span>
</template>

<script>
import * as ClipboardJS from "clipboard";
import { getNetwork } from "@/assets/lib/networks";

export default {
    name: "Clipboard",
    props: {
        link: String,
        linkOnly: Boolean,
        clipboard: String,
        container: String,
        containerElement: Element,
        address: String,
        network: [String, Number],
        ellipsize: Boolean,
        element: {
            type: String,
            default: "span",
        },
        button: {
            type: Boolean,
            default: false,
        },
        text: String,
        white: Boolean,
    },
    data() {
        return {
            showTooltip: false,
        };
    },
    mounted() {
        let opt = {};
        if (this.containerElement) {
            opt.container = this.containerElement;
        } else if (this.container) {
            opt.container = document.getElementById(this.container);
        }
        let clip = new ClipboardJS(this.$refs.button, opt);
        clip.on("success", (e) => {
            e.clearSelection();
            this.showTooltip = true;
            setTimeout(() => {
                this.showTooltip = false;
            }, 3000);
        });
    },
    computed: {
        hasDefaultSlot() {
            return !!this.$slots.default;
        },
        textValue() {
            if (this.clipboard) {
                return this.clipboard;
            }
            if (this.address) {
                return this.address;
            }
            return "";
        },
        displayText() {
            if (this.text !== undefined) {
                return this.text;
            }
            return this.textValue;
        },
        href() {
            if (this.link) {
                return this.link;
            }
            if (this.network) {
                let net = getNetwork(this.network);
                return net.explorerAddress(this.textValue);
            }

            return null;
        },
    },
};
</script>

<style>
.clipboard-icon {
    fill: var(--link);
}

.clipboard-icon.white-icon {
    fill: var(--light);
}
</style>

<style scoped lang="scss">
.clipboard-wrap {
    display: inline-flex;
    align-items: center;
}

.vue-clipboard-image {
    width: 22px;
    padding: 0 5px;
    box-sizing: border-box;
    cursor: pointer;
    display: block;

    ::v-deep( svg ) {
        display: block;
    }
}

.ellipsize {
    display: inline-block;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.clipboard-wrap {
    ::v-deep( .tooltip-inner ) {
        padding: 5px 10px;
        background: #333;
        color: #fff;
    }

    ::v-deep( div.tooltip-arrow ) {
        border-color: #333;
    }
}
</style>
