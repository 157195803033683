import Vue from "vue";
import Mailchimp from "../../components/Mailchimp.vue";
import FooterSocial from "./FooterSocial.vue";
export default Vue.extend({
    name: "WhitelabelFooter",
    components: { FooterSocial, Mailchimp },
    data() {
        return {
            year: new Date().getFullYear(),
        };
    },
});
