import Vue from "vue";
import equal from "fast-deep-equal";
import { Dumbapp, InputController } from "@blockwell/dumbapp";
import ResolveStepsMixin from "@/components/dumbapp/ResolveStepsMixin";
import moment from "moment";
import { clone } from "remeda";
import { debounce } from "@blockwell/util";
export default Vue.extend({
    provide() {
        return {
            provider: this.provider,
        };
    },
    mixins: [ResolveStepsMixin],
    props: {
        strategy: String,
        shortcode: String,
        instance: Dumbapp,
        extras: Object
    },
    data() {
        return {
            provider: new InputController(),
            formData: {},
            submitting: false,
            shortcodeOverride: null,
            dumbapp: null,
            dumbappError: null,
            hidden: true
        };
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        },
        network() {
            return this.dumbappChainId;
        },
        title() {
            if (this.dumbapp) {
                if (this.dumbapp.title) {
                    return this.dumbapp.title;
                }
                if (this.dumbapp.steps.length > 0) {
                    return this.dumbapp.steps[0].method;
                }
            }
            return "";
        },
        description() {
            if (this.dumbapp) {
                return this.dumbapp.description;
            }
            return null;
        },
        fields() {
            if (!this.dumbapp || !this.dumbapp.dynamic || this.dumbapp.dynamic.length === 0) {
                return null;
            }
            if (this.query || this.values) {
                return this.dumbapp.dynamic.filter((it) => !this.query?.[it.name] &&
                    (this.values?.[it.name] === undefined || this.values?.[it.name] === null));
            }
            return this.dumbapp.dynamic;
        },
        contextDeps() {
            return {
                dumbapp: this.dumbapp,
                hidden: this.hidden
            };
        },
        dumbappDeps() {
            return {
                instance: this.instance,
                shortcode: this.shortcodeOverride || this.shortcode,
            };
        },
        tracked() {
            if (this.trackingId) {
                let tracks = this.$store.getters["dumbapp/tracking"][this.trackingId]?.filter((it) => {
                    if (it.status !== "completed") {
                        return true;
                    }
                    return moment(it.submission.completedTime()).isAfter(moment().subtract(10, "seconds"));
                });
                if (tracks?.length > 0) {
                    return tracks[tracks.length - 1];
                }
            }
            return null;
        }
    },
    watch: {
        api: {
            immediate: true,
            handler(api) {
                this.provider.setApi(api.contracts);
            }
        },
        values(val, old) {
            if (!equal(val, old)) {
                this.provider?.trigger();
            }
        },
        query(val, old) {
            if (!equal(val, old)) {
                this.provider?.trigger();
            }
        },
        contextDeps: {
            deep: true,
            immediate: true,
            handler({ dumbapp, hidden }) {
                if (dumbapp) {
                    const controller = this.provider;
                    controller.dumbapp = dumbapp;
                    controller.setHidden(hidden);
                }
            },
        },
        formData: {
            deep: true,
            handler: debounce(function (val) {
                this.provider.trigger();
            }, 50),
        },
        dumbappDeps: {
            deep: true,
            immediate: true,
            async handler({ instance, shortcode }) {
                if (instance) {
                    this.dumbapp = instance;
                }
                else if (shortcode) {
                    try {
                        this.dumbapp = await this.api.getDumbapp(shortcode);
                    }
                    catch (err) {
                        console.error(err);
                        this.dumbappError = err;
                    }
                }
                else {
                    this.dumbapp = null;
                }
            },
        },
    },
    methods: {
        submit() {
            this.provider?.execution?.execute();
        },
        dumbappCompleted(submission) {
            if (this.tracked && submission.id === this.tracked.id) {
                this.$emit("completed", submission);
            }
        }
    },
    mounted() {
        let provider = this.provider;
        let id = "DumbappMixin-" + this.uid;
        provider.execution.addHandler(id, "preInput", async (state) => {
            let data = {};
            if (!state.simulation) {
                this.submitting = true;
            }
            if (this.extras) {
                data.extras = clone(this.extras);
            }
            return {
                success: true,
                data
            };
        });
        provider.execution.addHandler(id, "result", async (result) => {
            this.submitting = false;
            if (result.success === true) {
                await this.$store.dispatch("dumbapp/dumbappSubmitted", result.data);
                this.$bus.emit('dumbapp-submitted', result.data.submission);
                this.$bus.emit('dumbapp-updated', result.data.submission);
                this.$emit("submitted", result.data.submission);
                this.$store.dispatch("rolodex/saveDumbapp", result.data.submission);
                this.$gtag.event("dumbapp_submission", {
                    event_category: "dumbapps",
                    event_label: result.data.wallet.type,
                });
                if (this.trackingId) {
                    await this.$store.dispatch("dumbapp/trackSubmission", {
                        key: this.trackingId,
                        data: {
                            id: result.data.id
                        }
                    });
                }
            }
            else {
                this.$gtag.event("dumbapp_fail", {
                    event_category: "dumbapps",
                    event_label: result.state.wallet?.type || "unknown"
                });
            }
        });
        provider.execution.addHandler(id, "input", async () => {
            return {
                success: true,
                data: [
                    {
                        type: "query",
                        data: this.query,
                    },
                    {
                        type: "values",
                        data: this.values,
                    },
                ],
            };
        });
        if (this.trackingId) {
            let tracks = this.$store.getters["dumbapp/tracking"][this.trackingId];
            if (tracks?.length > 0) {
                for (let track of tracks) {
                    if (track.status !== "pending") {
                        this.$store.dispatch("dumbapp/removeTracking", {
                            key: this.trackingId,
                            id: track.id
                        });
                    }
                }
            }
        }
        this.$bus.on("dumbapp-completed", this.dumbappCompleted);
    },
    beforeDestroy() {
        this.provider.execution.removeHandler("DumbappMixin-" + this.uid);
        this.$bus.off("dumbapp-completed", this.dumbappCompleted);
    },
});
