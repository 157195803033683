import { createHttpClient } from "@/lib/api/client";
import bwTypeMap from "../../assets/data/TypeMap";
import store from "../../store";
import axios from "axios";
import RolodexApi from "@/lib/api/wallet/RolodexApi";
import { MemoryCache } from "@blockwell/cache";
import { CachedContractsApi, getApiMinerClient, } from "@blockwell/apiminer-client";
import { Dumbapp, DumbappSubmission, DumbappSubmissionData, } from "@blockwell/dumbapp";
import { LocalForageCache } from "@blockwell/cache/lib/LocalForageCache";
import { toChainId } from "@blockwell/chains";
import { addressRegex, getAddress } from "@blockwell/chain-client";
import mortice from "mortice";
import Emittery from "emittery";
import PaletteApi from "@/lib/api/wallet/PaletteApi";
import MediaApi from "@/lib/api/wallet/MediaApi";
import FiatApi from "@/lib/api/wallet/FiatApi";
import { DumbappLoader } from "@/lib/api/wallet/DumbappLoader";
import FuelApi from "@/lib/api/wallet/FuelApi";
export default class Api {
    constructor(auth) {
        this.dumbappCache = new MemoryCache(60000);
        this.contractsCache = new LocalForageCache("contracts6", 7 * 24 * 60 * 60 * 1000);
        this.contractsCacheOld = new LocalForageCache("contracts", 7 * 24 * 60 * 60 * 1000);
        this.accountHolder = { account: null };
        this.events = new Emittery();
        this.client = createHttpClient(auth);
        if (auth === "legacy") {
            this.loggedIn = () => {
                // @ts-ignore
                return !!store.state.user.auth;
            };
        }
        else if (auth) {
            this.loggedIn = () => true;
        }
        else {
            this.loggedIn = () => false;
        }
        this.dumbappLoader = new DumbappLoader(this.client);
        this.rolodex = new RolodexApi(this.client);
        this.palette = new PaletteApi(this.client, this.loggedIn());
        this.media = new MediaApi(this.client, this.loggedIn());
        this.fiat = new FiatApi(this.client, this.loggedIn());
        this.fuel = new FuelApi(this.client, this.loggedIn(), this.dumbappLoader);
        this.apiminer = getApiMinerClient(process.env.VUE_APP_APIMINER_URL);
        this.contracts = new CachedContractsApi(this.apiminer, this.contractsCache);
        this.contractsCacheOld.clean(0);
    }
    async connect() {
        if (!this.loggedIn()) {
            this.events.emit("login", true);
            return "loading-accounts";
        }
        return "accounts-loaded";
    }
    getConfig() {
        return this.client.get("api/config").then((res) => res.data);
    }
    login(email, password) {
        return this.client.post("api/auth/login", {
            email,
            password,
        });
    }
    register(email, password, referralId) {
        return this.client.post("api/auth/register", {
            email,
            password,
            referralId,
        });
    }
    forgotPassword(email) {
        return this.client.post("api/auth/forgot", { email }).then((res) => res.data);
    }
    setPassword(token, password) {
        return this.client.post("api/auth/password", {
            token,
            password,
        });
    }
    resendVerification(token) {
        return this.client.post("api/auth/verify/resend", {
            token,
        });
    }
    /**
     * Verify email address, reset password, etc.
     *
     * @param token The one-time random token in the link
     * @return {Promise<Object>}
     */
    verify(token) {
        return this.client
            .post(`api/auth/verify`, {
            token,
        })
            .then((res) => res.data);
    }
    async getAccount() {
        if (!this.loggedIn()) {
            return "";
        }
        if (!this.accountHolder.account) {
            let res = await this.getMe();
            this.accountHolder.account = res.account;
        }
        return this.accountHolder.account;
    }
    contractCall(contractId, method, args = []) {
        return this.client
            .get(`api/proxy/contracts/${contractId}/call/${method}`, {
            params: { arg: args },
        })
            .then((res) => res.data.data);
    }
    contractEvents(contractId, event) {
        return axios
            .get(`https://apiminer.blockwell.ai/contracts/${contractId}/events/${event}`)
            .then((res) => res.data.data);
    }
    batchCall(calls) {
        return this.client
            .post(`api/proxy/contracts/call`, {
            calls,
        })
            .then((res) => res.data.data);
    }
    transfer(contractId, to, value) {
        return this.client
            .post(`api/tokens/${contractId}/transfers`, {
            to,
            value,
        })
            .then((res) => res.data);
    }
    transferDirect(network, address, to, value) {
        return this.client
            .post(`api/tokens/transfers/direct`, {
            network,
            address,
            to,
            value,
        })
            .then((res) => res.data);
    }
    getAccounts() {
        return this.client.get("api/proxy/accounts").then((res) => res.data.data);
    }
    getTransactions(query = {}) {
        return this.client
            .get("api/proxy/transactions", {
            params: query,
        })
            .then((res) => res.data);
    }
    getTransaction(id) {
        return this.client.get(`api/proxy/transactions/${id}`).then((res) => res.data.data);
    }
    getTransfers(contractId, address) {
        let params;
        if (address) {
            params = { address };
        }
        return this.client
            .get(`api/proxy/tokens/${contractId}/transfers`, {
            params: params,
        })
            .then((res) => res.data.data);
    }
    getEthBalance(account, network) {
        return this.client
            .get(`api/proxy/accounts/${account}/balance/${network}`)
            .then((res) => res.data.data);
    }
    getCode(shortcode, query) {
        if (typeof query === "string") {
            return this.client.get(`${shortcode}${query}`).then((res) => res.data);
        }
        else {
            return this.client
                .get(`${shortcode}`, {
                params: query,
            })
                .then((res) => res.data);
        }
    }
    getDumbapp(shortcode) {
        return this.dumbappCache.withCache(shortcode, () => {
            return this.getCode(shortcode).then((data) => new Dumbapp(data));
        }, true);
    }
    submitDumbapp(params) {
        return this.client.post(`api/dumbapps/execute`, params).then((res) => {
            return new DumbappSubmissionData(res.data);
        });
    }
    getDumbappSubmission(id) {
        return this.client.get(`api/dumbapps/submissions/${id}`).then((res) => {
            return new DumbappSubmission(new Dumbapp(res.data.dumbapp), new DumbappSubmissionData(res.data.submission));
        });
    }
    getRecentSubmissions() {
        return this.client.get("api/dumbapps/recent").then((res) => res.data.map((it) => {
            return new DumbappSubmission(new Dumbapp(it.dumbapp), new DumbappSubmissionData(it.submission));
        }));
    }
    getSubmissionHistory(query) {
        return this.client
            .get("api/dumbapps/history", {
            params: query,
        })
            .then((res) => {
            return {
                count: res.data.count,
                data: res.data.data
                    .filter((it) => !!it.dumbapp)
                    .map((it) => {
                    return new DumbappSubmission(new Dumbapp(it.dumbapp), new DumbappSubmissionData(it.submission));
                }),
            };
        });
    }
    async addDumbappSubmission(data) {
        // Need to use a mutex so that add and update don't go out of order
        let mutex = mortice(data.id);
        let release = await mutex.writeLock();
        try {
            return await this.client.put(`api/dumbapps/submissions/${data.id}`, data);
        }
        finally {
            release();
        }
    }
    async updateDumbappSubmission(data) {
        // Need to use a mutex so that add and update don't go out of order
        let mutex = mortice(data.id);
        let release = await mutex.writeLock();
        try {
            return await this.client.put(`api/dumbapps/submissions/${data.id}`, {
                steps: data.steps,
                extras: data.extras,
            });
        }
        finally {
            release();
        }
    }
    createDumbapp(dumbapp, ticket, copiedFrom) {
        let data = {
            ...dumbapp,
            ticket
        };
        if (copiedFrom) {
            data.copiedFrom = copiedFrom;
        }
        return this.client
            .post(`v2/dumbapps`, data)
            .then((res) => res.data.data.shortcode);
    }
    getCodeTransaction(id) {
        return this.client.get(`api/transactions/${id}`).then((res) => res.data);
    }
    getAllCodes(query = {}) {
        return this.client
            .get("api/qr/codes/search", {
            params: query,
        })
            .then((res) => res.data);
    }
    getDeployedContract(network, address) {
        return this.client
            .post("api/deploy", {
            network,
            address,
        })
            .then((res) => res.data);
    }
    getDeployedHistory() {
        return this.client
            .get("api/dumbapps/history/deployed")
            .then((res) => res.data);
    }
    async getTokenData(contractId) {
        let result = (await this.contracts.getAll([contractId]))[0];
        if (!result) {
            return null;
        }
        if ("wallet" in result) {
            return null;
        }
        return {
            ...result,
            symbol: result.parameters?.symbol,
            decimals: result.parameters?.decimals,
        };
    }
    async getContractId(address, network) {
        console.log('getCOntractId', address, network);
        if (network) {
            let res = await this.contracts.getAll([
                {
                    address,
                    chainId: network,
                },
            ]);
            console.log("res", res);
            return res[0];
        }
        if (address.length === 36) {
            // UUID, treat it as such
            return this.contracts.getAll([address]).then((it) => it[0]);
        }
        let res = await this.contracts.findByAddress(address);
        return res[0];
    }
    async loadContract(address) {
        if (address.length === 36) {
            // UUID, treat it as such
            return this.contracts.getAll([address]);
        }
        let data = await this.contracts.findByAddress(address);
        if (data.length === 0) {
            throw new Error("Contract not found");
        }
        return data;
    }
    getContract(contractId) {
        return this.contracts.getAll([contractId]).then((it) => it[0]);
    }
    async getContractAbi(contractId) {
        let contract = await this.getContract(contractId);
        if (contract && "abi" in contract) {
            return contract.abi;
        }
        return null;
    }
    getMe() {
        return this.client.get("api/users/me").then((res) => res.data);
    }
    getReferralId() {
        return this.client.get("api/users/me/referral").then((res) => res.data.referralId);
    }
    getApiKeys() {
        return this.client.get(`api/users/me/apikeys`).then((res) => res.data);
    }
    createApiKey() {
        return this.client.post(`api/users/me/apikeys`).then((res) => res.data);
    }
    tokenSearch(text) {
        return this.client
            .get("api/contrib/search", {
            params: {
                s: text,
            },
        })
            .then((res) => res.data);
    }
    contractSearch(text, features, network) {
        let params = { text };
        if (features) {
            params.feature = features;
        }
        if (network) {
            params.network = network;
        }
        return this.client
            .get("api/proxy/contracts", {
            params,
        })
            .then((res) => res.data.data);
    }
    contribData(address) {
        return this.client.get(`api/contrib/${address}`).then((res) => res.data);
    }
    submitContrib(contract, amount, email) {
        let data = {
            contract,
            amount,
        };
        if (email) {
            data.email = email;
        }
        return this.client.post(`api/contrib`, data).then((res) => res.data);
    }
    loadPalette(name, contractId) {
        return this.client.get(`api/palette/${name}/${contractId}`).then((res) => res.data);
    }
    getProposals(contractId) {
        return this.client
            .get(`api/voting/${contractId}/proposals`)
            .then((res) => res.data.suggestions);
    }
    getExternalDescription() {
        return this.client.get(`api/external/description`).then((res) => res.data);
    }
    getExternalAccounts() {
        return this.client.get(`api/external`).then((res) => res.data);
    }
    newExternalAccount(address, name) {
        let data = {
            address,
        };
        if (name) {
            data.name = name;
        }
        return this.client.post(`api/external`, data).then((res) => res.data);
    }
    setKycDetails(details) {
        return this.client.post("api/users/me/details", details).then((res) => res.data);
    }
    getKycStatus() {
        return this.client.get(`api/users/me/kyc`).then((res) => res.data);
    }
    verifyEmail() {
        return this.client.post(`api/users/me/verifyemail`).then((res) => res.data);
    }
    getOrganization(organization) {
        return this.client.get(`api/organizations/${organization}`).then((res) => res.data);
    }
    addOrganization(organization) {
        return this.client.post(`api/users/me/kyc/${organization}`).then((res) => res.data);
    }
    denyOrganization(organization) {
        return this.client.post(`api/users/me/kyc/${organization}/deny`).then((res) => res.data);
    }
    allowOrganization(organization) {
        return this.client.post(`api/users/me/kyc/${organization}/allow`).then((res) => res.data);
    }
    getStatus() {
        return this.client
            .get("api/status")
            .then((res) => res.data)
            .catch((err) => {
            console.dir(err);
            return {
                status: "api_error",
                message: `There was a problem contacting the server. If this issue persists, contact us at blockwell@blockwell.ai. (` +
                    err.message +
                    " - " +
                    (err?.response?.status || "0") +
                    " - " +
                    err.config?.baseURL +
                    err.config?.url +
                    ")",
            };
        });
    }
    getMessages(page) {
        let params = {};
        if (page) {
            params.page = page;
        }
        return this.client
            .get(`api/messages`, {
            params,
        })
            .then((res) => res.data);
    }
    readMessages(timestamp, page) {
        let params = {};
        if (page) {
            params.page = page;
        }
        return this.client
            .post(`api/messages/read`, { timestamp }, { params })
            .then((res) => res.data);
    }
    dismissMessage(id) {
        return this.client.post(`api/messages/${id}/dismiss`).then((res) => res.data);
    }
    testMessage(type, content, dismissable = true) {
        return this.client
            .post(`api/messages/test`, {
            type,
            content,
            dismissable,
        })
            .then((res) => res.data);
    }
    getLottoCodes(contractId) {
        return this.getDumbapps(`api/lotto/${contractId}/dumbapps`);
    }
    lottoAddNftDumbapp(nftId) {
        return this.client.get(`api/lotto/nft/add/${nftId}`).then((res) => {
            return new Dumbapp(res.data);
        });
    }
    lottoWithdrawNftDumbapp(poolId) {
        return this.client.get(`api/lotto/nft/remove/${poolId}`).then((res) => {
            return new Dumbapp(res.data);
        });
    }
    getDaicoCodes(contractId) {
        return this.getDumbapps("daico", contractId);
    }
    getBookCodes(contractId) {
        return this.getDumbapps("book", contractId);
    }
    getRefundCodes(contractId) {
        return this.getDumbapps("refunds", contractId);
    }
    getSmartLicenseCodes(contractId) {
        return this.getDumbapps("smart", contractId);
    }
    getSmartLicenseTokenDumbapp(contractId, tokenAddress) {
        return this.client
            .get(`api/qr/smart/token/${contractId}/${tokenAddress}`)
            .then((res) => new Dumbapp(res.data));
    }
    getCoreDumbapps(contractId) {
        return this.getDumbapps("core", contractId);
    }
    getFreelanceCreateDumbapp(contractId, tokenAddress) {
        return this.client
            .get(`api/qr/core/freelance/create/${contractId}/${tokenAddress}`)
            .then((res) => new Dumbapp(res.data));
    }
    getCoreData(contractId) {
        return this.client
            .get(`api/core/data`, {
            params: {
                contractId: contractId.join(","),
            },
        })
            .then((res) => {
            let data = res.data;
            for (let it of data) {
                for (let [key, val] of Object.entries(it.codes)) {
                    it.codes[key] = new Dumbapp(val);
                }
            }
            return data;
        });
    }
    getNftDumbapps(contractId) {
        return this.getDumbapps(`api/nft/${contractId}/dumbapps`);
    }
    addNftMetadata(contractId, tokenId, name, description, image, attributes) {
        let data = { name };
        if (description) {
            data.description = description;
        }
        if (image) {
            data.image = image;
        }
        if (attributes) {
            data.attributes = attributes;
        }
        return this.client
            .put(`api/nft/${contractId}/token/${tokenId}`, data)
            .then((it) => process.env.VUE_APP_API_URL + `api/nft/${contractId}/token/${tokenId}`);
    }
    getHourglassDumbapps(contractId) {
        return this.getDumbapps(`api/hourglass/${contractId}/dumbapps`);
    }
    getDumbapps(typeOrUrl, contractId) {
        let url;
        if (contractId) {
            url = `api/qr/${typeOrUrl}/codes/${contractId}`;
        }
        else {
            url = typeOrUrl;
        }
        return this.dumbappCache.withCache(url, () => {
            return this.client.get(url).then((res) => {
                let dumbapps = {};
                for (let [key, val] of Object.entries(res.data)) {
                    if (val) {
                        dumbapps[key] = new Dumbapp(val);
                    }
                }
                return dumbapps;
            });
        }, true);
    }
    exception(code, err, data = {}) {
        let stack = null;
        let hash = null;
        this.client
            .post(`api/error`, {
            code,
            message: err.message,
            stack: stack,
            url: window.location.pathname,
            hash,
            data,
        })
            .catch(console.error);
    }
    tryCatch(code, func, onError) {
        try {
            return func();
        }
        catch (err) {
            this.exception(code, err, getErrorMetadata(err));
            if (onError) {
                return onError(err);
            }
            else {
                return null;
            }
        }
    }
    tryPromise(code, func) {
        return func().catch((err) => {
            this.exception(code, err, getErrorMetadata(err));
            throw err;
        });
    }
    addToContractHistory(link, title, address, network) {
        if (this.loggedIn()) {
            return this.client.post("api/users/me/history", {
                link,
                title,
                address,
                network,
            });
        }
    }
    getContractHistory() {
        return this.client.get("api/users/me/history").then((res) => res.data);
    }
    tokenList() {
        return this.client.get("api/users/me/tokens").then((res) => res.data);
    }
    addToken(network, address, name, symbol, decimals) {
        return this.client
            .post("api/users/me/tokens", {
            network,
            address,
            name,
            symbol,
            decimals,
        })
            .then((res) => res.data);
    }
    hideToken(network, address) {
        return this.client
            .delete(`api/users/me/tokens/${network}/${address}`)
            .then((res) => res.data);
    }
    async getTokenForDaico(contractId) {
        const storageKey = `daico.token.${contractId}`;
        let data;
        try {
            data = JSON.parse(localStorage.getItem(storageKey));
        }
        catch (err) {
            console.error(err);
        }
        if (!data) {
            let contract = await this.getTokenData(contractId);
            let tokenAddress = await this.contractCall(contractId, "token");
            let tokenId = (await this.getContractId(tokenAddress, contract.network)).id;
            let token = await this.getTokenData(tokenId);
            if (token.id) {
                if (storageKey) {
                    localStorage.setItem(storageKey, JSON.stringify(token));
                }
                return token;
            }
            else {
                return null;
            }
        }
        else {
            return data;
        }
    }
    async getBwType(contractId) {
        const storageKey = `bwtype.${contractId}`;
        let type;
        try {
            type = localStorage.getItem(storageKey);
        }
        catch (err) {
            console.error(err);
        }
        if (!type) {
            type = await this.contractCall(contractId, "bwtype");
            if (type) {
                localStorage.setItem(storageKey, type);
                return bwTypeMap[type];
            }
            else {
                return null;
            }
        }
        else {
            return bwTypeMap[type];
        }
    }
    getEncryptionKey(tokenId, params = {}) {
        return this.client
            .post(`api/encryption/tokens/${tokenId}`, params)
            .then((res) => res.data?.key);
    }
    getContractState(contractId) {
        if (!this.loggedIn()) {
            return Promise.resolve(null);
        }
        return this.client.get(`api/users/me/contract/${contractId}`).then((res) => res.data);
    }
    saveContractState(contractId, state) {
        return this.client
            .put(`api/users/me/contract/${contractId}`, state)
            .then((res) => res.data);
    }
    getEmojicryptionSettings() {
        return this.client.get("api/encryption/emojicryption").then((res) => res.data);
    }
    saveEmojicryptionSettings(data) {
        return this.client.put("api/encryption/emojicryption", data);
    }
    getEmojicryptionForEmail(email, address) {
        return this.client
            .post("api/encryption/emojicryption/email", { email, address })
            .then((res) => res.data);
    }
    testnetQex(account, network, asset, amount) {
        return this.client
            .post("api/testnet/qex", {
            account,
            network,
            asset,
            amount,
        })
            .then((res) => res.data);
    }
    getVapidKey() {
        return this.client.get("api/push/key").then((res) => res.data);
    }
    registerPush(subscription) {
        return this.client.post("api/push/register", { subscription }).then((res) => res.data);
    }
    unregisterPush(subscription) {
        return this.client.post("api/push/unregister", { subscription }).then((res) => res.data);
    }
    uniswapBuyDumbapp(contractId) {
        return this.client
            .get(`api/qr/uniswap/buy/${contractId}`)
            .then((res) => new Dumbapp(res.data));
    }
    contribAccount(reference) {
        return this.client.get(`api/contrib/account/${reference}`).then((res) => res.data);
    }
    createContribAccount(email) {
        return this.client.post(`api/contrib/account`, { email }).then((res) => res.data);
    }
    contribWalletLoaded(reference, address) {
        return this.client.post(`api/contrib/wallet/${reference}`, {
            address,
        });
    }
    offerDumbapp(address) {
        return this.client
            .get(`api/qr/offer/${address}`)
            .then((res) => (res.data.shortcode ? new Dumbapp(res.data) : null));
    }
    offerReceipt(reference, token, tokenAmount, tokenSymbol, tokenValue, prideValue, from) {
        this.client
            .post(`api/contrib/receipt/${reference}`, {
            token,
            tokenAmount,
            tokenSymbol,
            tokenValue,
            prideValue,
            from,
        })
            .catch(console.error);
    }
    primeSubmission(data) {
        return this.client.post("api/tokens/prime", data);
    }
    getDeposit(reference) {
        return this.client.get(`api/tokens/deposit/${reference}`).then((res) => res.data);
    }
    getTutorialState(id) {
        if (!this.loggedIn()) {
            return Promise.resolve(null);
        }
        return this.client.get(`api/tutorials/${id}`).then((res) => res.data);
    }
    updateTutorial(id, status, rewardShown = false) {
        if (!this.loggedIn()) {
            return Promise.resolve();
        }
        let data = { rewardShown };
        if (status) {
            data.status = status;
        }
        return this.client.put(`api/tutorials/${id}`, data);
    }
    locksDumbapps(address, network, stakedAddress, stakedNetwork) {
        let params = {
            address,
            network,
        };
        if (stakedAddress) {
            params.stakedAddress = stakedAddress;
            params.stakedNetwork = stakedNetwork;
        }
        return this.client
            .get(`api/locks/dumbapps`, { params })
            .then((res) => {
            let dumbapps = {};
            for (let [key, val] of Object.entries(res.data)) {
                if (val) {
                    dumbapps[key] = new Dumbapp(val);
                }
            }
            return dumbapps;
        });
    }
    buyFuelDumbapp(contractId) {
        return this.client.get(`api/qr/fuel/buy/${contractId}`).then((res) => {
            return new Dumbapp(res.data);
        });
    }
    swapperDumbapp(contractId, mainToken, token) {
        return this.client
            .get(`api/qr/swapper/${contractId}/${mainToken}/${token}`)
            .then((res) => {
            return new Dumbapp(res.data);
        });
    }
    greetingDumbapp(contractId, tokenId) {
        return this.client
            .get(`api/qr/greeting/${contractId}/${tokenId}`)
            .then((res) => {
            return new Dumbapp(res.data);
        });
    }
    marketDumbapp(contractId, tokenId) {
        let key = `${contractId}-${tokenId}-trade`;
        return this.dumbappCache.withCache(key, () => {
            return this.client
                .get(`api/qr/market/${contractId}/buy/${tokenId}`)
                .then((res) => {
                return new Dumbapp(res.data);
            });
        }, key);
    }
    marketListDumbapp(contractId, nftId) {
        let key = `${contractId}-${nftId}-list`;
        return this.dumbappCache.withCache(key, () => {
            return this.client
                .get(`api/qr/market/${contractId}/list/${nftId}`)
                .then((res) => {
                return new Dumbapp(res.data);
            });
        }, key);
    }
    multichainDumbapps(tokens) {
        return this.client
            .post(`api/multichain/dumbapps`, { tokens })
            .then((res) => {
            let dumbapps = {};
            for (let [key, val] of Object.entries(res.data)) {
                if (val) {
                    dumbapps[key] = new Dumbapp(val);
                }
            }
            return dumbapps;
        });
    }
    multichainStakeDumbapp(from, to, time) {
        return this.client
            .post(`api/multichain/dumbapps/stake`, { from, to, time })
            .then((res) => new Dumbapp(res.data));
    }
    voteDumbapps(contractId) {
        return this.getDumbapps(`api/voting/${contractId}/dumbapps`);
    }
    emailSignup(email) {
        return this.client.post("api/auth/email", { email });
    }
    pendingSwaps(account) {
        return this.client
            .get(`v2/swap/pending/${account}`)
            .then((res) => res.data.data);
    }
    signPendingSwap(swapId) {
        return this.client
            .post(`v2/swap/sign/${swapId}`)
            .then((res) => res.data.data);
    }
    nftStrictSwapDumbapp() {
        let key = `nft-strict-swap`;
        return this.dumbappCache.withCache(key, () => {
            return this.client.get(`api/qr/swap/signed/nft`).then((res) => {
                return new Dumbapp(res.data);
            });
        }, true);
    }
    swapNftDumbapp() {
        let key = `swap-nft`;
        return this.dumbappCache.withCache(key, () => {
            return this.client.get(`api/qr/swap/nft`).then((res) => {
                return new Dumbapp(res.data);
            });
        }, true);
    }
    signedSwapDumbapp() {
        let key = `signed-swap`;
        return this.dumbappCache.withCache(key, () => {
            return this.client.get(`api/qr/swap/signed/prime`).then((res) => {
                return new Dumbapp(res.data);
            });
        }, true);
    }
}
function getErrorMetadata(err) {
    let data = {};
    if ("config" in err) {
        data.url = err.config.url;
        data.method = err.config.method;
    }
    if ("response" in err) {
        data.response = err.response.data;
        data.status = err.response.status;
    }
    return data;
}
export function tokenLogo(address, network = 1) {
    if (!address) {
        return "";
    }
    let chainId = toChainId(network);
    if (address === "ether" ||
        address === "0x0" ||
        address === "0x0000000000000000000000000000000000000000") {
        if (chainId === 56 || chainId === 97) {
            return require("@/assets/images/logos/bnb.png");
        }
        else if (chainId === 2020) {
            return require("@/assets/images/logos/ronin.png");
        }
        else {
            return require("@/assets/images/ethereum.png");
        }
    }
    if (address === "pride") {
        return require("@/assets/images/tutor.png");
    }
    if (address === "fuel") {
        return require("@/assets/images/icons/fire.svg");
    }
    if (chainId === 1 && addressRegex.test(address)) {
        if (address.toLowerCase() === "0xdef1da03061ddd2a5ef6c59220c135dec623116d") {
            return "https://s2.coinmarketcap.com/static/img/coins/64x64/8845.png";
        }
        return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${getAddress(address)}/logo.png`;
    }
    if (chainId === 5 && address.toLowerCase() === "0x555555555130790a502c3c95e9c26633c10ae000") {
        return require("@/assets/images/tutor.png");
    }
    return "";
}
