<template>
    <div v-if="available" class="token-balance-block dumbapp-block">
        <div class="token-balance-heading">
            Votes Available
            <Info>
                The number of votes you can cast based on your staked tokens minus the number of votes you've used recently.
            </Info>
        </div>
        <TokenAmount :value="available" :decimals="context.contract.parameters.decimals" />
    </div>
</template>

<script>
import { BlockContext } from "@/components/dumbapp/block/Block";
import Info from "@/components/Info";
import TokenAmount from "@/components/TokenAmount";
import { Chain } from "@blockwell/chain-client";
import BigNumber from "bignumber.js";

export default {
    name: "AvailableVotesBlock",
    components: { Info, TokenAmount },
    props: {
        context: BlockContext,
    },
    data() {
        return {
            available: null,
        };
    },
    computed: {
        balanceDeps() {
            return {
                account: this.context.account,
                contract: this.context.contract,
            };
        },
    },
    watch: {
        balanceDeps: {
            deep: true,
            immediate: true,
            handler(val) {
                let { account, contract } = val;
                if (account) {
                    Chain.readString(
                        contract.network,
                        contract.address,
                        contract.abi,
                        "votesUsedTotal",
                        [account]
                    ).then(async (used) => {
                        let stake = new BigNumber(await Chain.readString(
                            contract.network,
                            contract.address,
                            contract.abi,
                            "stakeOf",
                            [account]
                        ));

                        let available = stake.minus(used);
                        if (available.lt(0)) {
                            available = new BigNumber(0);
                        }

                        if (this.balanceDeps.account === account) {
                            this.available = available;
                        }
                    });
                }
            },
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.token-balance-heading {
    color: $secondary;
    font-style: italic;
}

.token-balance-block {
    ::v-deep( .token-symbol ) {
        color: $text-muted;
    }
}
</style>
