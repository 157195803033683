<template>
    <div class="submission-compact-step">
        <div class="step-status-icon">
            <ProgressCircle v-if="status.progress" tiny/>
            <i v-else class="material-icons" :class="status.class">{{ status.icon }}</i>
        </div>
        <div v-if="dumbappStep" class="step-text">
            <span v-if="dumbappStep.ether" class="step-ether">Send ETH on {{ networkName }}</span>
            <template v-else>
                <span class="step-contract">
                    <a v-if="happLink && happLink.startsWith('http')" :href="happLink" class="happ-link">
                        {{ contractName }}
                        <i class="material-icons">launch</i>
                    </a>
                    <router-link v-else-if="happLink" :to="happLink" class="happ-link">
                        {{ contractName }}
                        <i class="material-icons">launch</i>
                    </router-link>
                    <template v-else>
                        {{ contractName }}
                    </template>
                </span>
                <span class="step-method">{{ dumbappStep.method }}</span>
            </template>
        </div>
    </div>
</template>

<script>
import bwTypeMap from "@/assets/data/TypeMap";
import ResolveStepsMixin from "@/components/dumbapp/ResolveStepsMixin";
import ProgressCircle from "@/components/ProgressCircle";
import { getChain } from "@blockwell/chains";
import { stepStatus } from "@blockwell/dumbapp";
import { mapGetters } from "vuex";

export default {
    name: "SubmissionCompactStep",
    components: {ProgressCircle},
    inject: ["provider"],
    mixins: [ResolveStepsMixin],
    props: {
        dumbappStep: Object,
        submissionStep: Object
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters("user", ["api"]),
        index() {
            return this.dumbappStep.index;
        },
        contractName() {
            return this.stepContract?.name || this.dumbappStep?.contractName || this.stepContract?.contractName;
        },
        status() {
            return stepStatus(this.submissionStep);
        },
        networkName() {
            if (this.submissionStep && this.submissionStep.network) {
                return getChain(this.submissionStep.network).networkName;
            }
            return "";
        },
        bwType() {
            if (this.stepContract?.bwtype) {
                return bwTypeMap[this.stepContract.bwtype];
            }
            return null;
        },
        happLink() {
            if (this.stepContract?.id === "be787228-ae6c-4da4-8d1f-48ee7b226aa0") {
                return "https://vote.defactor.com";
            }
            if (this.stepContract?.id === "66b834bb-c8bc-4bef-86ca-aeced9c22d12") {
                return "https://app.blockwell.ai/suggestions/testr";
            }
            if (this.stepContract?.id === "00d5bdc0-98a8-4d8d-95e7-3e57e15f4daf") {
                return "https://app.blockwell.ai/suggestions/lock-test5";
            }
            if (this.bwType?.happ) {
                return `/${this.bwType.happ}?contract=${this.stepAddress}&net=${this.stepNetwork.alias}`;
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.submission-compact-step {
    display: flex;
    align-items: center;
    padding: 7px;
    border-left: 4px solid;

    .step-status-icon {
        flex: 0 0 30px;
        text-align: center;

        .material-icons.failed {
            color: $danger;
        }

        .material-icons.confirmed {
            color: $success;
        }

        .material-icons.unknown {
            color: $warning;
        }
    }

    .step-method {
        font-style: italic;
        margin-left: 5px;
        color: $code-color;
    }

    .step-ether {
        font-style: italic;
        color: $text-muted;
    }
}

.happ-link {
    color: $dark;
    .material-icons {
        color: $primary;
    }
}
</style>
