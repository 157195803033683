<template>
    <Modal :show.sync="showing">
        <FullRolodex v-if="showing" :request-id="requestId" @select="$emit('select', $event)" />
    </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import FullRolodex from "@/components/rolodex/FullRolodex";

export default {
    name: "RolodexModal",
    components: { FullRolodex, Modal },
    props: {
        show: Boolean,
        requestId: String,
    },
    data() {
        return {
            showing: false,
        };
    },
    watch: {
        showing(val) {
            this.$emit("update:show", val);
        },
        show(val) {
            if (!val) {
                this.close();
            } else {
                this.open();
            }
        },
        saveDisabled(val, old) {
            if (!val && old && this.saved) {
                this.saved = false;
            }
        },
    },
    methods: {
        open() {
            this.showing = true;
        },
        close() {
            this.showing = false;
        },
    },
    mounted() {
        if (this.show) {
            this.open();
        }
    },
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";
</style>
