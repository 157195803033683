<template>
    <div>
        <strong>Not connected to MetaMask, connect to continue.</strong>
        <div v-if="connecting">
            MetaMask should be asking you to connect. If not,
            <a href="javascript: void 0;" @click="connect">Click Here</a> to try again.
        </div>
        <div v-else-if="!!ethereum && !account">
            <button type="button" class="btn btn-sm btn-metamask" @click="connect">Connect to MetaMask</button>
        </div>
    </div>
</template>

<script>
import Eip1193Mixin from "@/components/dumbapp/executor/Eip1193Mixin";
import { getChain } from "@blockwell/chains";
import { DumbappSubmission } from "@blockwell/dumbapp";

export default {
    name: "MetamaskWrongNetwork",
    mixins: [Eip1193Mixin],
    props: {
        submission: DumbappSubmission,
        network: [String, Number]
    },
    computed: {
        networkName() {
            let net = getChain(this.network);
            return net.networkName;
        }
    },
    code: "not_connected",
    executor: "metamask"
}
</script>

<style scoped>

</style>
