
export function getPage(path) {
    switch (path) {
        case '/app/wallet':
            return 'wallet';
        case '/app/account/external':
        case '/app/account/kyc/external':
            return 'external';
    }

    return null;
}

export function isEmbed() {
    return window.location.pathname === "/embed" || window.location.pathname.startsWith("/embed/");
}

export function isStakingWallet(name, domain) {
    if (window.location.hostname.toLowerCase() === `${name.toLowerCase()}.${process.env.VUE_APP_COOKIE_DOMAIN}`) {
        return true;
    }
    if (domain && window.location.hostname.toLowerCase() === domain.toLowerCase()) {
        return true;
    }
    return false;
}
