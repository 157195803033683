import { MemoryCache } from "@blockwell/cache";
import BigNumber from "bignumber.js";
import { createUrlBuilder } from "@/lib/api/wallet/util";
const url = createUrlBuilder("v2/fuel");
export default class FuelApi {
    constructor(client, loggedIn, dumbapps) {
        this.client = client;
        this.loggedIn = loggedIn;
        this.dumbapps = dumbapps;
        this.cache = new MemoryCache(3000);
    }
    getDumbapps() {
        return this.dumbapps.getMap(url("dumbapps"));
    }
    getStatus() {
        return this.cache.withCache("status", () => {
            return this.client.get(url("status")).then((res) => {
                let status = res.data.data;
                status.holdTotal = new BigNumber(0);
                for (let it of status.holds) {
                    status.holdTotal = status.holdTotal.plus(it.fuel);
                }
                return status;
            });
        }, true);
    }
    getHistory(start, limit) {
        return this.client
            .get(url("history"), {
            params: {
                start,
                limit,
            },
        })
            .then((res) => res.data);
    }
    getProduct(product) {
        return this.cache.withCache(`product-${product}`, () => {
            return this.client
                .get(url("products", product))
                .then((res) => res.data.data);
        }, false, 30000);
    }
    getTickets() {
        return this.client
            .get(url("tickets"))
            .then((res) => res.data);
    }
    buyTicket(product) {
        return this.client
            .post(url("products", product, "ticket"))
            .then((res) => res.data.data);
    }
    requestTrial() {
        return this.client.post(url("trial")).then((res) => res.data.data);
    }
    subscriptions() {
        return this.client
            .get(url("subscriptions"))
            .then((res) => res.data);
    }
    findSubscription(productId, reference) {
        let parts = ["subscriptions", "find", productId];
        if (reference) {
            parts.push(reference);
        }
        return this.client
            .get(url(...parts))
            .then((res) => res.data.data);
    }
    cancelSubscription(subscriptionId) {
        return this.client
            .post(url("subscriptions", subscriptionId, "cancel"))
            .then((res) => res.data.data);
    }
    restartSubscription(subscriptionId) {
        return this.client
            .post(url("subscriptions", subscriptionId, "restart"))
            .then((res) => res.data.data);
    }
    getSubscription(subscriptionId) {
        return this.client
            .get(url("subscriptions", subscriptionId))
            .then((res) => res.data.data);
    }
}
