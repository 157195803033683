<template>
    <div class="mainbody embedbody">
        <router-view></router-view>

        <RolodexModals/>
        <Modal :show="!!loginRequest" @update:show="loginRequestShow" fullscreen>
            <template #header>
                <h3>Sign In</h3>
            </template>
            <div v-if="loginRequest">
                <p>{{ loginRequest.message }}</p>
                <LoginForm @login="loginRequestEnd"/>
            </div>
        </Modal>
    </div>
</template>

<script>
import LoginForm from "@/components/LoginForm";
import Modal from "@/components/Modal";
import RolodexModals from "@/components/RolodexModals";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import * as interval from '../lib/interval';
import store from '../store/index';
import 'bootstrap';

export default {
    name: "Embed",
    components: {
        RolodexModals,
        LoginForm,
        Modal
    },
    store,
    data() {
        return {
            interval: null,
            loginRequest: null
        }
    },
    computed: {
        ...mapGetters('user', ['loggedIn', 'api']),
        ...mapState('user', ['email'])
    },
    methods: {
        requestLogin(data) {
            this.loginRequest = data;
        },
        loginRequestEnd() {
            this.loginRequest = null;
        },
        loginRequestShow(shown) {
            if (!shown) {
                this.loginRequestEnd();
            }
        },
        clear() {
            if (this.interval !== null) {
                this.interval.clear();
                this.interval = null;
            }
            this.$bus.off('login_request', this.requestLogin)
        }
    },
    created() {
        this.interval = interval.every(() => {
            store.dispatch('updateStatus');
        }, 10000);
        this.$bus.on('login_request', this.requestLogin)
    },
    destroyed() {
        this.clear();
    }
}
</script>

<style lang="scss">
@import "../assets/css/main";
@import "../assets/css/forms";

.embedbody {
    background: #fff;
    padding: 15px;
}

.user-links {
    padding: 0 20px;
    position: relative;

    & > .nav-dropdown {
        margin-right: 8px;
    }
}
</style>
