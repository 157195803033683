import Modal from "@/components/Modal";
import { getNetwork } from "../../assets/lib/networks";
import Info from "../Info.vue";
import LoadingButton from "../LoadingButton.vue";
import DataValue from "../DataValue.vue";
import InfoField from "../../views/happs/locks/InfoField.vue";
import TypeMap from "../../assets/data/TypeMap";
export default {
    name: "RolodexAddressModal",
    components: { InfoField, DataValue, LoadingButton, Info, Modal },
    props: {
        show: Boolean,
        hideFullButton: Boolean,
        entryId: String,
    },
    data() {
        return {
            showing: false,
            alias: this.entry?.alias || "",
            saved: false,
            tags: "",
            contract: null
        };
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        },
        entry() {
            let entries = this.$store.getters["rolodex/addressCards"];
            return entries.find((it) => it.id === this.entryId);
        },
        explorerLink() {
            if (this.entry?.type === "contract" && this.entry.chainId) {
                return getNetwork(this.entry.chainId).explorerAddress(this.entry.address);
            }
            return getNetwork(1).explorerAddress(this.entry.address);
        },
        saveDisabled() {
            return this.alias === this.entry?.alias && this.tags === (this.entry?.tags?.join(" ") || "");
        },
        networkName() {
            if (this.contract) {
                return getNetwork(this.contract.network).networkName;
            }
            return null;
        },
        bwtype() {
            if (this.contract?.bwtype) {
                return TypeMap[this.contract.bwtype];
            }
            return null;
        },
    },
    watch: {
        entry: {
            immediate: true,
            deep: true,
            async handler(val) {
                console.log("watch entry", val);
                if (val) {
                    this.alias = val.alias;
                    this.tags = val.tags?.join(" ");
                    if (val.type === "contract") {
                        this.contract = await this.api.contracts.getOne({
                            chainId: val.chainId,
                            address: val.address
                        });
                    }
                    else {
                        this.contract = null;
                    }
                }
            },
        },
        showing(val) {
            this.saved = false;
            this.$emit("update:show", val);
        },
        show(val) {
            if (!val) {
                this.close();
            }
            else {
                this.open();
            }
        },
        saveDisabled(val, old) {
            if (!val && old && this.saved) {
                this.saved = false;
            }
        },
    },
    methods: {
        open() {
            this.showing = true;
        },
        close() {
            this.showing = false;
        },
        submit() {
            if (!this.saveDisabled) {
                this.$store.dispatch("rolodex/setAlias", {
                    payload: this.entry,
                    alias: this.alias,
                    tags: this.tags ? this.tags.split(" ").filter(it => !!it) : []
                });
                this.saved = true;
            }
        },
        openFull() {
            this.close();
            this.$bus.emit("rolodex_request");
        },
    },
    mounted() {
        if (this.show) {
            this.open();
        }
    },
};
