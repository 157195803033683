<template>
    <header class="dumbapp-title" @click="$emit('click')">
        <h5>
            <router-link :to="link">{{ title }}</router-link>
        </h5>
        <span class="dumbapp-time">{{ time }}</span>
        <span v-if="expandable" v-visible="!expanded" class="expand-info">click to expand</span>
    </header>
</template>

<script>
import * as interval from "@/lib/interval";
import { DumbappSubmission } from "@blockwell/dumbapp";
import moment from "moment";

export default {
    name: "SubmissionHeader",
    props: {
        submission: DumbappSubmission,
        expanded: {
            type: Boolean,
            default: false
        },
        expandable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            now: moment()
        };
    },
    computed: {
        title() {
            return this.submission?.dumbapp?.getTitle();
        },
        timestamp() {
            return this.submission?.data?.meta?.created;
        },
        time() {
            if (this.timestamp) {
                let t = moment(this.timestamp);
                let yesterday = this.now.subtract(1, 'day');
                if (t.isBefore(yesterday)) {
                    return t.format('MMM D, h:mm a');
                } else {
                    return t.fromNow();
                }
            }
            return "";
        },
        link() {
            let shortcode = this.submission?.dumbapp?.shortcode;

            if (shortcode) {
                return `/${shortcode}`;
            }
            return "";
        }
    },
    methods: {
        clear() {
            if (this.interval !== null) {
                this.interval.clear();
                this.interval = null;
            }
        }
    },
    created() {
        this.interval = interval.every(() => {
            this.now = moment();
        }, 1000);
    },
    destroyed() {
        this.clear();
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.dumbapp-title {
    font-size: 18px;
    padding: 12px 6px 12px 19px;
    display: flex;
    align-items: baseline;

    @media screen and (max-width: 500px) {
        font-size: 16px;
    }
    @media screen and (max-width: 400px) {
        font-size: 13px;
    }

    ::v-deep( h5 ) {
        margin: 0;
        font-weight: 400;
        font-size: 1em;


    }

    .dumbapp-time {
        font-size: 0.9em;
        margin-left: 10px;
        color: $text-muted;
        flex: 1 1 auto;
        line-height: 1.2;
    }

    .expand-info {
        margin-right: 15px;
        font-size: 0.75em;
        color: $text-muted;
        transition: visibility 0.3s, opacity 0.3s;
        white-space: nowrap;
    }
}
</style>
