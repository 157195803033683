var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FormulateInput',_vm._g({attrs:{"name":_vm.field.name,"type":"checkbox","id":_vm.id,"validation":"present","validation-rules":{
        present: ({getFormValues}) => {
            let vals = getFormValues();
            let value = vals[_vm.field.name];
            return value !== undefined && value !== null;
        }
    },"validation-messages":{
        present: 'Must be checked or unchecked.'
    }},on:{"input":_vm.input},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("label")]},proxy:true},{key:"help",fn:function(){return [_vm._t("help")]},proxy:true}],null,true)},_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }