import abi from "@/assets/data/BaseBook.abi.json";
import { Chain } from "@blockwell/chain-client";
export async function loadAppList() {
    let raw = await Chain.readString(5, "0xaEEeBd6ca46BEf69689F043681fEe5F9961D199A", abi, "get", ["Apps", "data"]);
    let list = JSON.parse(raw);
    for (let it of list) {
        if (!/^https?:\/\//.test(it.logo)) {
            it.logo = `/images/icons/${it.logo}`;
        }
        if (it.url === "/rks1rq") {
            it.url = "/primed";
        }
    }
    return list;
}
export async function randomBlockwellApp() {
    let list = await loadAppList();
    let index = Math.floor(Math.random() * list.length);
    return list[index];
}
