import { FuelData, loadFuel } from "@/lib/fuel";
import { mapGetters, mapState } from "vuex";
import { every } from "@/lib/interval";
import BigNumber from "bignumber.js";
export default {
    data() {
        return {
            fuel: new FuelData(),
            status: null,
            interval: null,
        };
    },
    computed: {
        ...mapState("user", ["account"]),
        ...mapGetters("user", ["loggedIn", "api", "batches"]),
        balance() {
            let bal = new BigNumber(this.fuel.stake);
            if (this.status) {
                bal = bal.minus(this.status.holdTotal);
            }
            return bal;
        },
        balanceWhole() {
            return this.balance.dp(0, BigNumber.ROUND_FLOOR).toFormat();
        },
        balanceDecimals() {
            let decimals = this.balance.mod(1);
            if (decimals.gt(0)) {
                return decimals.toString(10).slice(2, 4);
            }
            return null;
        },
    },
    watch: {
        "status.trial": {
            handler(val, old) {
                if (old === "granting" && val === "used") {
                    this.loadData(true);
                }
            }
        }
    },
    methods: {
        loadData(skipCache = false) {
            const api = this.api.fuel;
            if (this.account) {
                Promise.all([loadFuel(this.api, this.batches, this.account, skipCache), api.getStatus()])
                    .then(([fuel, status]) => {
                    this.fuel = fuel;
                    this.status = status;
                })
                    .catch(console.error);
            }
        },
    },
    created() {
        this.interval = every(() => {
            this.loadData();
        }, 4000);
    },
    beforeDestroy() {
        this.interval?.clear();
    },
};
