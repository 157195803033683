import Clipboard from "@/components/Clipboard.vue";
import MetamaskWalletView from "@/components/dumbapp/submit/MetamaskWalletView.vue";
import BlockwellWalletView from "@/components/dumbapp/submit/BlockwellWalletView.vue";
import LoadingButton from "@/components/LoadingButton.vue";
import Modal from "@/components/Modal.vue";
import { getChain } from "@blockwell/chains";
import { mapGetters, mapMutations, mapState } from "vuex";
import Vue from "vue";
import { allSubmitProps } from "@/components/dumbapp/submit/SubmitMixin";
import { walletProvider } from "@/components/dumbapp/init";
const walletTypes = [
    {
        type: "wallet",
        name: "Blockwell Wallet",
        component: BlockwellWalletView,
        description: "Flexible blockchain wallet by Blockwell.",
        logo: require("@/assets/images/lion.png"),
    },
    {
        type: "metamask",
        name: "MetaMask",
        component: MetamaskWalletView,
        description: "Browser extension crypto wallet.",
        logo: require("@/assets/images/logos/metamask-fox.svg"),
    },
];
const walletTypesMap = Object.fromEntries(walletTypes.map((it) => [it.type, it]));
export default Vue.extend({
    name: "DumbappWallet",
    components: { Clipboard, Modal, LoadingButton, BlockwellWalletView, MetamaskWalletView },
    inject: {
        provider: {
            default: null
        },
        forceWalletType: {
            default: null,
        },
    },
    provide() {
        return {
            walletHolder: this.walletHolder,
        };
    },
    props: allSubmitProps,
    data() {
        return {
            walletHolder: {
                wallet: null,
                account: null,
                chainId: null,
                state: null,
            },
            showWalletSelect: false,
            types: walletTypes,
            typeChanged: false,
        };
    },
    computed: {
        ...mapState("dumbapp", ["walletType"]),
        ...mapGetters("user", ["loggedIn", "executorParameters"]),
        account() {
            return this.walletHolder.account;
        },
        blockwellWallet() {
            return this.$store.state["user"].account;
        },
        disableDumbapp() {
            return this.disabled || !!this.requiresWallet;
        },
        dumbapp() {
            return this.provider?.dumbapp;
        },
        allowedTypes() {
            if (this.forceWalletType?.type) {
                return [walletTypesMap[this.forceWalletType.type]];
            }
            let list = walletProvider.allowedTypes(this.dumbapp, this.strategy);
            return list.map((it) => walletTypesMap[it]);
        },
        type() {
            let dumbapp = this.dumbapp;
            // Define this early so the function is always reactive to loggedIn changes
            let loggedIn = this.loggedIn;
            if (!dumbapp) {
                if (this.priority && !this.typeChanged) {
                    return walletTypes.find((it) => it.type === this.priority);
                }
                return walletTypes.find((it) => it.type === this.walletType) || walletTypes[0];
            }
            let allowedTypes = this.allowedTypes;
            if (allowedTypes.length === 1) {
                return allowedTypes[0];
            }
            let types = this.types;
            let strategy = dumbapp.walletStrategy || { type: "auto" };
            if (this.strategy) {
                strategy = { type: this.strategy };
            }
            if (strategy.type !== "auto") {
                let useType;
                if (this.typeChanged && this.walletType) {
                    useType = allowedTypes.find((it) => it.type === this.walletType);
                }
                if (!useType) {
                    useType = allowedTypes[0];
                }
                return useType;
            }
            if (!this.typeChanged) {
                if (dumbapp?.requiresAsset) {
                    return types.find((it) => it.type === "wallet");
                }
                if (this.network && getChain(this.network).chainId === 1) {
                    if (loggedIn) {
                        return types.find((it) => it.type === "wallet");
                    }
                    else {
                        return types.find((it) => it.type === "metamask");
                    }
                }
            }
            if (this.walletType) {
                let type = allowedTypes.find((it) => it.type === this.walletType);
                if (type) {
                    return type;
                }
            }
            return allowedTypes[0];
        },
        submitComponent() {
            if (this.type) {
                return this.type.component;
            }
            return BlockwellWalletView;
        },
        requiresWallet() {
            if (this.requireWalletAddress &&
                this.account?.toLowerCase() !== this.requireWalletAddress.toLowerCase()) {
                // Don't show the error if not connected to MetaMask
                if (this.type?.type === "metamask" && !this.account) {
                    return null;
                }
                return this.requireWalletAddress;
            }
            return null;
        },
        walletDeps() {
            return {
                type: this.type?.type,
                parameters: this.executorParameters,
            };
        },
    },
    watch: {
        account: {
            immediate: true,
            handler(val) {
                this.$emit("input", val);
                this.provider?.trigger();
            },
        },
        walletDeps: {
            immediate: true,
            deep: true,
            async handler(val) {
                let controller = this.provider;
                if (val.type) {
                    let wallet = await walletProvider.get(val.type, val.parameters);
                    // We don't want Vue to pollute this
                    Object.freeze(wallet);
                    this.clearWalletListener();
                    this.walletHolder.wallet = wallet;
                    if (controller) {
                        wallet.register(controller.execution);
                    }
                    wallet.events.on("connection", this.walletEvent);
                    await wallet.checkConnection();
                }
                else {
                    this.clearWalletListener();
                    this.walletHolder.wallet = null;
                }
            },
        },
    },
    methods: {
        ...mapMutations("dumbapp", ["set_wallet_type"]),
        walletEvent(event) {
            this.walletHolder.account = event.account;
            this.walletHolder.state = event.state;
            this.walletHolder.chainId = event.chainId;
        },
        changeWallet() {
            this.showWalletSelect = true;
        },
        selectWallet(type) {
            if (this.disableWallet === type.type) {
                return;
            }
            this.$emit("update:priority", type.type);
            this.set_wallet_type(type.type);
            this.showWalletSelect = false;
            this.typeChanged = true;
        },
        login() {
            this.$bus.emit("login_request", {
                message: "Sign In to load your Blockwell Wallet.",
            });
        },
        resolved(params) {
            if (params.success === true) {
                if (params.arguments[0].chainId) {
                    this.net = getChain(params.arguments[0].chainId);
                }
            }
        },
        clearWalletListener() {
            let controller = this.provider;
            let wallet = this.walletHolder.wallet;
            if (wallet) {
                wallet.events.off("connection", this.walletEvent);
                if (controller) {
                    wallet.unregister(controller.execution);
                }
            }
        },
    },
    created() {
        this.provider?.on("resolve", this.resolved);
    },
    beforeDestroy() {
        this.provider?.off("resolve", this.resolved);
        this.clearWalletListener();
    },
});
