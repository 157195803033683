import { isContractStep } from "@blockwell/dumbapp";
import { addressEqual, chainEqual } from "@blockwell/chains";
export class ErrorContext {
    static forSubmission(submission) {
        if (!submission) {
            return null;
        }
        let index = submission.data.errorIndex();
        if (index > -1) {
            return new ErrorContext(submission.dumbapp.steps[index], submission.data.steps[index], submission.data.steps[index].error);
        }
        return null;
    }
    constructor(step, submissionStep, error) {
        this.step = step;
        this.submissionStep = submissionStep;
        this.error = error;
    }
    /**
     * Tests if this error is for a specific contract.
     */
    is(address, network) {
        if (!this.step) {
            return false;
        }
        return addressEqual(this.submissionStep.address, address) && chainEqual(this.submissionStep.network, network);
    }
    method() {
        if (isContractStep(this.step)) {
            return this.step.method;
        }
        return null;
    }
}
