import { createHttpClient } from "./client";
export default class ChronicaApi {
    constructor(auth) {
        this.client = createHttpClient(auth, "api/chronica/");
        this.matchers = new Matchers(this.client);
    }
}
class Matchers {
    constructor(client) {
        this.client = client;
    }
    all() {
        return this.client.get("matchers")
            .then(it => it.data.data);
    }
    create(input) {
        return this.client.post("matchers", input)
            .then(it => it.data.data);
    }
    delete(matcherId) {
        return this.client.delete(`matchers/${matcherId}`);
    }
}
