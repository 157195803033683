<template>
    <div class="proposal-selection">
        <ProgressCircle v-if="loading"/>
        <div v-else class="proposal-list">
            <div v-for="prop in filtered" class="proposal-select" @click="selected(prop)">
                <div class="proposal-id">Proposal #{{ prop.id }}</div>
                <ProposalInfo
                    :proposal="prop"
                    />
                <div class="payment-proposal-text">
                    <ClampedText>
                        {{ prop.text }}
                    </ClampedText>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClampedText from "@/components/ClampedText";
import Clipboard from "@/components/Clipboard";
import ProgressCircle from "@/components/ProgressCircle";
import ProposalInfo from "@/components/ProposalInfo";
import TokenAmount from "@/components/TokenAmount";
import Daico from "@/lib/eth/Daico";
import { InputController } from "@blockwell/dumbapp";

export default {
    name: "ProposalSelection",
    components: {ProposalInfo, Clipboard, TokenAmount, ClampedText, ProgressCircle},
    props: {
        field: Object,
        provider: InputController
    },
    data() {
        return {
            proposals: [],
            loading: true
        };
    },
    computed: {
        filtered() {
            if (this.proposals) {
                return this.proposals;
            }
            return [];
        }
    },
    methods: {
        selected(proposal) {
            this.$emit('selected', proposal);
        }
    },
    mounted() {
        if (this.field) {
            Daico.loadAllProposals(this.field.network, this.field.address)
                .then(res => {
                    this.loading = false;
                    this.proposals = res;
                })
        }
    },
}
</script>

<style lang="scss">
@import "~@/assets/css/custom.scss";

.proposal-list {
    .proposal-select {
        cursor: pointer;
        padding: 16px;
        border-bottom: 1px solid $grey;

        .proposal-id {
            color: $secondary;
        }

        .proposal-text {
            margin-top: 5px;
        }

        &:hover {
            background-color: $grey;
        }
    }
}
</style>
