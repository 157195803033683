<template>
    <div class="dumbapp-embed-submission">
        <MessagesForSubmission :submission="loadedSubmission" class="submission-embed-messages"/>
        <div class="dumbapp-page-submission">
            <h5 class="dumbapp-submission-title">{{ title }}</h5>
            <router-link :to="link" class="back-to-form">Back To Form</router-link>
            <div v-if="steps">
                <DumbappPreviewStep v-for="(step, i) in steps"
                             :step="step.dumbapp"
                             :submission-step="step.submission"
                             :index="i"
                             :key="step.submission.id"
                             class="step"/>
            </div>
        </div>
    </div>
</template>

<script>
import MessagesForSubmission from "@/components/dumbapp/confirmation/MessagesForSubmission";
import DumbappPreviewStep from "@/components/dumbapp/DumbappPreviewStep";
import Eip1193Mixin from "@/components/dumbapp/executor/Eip1193Mixin";
import Submission from "@/components/dumbapp/Submission";
import SubmissionHeader from "@/components/dumbapp/SubmissionHeader";
import SubmissionMixin from "@/components/dumbapp/SubmissionMixin";
import FadeSlide from "@/components/FadeSlide";
import * as interval from "@/lib/interval";
import {mapActions} from "vuex";

export default {
    name: "DumbappSubmissionPage",
    components: {MessagesForSubmission, SubmissionHeader, DumbappPreviewStep, FadeSlide, Submission},
    mixins: [SubmissionMixin, Eip1193Mixin],
    data() {
        return {
            interval: null
        }
    },
    computed: {
        title() {
            return this.loadedSubmission?.dumbapp?.getTitle();
        },
        link() {
            let shortcode = this.loadedSubmission?.dumbapp?.shortcode;
            let query = this.loadedSubmission?.data?.query;

            if (shortcode) {
                return {
                    path: `/dumbapp/${shortcode}`,
                    query
                };
            }
            return "";
        }
    },
    methods: {
        ...mapActions('dumbapp', ["updateStatuses"]),
        submissionCompleted(submission) {
            this.$bus.emit('dumbapp-completed', submission);
        },
        submissionFailed(submission) {
            this.$bus.emit('dumbapp-failed', submission);
        }
    },
    created() {
        this.$store.restored.then(() => {
            this.interval = interval.every(() => {
                this.updateStatuses({
                    component: this
                }).catch(console.error);
            }, 3000);
        });
        this.$on('completed', this.submissionCompleted);
        this.$on('error', this.submissionFailed);
    },
    destroyed() {
        if (this.interval !== null) {
            this.interval.clear();
            this.interval = null;
        }
    }
}
</script>

<style lang="scss">

.dumbapp-submission-title {
    margin-bottom: 0;
}

.back-to-form {
    display: block;
    margin-bottom: 15px;
}

.submission-embed-messages {
    .dumbapp-confirmation-message {
        padding: 16px;
    }
}

.dumbapp-page-submission {
    padding: 0;
}
</style>
