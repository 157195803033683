<template>
    <div class="messages-inner">
        <div v-if="messages && messages.length > 0" class="message-list">
            <Message
                    v-for="message of messages"
                    :message="message"
                    :key="message.id"
                    :news="showNew"
                    :show-time="showTime"
                    @dismiss="it => $emit('dismiss', it)"/>
        </div>
        <p v-else-if="showEmptyMessage">No Messages</p>
    </div>
</template>

<script>
import {getPage} from "@/lib/page";
import Message from "@/components/message/Message";
import smoothReflow from 'vue-smooth-reflow'

export default {
    mixins: [smoothReflow],
    name: "Messages",
    components: {Message},
    props: {
        showNew: Boolean,
        showEmptyMessage: Boolean,
        showTime: Boolean,
        pageFilter: Boolean,
        messages: Array
    },
    mounted() {
        this.$smoothReflow()
    }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/custom";

.messages-inner {
    padding: 8px;
    box-sizing: border-box;
}

.message {
    margin: 0 0 10px 0;
}

.message:last-child {
    margin-bottom: 0;
}
</style>
