<template>
    <Modal :show="!!loadedSubmission" @closed="$emit('closed')" class="submission-modal">
        <template #header>
            <SubmissionHeader :expanded="true" :submission="loadedSubmission" />
        </template>

        <MessagesForSubmission :submission="loadedSubmission" />
        <div v-if="steps">
            <DumbappPreviewStep
                v-for="(step, i) in steps"
                :step="step.dumbapp"
                :submission-step="step.submission"
                :index="i"
                :key="step.submission.id"
                class="step"
            />
        </div>
    </Modal>
</template>

<script>
import DumbappPreviewStep from "@/components/dumbapp/DumbappPreviewStep";
import SubmissionHeader from "@/components/dumbapp/SubmissionHeader";
import SubmissionMixin from "@/components/dumbapp/SubmissionMixin";
import Modal from "@/components/Modal";
import FadeSlide from "@/components/FadeSlide.vue";
import MessagesForSubmission from "@/components/dumbapp/confirmation/MessagesForSubmission.vue";

export default {
    name: "SubmissionModal",
    components: { MessagesForSubmission, FadeSlide, DumbappPreviewStep, SubmissionHeader, Modal },
    mixins: [SubmissionMixin],
};
</script>

<style lang="scss">
div.submission-modal.v-modal-backdrop {
    z-index: 100;

    section.v-modal-body {
        padding: 0;
    }
}
</style>
