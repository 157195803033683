let intervals = [];
let hides = [];
class IntervalEntry {
    constructor(milliseconds, func, once = false) {
        this.milliseconds = milliseconds;
        this.func = func;
        this.once = once;
        this.lastRun = Date.now();
        this.locked = false;
        this.finished = false;
    }
    start(runImmediately = true) {
        if (this.interval) {
            clearInterval(this.interval);
        }
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        if (runImmediately) {
            this.run();
        }
        if (this.once) {
            this.timeout = setTimeout(() => {
                if (isVisible()) {
                    this.run();
                }
            }, this.milliseconds);
        }
        else {
            this.interval = setInterval(() => {
                if (isVisible()) {
                    this.run();
                }
            }, this.milliseconds);
        }
    }
    runNow() {
        // If the difference is less than 100 milliseconds, we'll just let it run normally.
        // Otherwise we'll run it now and reset the interval.
        if (!this.locked && Date.now() - this.lastRun < this.milliseconds - 100) {
            this.start();
        }
    }
    clear() {
        intervals = intervals.filter((it) => it.interval !== this.interval);
        if (this.interval) {
            clearInterval(this.interval);
        }
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.interval = null;
        this.timeout = null;
    }
    delay() {
        if (this.locked) {
            return false;
        }
        this.start(false);
        return true;
    }
    run() {
        if (this.locked) {
            console.log("Not running interval because it is locked", this);
            return;
        }
        let promise = false;
        this.locked = true;
        try {
            let ret = this.func();
            if (ret && typeof ret.then === "function") {
                promise = true;
                ret.finally(() => {
                    this.finish();
                    this.locked = false;
                    this.lastRun = Date.now();
                });
                ret.catch(console.error);
            }
        }
        catch (err) {
            console.error(err);
        }
        finally {
            if (!promise) {
                this.finish();
                this.locked = false;
                this.lastRun = Date.now();
            }
        }
    }
    finish() {
        if (this.once) {
            this.finished = true;
            this.clear();
        }
    }
}
class OnHideEntry {
    constructor(func, once = false) {
        this.func = func;
        this.once = once;
        this.lastRun = Date.now();
        this.locked = false;
        this.finished = false;
    }
    clear() {
        let index = hides.indexOf(this);
        if (index > -1) {
            hides.splice(index, 1);
        }
    }
    run() {
        if (this.finished) {
            return;
        }
        if (this.locked) {
            console.log("Not running OnHide because it is locked", this);
            return;
        }
        let promise = false;
        this.locked = true;
        try {
            let ret = this.func();
            if (ret && typeof ret.then === "function") {
                promise = true;
                ret.finally(() => {
                    this.finish();
                });
                ret.catch(console.error);
            }
        }
        catch (err) {
            console.error(err);
        }
        finally {
            if (!promise) {
                this.finish();
            }
        }
    }
    finish() {
        if (this.once) {
            this.finished = true;
            this.clear();
        }
        this.locked = false;
        this.lastRun = Date.now();
    }
}
// Set the name of the hidden property and the change event for visibility
let hidden;
let visibilityChange;
if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    hidden = "hidden";
    visibilityChange = "visibilitychange";
}
else {
    // @ts-ignore
    if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
        // @ts-ignore
    }
    else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
    }
}
function visibilityChanged() {
    if (isVisible()) {
        for (let data of intervals) {
            if (data) {
                if (data.finished) {
                    continue;
                }
                // Run functions that are overdue.
                if (!data.locked && Date.now() - data.lastRun > data.milliseconds - 100) {
                    data.start();
                }
            }
        }
    }
    else {
        console.log("visibilityChanged", hides);
        for (let data of hides) {
            if (data) {
                if (data.finished) {
                    continue;
                }
            }
            data.run();
        }
    }
}
export function isVisible() {
    // Fallback to always visible if browser doesn't support it
    if (hidden === undefined || typeof document.addEventListener === "undefined") {
        return true;
    }
    // @ts-ignore
    return !document[hidden];
}
if (hidden !== undefined && typeof document.addEventListener !== "undefined") {
    // Remove if it's already there, just in case
    document.removeEventListener(visibilityChange, visibilityChanged, false);
    document.addEventListener(visibilityChange, visibilityChanged, false);
}
export function every(func, milliseconds, runImmediately = true) {
    if (runImmediately) {
        func();
    }
    let data = new IntervalEntry(milliseconds, func);
    intervals.push(data);
    data.start(false);
    return data;
}
export function once(func, milliseconds) {
    let data = new IntervalEntry(milliseconds, func, true);
    intervals.push(data);
    data.start(false);
    return data;
}
export function onHide(func) {
    let data = new OnHideEntry(func);
    hides.push(data);
    return data;
}
export function onceOnHide(func) {
    let data = new OnHideEntry(func, true);
    hides.push(data);
    return data;
}
export function clearAll() {
    for (let data of intervals) {
        if (data) {
            clearInterval(data.interval);
        }
    }
    intervals = [];
    hides = [];
}
