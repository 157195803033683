<template>
    <div class="nav-dropdown">
        <a href="javascript: void 0" class="dropdown-icon" @click="toggle" ref="icon">
            <slot v-if="$slots.icon" name="icon"/>
            <i v-else class="material-icons">{{ icon }}</i>
            <transition name="fade-slide">
                <div v-if="open" class="icon-arrow"></div>
            </transition>
        </a>
        <transition name="fade-slide">
            <div v-if="open" class="wrapper" ref="wrapper">
                <div class="dropdown-box">
                    <slot/>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "Dropdown",
    props: {
        icon: String
    },
    data() {
        return {
            open: false
        };
    },
    methods: {
        toggle() {
            this.open = !this.open;
        },
        globalClick(event) {
            if (this.open
                && !this.$refs.icon.contains(event.target)
                && (!this.$refs.wrapper.contains(event.target) || event.target.tagName === "A")) {

                requestAnimationFrame(() => {
                    this.open = false;
                });
            }
        }
    },
    watch: {
        open() {
            if (this.open) {
                window.addEventListener("click", this.globalClick, true);
                this.$emit('open');
            } else {
                window.removeEventListener("click", this.globalClick, true);
                this.$emit('close');
            }
        }
    },
    destroyed() {
        window.removeEventListener("click", this.globalClick, true);
    }
}
</script>

<style lang="scss">
@media screen and (max-width: 400px) {
    .main-header .dropdown-icon i.material-icons {
        font-size: 22px;
    }
}
</style>

<style scoped lang="scss">
@import "../assets/css/custom";

.nav-dropdown {
}

.dropdown-icon {
    display: inline-block;
    position: relative;
    color: #fff;
}

.icon-arrow {
    width: 0;
    border-style: solid;
    border-width: 0 5px 5px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom-color: #fff;
    position: absolute;
    top: 35px;
    left: calc(50% - 5px);
    z-index: 11;
}

div.wrapper {
    position: absolute;
    top: 40px;
    right: 15px;
    z-index: 20;

    @media screen and (max-width: 500px) {
        max-width: calc(100vw - 30px);
    }
}

.dropdown-box {
    background: #fff;
    color: $dark;
    @include mdElevation(5);
}
</style>
