<template>
    <FormulateInput
        :name="field.name"
        type="checkbox"
        v-on="$listeners"
        :id="id"
        @input="input"
        validation="present"
        :validation-rules="{
            present: ({getFormValues}) => {
                let vals = getFormValues();
                let value = vals[field.name];
                return value !== undefined && value !== null;
            }
        }"
        :validation-messages="{
            present: 'Must be checked or unchecked.'
        }">

        <template #label>
            <slot name="label"></slot>
        </template>
        <template #help>
            <slot name="help"></slot>
        </template>
    </FormulateInput>
</template>

<script>
export default {
    name: "BooleanInput",
    props: {
        id: {
            type: String,
            required: true
        },
        field: Object
    },
    data() {
        return {
            value: null
        }
    },
    methods: {
        input(val) {
            this.value = val;
            if (this.$el && (val === false || val === true)) {
                $(this.$el).find('input')[0].indeterminate = false;
            }
        },
    },
    mounted() {
        if (this.value === null) {
            $(this.$el).find('input')[0].indeterminate = true;
        }
    }
}
</script>

<style scoped>

</style>
