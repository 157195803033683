<template>
    <FormulateForm @submit="submit">
        <FormulateInput
                name="email"
                type="email"
                label="Email"
                validation="email"
                help="Enter your account email address."
                class="width-8"
                v-model="email"
                autocomplete="username"
        />
        <FormulateInput
                name="password"
                type="password"
                label="Password"
                validation="required|min:4|max:128"
                help="Enter your password."
                class="width-6"
                v-model="password"
                autocomplete="current-password"
        />
        <p v-if="feedback" class="error">{{feedback}}</p>
        <FormulateInput
                type="submit"
                name="Sign In"
        />
    </FormulateForm>
</template>

<script>
import * as bootbox from "bootbox";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "LoginForm",
    data() {
        return {
            email: '',
            password: '',
            feedback: null,
            loading: false
        }
    },
    computed: {
        ...mapGetters('user', ['loggedIn'])
    },
    methods: {
        ...mapActions('user', ['login', 'register']),
        async submit() {
            let email = this.email;
            let password = this.password;
            this.loading = true;
            this.feedback = null;
            try {
                await this.login({email, password});
                if (this.loggedIn) {
                    this.$emit('login');
                } else {
                    this.feedback = "Unknown error occurred, please try again later.";
                }
            } catch (err) {
                if (err.code === "user_not_found") {
                    return this.promptRegister();
                }

                if (err.response) {
                    switch (err.response.status) {
                        case 400:
                            this.feedback = "Invalid email address or password. Check that you've typed them correctly.";
                            break;
                        case 401:
                            this.feedback = "Incorrect email address or password.";
                            break;
                        case 429:
                            this.feedback = "Too many login attempts, please wait 10 minutes before trying again.";
                            break;
                        default:
                            this.feedback = "Unknown error occurred, please try again later.";
                            break;
                    }
                } else {
                    this.feedback = "Unknown error occurred, please try again later.";
                }
            } finally {
                this.loading = false;
            }
        },
        promptRegister() {
            bootbox.confirm({
                title: "Account Not Found",
                message: `Looks like there's no account for ${this.email}, would you like to create it?`,
                backdrop: true,
                callback: (result) => {
                    if (result) {
                        this.submitRegister().catch(err => {
                            this.feedback = "Unknown error occurred, please try again later.";
                        })
                    }
                }
            });
        },
        async submitRegister() {
            let email = this.email;
            let password = this.password;
            this.$gtag.event('sign_up');
            await this.register({email, password});
            if (this.loggedIn) {
                this.$emit('login');
            } else {
                this.feedback = "Unknown error occurred, please try again later.";
            }
        }
    }
}
</script>

<style scoped>

</style>
