var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"happ-recent-contract\">\n    <a href=\"javascript: void 0;\" data-address=\""
    + alias4(((helper = (helper = helpers.address || (depth0 != null ? depth0.address : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"address","hash":{},"data":data,"loc":{"start":{"line":2,"column":48},"end":{"line":2,"column":59}}}) : helper)))
    + "\" data-network=\""
    + alias4(((helper = (helper = helpers.network || (depth0 != null ? depth0.network : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"network","hash":{},"data":data,"loc":{"start":{"line":2,"column":75},"end":{"line":2,"column":86}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":88},"end":{"line":2,"column":96}}}) : helper)))
    + "</a>\n</div>\n";
},"useData":true});