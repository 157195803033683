export class BlockContext {
    constructor(dumbapp, contract, step, account) {
        this.dumbapp = dumbapp;
        this.contract = contract;
        this.step = step;
        this.account = account;
    }
}
export class Block {
    constructor(context, component, id) {
        this.context = context;
        this.component = component;
        this.id = id;
    }
}
