<template>
    <FormulateInput
            :name="field.name"
            :validation-name="field.label"
            :id="id"
            step="any"
            type="currency"
            validation="required|number"
            error-behavior="blur"
            v-on="$listeners">

        <template #label><slot name="label"></slot></template>
        <template #help><slot name="help"></slot></template>
    </FormulateInput>
</template>

<script>
export default {
    name: "NumberInput",
    props: {
        id: {
            type: String,
            required: true
        },
        field: Object
    },
    data() {
        return {};
    }
}
</script>

<style scoped>

</style>
