import ArgumentMixin from "@/components/dumbapp/argument/ArgumentMixin";
import ArrayArgument from "@/components/dumbapp/argument/ArrayArgument.vue";
import ProgressCircle from "@/components/ProgressCircle.vue";
import ArgumentComponentMixin from "@/components/dumbapp/argument/ArgumentComponentMixin";
export default {
    name: "DumbappArgumentView",
    components: { ProgressCircle },
    mixins: [ArgumentMixin, ArgumentComponentMixin],
    inject: ["provider"],
    props: {
        arg: {
            type: Object,
        },
        index: Number,
        step: {
            type: Object,
        },
    },
    computed: {
        argumentComponent() {
            if (this.arg?.typing?.array) {
                return ArrayArgument;
            }
            return this.component;
        },
        submissionStep() {
            if (this.provider?.submissionStep) {
                return this.provider.submissionStep(this.step.index);
            }
            return null;
        },
        errorAnnotation() {
            if (this.error) {
                return this.error.annotation;
            }
            return null;
        },
    },
    watch: {
        arg() {
            this.onInput(this.provider.values);
        },
    },
    mounted() {
        this.bindSource();
    },
    beforeDestroy() {
        this.unbindSource();
    },
    methods: {
        bindSource() {
            this.onInput(this.provider.values);
            this.provider.on("change", this.onInput);
        },
        unbindSource() {
            this.provider.off("change", this.onInput);
        },
        onInput(provider) {
            if (this.arg) {
                this.value = provider.getArgValue(this.arg, this.step, this.index);
            }
        },
    },
};
