// @ts-ignore
import store from "@/store";
import axios from "axios";
import rateLimit from "axios-rate-limit";
export function createHttpClient(auth, baseUrl = "") {
    let client = axios.create({
        baseURL: process.env.VUE_APP_API_URL + baseUrl,
        paramsSerializer: {
            indexes: null,
        },
        timeout: 20000
    });
    if (auth) {
        if (auth === 'legacy') {
            console.log('using legacy api auth');
            client.interceptors.request.use(function (config) {
                // @ts-ignore
                let key = store.state.user.auth;
                if (key) {
                    config.headers['Authorization'] = "Bearer " + key;
                }
                else {
                    delete config.headers['Authorization'];
                }
                return config;
            });
        }
        else {
            client.defaults.headers.common['Authorization'] = "Bearer " + auth;
        }
    }
    client.interceptors.response.use(function (res) {
        return res;
    }, function (err) {
        if (err.response && err.response.data && err.response.data.error) {
            err.code = err.response.data.error.code;
            if (err.response.data.error.message) {
                err.message = err.response.data.error.message;
            }
        }
        return Promise.reject(err);
    });
    client = rateLimit(client, {
        maxRequests: 20,
        perMilliseconds: 4000
    });
    client.defaults.headers.common['Client-Name'] = "Blockwell Wallet Web bwqrweb " + navigator.userAgent;
    return client;
}
