<template>
    <div class="error">
        The transaction failed because you have not registered for Shopin Tokenholder Voices yet. Go
        to <a href="https://voice.blockwell.ai/shopin">voice.blockwell.ai/shopin</a> and follow the
        instructions to register.
    </div>
</template>

<script>
export default {
    name: "ShopinError",
    match(context) {
        return context.is('0xf3a3fdaa81a33cf73c122bb46d9b04ddf7360244', 4)
            && ['vote', 'proposalVote', 'createSuggestion'].includes(context.method())
    }
}
</script>

<style scoped>

</style>
