<template>
    <div class="info-popover">
        <a href="javascript: void 0;" class="tooltip-target material-icons" :class="colorClass" @click="open">{{
            icon
        }}</a>
        <div ref="popover" class="info-popover-wrapper">
            <transition
                name="fade"
                v-on:before-enter="enter"
                v-on:before-leave="beforeLeave"
                v-on:after-enter="afterEnter"
                v-on:after-leave="leave"
            >
                <div v-if="showing" class="info-popover-inner">
                    <div class="info-content" ref="content">
                        <slot />
                    </div>
                </div>
            </transition>
            <div ref="arrow" class="info-popover-arrow-wrapper">
                <transition name="fade">
                    <div v-if="showing" class="info-popover-arrow"></div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import PopperMixin from "@/components/PopperMixin";
import { createPopper } from "@popperjs/core";
import ResizeObserver from "resize-observer-polyfill";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name: "Info",
    mixins: [PopperMixin],
    props: {
        icon: {
            type: String,
            default: "help",
        },
        color: String,
    },
    computed: {
        colorClass() {
            if (this.color) {
                return "color-" + this.color;
            }
            return "";
        }
    }
};
</script>

<style scoped lang="scss">
.info-popover {
    display: inline;

    .tooltip-target {
        text-decoration: none;
        color: var(--secondary);
    }

    .color-red {
        color: rgba(123, 46, 46, 0.5);
    }
}

.info-popover-wrapper {
    display: none;
    z-index: 10;
    max-width: calc(100vw - 20px);
    margin: 0;

    &[data-show] {
        display: block;
    }

    $placements: ("bottom": "top", "top": "bottom", "left": "right", "right": "left");

    @each $pos, $side in $placements {
        &[data-popper-placement^=#{$pos}] .info-popover-arrow-wrapper {
            #{$side}: -4px;
        }
    }
}

.info-popover-arrow-wrapper, .info-popover-arrow, .info-popover-arrow::before {
    position: absolute;
    width: 8px;
    height: 9px;
}

.info-popover-arrow {
    z-index: -1;
}

.info-popover-inner {
    background: var(--light);
    filter: drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.2)) drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.3));
    color: var(--body-text);

    max-width: 400px;
    padding: 10px;
}

.info-popover-arrow::before {
    z-index: -1;
    content: '';
    transform: rotate(45deg);
    background: var(--light);
}

.info-content {
    font-size: 14px;
    font-weight: normal;
}
</style>
