import { getTokenData } from "@/lib/eth/Token";
import deployerData from "@/assets/data/Deployers";
import Dapp from "@/assets/lib/Dapp";
import { addressEqual, chainEqual } from "@blockwell/chains";
export default class Recent {
    constructor() { }
    getUserRecent(type) {
        const storageKey = `happ.recent.${type}`;
        let data = [];
        try {
            let storage = localStorage.getItem(storageKey);
            if (storage) {
                data = JSON.parse(storage);
            }
        }
        catch (err) {
            console.error(err);
        }
        return data;
    }
    addUserRecent(type, contract) {
        const storageKey = `happ.recent.${type}`;
        let recents = this.getUserRecent(type);
        recents = recents.filter((it) => it.name !== contract.name ||
            !addressEqual(it.address, contract.address) ||
            (it.network && !chainEqual(it.network, contract.network)));
        recents.unshift(contract);
        recents = recents.slice(0, 7);
        localStorage.setItem(storageKey, JSON.stringify(recents));
        return recents;
    }
    async getDeployerRecent(type, deployerAddress) {
        if (!deployerAddress) {
            return null;
        }
        let abi = deployerData[type];
        if (!abi) {
            return null;
        }
        let addresses;
        if (Array.isArray(deployerAddress)) {
            addresses = deployerAddress;
        }
        else {
            addresses = [deployerAddress];
        }
        let recent = [];
        for (let it of addresses) {
            let net;
            let address;
            if (typeof it === "string") {
                continue;
            }
            else {
                net = Dapp.getNetwork(it.network);
                address = it.address;
                if (!net || net.chainId === 0) {
                    continue;
                }
            }
            recent = recent.concat(await this._recent(net.chainId, net.web3, abi, address));
            if (recent.length >= 7) {
                recent = recent.slice(0, 7);
                break;
            }
        }
        return recent;
    }
    async _recent(chainId, web3, abi, address) {
        // Different typing for Web3
        let _abi = abi;
        let contract = new web3.eth.Contract([_abi], address);
        let events = await contract.getPastEvents(abi.name, {
            fromBlock: 0,
            toBlock: "latest",
        });
        events.reverse();
        events = events.slice(0, 7);
        return getTokenData(chainId, events.map((it) => it.returnValues.contractAddress));
    }
}
