<template>
    <div class="address-qr-scanner">
        <div v-if="!loadingError" class="qr-video-container">
            <video muted playsinline autoplay ref="video" class="qr-video"></video>
            <div class="qr-video-overlay">
                <div class="shaded"></div>
                <div class="shaded"></div>
                <div class="shaded"></div>
                <div class="shaded"></div>
                <div class="finder" ref="finder"></div>
                <div class="shaded"></div>
                <div class="shaded"></div>
                <div class="shaded"></div>
                <div class="shaded"></div>
            </div>
        </div>
        <div v-else class="error">{{ loadingError }}</div>
        <div class="error">{{ error }}</div>
    </div>
</template>

<script>
import QrScanner from "qr-scanner";
import QrScannerWorkerPath from '!!file-loader!qr-scanner/qr-scanner-worker.min';
import ResizeObserver from 'resize-observer-polyfill';

QrScanner.WORKER_PATH = QrScannerWorkerPath;

export default {
    name: "AddressQrScanner",
    data() {
        return {
            loadingError: null,
            error: null,
            scannedAddress: null
        };
    },
    mounted() {
        (async () => {
            try {
                const video = this.$refs.video;
                video.setAttribute('autoplay', '');
                video.setAttribute('muted', '');
                video.setAttribute('playsinline', '');
                video.controls = false;

                this.qrScanner = new QrScanner(video, result => {
                    let res = /^(ethereum:)?(0x[a-f0-9]{40})$/i.exec(result);

                    if (res) {
                        this.scannedAddress = res[2];
                        this.qrScanner.destroy();
                        this.qrScanner = null;
                        this.$emit('address', this.scannedAddress);
                    } else {
                        this.error = 'Not a valid Ethereum address.';
                    }
                });
                this.qrScanner.setInversionMode('both');
                await this.qrScanner.start();
                this.loadingError = null;
            } catch (err) {
                if (navigator.userAgent.match(/(iPad|iPhone)/)) {
                    alert("Looks like you're on iOS. The QR Scanner only works in Safari at this time.");
                }
                this.loadingError = "There was a problem loading a camera for the scanner: " + (err.message || err);
            }
        })()
            .catch(console.error);

        this.observer = new ResizeObserver(entries => {
            const {width, height} = entries[0].contentRect;
            const finder = this.$refs.finder;

            let size = Math.round(Math.min(width, height) * 0.66);

            finder.style.width = size + "px";
            finder.style.height = size + "px";
        });

        this.observer.observe(this.$refs.video);
    },
    destroyed() {
        if (this.qrScanner) {
            this.qrScanner.destroy();
            this.qrScanner = null;
        }
        if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }
    }
}
</script>

<style lang="scss">
.qr-video {
    max-width: 100%;
}

.qr-video-container {
    position: relative;
}

.qr-video-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: grid;
    grid-template-columns: 0.5fr auto 0.5fr;
    grid-template-rows: 0.5fr auto 0.5fr;

    .finder {
        border: 1px solid #fff;
    }

    .shaded {
        background: rgba(0, 0, 0, 0.3);
    }
}
</style>
