<template>
    <span class="address-blockie">
        <img :src="dataUrl" alt="address" class="address-blockie-image"/>
    </span>
</template>

<script>
import * as blockies from 'blockies-ts';

export default {
    name: "AddressBlockie",
    props: {
        address: String,
        size: {
            type: Number,
            default: 8
        },
        scale: {
            type: Number,
            default: 3
        }
    },
    computed: {
        dataUrl() {
            return blockies.create({
                seed: this.address.toLowerCase(),
                scale: this.scale,
                size: this.size
            }).toDataURL()
        }
    }
}
</script>

<style scoped lang="scss">
.address-blockie-image {
    display: block;
    border-radius: 4px;
}
</style>
