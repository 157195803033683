<template>
    <div class="error">
        {{ message }} You need at least {{ gasRequired }} {{ networkName }} ETH to your wallet to send this transaction.
    </div>
</template>

<script>
import {ErrorContext} from "@/components/dumbapp/error/ErrorContext";
import { getChain } from "@blockwell/chains";

export default {
    name: "GasError",
    props: {
        context: ErrorContext
    },
    computed: {
        message() {
            return this.context?.error?.message;
        },
        gasRequired() {
            return this.context?.error?.gasRequired;
        },
        step() {
            return this.context?.step;
        },
        network() {
            if (this.context?.submissionStep) {
                return getChain(this.context.submissionStep.network);
            }
        },
        networkName() {
            return this.network?.networkName || "";
        }
    },
    match(context) {
        return context.error && context.error.gasRequired;
    }
}
</script>

<style scoped>

</style>
