import AddressArgument from "@/components/dumbapp/argument/AddressArgument.vue";
import BooleanArgument from "@/components/dumbapp/argument/BooleanArgument.vue";
import JsonArgument from "@/components/dumbapp/argument/JsonArgument.vue";
import MarkdownArgument from "@/components/dumbapp/argument/MarkdownArgument.vue";
import NumberArgument from "@/components/dumbapp/argument/NumberArgument.vue";
import PlainArgument from "@/components/dumbapp/argument/PlainArgument.vue";
import ProposalArgument from "@/components/dumbapp/argument/ProposalArgument.vue";
import SuggestionArgument from "@/components/dumbapp/argument/SuggestionArgument.vue";
import TimeArgument from "@/components/dumbapp/argument/TimeArgument.vue";
import Vue from "vue";
import TupleArgument from "@/components/dumbapp/argument/TupleArgument.vue";
export function componentForType(type) {
    switch (type) {
        case 'uint':
            return NumberArgument;
        case 'bool':
            return BooleanArgument;
        case 'suggestion':
        case 'proposal':
            return SuggestionArgument;
        case 'payment-proposal':
            return ProposalArgument;
        case 'time':
            return TimeArgument;
        case 'address':
            return AddressArgument;
        case 'markdown':
            return MarkdownArgument;
        case 'tuple':
            return TupleArgument;
    }
    return PlainArgument;
}
export default Vue.extend({
    components: {
        SuggestionArgument,
        PlainArgument,
        NumberArgument,
        BooleanArgument,
        TimeArgument,
        AddressArgument,
        JsonArgument,
        ProposalArgument,
        MarkdownArgument,
        TupleArgument
    },
    props: {
        arg: {
            type: Object
        }
    },
    computed: {
        component() {
            if (this.arg?.value?.type === "json") {
                return JsonArgument;
            }
            return componentForType(this.arg?.type);
        }
    }
});
