<template>
    <div class="mainbody">
        <StatusBar />
        <div class="mainbody-content" :class="openWhitelabel ? 'show-whitelabel' : ''">
            <WhitelabelSettings
                v-if="openWhitelabel"
                :whitelabel-id="whitelabelId"
                @close="openWhitelabel = false"
            />
            <div
                v-else-if="showWhitelabel"
                class="whitelabel-button"
                @click="openWhitelabel = true"
            >
                <span>Whitelabel</span> <i class="material-icons">settings</i>
            </div>
            <router-view></router-view>
        </div>
        <RolodexModals />
        <Modal :show="!!loginRequest" @update:show="loginRequestShow">
            <template #header>
                <h3>Sign In</h3>
            </template>
            <div v-if="loginRequest">
                <p>{{ loginRequest.message }}</p>
                <p><strong>Sign In</strong> using your Blockwell account.</p>
                <p>
                    Want to <strong>register</strong>? Simply type in your email address and a
                    desired password.
                </p>
                <LoginForm @login="loginRequestEnd" />
            </div>
        </Modal>
    </div>
</template>

<script>
import AccountDropdown from "@/components/AccountDropdown";
import ContractHistoryDropdown from "@/components/ContractHistoryDropdown";
import LoginForm from "@/components/LoginForm";
import MessagesDropdown from "@/components/message/MessagesDropdown";
import Modal from "@/components/Modal";
import RolodexModals from "@/components/RolodexModals";
import StatusBar from "@/components/StatusBar";
import SubmissionsDropdown from "@/components/SubmissionsDropdown";
import MainMenu from "@/components/MainMenu";
import WhitelabelSettings from "@/components/whitelabel/WhitelabelSettings";
import MobileNav from "@/layouts/components/MobileNav";
import MainFooter from "@/layouts/components/MainFooter";
import MainHeader from "@/layouts/components/MainHeader";
import { every } from "@/lib/interval";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { getWindow } from "../../../jslib/packages/dumbapp/src";
import Mailchimp from "./components/Mailchimp";
import * as ClipboardJS from "clipboard";
import store from "./store/index";
import "bootstrap";

export default {
    name: "App",
    components: {
        WhitelabelSettings,
        MainFooter,
        MainHeader,
        RolodexModals,
        MobileNav,
        MainMenu,
        LoginForm,
        Modal,
        SubmissionsDropdown,
        ContractHistoryDropdown,
        AccountDropdown,
        StatusBar,
        MessagesDropdown,
        Mailchimp,
    },
    store,
    data() {
        return {
            year: new Date().getFullYear(),
            interval: null,
            contractHistory: [],
            loginRequest: null,
            openWhitelabel: false,
        };
    },
    computed: {
        ...mapGetters("user", ["loggedIn", "api"]),
        ...mapState("user", ["email", "redirect"]),
        whitelabelId() {
            return this.$route.meta?.whitelabelId;
        },
        showWhitelabel() {
            if (this.$route.path?.startsWith("/lotto")) {
                return true;
            }
            return this.$store.state.whitelabel.showWhitelabel[this.whitelabelId];
        },
    },
    watch: {
        loggedIn(to, from) {
            if (from && !to) {
                getWindow().dumbappNamespace.removeContext("wallet");
                this.clearDumbapps();
                this.clearAutocrypt();
                this.$store.dispatch("rolodex/clear");

                this.$router.push({
                    path: "/app/login",
                    query: {
                        redirect: encodeURIComponent(this.$route.fullPath),
                    },
                });
            } else if (!from && to) {
                this.$gtag.event("login");
                this.clearDumbapps();
                this.loadRecent();
                this.restoreHelp();
                this.$store.dispatch("rolodex/syncExternal");

                if (this.$route.path === "/app/login") {
                    let redirect = this.$route.query.redirect;
                    if (redirect) {
                        this.$router.replace(decodeURIComponent(redirect));
                    } else if (this.redirect) {
                        this.$router.push(this.redirect);
                        this.$store.commit("user/save_redirect", null);
                    } else {
                        this.$router.push("/");
                    }
                }
            }
        },
    },
    methods: {
        ...mapActions("dumbapp", ["loadRecent"]),
        ...mapActions("help", ["restoreHelp"]),
        ...mapMutations("dumbapp", {
            clearDumbapps: "clear",
        }),
        ...mapMutations("autocrypt", {
            clearAutocrypt: "clear",
        }),
        requestLogin(data) {
            this.loginRequest = data;
            this.$gtag.event("login_request", {
                event_category: "flow",
            });
        },
        loginRequestEnd() {
            this.loginRequest = null;
        },
        loginRequestShow(shown) {
            if (!shown) {
                this.loginRequestEnd();
            }
        },
        clear() {
            if (this.interval !== null) {
                this.interval.clear();
                this.interval = null;
            }
            this.$bus.off("login_request", this.requestLogin);
        },
    },
    created() {
        let clip = new ClipboardJS(".clipboard");
        clip.on("success", (e) => {
            e.clearSelection();
            $(e.trigger)
                .tooltip({
                    title: "Copied to Clipboard",
                    trigger: "manual",
                    animation: true,
                })
                .tooltip("show");
            setTimeout(() => {
                $(e.trigger).tooltip("hide");
            }, 3000);
        });

        $(".popover-trigger:not(a)").popover({
            trigger: "click hover",
        });
        $("a.popover-trigger").popover({
            trigger: "focus hover",
        });
        this.interval = every(() => {
            store.dispatch("updateStatus");
        }, 10000);
        this.$bus.on("login_request", this.requestLogin);

        this.restoreHelp();
    },
    destroyed() {
        this.clear();
    },
};
</script>

<style lang="scss">
@import "./assets/css/main";
@import "./assets/css/forms";

.user-links {
    padding: 0 20px;
    position: relative;

    & > .nav-dropdown {
        margin-right: 8px;
    }
}

.header-newsletter {
    margin: 0 15px;

    .mc_embed_signup {
        input[type="email"] {
            width: 150px;
            height: 40px;
            padding-left: 12px;
            font-size: 14px;
            line-height: 14px;
        }

        button[type="submit"] {
            height: 40px;
            font-size: 13px;
            min-width: 120px;
        }
    }
}

.nav-home .router-link-exact-active {
    display: none;
}

div.mobile-newsletter {
    .newsletter {
        margin: 8px 30px;
    }

    input[type="email"] {
        font-size: 14px;
        height: 40px;
        width: 160px;
    }

    button[type="submit"] {
        height: 40px;
        white-space: nowrap;
    }
}

@media screen and (min-width: 641px) {
    div.mobile-newsletter,
    .main-header .nav-home {
        display: none;
    }
}

@media screen and (max-width: 640px) {
    div.main-header-wrap {
        height: 50px;
    }
    div.main-header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: $dark;
        z-index: 2;
        min-height: 50px;
        padding: 0;

        h1.logo-wrap,
        span.user-email,
        ul.main-menu,
        .account-dropdown,
        .history-dropdown,
        .header-newsletter {
            display: none;
        }

        div.user-links {
            margin: 10px 0;
            padding: 0 8px;
        }
    }
}

#modals-portal {
    width: 0 !important;
    height: 0 !important;
}

.mainbody-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.mainbody-content.show-whitelabel {
    display: flex;
    flex-direction: row;

    & > .layout {
        flex: 0 0 calc(100% - 228px);
    }

    @media screen and (max-width: 640px) {
        .mobile-nav-button {
            margin-left: 228px;
        }
    }
}
</style>

<style scoped lang="scss">
.whitelabel-button {
    position: absolute;
    left: 0;
    top: 300px;
    color: #fff;
    background: #555;
    border: 2px solid #333;
    border-left: none;
    padding: 4px 8px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    cursor: pointer;

    ::v-deep(span) {
        display: block;
        width: 13px;
        overflow-x: hidden;
        white-space: nowrap;
        transition: width 0.3s;
    }

    &:hover ::v-deep(span) {
        width: 80px;
    }
}
</style>
