import TokenBalanceBlock from "./TokenBalanceBlock.vue";
import UserGroupsBlock from "./UserGroupsBlock.vue";
import AvailableVotesBlock from "./AvailableVotesBlock.vue";
import { Block, BlockContext } from "@/components/dumbapp/block/Block";
import { isContractStep } from "@blockwell/dumbapp";
function blocksForContract(blocks, req, contract, step) {
    if (contract.features.includes("erc20")) {
        let id = `erc20-balance-${contract.id}`;
        if (!blocks.find((it) => it.id === id)) {
            let context = new BlockContext(req.dumbapp, contract, step, req.account);
            blocks.push(new Block(Object.freeze(context), TokenBalanceBlock, id));
        }
    }
    if (contract.bwtype || contract.name === "Game") {
        let id = `groups-${contract.id}`;
        if (!blocks.find((it) => it.id === id)) {
            let context = Object.freeze(new BlockContext(req.dumbapp, contract, step, req.account));
            blocks.push(new Block(context, UserGroupsBlock, id));
        }
    }
    if (isContractStep(step)) {
        if (contract.bwtype === 1 && contract.bwver > 73 && step.method === "multiVote") {
            let id = `available-${contract.id}`;
            if (!blocks.find((it) => it.id === id)) {
                let context = Object.freeze(new BlockContext(req.dumbapp, contract, step, req.account));
                blocks.push(new Block(context, AvailableVotesBlock, id));
            }
        }
    }
}
export async function getBlocks(api, req) {
    let blocks = [];
    let i = 0;
    for (let step of req.dumbapp.steps) {
        if (isContractStep(step)) {
            let contract = req.contracts[i];
            if (!contract) {
                continue;
            }
            blocksForContract(blocks, req, contract, step);
        }
        ++i;
    }
    if (req.approvalContract) {
        blocksForContract(blocks, req, req.approvalContract, req.dumbapp.steps[0]);
    }
    return blocks;
}
