import AddressInput from "@/components/dumbapp/input/AddressInput";
import ArrayInput from "@/components/dumbapp/input/ArrayInput";
import BooleanInput from "@/components/dumbapp/input/BooleanInput";
import MarkdownInput from "@/components/dumbapp/input/MarkdownInput";
import NumberInput from "@/components/dumbapp/input/NumberInput";
import PlainInput from "@/components/dumbapp/input/PlainInput";
import ProposalInput from "@/components/dumbapp/input/ProposalInput";
import SuggestionInput from "@/components/dumbapp/input/SuggestionInput";
import TimeInput from "@/components/dumbapp/input/TimeInput";
import EthInput from "@/components/dumbapp/input/EthInput.vue";

export default {
    props: {
        field: Object
    },
    components: {SuggestionInput, NumberInput, PlainInput, BooleanInput, TimeInput, ProposalInput, EthInput},
    computed: {
        component() {
            switch (this.field?.type) {
                case "uint":
                    return NumberInput;
                case "address":
                    return AddressInput;
                case "bool":
                    return BooleanInput;
                case "suggestion":
                case "proposal":
                    return SuggestionInput;
                case "payment-proposal":
                    return ProposalInput;
                case "time":
                    return TimeInput;
                case "markdown":
                    return MarkdownInput;
                case "eth":
                    return EthInput;
                default:
                    return PlainInput;
            }
        }
    }
}
