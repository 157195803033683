<template>
    <mailchimp-subscribe
        url="https://novoamedia.us17.list-manage.com/subscribe/post-json"
        user-id="41f1572bef210ffd497118e79"
        list-id="ccefeee897"
        @success="success"
    >
        <template v-slot="{ subscribe, setEmail, error, success, loading }">
            <div class="mc_embed_signup">
                <form @submit.prevent="subscribe">
                    <div class="mc-field-group">
                        <input
                            type="email"
                            @input="setEmail($event.target.value); email($event.target.value)"
                            placeholder="Enter Email, Earn Tokens"
                        />
                        <button type="submit">GET UPDATES</button>
                    </div>
                    <div class="mce-error-response" v-if="error">{{ error }}</div>
                    <div class="mce-success-response" v-if="success">Subscribed!</div>
                    <div class="mce-success-response" v-if="loading">Loading…</div>
                </form>
            </div>
        </template>
    </mailchimp-subscribe>
</template>

<script>
import MailchimpSubscribe from "vue-mailchimp-subscribe";
import { mapGetters } from "vuex";

export default {
    components: {
        MailchimpSubscribe,
    },
    data() {
        return {
            entry: ""
        }
    },
    computed: {
        ...mapGetters("user", ["api"])
    },
    methods: {
        success() {
            this.api.emailSignup(this.entry);
        },
        email(input) {
            this.entry = input;
        }
    }
};
</script>

<style lang="scss">
@import "../assets/css/custom";

.mc_embed_signup {
    position: relative;

    .mce-success-response,
    .mce-error-response {
        position: absolute;
        top: 100%;
        left: 5px;
        color: $light;

        a {
            color: $light;
        }
    }

    .mc-field-group {
        display: flex;

        input,
        button {
            flex: 1 1 auto;
        }
    }

    input[type="email"] {
        width: 300px;
        height: 50px;
        box-sizing: border-box;
        background: none;
        border: 2px solid $primary !important;
        padding-left: 24px;
        color: #eee;
        font-family: $font-family-sans-serif;
        font-size: 18px;
        line-height: 18px;
    }

    button[type="submit"] {
        font-family: $font-family-sans-serif;
        font-size: 14px;
        height: 50px;
        background: $primary none;
        border: none;
        color: $light;
        margin: 0;
        font-weight: 700;
        cursor: pointer;

        @media screen and (min-width: 960px) {
            min-width: 160px;
        }
    }
}
</style>
