import DefaultError from "@/components/dumbapp/error/DefaultError";
import GasError from "@/components/dumbapp/error/GasError";
import ShopinError from "@/components/dumbapp/error/ShopinError";

const components = [
    GasError,
    ShopinError
];

/**
 *
 * @param {ErrorContext} context
 */
export function getErrorComponent(context) {
    for (let comp of components) {
        if (comp.match(context)) {
            return comp;
        }
    }

    return DefaultError;
}
