<template>
    <div class="rolodex-wrapper">
        <div class="rolodex-anchor">
            <div class="rolodex-popover">
                <transition name="fade">
                    <div v-if="showing" class="rolodex-inner" :style="{width}">
                        <div class="rolodex-heading">
                            <h4 class="address-book">
                                <i class="material-icons rolodex-icon">import_contacts</i>
                                Recent Addresses
                            </h4>
                            <a href="javascript: void 0;" class="btn btn-sm btn-link"
                               @click="requestFullRolodex"
                               tabindex="1">View Full Address Book</a>
                        </div>
                        <small class="start-typing">Start typing to filter</small>
                        <div class="rolodex-columns">
                            <div v-if="recentWallets.length > 0" class="rolodex-wallets">
                                <RolodexEntry  v-for="it in recentWallets" @click="selected"
                                               :key="it.id" :entry="it" />

                            </div>
                            <div v-else class="no-results">No Wallets Found</div>
                            <div v-if="recentContracts.length > 0" class="rolodex-contracts">
                                <RolodexEntry v-for="it in recentContracts" @click="selected" :key="it.id" :entry="it" />
                            </div>
                            <div v-else class="no-results">No Contracts Found</div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>

    </div>
</template>

<script>
import RolodexEntry from "@/components/rolodex/RolodexEntry";
import { ContractAddressData } from "@/components/rolodex/RolodexMixin";
import RolodexMixin from "@/components/rolodex/RolodexMixin";
import { WalletAddress } from "@/lib/rolodex/AddressCard";
import { createPopper } from "@popperjs/core";

export default {
    name: "Rolodex",
    components: { RolodexEntry },
    mixins: [RolodexMixin],
    props: {
        show: Boolean,
        fieldId: String
    },
    data() {
        return {
            showing: false,
            width: "auto"
        };
    },
    computed: {},
    watch: {
        showing(val) {
            this.$emit("update:show", val);
        },
        show(val) {
            if (!val) {
                this.close();
            } else {
                this.open();
            }
        }
    },
    methods: {
        open() {
            this.showing = true;
        },
        close() {
            this.showing = false;
        },
        selected(entry) {
            this.$emit("address", entry);
            this.$store.dispatch("rolodex/updateTimestamp", entry);
            this.close();
        },
        globalClick(ev) {
            if (!this.showing) {
                return;
            }
            let target = ev.target;

            if (target.id === this.fieldId) {
                return;
            }

            if (target.closest(".rolodex-inner")) {
                return;
            }
            if (target.closest(".v-modal-backdrop")) {
                return;
            }
            if (target.classList.contains("formulate-tags--tag-remove")) {
                return;
            }

            this.close();
        },
        requestFullRolodex() {
            this.$bus.emit("rolodex_request", this.uid);
        },
        /**
         *
         * @param {{requestId: string, entry: WalletAddress | ContractAddressData}} data
         */
        rolodexSelect(data) {
            if (data.requestId === this.uid) {
                this.selected(data.entry);
            }
        }
    },
    mounted() {
        document.addEventListener("click", this.globalClick);
        this.$bus.on("rolodex_select", this.rolodexSelect);

        if (this.show) {
            this.open();
        }
    },
    beforeDestroy() {
        document.removeEventListener("click", this.globalClick);
        this.$bus.off("rolodex_select", this.rolodexSelect);
    }
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.rolodex-wrapper {
    position: relative;
}

.rolodex-popover {
    z-index: 9;
    position: absolute;
    top: 0;
    right: 0;
    left: 10%;
}

.rolodex-inner {
    @include mdElevation(5);
    background: #fff;
    padding: 10px;
}

.address-book {
    font-size: 16px;
    margin: 0;
    padding: .25rem 0;
}

.rolodex-heading {
    display: flex;
    justify-content: space-between;
}

.start-typing {
    color: $secondary;
}

.rolodex-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    max-width: 100%;

    & > ::v-deep( div ) {
        min-width: 0;
    }
}

.no-results {
    color: $text-muted;
}

.rolodex-icon {
    color: $primary;
}
</style>
