export default class FiatApi {
    constructor(client, loggedIn) {
        this.client = client;
        this.loggedIn = loggedIn;
    }
    getUserConfigs() {
        return this.client.get("v2/fiat/processors").then((res) => res.data.data);
    }
    addUserConfig(data) {
        return this.client.post("v2/fiat/processors", data).then((res) => res.data.data);
    }
    getUserLinks() {
        return this.client.get("v2/fiat/links").then((res) => res.data.data);
    }
    createLink(data) {
        return this.client.post("v2/fiat/links", data).then((res) => res.data.data);
    }
    getLink(id) {
        return this.client.get(`v2/fiat/links/${id}`).then((res) => {
            return {
                link: res.data.data,
                config: res.data.config,
            };
        });
    }
}
