import { render, staticRenderFns } from "./Pagination.vue?vue&type=template&id=c3622cfa&scoped=true&"
import script from "./Pagination.vue?vue&type=script&lang=js&"
export * from "./Pagination.vue?vue&type=script&lang=js&"
import style0 from "./Pagination.vue?vue&type=style&index=0&id=c3622cfa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3622cfa",
  null
  
)

export default component.exports