import { getEthereum } from "@/components/dumbapp/executor/metamask";
import { once } from "@/lib/interval";
import { getChain } from "@blockwell/chains";

export default {
    data() {
        return {
            account: null,
            chainId: null,
            /**
             * @type {{ethereum: MetamaskProvider}}
             */
            ethereumHolder: Object.freeze({ethereum: null}),
            connecting: false,
            addTokenRequest: null,
            networkRequest: null,
            wrongAddTokenNetwork: false,
            networkChangeInterval: null,
        };
    },
    computed: {
        noProvider() {
            return this.ethereumHolder.ethereum === null;
        },
        network() {
            if (this.chainId) {
                return getChain(this.chainId);
            }
            return null;
        },
        networkName() {
            return this.network?.networkName;
        },
        networkChange() {
            let wantChainId;
            if (this.dumbappChainId) {
                wantChainId = this.dumbappChainId;
            } else if (this.networkRequest) {
                wantChainId = getChain(this.networkRequest).chainId;
            }
            if (this.network && wantChainId && wantChainId !== this.network.chainId) {
                return getChain(wantChainId);
            }
            return null;
        },
        addNetworkRequirements() {
            return {
                ethereumHolder: this.ethereumHolder,
                networkChange: this.networkChange,
            };
        },
    },
    watch: {
        ethereumHolder: {
            immediate: true,
            handler(val, old) {
                if (old?.ethereum) {
                    this.removeListeners(old.ethereum);
                }
                if (val?.ethereum) {
                    this.$gtag.event("metamask_loaded", {
                        event_category: "dumbapps",
                    });
                    this.chainId = val.ethereum.chainId;
                    this.addListeners(val.ethereum);
                }
            },
        },
        account: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.$gtag.event("metamask_account", {
                        event_category: "dumbapps",
                    });
                }
            },
        },
        network() {
            this.internalAddToken();
        },
        /**
         *
         * @param { { ethereumHolder: {ethereum:MetamaskProvider}, networkChange: EthNetwork }} val
         */
        addNetworkRequirements(val) {
            // Using the interval will ensure a request isn't sent when the browser tab is
            // hidden. It'll get sent when it becomes visible.
            if (this.networkChangeInterval) {
                // If there's an existing interval, but it's not locked, clear it out
                if (!this.networkChangeInterval.locked) {
                    this.networkChangeInterval.clear();
                }
            }
            this.networkChangeInterval = once(async () => {
                try {
                    if (val.ethereumHolder.ethereum && val.networkChange) {
                        if (val.networkChange?.networkId > 42) {
                            await this.addCustomChain(val);
                        } else {
                            await this.switchDefaultChain(val);
                        }
                    }
                } finally {
                    this.networkChangeInterval = null;
                }
            }, 0);
        },
    },
    methods: {
        connect() {
            this.connecting = true;
            this.ethereumHolder.ethereum
                .request({ method: "eth_requestAccounts" })
                .then((accounts) => {
                    this.accountsChanged(accounts);
                    this.internalAddToken();
                })
                .catch((err) => {
                    console.error("eth_requestAccounts error", err);
                    this.addTokenRequest = null;
                })
                .finally(() => {
                    this.connecting = false;
                });
        },
        accountsChanged(accounts) {
            if (accounts.length > 0) {
                this.account = accounts[0];
            } else {
                this.account = null;
            }
        },
        chainChanged(chainId) {
            this.chainId = chainId;
        },
        addListeners(ethereum) {
            ethereum.on("accountsChanged", this.accountsChanged);
            ethereum.on("chainChanged", this.chainChanged);
        },
        removeListeners(ethereum) {
            ethereum.removeListener("accountsChanged", this.accountsChanged);
            ethereum.removeListener("chainChanged", this.chainChanged);
        },
        async addToken(network, address, symbol, decimals, image) {
            this.addTokenRequest = {
                network,
                address,
                symbol,
                decimals,
                image,
            };
            this.internalAddToken();
        },
        internalAddToken() {
            if (!this.addTokenRequest) {
                return;
            }

            if (!this.ethereumHolder.ethereum) {
                this.connect();
                return;
            }

            let net = getChain(this.addTokenRequest.network);

            if (this.network.chainId !== net.chainId) {
                this.networkRequest = net.chainId;
                return;
            }
            this.wrongAddTokenNetwork = false;
            this.processAddTokenRequest();
        },
        processAddTokenRequest() {
            if (this.addTokenRequest) {
                let request = this.addTokenRequest;
                this.addTokenRequest = null;

                this.ethereumHolder.ethereum.request({
                    method: "wallet_watchAsset",
                    params: {
                        type: "ERC20",
                        options: request,
                    },
                });
            }
        },
        addCustomChain(val) {
            let currencyName = val.networkChange.coinName || val.networkChange.networkName + " ETH";
            let currencySymbol = val.networkChange.symbol || "ETH";
            return val.ethereum
                .request({
                    method: "wallet_addEthereumChain",
                    params: [
                        {
                            chainId: val.networkChange.hexChainId(),
                            chainName: val.networkChange.networkName,
                            nativeCurrency: {
                                name: currencyName,
                                symbol: currencySymbol,
                                decimals: 18,
                            },
                            rpcUrls: [val.networkChange.getNodeUrl()],
                            blockExplorerUrls: [val.networkChange.explorer],
                            iconUrls: [],
                        },
                    ],
                })
                .then((err) => {
                    if (err) {
                        console.error("wallet_addEthereumChain error", err);
                        this.addTokenRequest = null;
                    }
                });
        },
        switchDefaultChain(val) {
            return val.ethereum
                .request({
                    method: "wallet_switchEthereumChain",
                    params: [
                        {
                            chainId: val.networkChange.hexChainId(),
                        },
                    ],
                })
                .then((err) => {
                    if (err) {
                        console.error("wallet_switchEthereumChain error", err);
                        this.addTokenRequest = null;
                    }
                });
        },
    },
    created() {
        getEthereum().then((it) => {
            if (it) {
                this.ethereumHolder = Object.freeze({ethereum: it});
            } else {
                this.ethereumHolder = Object.freeze({ethereum: null});
            }
        });
    },
    beforeDestroy() {
        if (this.ethereumHolder.ethereum) {
            this.removeListeners(this.ethereumHolder.ethereum);
        }
    }
};
