<template>
    <div class="submissions-list">
        <div v-if="empty">
            <p>
                No submitted bApps.
                <span v-if="!loggedIn">
                    <a href="javascript: void 0;" @click="login"> Sign In</a> to see your history.
                </span>
            </p>
            <p>
                Want to try one? <router-link to="/dqalpt">Play a Lotto game</router-link>
            </p>
        </div>
        <Submission v-else v-for="submission in submissions"
                    :key="submission.id"
                    :submission="submission"
        />
    </div>
</template>

<script>

import Submission from "@/components/dumbapp/Submission";
import {mapGetters} from "vuex";

export default {
    name: "SubmissionsList",
    components: {Submission},
    props: {
        submissions: Array
    },
    data() { return {}},
    computed: {
        ...mapGetters('user', ['loggedIn']),
        empty() {
            return !this.submissions || this.submissions.length === 0;
        }
    },
    methods: {
        login() {
            this.$bus.emit('login_request', {
                message: ""
            });
        }
    }
}
</script>

<style scoped>

</style>
