import Bwns from "@/lib/api/Bwns";
import FormulateProposal from "@/components/formulate/FormulateProposal";
import FormulateSuggestion from "@/components/formulate/FormulateSuggestion";
import FormulateTags from "@/components/formulate/FormulateTags";
import FormulateCurrency from "@/components/formulate/FormulateCurrency";
import FormulateMarkdown from "@/components/formulate/FormulateMarkdown";
import { isEmbed } from "@/lib/page";
import VueCleave from "@/lib/VueCleave";
import registerServiceWorkers from "@/service/service";
import dayjs from "dayjs";
import Duration from "dayjs/plugin/duration";
import RelativeTime from "dayjs/plugin/relativeTime";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import preciseDiff from "@/lib/dayjs-precise-range";
import EventEmitter from "eventemitter3";
import Vue from "vue";
import App from "./App.vue";
import Embed from "./embed/Embed.vue";
import router from "./router";
import store from "./store";
import Api from "@/lib/api/WalletApi";
import Promise from "bluebird";
import Default from "./layouts/Default";
import Heading from "./layouts/Heading";
import VueFormulate from "@braid/vue-formulate/src/Formulate";
import FormulateForm from "@braid/vue-formulate/src/FormulateForm";
import Config from "./lib/config";
import { VTooltip, VPopover, VClosePopover } from "v-tooltip";
import AsyncComputed from "vue-async-computed";
import VueHammer from "./lib/hammer";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import UniqueId from "vue-unique-id";
import Toasted from "vue-toasted";
import { Portal, PortalTarget, MountingPortal } from "portal-vue";
import VueGtag from "vue-gtag";
import { Portal as SimplePortal } from "@linusborg/vue-simple-portal";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import VueScreen from "vue-screen";
import Hotjar from "vue-hotjar";
import Cleave from "cleave.js";
import "swiper/css/swiper.css";
import "./components/dumbapp/init";

if (process.env.NODE_ENV === "production") {
    Promise.config({
        longStackTraces: false,
    });
}

dayjs.extend(Duration);
dayjs.extend(RelativeTime, { strict: true });
dayjs.extend(LocalizedFormat);
dayjs.extend(preciseDiff);


Vue.config.productionTip = false;
Vue.prototype.$config = new Config(new Api());
Vue.prototype.$bwns = new Bwns();
Vue.prototype.$bus = new EventEmitter();

Vue.use(Hotjar, {
    id: "2206636",
    isProduction: process.env.NODE_ENV === "production",
});

Vue.component("FormulateSuggestion", FormulateSuggestion);
Vue.component("FormulateProposal", FormulateProposal);
Vue.component("FormulateTags", FormulateTags);
Vue.component("FormulateCurrency", FormulateCurrency);
Vue.component("FormulateMarkdown", FormulateMarkdown);

VueFormulate.install(Vue, {
    library: {
        suggestion: {
            classification: "text",
            component: "FormulateSuggestion",
        },
        proposal: {
            classification: "text",
            component: "FormulateProposal",
        },
        tags: {
            classification: "text",
            component: "FormulateTags",
        },
        currency: {
            classification: "text",
            component: "FormulateCurrency",
            slotProps: {
                component: ["prefix", "unit"],
            },
        },
        markdown: {
            classification: "text",
            component: "FormulateMarkdown",
        },
    },
});

Vue.component("FormulateForm", FormulateForm);
Vue.component("layout-default", Default);
Vue.component("layout-heading", Heading);
Vue.component("portal", Portal);
Vue.component("portal-target", PortalTarget);
Vue.component("portal-mounting", MountingPortal);
Vue.component("simple-portal", SimplePortal);
Vue.component("swiper", Swiper);
Vue.component("swiper-slide", SwiperSlide);

VTooltip.options.popover.defaultBaseClass = "vue-tooltip vue-popover";
VTooltip.options.defaultTemplate =
    '<div class="vue-tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>';

VTooltip.options.defaultDelay = {show: 200, hide: 50};
Vue.directive("tooltip", VTooltip);
Vue.directive("close-popover", VClosePopover);
Vue.component("v-popover", VPopover);

Vue.use(
    VueGtag,
    {
        config: { id: "G-QSG9BLVKTB" },
        enabled: process.env.NODE_ENV !== "development",
        onReady(gtag) {
            (async () => {
                // Try for 5 seconds to detect MetaMask
                for (let i = 0; i < 10; i++) {
                    await Promise.delay(500);
                    if (window.ethereum) {
                        return true;
                    }
                }
                return false;
            })()
                .then((res) => {
                    gtag("set", "user_properties", {
                        has_metamask: res,
                    });
                })
                .catch(console.error);
        },
    },
    router
);

Vue.use(VueScreen, {
    sm: 0,
    md: 641,
    lg: 992,
    mobile: (screen) => screen.sm && !screen.md,
});
Vue.use(VueHammer);
Vue.use(VueCleave);
Vue.use(AsyncComputed);
Vue.use(UniqueId);
Vue.use(Toasted);
Vue.component("Autocomplete", Autocomplete);

Vue.directive("visible", (el, binding) => {
    el.style.visibility = binding.value ? "" : "hidden";
    el.style.opacity = binding.value ? "1" : "0";
});

new Vue({
    router,
    store,
    render: (h) => h(isEmbed() ? Embed : App),
}).$mount("#app");

