<template>
    <div v-if="deployments">
        <div v-for="it in deployments">
            <div class="dumbapp-confirmation-message deployment-message">
                <div class="material-icons message-icon">verified</div>
                <div class="message">
                    <span>{{ it.name }} has been deployed at </span>
                    <Clipboard :address="it.address" :network="it.network"/>
                    .
                    <span v-if="it.happ">
                        This contract has a happ (hybrid app):
                        <router-link :to="it.happ">go to happ</router-link>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Clipboard from "@/components/Clipboard";
import { DumbappSubmission } from "@blockwell/dumbapp";

export default {
    name: "DeploymentMessages",
    components: {Clipboard},
    props: {
        submission: DumbappSubmission
    },
    data() {
        return {};
    },
    computed: {
        deployments() {
            return this.submission?.data?.extras?.deployed;
        }
    }
}
</script>

<style scoped>

</style>
