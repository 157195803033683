<template>
        <button type="button" class="loading-button"
                :class="classes"
                v-on="$listeners"
                :disabled="disabled">
            <ProgressCircle v-if="loading" tiny class="side" color="light" />
            <span v-else-if="icon" class="progress-placeholder material-icons">
                {{icon}}
            </span>
            <span v-else class="progress-placeholder"></span>
            <span class="button-text"><slot /></span>
            <span class="side"></span>
        </button>
</template>

<script>
import ProgressCircle from "@/components/ProgressCircle";
export default {
    name: "LoadingButton",
    components: {ProgressCircle},
    props: {
        small: Boolean,
        outline: Boolean,
        disabled: Boolean,
        loading: Boolean,
        color: String,
        icon: String
    },
    computed: {
        classes() {
            let color = 'btn-';
            if (this.outline) {
                color += 'outline-';
            }
            color += (this.color || "primary");
            return [
                'btn',
                color,
                {
                    'btn-sm': this.small
                }
            ]
        }
    }
}
</script>

<style scoped lang="scss">
.progress-placeholder {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
}
.loading-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    & > .side {
        min-width: 20px;
        height: 20px;
        flex: 0 0 20px;
    }

    .button-text {
        flex: 1 0 auto;
    }
}
</style>
