<template>
    <div class="contrib-method visa-method">
        <h3>
            <img src="../../assets/images/logos/visa.png" alt="" width="90"
                 style="margin-right: 5px;"/>
        </h3>
        <p>
            Contribute with a credit card.
        </p>
        <div class="visa-amount formulate-input" data-classification="text">
            <div class="visa-input-wrapper">
                <input :id="$id('amount')" type="number" step="any" v-model="value"/>
                <span class="visa-usd">$</span>
            </div>
        </div>
        <DumbappPopover
            label="Contribute"
            v-if="value"
            shortcode="bh8s4y"
            :values="{
                                    value: amount,
                                    i: reference
                                }"
            @submitted="value = ''"
        />

        <OfferMultiplier v-if="value"
                         :amount="amount"
                         :value="value"
                         :multiplier="multiplier"
                         symbol="ETH"/>
    </div>
</template>

<script>
import DumbappPopover from "@/components/DumbappPopover";
import OfferMultiplier from "@/components/offer/OfferMultiplier";
import {getPrice} from "@/lib/prices";
import BigNumber from "bignumber.js";

export default {
    name: "VisaOffer",
    components: {OfferMultiplier, DumbappPopover},
    props: {
        reference: String,
        multiplier: Number
    },
    data() {
        return {
            value: ''
        };
    },
    computed: {
        amount() {
            return new BigNumber(this.value).div(this.ethPrice).toString(10);
        },
        prideValue() {
            let value = new BigNumber(this.value);
            if (value && !value.isNaN()) {
                return value.times(this.multiplier).toFormat(2);
            }
            return '';
        }
    },
    asyncComputed: {
        async ethPrice() {
            return getPrice("0x0000000000000000000000000000000000000000");
        }
    },
    methods: {
        dumbappSubmitted(data) {
            console.log('dumbappSubmitted', data);

            setTimeout(() => {
                let updated = this.submissions[data.id];

                if (!updated.data.error()) {
                    this.api.offerReceipt(this.reference, "0x0000000000000000000000000000000000000000", this.amount, 'ETH', this.value, this.prideValue, data.data.from);
                } else {
                    console.log('Error! no receipt');
                }

                this.clearToken();
            }, 4000);
        }
    }
}
</script>

<style scoped lang=scss>
@import "~@/assets/css/custom.scss";


.visa-amount {
    width: 160px;
    margin: 0 auto;

    ::v-deep( input ) {
        padding-left: 30px;
    }
}

.visa-input-wrapper {
    position: relative;
}

.visa-usd {
    position: absolute;
    left: 10px;
    top: 0;
    font-size: 22px;
    line-height: 40px;
}

.visa-method {


    ::v-deep( .dumbapp-popover ) {
        width: 160px;
        margin: 0 auto;

        .dumbapp-popover-trigger button {
            width: 160px;
        }
    }
}

</style>
