import { every } from "@/lib/interval";
import { ExecutionStateValueProvider, } from "@blockwell/dumbapp";
import BigNumber from "bignumber.js";
import { Chain } from "@blockwell/chain-client";
import { getChain } from "@blockwell/chains";
export default {
    name: "EthInput",
    inject: ["provider"],
    props: {
        id: {
            type: String,
            required: true,
        },
        field: Object,
    },
    data() {
        return {
            account: null,
            estimate: null,
            gasPrice: null,
            balance: null,
            interval: null,
            net: null,
        };
    },
    computed: {
        wallet() {
            return this.walletHolder?.wallet;
        },
        gasCost() {
            let estimate = 21000;
            if (this.estimate) {
                estimate = this.estimate.toNumber();
            }
            if (this.gasPrice) {
                const gasPrice = this.gasPrice;
                if (gasPrice.type === 0) {
                    return new BigNumber(gasPrice.gasPrice).times(estimate).shiftedBy(-18);
                }
                else {
                    return new BigNumber(gasPrice.maxFeePerGas).times(estimate).shiftedBy(-18);
                }
            }
            return null;
        },
        totalBalance() {
            if (this.balance) {
                return this.balance.toString(10);
            }
        }
    },
    methods: {
        sendMax() {
            let balance = this.balance;
            let gasCost = this.gasCost;
            if (balance && gasCost && balance.gte(gasCost)) {
                this.$emit("updated", {
                    val: balance.minus(gasCost).toString(10),
                    field: this.field
                });
            }
            else {
                this.$emit("updated", {
                    val: "0",
                    field: this.field
                });
            }
        },
        loadBalance() {
            let provider = this.provider;
            const account = provider.account;
            this.account = account;
            const res = provider.lastResolve;
            if (res?.success) {
                this.net = getChain(res.arguments[0].chainId);
            }
            if (!this.account || !this.net) {
                this.balance = null;
            }
            else {
                Promise.all([Chain.gasPrice(this.net), Chain.getBalance(this.net.chainId, account)])
                    .then(([gas, res]) => {
                    this.gasPrice = gas;
                    if (this.account === account) {
                        this.balance = new BigNumber(res).div(`1e${this.net.decimals}`);
                    }
                })
                    .catch(console.error);
                if (res?.success) {
                    if (res.values instanceof ExecutionStateValueProvider) {
                        const values = res.values;
                        let args = res.arguments[0];
                        if (values.dumbapp && !res.approve && args.address && args.value) {
                            Chain.estimateTransactionGas({
                                from: account,
                                ext: this.net,
                                net: this.net,
                            }, args.address, args.value).then((it) => {
                                this.estimate = it;
                            });
                        }
                        else {
                            this.estimate = null;
                        }
                    }
                }
            }
        },
    },
    created() {
        this.interval = every(() => {
            this.loadBalance();
        }, 8000);
        this.provider.on("resolve", this.loadBalance);
    },
    beforeDestroy() {
        if (this.interval) {
            this.interval.clear();
        }
        this.interval = null;
        this.provider.off("resolve", this.loadBalance);
    },
};
