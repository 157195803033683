<template>
    <span
        class="token-amount"
        :class="{
            negative,
        }"
        >{{ amount }} <span v-if="useSymbol" class="token-symbol">{{ useSymbol }}</span></span
    >
</template>

<script>
import BigNumber from "bignumber.js";

export default {
    name: "TokenAmount",
    props: {
        params: Object,
        value: [Number, String, BigNumber],
        decimals: [Number, String],
        symbol: String,
        places: Number,
    },
    inject: {
        token: {
            name: "token",
            default: null,
        },
    },
    computed: {
        parsed() {
            return new BigNumber(this.useValue);
        },
        negative() {
            return this.parsed?.lt(0);
        },
        zero() {
            return this.parsed?.eq(0);
        },
        amount() {
            if (!this.parsed || this.parsed.isNaN()) {
                return "...";
            }
            return this.parsed
                .div(`1e${this.useDecimals}`)
                .toFormat(this.usePlaces, BigNumber.ROUND_FLOOR);
        },
        useValue() {
            if (this.params?.value !== undefined) {
                return this.params.decimals;
            }
            if (this.value !== undefined) {
                return this.value;
            }
            if (this.token && this.token.value !== undefined) {
                return this.token.value;
            }
            return 0;
        },
        useSymbol() {
            if (this.params && this.params.symbol !== undefined) {
                return this.params.symbol;
            }
            if (this.symbol !== undefined) {
                return this.symbol;
            }
            if (this.token && this.token.symbol !== undefined) {
                return this.token.symbol;
            }
            return "";
        },
        useDecimals() {
            if (this.params?.decimals !== undefined) {
                return this.params.decimals;
            }
            if (this.decimals !== undefined) {
                return this.decimals;
            }
            if (this.token && this.token.decimals !== undefined) {
                return this.token.decimals;
            }
            return 18;
        },
        usePlaces() {
            if (this.params?.places !== undefined) {
                return this.params.places;
            }
            if (this.places !== undefined) {
                return this.places;
            }
            if (this.token && this.token.places !== undefined) {
                return this.token.places;
            }
            return null;
        },
    },
};
</script>

<style scoped></style>
