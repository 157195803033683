<template>
    <div v-if="step" class="dumbapp-step">
        <h5 class="method-name">{{ step.method }}</h5>

        <div v-if="step.method && contractName" class="contract-name">
            <span class="contract-name-label">Contract:</span>
            <div class="contract-name-content">
                <span class="contract-name-value">{{ contractName }}</span>
                <span class="contract-name-address">
                    <Clipboard :address="stepAddress" :network="chainId" />
                </span>
            </div>
        </div>
        <div v-else-if="step.method" class="contract-address">
            <Clipboard :address="stepAddress" :network="chainId" />
        </div>

        <div v-if="step.ether" class="send-eth">Send {{ ethSymbol }} on {{ networkName }}</div>
        <div v-else class="contract-network">
            <span class="contract-network-label">Network: </span>
            <span class="contract-network-name">{{ networkName }}</span>
        </div>

        <div v-if="showEth" class="send-eth-value">
            <div style="margin-right: 5px">Sending</div>
            <DumbappArgumentView
                :arg="ethValue"
                :step="step"
                :step-index="index"
                style="margin-right: 5px"
            />
            <div v-if="step.ether">&nbsp;to the address below.</div>
            <div v-else>&nbsp;to the contract.</div>
        </div>
        <div v-if="ethAddress" class="eth-address">
            <DumbappArgumentView :arg="ethAddress" :step="step" :step-index="index" />
        </div>
        <div v-if="submissionStep" class="dumbapp-submission-step" :class="status.class">
            <div class="status-wrap">
                <div class="status-icon">
                    <ProgressCircle v-if="status.progress" small />
                    <i v-else class="material-icons">{{ status.icon }}</i>
                </div>
                <span class="status-text">{{ status.text }}</span>
                <a v-if="explorerLink" :href="explorerLink" target="_blank" class="explorer-link"
                    >View In Explorer</a
                >
            </div>
            <div v-if="waitForAsset" class="wait-for-asset">
                <div class="wait-progress">
                    <ProgressCircle tiny />
                    Waiting for
                    <Clipboard :address="submissionStep.from" :network="chainId" ellipsize />
                    to have {{ waitForAsset.total }} {{ waitForAsset.symbol }}.
                </div>
                <div class="wait-help">
                    {{ waitHelp }}
                </div>
            </div>
            <component v-if="errorContext" :is="errorComponent" :context="errorContext" />
        </div>
        <div class="step-arguments">
            <DumbappArgumentView
                v-for="(arg, i) in step.arguments"
                :key="arg.name"
                :arg="arg"
                :index="i"
                :step="step"
                :step-index="index"
            />
        </div>
    </div>
</template>

<script>
import Address from "@/components/Address";
import Clipboard from "@/components/Clipboard";
import DumbappArgumentView from "@/components/dumbapp/argument/DumbappArgumentView";
import { getErrorComponent } from "@/components/dumbapp/error/components";
import { ErrorContext } from "@/components/dumbapp/error/ErrorContext";
import ResolveStepsMixin from "@/components/dumbapp/ResolveStepsMixin";
import ProgressCircle from "@/components/ProgressCircle";
import { getTokenData } from "@/lib/eth/Token";
import { getChain } from "@blockwell/chains";
import {
    ArgumentValueProvider,
    convertFromValue,
    stepStatus,
    isDumbappValue,
    resolveAddressSourcable,
    resolveStringSourcable,
    isContractStep,
    createLiteralSource
} from "@blockwell/dumbapp";
import BigNumber from "bignumber.js";
import { mapGetters } from "vuex";

export default {
    name: "DumbappPreviewStep",
    components: { Address, ProgressCircle, DumbappArgumentView, Clipboard },
    inject: ["provider"],
    mixins: [ResolveStepsMixin],
    props: {
        step: Object,
        index: Number,
        submissionStep: Object,
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters("user", ["api"]),
        status() {
            if (this.submissionStep) {
                return stepStatus(this.submissionStep);
            }
            return null;
        },
        networkName() {
            return this.stepNetwork?.networkName;
        },
        chainId() {
            return this.stepNetwork?.chainId;
        },
        explorerLink() {
            if (this.submissionStep?.transactionHash) {
                return this.stepNetwork?.explorerTx(this.submissionStep.transactionHash);
            }
            return null;
        },
        contractName() {
            return this.stepContract?.name || this.dumbappStep?.contractName || this.stepContract?.contractName;
        },
        errorContext() {
            let error = this.submissionStep?.error;
            if (error) {
                return new ErrorContext(this.step, this.submissionStep, error);
            }
            return null;
        },
        errorComponent() {
            if (this.errorContext) {
                return getErrorComponent(this.errorContext);
            } else {
                return null;
            }
        },
        showEth() {
            return !!this.step.value;
        },
        ethSymbol() {
            return this.stepNetwork?.symbol || "ETH";
        },
        ethAddress() {
            if (this.step.ether) {
                return {
                    label: "To",
                    type: "address",
                    name: "account",
                    value: createLiteralSource(this.stepAddress),
                    stepAddress: true,
                };
            }
            return null;
        },
        ethValue() {
            return {
                type: "uint",
                name: "value",
                value: createLiteralSource(this.stepValue),
                symbol: createLiteralSource(this.ethSymbol),
                stepValue: true,
            };
        },
        waitHelp() {
            if (this.stepNetwork?.chainId === 1) {
                return `The transaction will be processed once the necessary ${this.waitForAsset?.symbol} has been deposited
                    by Quick Exchange.`;
            }

            return `The transaction will be processed once the necessary ${this.waitForAsset?.symbol} has been deposited by our system.`;
        },
    },
    asyncComputed: {
        async waitForAsset() {
            if (this.submissionStep?.waitForAsset?.status === "waiting") {
                if (
                    this.submissionStep.waitForAsset.address ===
                    "0x0000000000000000000000000000000000000000"
                ) {
                    return {
                        name: "Ether",
                        symbol: "ETH",
                        decimals: 18,
                        total: new BigNumber(this.submissionStep.waitForAsset.value)
                            .div("1e18")
                            .toFormat(),
                    };
                } else {
                    let res = await getTokenData(this.submissionStep.network, [
                        this.submissionStep.waitForAsset.address,
                    ]);
                    let token = res[0];

                    return {
                        ...token,
                        total: new BigNumber(this.submissionStep.waitForAsset.value)
                            .div(`1e${token.decimals}`)
                            .toFormat(),
                    };
                }
            }
            return null;
        },
    }
};
</script>

<style lang="scss">
@import "~@/assets/css/custom.scss";

div.dumbapp-step {
    padding: 10px 10px 1px 20px;
    line-height: 1.2;

    .method-name {
        margin-top: 0;
    }

    .step-arguments {
        margin: 0;
    }

    .dumbapp-argument {
        margin: 0 0 10px;
    }

    .contract-name-address {
        margin-left: 5px;
    }

    .send-eth {
        margin-bottom: 8px;
        font-style: italic;
        color: var(--text-muted);
    }

    .send-eth-value {
        margin-bottom: 8px;

        & > div {
            display: inline-block;
        }

        ::v-deep( div.dumbapp-argument ) {
            margin: 0 5px;
            font-weight: 600;
        }
    }

    .dumbapp-submission-step {
        margin-bottom: 10px;

        &.confirmed,
        &.confirmed .material-icons {
            color: var(--success);
        }

        &.unknown,
        &.unknown .material-icons {
            color: $warning;
        }

        &.failed,
        &.failed .material-icons {
            color: var(--danger);
        }
    }

    .status-wrap {
        margin-bottom: 8px;
    }

    .status-icon {
        text-align: center;

        .material-icons {
            font-size: 24px;
        }
    }

    .status-text {
        flex: 1 0 auto;
        margin-left: 8px;
        font-size: 24px;
    }

    .explorer-link {
        font-size: 14px;
    }

    .status-icon {
        width: 30px;
    }

    .status-error {
        margin-top: 8px;
    }

    .wait-progress {
        .progress-circular {
            float: left;
            margin-right: 10px;
        }
    }

    .wait-help {
        margin-top: 10px;
        color: var(--secondary);
    }

    .contract-name {
        margin-bottom: 0;
    }

    .contract-network {
        font-size: 0.9em;
        margin-bottom: 10px;

        .contract-network-label {
            color: var(--text-muted);
        }
        .contract-network-name {
            color: var(--secondary);
        }
    }
}
</style>
