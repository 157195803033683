import Info from "@/components/Info.vue";
import RolodexMixin from "@/components/rolodex/RolodexMixin";
import FullRolodexEntry from "./FullRolodexEntry.vue";
import Pagination from "../Pagination.vue";
import Vue from "vue";
export default Vue.extend({
    name: "FullRolodex",
    components: { Pagination, FullRolodexEntry, Info },
    mixins: [RolodexMixin],
    props: {
        requestId: String,
    },
    data() {
        return {
            pageSize: 10,
            page: 1,
            editEntry: null,
            addForm: {},
        };
    },
    computed: {
        addresses() {
            let list = this.wallets.concat(this.contracts);
            list.sort((a, b) => b.created - a.created);
            return list;
        },
        count() {
            return this.addresses.length;
        },
        pages() {
            return Math.ceil(this.count / this.pageSize);
        },
        entries() {
            return this.addresses.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
        },
    },
    methods: {
        edit(entry) {
            this.$bus.emit("rolodex_address", entry);
        },
        select(entry) {
            if (this.requestId) {
                this.$emit("select", {
                    requestId: this.requestId,
                    entry,
                });
            }
        },
        add() {
            this.$store.dispatch("rolodex/saveUnknownAddress", {
                address: this.addForm.address,
            });
            this.$formulate.reset(this.uid);
        },
    },
});
