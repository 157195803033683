import MarkdownIt from 'markdown-it';
import markdownItAttrs from "markdown-it-attrs";
import mila from 'markdown-it-link-attributes';
import center from 'markdown-it-center-text';
import anchor from 'markdown-it-anchor';
import toc from 'markdown-it-toc-done-right';
import slug from 'slug';
import Mermaid from 'mermaid';
import katex from 'katex';
import tex from 'markdown-it-texmath';
import 'katex/dist/katex.min.css';

const md = MarkdownIt({
    linkify: true
});

const pattern = /!([a-z][a-z0-9]*)$/i;

md.use((md) => {
    const defaultRender = md.renderer.rules.link_open || function (tokens, idx, options, env, self) {
        return self.renderToken(tokens, idx, options);
    };

    md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
        let link = tokens[idx];
        let href;
        let hrefIndex = link.attrIndex('href')
        if (hrefIndex >= 0) {
            href = link.attrs[hrefIndex][1];

            let match = pattern.exec(href);
            if (match) {
                let type = match[1].toLowerCase();
                let addClass;
                switch (type) {
                    case 'button':
                        addClass = ' btn btn-primary';
                        break;
                }

                if (addClass) {
                    link.attrs[hrefIndex][1] = href.replace(pattern, '');

                    let classIndex = link.attrIndex('class');

                    if (classIndex < 0) { // attr doesn't exist, add new attribute
                        link.attrPush(['class', addClass])
                    } else {
                        link.attrs[classIndex][1] += addClass;
                    }
                }
            }
        }

        // pass token to default renderer.
        return defaultRender(tokens, idx, options, env, self)
    }
})

// Altered from https://github.com/tylingsoft/markdown-it-mermaid

let idCounter = 0;
const MermaidChart = code => {
    try {
        let render;
        Mermaid.mermaidAPI.render(`mermaid-render-${idCounter++}`, code, sc => {
            render = sc
        });
        return `<div class="mermaid">${render}</div>`;
    } catch (err) {
        console.log(err);
        return `<pre>${err.str}</pre>`;
    }
}

const MermaidPlugIn = (md, opts) => {
    Mermaid.initialize(Object.assign(MermaidPlugIn.default, opts));

    const defaultRenderer = md.renderer.rules.fence.bind(md.renderer.rules);

    md.renderer.rules.fence = (tokens, idx, opts, env, self) => {
        const token = tokens[idx]
        const code = token.content.trim();
        if (token.info === 'mermaid' || token.info === 'chart') {
            return MermaidChart(code)
        }
        return defaultRenderer(tokens, idx, opts, env, self)
    }
}

MermaidPlugIn.default = {
    startOnLoad: false,
    theme: "default",
    themeCSS:'.label { font-family: "Source Sans Pro", sans-serif; } .edgeLabel { background: none; } .edgeLabel rect { opacity: 0.9; }',
    flowchart: {
        curve: 'cardinal'
    }
}

md.use(mila, {
    pattern: /^https?:\/\//i,
    attrs: {
        target: '_blank',
        class: 'external-link'
    }
});

md.use(center);
md.use(anchor, {permalink: true, permalinkSymbol: '#', permalinkBefore: true, slugify: slug});
md.use(toc, {slugify: slug});
md.use(MermaidPlugIn);
md.use(tex, {engine: katex})
md.use(markdownItAttrs, {
    allowedAttributes: ['id', 'class', 'width', 'height']
});

let svgbob;

/**
 *
 * @return {Promise<MarkdownIt>}
 */
export default async function init() {
    return md;
};
