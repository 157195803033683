import Vue from "vue";
import MobileNav from "./MobileNav.vue";
import SubmissionsDropdown from "../../components/SubmissionsDropdown.vue";
import ContractHistoryDropdown from "../../components/ContractHistoryDropdown.vue";
import MessagesDropdown from "../../components/message/MessagesDropdown.vue";
import AccountDropdown from "../../components/AccountDropdown.vue";
import Mailchimp from "../../components/Mailchimp.vue";
import MainMenu from "../../components/MainMenu.vue";
export default Vue.extend({
    name: "WhitelabelMainHeader",
    components: { MainMenu, Mailchimp, AccountDropdown, MessagesDropdown, ContractHistoryDropdown, MobileNav, SubmissionsDropdown },
    props: {
        icon: String,
        external: String
    },
    data() {
        return {};
    },
    computed: {
        loggedIn() {
            return this.$store.getters['user/loggedIn'];
        },
        email() {
            return this.$store.state.user.email;
        }
    }
});
