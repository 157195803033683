<template>
    <div>
        <div v-if="showValue" class="plain-argument">
            <div class="value">{{ parsedValue ? 'Yes' : 'No' }}</div>
        </div>
        <slot v-else/>
    </div>
</template>

<script>
    export default {
        name: "BooleanArgument",
        props: {
            value: [String, Boolean]
        },
        computed: {
            parsedValue() {
                if (this.value === true || this.value === false) {
                    return this.value;
                }
                if (typeof this.value === 'string' || this.value instanceof String) {
                    switch (this.value.toLowerCase()) {
                        case '':
                        case '0':
                        case 'false':
                        case 'no':
                            return false;
                        default:
                            return true;
                    }
                }
                return !!this.value;
            },
            showValue() {
                return this.value !== undefined && this.value !== null;
            }
        }
    }
</script>

<style scoped>
</style>
