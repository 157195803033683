import abi from "@/assets/data/CommunityToken.abi.json";
import {Batcher} from "@/lib/eth/Batcher";
import Dapp from "@/assets/lib/Dapp";
import BigNumber from "bignumber.js";

/**
 * @param network
 * @param address
 * @param suggestionId
 * @return {Promise<Object>}
 */
export function getSuggestion(network, address, suggestionId) {
    const net = Dapp.getNetwork(network);
    const web3 = net.web3;

    const batcher = new Batcher(web3);

    batcher.setContract(abi, address)
        .add('getSuggestionText', 'text', [suggestionId])
        .add('isProposal', 'proposal', [suggestionId])
        .add('getVotes', 'votes', [suggestionId])
        .add('getProposalVotes', 'proposalVotes', [suggestionId]);

    return batcher.execute();
}

export async function getAllSuggestions(network, address) {
    const net = Dapp.getNetwork(network);
    const web3 = net.web3;
    const contract = new web3.eth.Contract(abi, address);

    const count = parseInt(await contract.methods.suggestionCount().call());
    let ids = [];

    let batch = new web3.BatchRequest();

    batch.add(contract.methods.decimals().call.request());

    for (let i = 0; i < count; i++) {
        batch.add(contract.methods.getSuggestionText(i).call.request());
        batch.add(contract.methods.getVotes(i).call.request());
        batch.add(contract.methods.isProposal(i).call.request());
        batch.add(contract.methods.getProposalVotes(i).call.request());
        ids.push(i);
    }

    let res;
    try {
        let result = await batch.execute();
        res = result?.response;
    } catch (err) {
        res = err.response.map(it => it.jsonrpc ? it.result : it);
    }

    let i = 0;
    let decimals = res[i++];
    let suggestions = [];

    for (let suggestionId of ids) {
        let it = {
            id: suggestionId,
            text: res[i++],
            votes: new BigNumber(res[i++]),
            proposal: res[i++],
            proposalVotes: new BigNumber(res[i++]).div(`1e${decimals}`)
        };

        suggestions.push(it);
    }

    return suggestions;
}
