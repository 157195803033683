import Vue from "vue";
import MarkdownEditor from "../../views/happs/book/editors/MarkdownEditor.vue";
export default Vue.extend({
    name: "FormulateMarkdown",
    components: { MarkdownEditor },
    props: {
        context: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            rawValue: "",
        };
    },
    watch: {
        "context.model": {
            immediate: true,
            handler(val) {
                if (val !== this.rawValue) {
                    this.rawValue = val;
                    this.cleave?.setRawValue(val);
                }
            },
        },
        rawValue(val) {
            this.context.model = val;
            this.$emit("value", val);
        },
    },
});
