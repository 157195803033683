import { addDefaultStepCreator, defaultExtrasProvider, EthSubmissionStep, getWindow, metamaskFactory, WalletProvider, } from "@blockwell/dumbapp";
import { SwapperExtraProcessor } from "@/components/dumbapp/confirmation/SwapperExtra";
import { blockwellFactory, WalletSubmissionStep } from "@blockwell/dumbapp-wallet";
addDefaultStepCreator("wallet", (data, index) => {
    return new WalletSubmissionStep(data, index);
});
addDefaultStepCreator("metamask", (data, index) => {
    return new EthSubmissionStep(data, index);
});
defaultExtrasProvider.addExtra(new SwapperExtraProcessor());
export const walletProvider = new WalletProvider(getWindow());
walletProvider.addType(blockwellFactory).addType(metamaskFactory);
