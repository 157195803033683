<template>
    <div class="suggestion-selection">
        <ProgressCircle v-if="loading" />
        <div v-else class="suggestion-list">
            <div v-for="sug in filtered" class="suggestion row no-gutters" @click="selected(sug)">
                <div class="col-6 suggestion-id">
                    {{ sug.proposal ? "Proposal" : "Suggestion" }} #{{ sug.id }}
                </div>
                <div class="col-3">
                    <span class="suggestion-tag">
                        <span class="badge badge-secondary">{{ sug.tag }}</span>
                    </span>
                </div>
                <div class="col-3 suggestion-votes">{{ sug.votes }} Votes</div>
                <div class="suggestion-text-wrap col-12">
                    <ClampedText>
                        {{ sug.text }}
                    </ClampedText>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClampedText from "@/components/ClampedText";
import ProgressCircle from "@/components/ProgressCircle";
import * as voting from "@/lib/voting";
import { InputController } from "@blockwell/dumbapp";

export default {
    name: "SuggestionSelection",
    components: { ClampedText, ProgressCircle },
    props: {
        field: Object,
        provider: InputController,
    },
    data() {
        return {
            loading: true,
            suggestions: []
        };
    },
    computed: {
        filtered() {
            if (this.suggestions) {
                let filter;
                if (this.field.type === "suggestion") {
                    filter = (it) => !it.proposal;
                } else if (this.field.type === "proposal") {
                    filter = (it) => !!it.proposal;
                }

                if (filter) {
                    return this.suggestions.filter(filter);
                } else {
                    return this.suggestions;
                }
            }
            return [];
        },
    },
    methods: {
        selected(suggestion) {
            this.$emit("selected", suggestion);
        },
    },
    mounted() {
        voting.getAllSuggestions(this.field.network, this.field.address)
            .then(res => {
                this.loading = false;
                this.suggestions = res;
            })
    },
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.suggestion-list {
    .suggestion {
        cursor: pointer;
        padding: 16px;
        border-bottom: 1px solid $grey;

        .suggestion-id {
            color: $secondary;
        }

        .suggestion-text {
            margin-top: 5px;
        }

        &:hover {
            background-color: $grey;
        }
    }
}
</style>
