import erc20Abi from "@/assets/data/Erc20.abi.json";
import balanceAbi from "@/assets/data/BalanceToken.abi.json";
import Dapp from "@/assets/lib/Dapp";
import { Batcher } from "@/lib/eth/Batcher";
import BigNumber from "bignumber.js";
import groupsAbi from "@/assets/data/Groups.abi.json";
import mem from "mem";
import { getChain } from "@blockwell/chains";
export async function getTokenData(net, addresses, balanceFor, skipCache) {
    let addressList;
    let network = getChain(net).chainId;
    if (Array.isArray(addresses)) {
        addressList = addresses;
    }
    else {
        addressList = [addresses];
    }
    let tokens = [];
    let web3 = Dapp.getNetwork(network).web3;
    let batch = new web3.BatchRequest();
    let inBatch = [];
    let balanceBatch = [];
    for (let address of addressList) {
        const storageKey = `token.${network}.${address}`;
        let data;
        try {
            data = JSON.parse(localStorage.getItem(storageKey));
        }
        catch (err) {
            console.error(err);
        }
        if (!data || skipCache) {
            const assetContract = new web3.eth.Contract(erc20Abi, address);
            batch.add(assetContract.methods.bwtype().call.request());
            batch.add(assetContract.methods.bwver().call.request());
            batch.add(assetContract.methods.name().call.request());
            batch.add(assetContract.methods.symbol().call.request());
            batch.add(assetContract.methods.decimals().call.request());
            if (balanceFor) {
                balanceBatch.push(assetContract.methods.balanceOf(balanceFor).call.request());
            }
            data = {
                network,
                address,
                name: undefined,
                symbol: undefined,
                decimals: undefined
            };
            inBatch.push(data);
        }
        else if (balanceFor) {
            const assetContract = new web3.eth.Contract(erc20Abi, address);
            balanceBatch.push(assetContract.methods.balanceOf(balanceFor).call.request());
        }
        tokens.push(data);
    }
    for (let it of balanceBatch) {
        batch.add(it);
    }
    if (inBatch.length > 0 || balanceBatch.length > 0) {
        let res;
        try {
            let result = await batch.execute();
            res = result?.response;
        }
        catch (err) {
            console.log("Caught error in batch calling ERC-20 functions, probably not an ERC-20 contract", err.message);
            res = err.response.map((it) => (it.jsonrpc ? it.result : it));
        }
        let i = 0;
        if (inBatch.length > 0) {
            for (let token of inBatch) {
                token.bwtype = parseInt(res[i++]);
                token.bwver = parseInt(res[i++]);
                token.name = res[i++];
                token.symbol = res[i++];
                token.decimals = parseInt(res[i++]);
                fixTokenName(token);
                if (token.name && token.symbol && token.decimals) {
                    localStorage.setItem(`token.${token.network}.${token.address}`, JSON.stringify(token));
                }
            }
        }
        if (balanceBatch.length > 0) {
            for (let token of tokens) {
                let bal = res[i++];
                token.balance = bal ? bal.toString() : bal;
            }
        }
    }
    return tokens;
}
export async function getTokenBalances(network, contracts, wallet) {
    if (contracts.length === 0) {
        return [];
    }
    const web3 = Dapp.getNetwork(network).web3;
    let batch = new web3.BatchRequest();
    for (let address of contracts) {
        if (address === "0x0" || address === "0x0000000000000000000000000000000000000000") {
            batch.add(web3.eth.getBalance.request(wallet));
        }
        else {
            const contract = new web3.eth.Contract(erc20Abi, address);
            batch.add(contract.methods.balanceOf(wallet).call.request());
        }
    }
    return (await batch.execute()).response.map((it) => (it ? new BigNumber(it.toString()) : ""));
}
export async function getAvailableBalances(network, contracts, wallet) {
    if (contracts.length === 0) {
        return [];
    }
    const web3 = Dapp.getNetwork(network).web3;
    let batch = new web3.BatchRequest();
    for (let address of contracts) {
        if (address !== "0x0" && address !== "0x0000000000000000000000000000000000000000") {
            const contract = new web3.eth.Contract(balanceAbi, address);
            batch.add(contract.methods.availableBalanceOf(wallet).call.request());
        }
    }
    let res;
    try {
        let result = await batch.execute();
        res = result?.response;
    }
    catch (err) {
        console.log("Caught error in batch calling for availableBalanceOf", err);
        res = err.response.map((it) => {
            if (it && !it.error) {
                return it;
            }
            return null;
        });
    }
    let balances = [];
    let i = 0;
    for (let address of contracts) {
        if (address !== "0x0" && address !== "0x0000000000000000000000000000000000000000") {
            let value = res[i++];
            if (value) {
                balances.push(new BigNumber(value));
            }
            else {
                balances.push(null);
            }
        }
        else {
            balances.push(null);
        }
    }
    return balances;
}
const _getUserGroups = async (network, contractAddress, wallet) => {
    const web3 = Dapp.getNetwork(network).web3;
    let batcher = new Batcher(web3);
    batcher.setContract(groupsAbi, contractAddress);
    for (let it of groupsAbi) {
        let group = it.name.slice(2);
        batcher.addBoolean(it.name, group, [wallet]);
    }
    let groups = await batcher.execute();
    return groups;
};
export const getUserGroups = mem(_getUserGroups, {
    cacheKey: JSON.stringify,
    maxAge: 60000,
});
function fixTokenName(token) {
    if (token.address.toLowerCase() === "0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2") {
        token.name = "Maker";
        token.symbol = "MKR";
    }
}
