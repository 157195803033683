import BigNumber from "bignumber.js";
import moment from "moment";
import { BigNumber as EthersBig } from "ethers/utils";
export function hasHex(val) {
    return val?._hex && typeof val._hex === "string";
}
export class Batcher {
    constructor(web3) {
        this.mapping = [];
        this.subs = [];
        this.web3 = web3;
        this.batch = new web3.BatchRequest();
    }
    setContract(abiOrContract, address) {
        if ("address" in abiOrContract) {
            this.contract = abiOrContract;
        }
        else {
            this.contract = new this.web3.eth.Contract(abiOrContract, address);
        }
        return this;
    }
    add(method, name, args = [], callback) {
        this._add(method, name, args, "string", callback);
        return this;
    }
    try(method, name, args = [], callback) {
        this._add(method, name, args, "string", callback, true);
        return this;
    }
    addInt(method, name, args = [], callback) {
        this._add(method, name, args, "int", callback);
        return this;
    }
    addBig(method, name, args = [], callback) {
        this._add(method, name, args, "bignumber", callback);
        return this;
    }
    tryBig(method, name, args = [], callback) {
        this._add(method, name, args, "bignumber", callback, true);
        return this;
    }
    addBigArray(method, name, args = [], callback) {
        this._add(method, name, args, "bignumber", callback);
        return this;
    }
    addTimestamp(method, name, args = [], callback) {
        this._add(method, name, args, "timestamp", callback);
        return this;
    }
    addBoolean(method, name, args = [], callback) {
        this._add(method, name, args, "boolean", callback);
        return this;
    }
    addStructArray(method, name, args = [], callback) {
        this._add(method, name, args, "struct[]", callback);
        return this;
    }
    tryStructArray(method, name, args = [], callback) {
        this._add(method, name, args, "struct[]", callback, true);
        return this;
    }
    addList(method, name, count, opt = {}, convert) {
        let start = opt.start || 0;
        let end = typeof count === "number" ? count : opt.argsList.length;
        let map = {
            key: name || method,
            count: end,
            type: "list",
            start,
            items: convert || "string",
        };
        this.addListMapping(method, map, opt);
        return this;
    }
    addListConvert(method, convert, name, count, opt = {}) {
        let start = opt.start || 0;
        let end = typeof count === "number" ? count : opt.argsList.length;
        let map = {
            key: name || method,
            count: end,
            type: "list",
            start,
            convert: convert,
        };
        this.addListMapping(method, map, opt);
        // @ts-ignore
        return this;
    }
    addListMapping(method, map, opt) {
        let calls = [];
        let m = this.contract.methods[method].bind(this.contract.methods);
        let argsPos = 0;
        for (let i = map.start; i < map.count; i++) {
            let args;
            if (opt.args) {
                args = [];
                for (let it of opt.args) {
                    if (it === true) {
                        args.push(i.toString());
                    }
                    else {
                        args.push(it);
                    }
                }
            }
            else if (opt.argsList) {
                args = opt.argsList[argsPos++];
            }
            else {
                args = [i.toString()];
            }
            calls.push(m(...args).call.request());
        }
        this._addCalls(calls, map);
    }
    addObjectList(name, count, props, start = 0, itemType = Object) {
        let argsList = [];
        let map = {
            key: name,
            count,
            props,
            type: "objectlist",
            args: argsList,
            start,
            itemType,
        };
        let calls = [];
        for (let [key, val] of Object.entries(props)) {
            if (val.method) {
                let argsPos = 0;
                let method = this.contract.methods[val.method].bind(this.contract.methods);
                for (let i = start; i < count; i++) {
                    let args;
                    if (val.argsList) {
                        args = val.argsList[argsPos++];
                    }
                    else if (val.args) {
                        args = [];
                        for (let it of val.args) {
                            if (it === true) {
                                args.push(i.toString());
                            }
                            else {
                                args.push(it);
                            }
                        }
                    }
                    else {
                        args = [i.toString()];
                    }
                    argsList.push(args);
                    calls.push(method(...args).call.request());
                }
            }
        }
        this._addCalls(calls, map);
        // @ts-ignore
        return this;
    }
    subBatcher(type = Object) {
        let index = this.subs.length;
        let sub = new SubBatcher(this.web3, this, index, type);
        this.subs.push(sub);
        return sub;
    }
    _add(method, name, args = [], type, callback, softFail) {
        let map = {
            type,
            callback,
            key: name || method,
        };
        if (!this.contract.methods[method]) {
            if (softFail) {
                map.softFailed = true;
                this._addMapping(map);
            }
            else {
                throw new Error(`ABI does not have the method '${method}'`);
            }
        }
        else {
            let call = this.contract.methods[method].apply(this.contract.methods, args).call.request();
            this._addCalls(call, map);
        }
    }
    _addCalls(call, map) {
        if (Array.isArray(call)) {
            for (let it of call) {
                this._addToBatch(it);
            }
        }
        else {
            this._addToBatch(call);
        }
        this._addMapping(map);
    }
    _addMapping(map) {
        this.mapping.push(map);
    }
    _addToBatch(call) {
        this.batch.add(call);
    }
    async execute(type) {
        let data;
        if (type) {
            data = new type();
        }
        else {
            data = {};
        }
        if (this.mapping.length > 0) {
            let res;
            try {
                let result = await this.batch.execute();
                res = result?.response;
            }
            catch (err) {
                res = err.response.map((it) => (it?.jsonrpc ? it.result : it));
            }
            return this._mapResponses(res, data);
        }
        else {
            return data;
        }
    }
    _mapResponses(responses, data) {
        let res = [].concat(responses);
        for (let map of this.mapping) {
            let obj = data;
            if (map.sub !== undefined) {
                // @ts-ignore Need to implement better sub handling
                if (!data.subs) {
                    // @ts-ignore
                    data.subs = [];
                }
                let sub = this.subs[map.sub];
                // @ts-ignore
                obj = data.subs[map.sub];
                if (!obj) {
                    // @ts-ignore
                    obj = new sub.type();
                    // @ts-ignore
                    data.subs[map.sub] = obj;
                }
            }
            this._processMapping(res, obj, map);
        }
        return data;
    }
    _processMapping(res, data, map) {
        let { type, callback } = map;
        // @ts-ignore
        let key = map.key;
        if (data[key]) {
            throw new Error(`Batcher data already has the key ${key.toString()}`);
        }
        if (type === "objectlist") {
            let list = [];
            for (let i = map.start; i < map.count; i++) {
                let item = new map.itemType();
                item.id = i;
                list.push(item);
            }
            let argsPos = 0;
            for (let [key, val] of Object.entries(map.props)) {
                for (let j = 0; j < map.count - map.start; j++) {
                    if (val.method) {
                        let args = map.args[argsPos++];
                        if (val.convert) {
                            val.convert(res.shift(), list[j], args);
                        }
                        else {
                            list[j][key] = this._parseType(res.shift(), val.type);
                        }
                    }
                    else if (typeof val.value === "function") {
                        list[j][key] = val.value(list[j]);
                    }
                    else if (val.valueList) {
                        list[j][key] = val.valueList[j];
                    }
                    else {
                        list[j][key] = val.value;
                    }
                }
            }
            // @ts-ignore
            data[key] = list;
        }
        else if (type === "list") {
            let list = [];
            for (let j = map.start; j < map.count; j++) {
                let value = res.shift();
                if (map.convert) {
                    list.push(map.convert(value, j));
                }
                else {
                    list.push(this._parseType(value, map.items));
                }
            }
            // @ts-ignore
            data[key] = list;
        }
        else if (map.softFailed) {
            data[key] = null;
        }
        else {
            let parsed = this._parseType(res.shift(), type);
            // @ts-ignore
            data[key] = parsed;
            if (callback) {
                callback(data[key]);
            }
        }
    }
    _parseType(val, type) {
        if (type === "struct") {
            return this._parseSingle(val, "struct");
        }
        if (type === "struct[]" && Array.isArray(val)) {
            return val.map((it) => {
                return this._parseSingle(it, "struct");
            });
        }
        if (Array.isArray(val)) {
            return val.map((it) => {
                return this._parseSingle(it, type);
            });
        }
        return this._parseSingle(val, type);
    }
    _parseSingle(val, type) {
        if (!type || type === "string") {
            return val?.toString();
        }
        if (type === "struct") {
            let converted = {};
            for (let [key, value] of Object.entries(val)) {
                if (!/^\d+$/.test(key)) {
                    if (hasHex(value)) {
                        converted[key] = new BigNumber(value._hex);
                    }
                    else {
                        converted[key] = value;
                    }
                }
            }
            return converted;
        }
        if (type === "int") {
            if (EthersBig.isBigNumber(val)) {
                return val.toNumber();
            }
            else if (typeof val === "number" || typeof val === "string") {
                return new BigNumber(val).toNumber();
            }
            else if (hasHex(val)) {
                return new BigNumber(val._hex).toNumber();
            }
            else {
                return NaN;
            }
        }
        if (type === "bignumber") {
            if (EthersBig.isBigNumber(val)) {
                return new BigNumber(val.toString());
            }
            else if (typeof val === "object") {
                if (hasHex(val)) {
                    return new BigNumber(val._hex);
                }
            }
            else if (typeof val === "boolean") {
                return new BigNumber(NaN);
            }
            else {
                return new BigNumber(val);
            }
        }
        if (type === "timestamp") {
            if (EthersBig.isBigNumber(val)) {
                return moment.unix(val.toNumber());
            }
            else if (typeof val === "number" || typeof val === "string") {
                return moment.unix(new BigNumber(val).toNumber());
            }
            else if (hasHex(val)) {
                return moment.unix(new BigNumber(val._hex).toNumber());
            }
            else {
                return null;
            }
        }
        if (type === "boolean") {
            return val === true || val === "true";
        }
        return null;
    }
}
class SubBatcher extends Batcher {
    constructor(web3, main, index, type = Object) {
        super(web3);
        this.main = main;
        this.index = index;
        this.type = type;
    }
    _addMapping(map) {
        map.sub = this.index;
        this.main.mapping.push(map);
    }
    _addToBatch(call) {
        this.main.batch.add(call);
    }
    subBatcher(type = Object) {
        throw new Error("SubBatcher can't have its own SubBatcher.");
    }
}
