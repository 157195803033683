<template>
    <div>
        <FormulateInput
            :name="field.name"
            :id="id"
            :validation-name="field.label"
            type="text"
            validation="bail|required|matches:/^0x[a-fA-F0-9]{40}$/"
            :validation-messages="{ matches: 'Must be a valid Ethereum address.'}"
            error-behavior="blur"
            class="dumbapp-address-input"
            autocomplete="off"
            @blur="blur"
            @input="input"
            @focus="focus"
            @keypress.enter="showRolodex = false"
            @keydown.tab="showRolodex = false"
            v-on="$listeners">
            <template #label>
                <slot name="label"></slot>
            </template>
            <template #help>
                <slot name="help"></slot>
            </template>
            <template #suffix>
                <Rolodex v-if="showRolodex" :show.sync="showRolodex" :fieldId="id" @address="rolodexAddress" :search="value" />
            </template>
        </FormulateInput>
        <Modal fullscreen :show.sync="showScanner">
            <template #header>
                <h5>Scan Address QR Code</h5>
            </template>
            <AddressQrScanner @address="scannedAddress" />
        </Modal>
    </div>
</template>

<script>
import Rolodex from "@/components/rolodex/Rolodex";
import { addressRegex } from "@blockwell/chain-client";
import QrScanner from "qr-scanner";
import AddressQrScanner from "@/components/AddressQrScanner";
import Modal from "@/components/Modal";

export default {
    name: "AddressInput",
    components: { Rolodex, AddressQrScanner, Modal },
    props: {
        id: {
            type: String,
            required: true
        },
        field: Object
    },
    data() {
        return {
            showScanner: false,
            showRolodex: false,
            value: ""
        };
    },
    methods: {
        blur(ev) {
            let val = ev.target.value;
            if (addressRegex.test(val)) {
                this.$store.dispatch("rolodex/saveUnknownAddress", { address: val });
            }
        },
        input(val) {
            this.value = val;
        },
        focus() {
            this.showRolodex = true;
        },
        scannedAddress(address) {
            this.$emit("updated", {
                val: address,
                field: this.field
            });
            this.showScanner = false;
        },
        rolodexAddress(entry) {
            this.$emit("updated", {
                val: entry.address,
                field: this.field
            });
        }
    },
    mounted() {
        QrScanner.hasCamera()
            .then(camera => {
                if (camera) {
                    // vue-formulate doesn't provide a slot this could go in, so we need to insert it
                    $(`<img class="dumbapp-address-qr"
                        src="${require("../../../assets/images/icon-QR.svg")}"
                        width="24"
                        alt="Scan Address QR Code">`)
                        .on("click", () => {
                            this.showScanner = true;
                        })
                        .insertAfter("#" + this.id);
                } else if (process.env.NODE_ENV === "development") {
                    $(`<img class="dumbapp-address-qr"
                        src="${require("../../../assets/images/icon-QR.svg")}"
                        width="24"
                        alt="Scan Address QR Code">`)
                        .on("click", () => {
                            this.scannedAddress("0x0000000000000000000000000000000000000000");
                        })
                        .insertAfter("#" + this.id);
                }
            });
    }
};
</script>

<style lang="scss">
.dumbapp-address-input {
    .formulate-input-element {
        position: relative;

        .dumbapp-address-qr {
            position: absolute;
            top: calc(50% - 12px);
            right: 24px;
            cursor: pointer;
        }
    }
}
</style>
