import abi from "@/assets/data/Prices.abi.json";
import Bwns from "@/lib/api/Bwns";
import Dapp from "@/assets/lib/Dapp";
import { Batcher } from "@/lib/eth/Batcher";
import { Chain } from "@blockwell/chain-client";
import BigNumber from "bignumber.js";
import axios from "axios";
let ns;
export const ETH_ADDRESS = "0x0000000000000000000000000000000000000000";
export async function getPrices(tokens) {
    let contract = await getPricesContract();
    let batch = new Batcher(Dapp.getNetwork(contract.network).web3)
        .setContract(abi, contract.address)
        .addListConvert("latestPrice", (value, index) => {
        let address = tokens[index];
        let price = null;
        if (Array.isArray(value)) {
            price = new BigNumber(value[1].toString()).div("1e8");
        }
        return { address, price };
    }, false, tokens.length, {
        argsList: tokens.map((it) => [it]),
    });
    let data = await batch.execute();
    let prices = {};
    for (let it of data.latestPrice) {
        prices[it.address.toLowerCase()] = it.price;
    }
    return prices;
}
export async function getPrice(address) {
    let contract = await getPricesContract();
    let res = await Chain.readStringArray(contract.network, contract.address, abi, "latestPrice", [
        address,
    ]);
    let price = new BigNumber(res?.[1]);
    if (price.isNaN() || price.eq(0)) {
        return null;
    }
    return price.div("1e8");
}
export async function getPricesContract() {
    let bwns = new Bwns();
    if (!ns) {
        ns = (await bwns.resolve("primary", "prices"));
    }
    return ns;
}
let ethPrice;
let ethPriceTimestamp;
export async function getEtherPrice() {
    if (!ethPrice || ethPriceTimestamp < Date.now() - 600 * 1000) {
        let res = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd");
        ethPrice = new BigNumber(res.data.ethereum.usd);
        ethPriceTimestamp = Date.now();
    }
    return ethPrice;
}
