
export default class Config {

    /**
     *
     * @param {Api} api
     */
    constructor(api) {
        this.api = api;
        /**
         *
         * @type {Object}
         * @private
         */
        this._config = null;
    }

    async get(name) {
        await this._loadConfig();

        return this._config[name];
    }

    async _loadConfig() {
        if (!this._config) {
            this._config = await this.api.getConfig();
        }
    }
}
