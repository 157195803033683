import Vue from "vue";
import ResolveStepsMixin from "@/components/dumbapp/ResolveStepsMixin";
export const submitProps = {
    showCancel: Boolean,
    disabled: Boolean,
    loading: Boolean,
    hideGas: Boolean,
    hideSend: Boolean,
    largeButtons: Boolean,
    sendLabel: {
        type: String,
        default: "Send"
    },
    sendColor: String
};
export const allSubmitProps = {
    ...submitProps,
    network: [String, Number],
    disableWallet: String,
    strategy: String,
    requireWalletAddress: String,
    priority: String,
};
export default Vue.extend({ inject: {
        provider: {
            default: null
        },
        walletHolder: {
            default: null,
        },
    },
    mixins: [ResolveStepsMixin],
    props: submitProps,
    data() {
        return {
            errorMessage: null,
        };
    },
    computed: {
        dumbapp() {
            return this.provider?.dumbapp;
        },
        wallet() {
            return this.walletHolder?.wallet;
        },
        account() {
            return this.walletHolder?.account;
        },
        state() {
            return this.walletHolder?.state;
        },
        chainId() {
            return this.walletHolder?.chainId;
        }
    },
    methods: {
        cancel() {
            this.$emit("cancel");
        },
        send() {
            if (!this.disabled) {
                this.provider?.execution?.execute();
            }
        },
    },
    mounted() {
        const controller = this.provider;
        if (controller) {
            controller.execution.addHandler("SubmitMixin-" + this.uid, "result", async (result) => {
                if (result.success === false) {
                    switch (result.code) {
                        case "validation_error":
                            this.errorMessage = "Invalid input, check your form fields.";
                            break;
                        default:
                            this.errorMessage =
                                result.message || "Error when submitting: " + result.code;
                            break;
                    }
                }
            });
        }
    },
    beforeDestroy() {
        this.provider?.execution?.removeHandler("SubmitMixin-" + this.uid);
    }, });
