import {delay} from "@blockwell/util";

export async function getEthereum() {
    let ethereum = await loadEthereum();

    if (ethereum) {
        ethereum.autoRefreshOnNetworkChange = false;
    }

    return ethereum;
}

export async function getMetamaskAccount() {
    try {
        let ethereum = await getEthereum();

        if (ethereum) {
            let accounts = await ethereum.request({method: 'eth_accounts'});

            if (accounts.length > 0) {
                return accounts[0];
            }
        }
    } catch (err) {
        console.error(err);
    }

    return null;
}

async function loadEthereum() {
    if (window.ethereum) {
        return window.ethereum;
    }

    return Promise.any([
        delay(500),
        new Promise(resolve => {
            window.addEventListener('ethereum#initialized', ethereum => {
                console.log('ethereum#initialized', ethereum);
                resolve(ethereum);
            }, {once: true});
        })
    ]);
}
