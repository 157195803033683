<template>
    <div class="dumbapp-input">
        <component
            :is="useComponent"
            :field="field"
            :id="uid"
            v-on="$listeners">
            <template #label>
                <label :for="uid">{{ field.label }}</label>
            </template>
            <template #help>
                <div class="formulate-input-help" :class="{'array-input-help': isArray}">
                    {{ field.help }}
                </div>
            </template>
        </component>
    </div>
</template>

<script>
import ArrayInput from "@/components/dumbapp/input/ArrayInput";
import InputMixin from "@/components/dumbapp/input/InputMixin";

export default {
    name: "DumbappInput",
    mixins: [InputMixin],
    provide() {
        return {
            field: this.field
        }
    },
    components: {ArrayInput},
    computed: {
        isArray() {
            return !!this.field?.typing?.array;
        },
        useComponent() {
            if (this.isArray) {
                return ArrayInput;
            }
            return this.component;
        }
    }
}
</script>

<style lang="scss">
.dumbapp-input {

    .formulate-input {
        margin-bottom: 0;
    }

    .formulate-input-help {
        margin-bottom: 1rem;
    }

    .array-input-help {
        margin-bottom: 5px;
    }
}
</style>
