<template>
    <div class="message" :class="typeClass" :data-id="message.id" :data-order="order">
        <div v-if="message.dismissable" class="message-dismiss">
            <a href="javascript: void 0;" class="message-dismiss-link" @click="dismiss">
                <i class="material-icons">close</i>
            </a>
        </div>
        <div class="message-header">
            <div v-if="showTime" class="message-time">{{time}}</div>
            <div v-if="showNew" class="message-unread">new</div>
        </div>
        <div class="message-content" v-html="message.message"/>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: "Message",
        props: {
            message: Object,
            news: Boolean,
            showTime: Boolean
        },
        data() {
            return {
                yesterday: moment().subtract(1, 'days'),
                interval: null
            };
        },
        computed: {
            typeClass() {
                return `message-${this.message.type}`;
            },
            moment() {
                return moment(this.message.t);
            },
            order() {
                this.moment.valueOf();
            },
            time() {
                if (this.moment.isBefore(this.yesterday)) {
                    return this.moment.format('MMM D, h:mm a');
                } else {
                    return this.moment.fromNow();
                }
            },
            showNew() {
                return !this.message.read && this.news;
            }
        },
        methods: {
            dismiss() {
                this.$emit('dismiss', this.message);
            }
        },
        created() {
            this.interval = setInterval(() => this.yesterday = moment().subtract(1, 'days'), 60000);
        },
        destroyed() {
            clearInterval(this.interval);
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/css/custom";

    .message {
        @include mdElevation(3);
        background: #fff;
        margin: 10px 0;
        padding: 10px 15px 0;


        .message-header {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .message-time {
            font-size: 14px;
            color: $text-muted;
            flex: 1 1 auto;
        }

        .message-unread {
            color: $white;
            background: $primary;
            padding: 0 3px 2px;
            border-radius: 3px;
            line-height: 1.2;
            font-size: 14px;
            font-weight: bold;
            margin-right: 15px;
        }

        .message-dismiss {
            flex: 0 0 28px;
            text-align: right;
            float: right;

            i {
                margin: 0;
                font-size: 16px;
            }
        }

        .message-content {
            line-height: 1.5;
        }
    }

    a {
        color: $primary;
    }

    i {
        color: #020a1e;
    }


    .messages-wrapper {

        .message {
            @include mdElevation(3);
            background: #fff;
            margin: 0 0 10px;
            padding: 15px 15px 1px;

            &:last-child {
                margin-bottom: 30px;
            }

            .message-header {
                float: right;
            }

            .message-time {
                display: none;
            }

            .message-unread {
                display: none;
            }

            .message-dismiss {
                text-align: right;

                i {
                    margin: 0;
                    font-size: 16px;
                    color: #020a1e;
                }
            }

            .message-content {
                line-height: 1.5;
            }
        }
    }

    .message {
        border: 1px solid transparent;
        border-left: 6px solid $secondary;
    }

    .message-warn {
        border-left-color: $warning;
    }

    .message-error {
        border-color: rgba(220, 53, 69, 0.27);
        border-left-color: $danger;
    }

    .message-success {
        border-left-color: $success;
    }
</style>
