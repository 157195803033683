<template>

    <div class="contrib-method">
        <h3>
            <img src="../../assets/images/bitcoin.png" alt="" width="45"
                 style="margin-right: 5px;"/>
            Bitcoin
        </h3>
        <p>
            Contribute Bitcoin.
        </p>
        <Clipboard address="33amLdcvFHybJJB6nMkQsBcDpkb6epfSJx" class="contrib-address"/>
        <div class="any-wallet-description">
            Any Bitcoin you send to the above address will be credited to you and you'll
            receive {{multiplier}}x PRIDE based on the USD price at the time of sending (pending KYC passing).
        </div>
    </div>
</template>

<script>
import Clipboard from "@/components/Clipboard";

export default {
    name: "BitcoinOffer",
    components: {Clipboard},
    props: {
        multiplier: Number
    },
    data() {
        return {};
    }
}
</script>

<style scoped lang=scss>
@import "~@/assets/css/custom.scss";

.contrib-address {
    border: 1px solid rgba($primary, .3);
    @include mdElevation(1);
    padding: 10px;
    margin-top: 10px;
    @media screen and (max-width: 374px) {
        font-size: 14px;
    }
    @media screen and (max-width: 330px) {
        font-size: 12px;
    }
}

.any-wallet-description {
    font-size: 14px;
    margin-top: 8px;
    color: $secondary;
}

</style>
