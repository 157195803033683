<template>
    <div>
        Waiting for confirmation from MetaMask, check MetaMask to continue.
    </div>
</template>

<script>

import { getChain } from "@blockwell/chains";
import { DumbappSubmission } from "@blockwell/dumbapp";

export default {
    name: "MetamaskWrongNetwork",
    props: {
        submission: DumbappSubmission,
        network: [String, Number]
    },
    computed: {
        networkName() {
            let net = getChain(this.network);
            return net.networkName;
        }
    },
    code: "confirm",
    executor: "metamask"
}
</script>

<style scoped>

</style>
