import Hammer from 'hammerjs';

class VueHammer {
    install(Vue) {
        Vue.directive('longpress', {
            bind(el, binding) {
                if (!el.hammer) {
                    el.hammer = new Hammer.Manager(el);
                }
                el.hammer.add(new Hammer.Press({event: 'longpress', time: 500}));
            },
            inserted(el, binding) {
                const ham = el.hammer;
                if (typeof binding.value !== 'function') {
                    console.warn('[v-longpress] invalid value, expected a function:', binding.arg);
                } else {
                    el.longpressHandler = binding.value;
                    ham.on('longpress', binding.value);
                }
            },
            componentUpdated(el, binding) {
                const ham = el.hammer;
                if (el.longpressHandler) {
                    ham.off('longpress', el.longpressHandler);
                }
                if (typeof binding.value !== 'function') {
                    el.longpressHandler = null;
                    console.warn('[v-longpress] invalid value, expected a function:', binding.arg);
                } else {
                    el.longpressHandler = binding.value;
                    ham.on('longpress', binding.value);
                }
            },
            unbind(el, binding) {
                const ham = el.hammer;
                if (el.longpressHandler) {
                    ham.off('longpress', el.longpressHandler);
                }
            }
        })
        Vue.directive('doubletap', {
            bind(el, binding) {
                if (!el.hammer) {
                    el.hammer = new Hammer.Manager(el);
                }
                el.hammer.add(new Hammer.Tap({taps: 2, event: "doubletap"}));
            },
            inserted(el, binding) {
                const ham = el.hammer;
                if (typeof binding.value !== 'function') {
                    console.warn('[v-doubletap] invalid value, expected a function:', binding.arg);
                } else {
                    el.doubletapHandler = binding.value;
                    ham.on('tap', binding.value);
                }
            },
            componentUpdated(el, binding) {
                const ham = el.hammer;
                if (el.doubletapHandler) {
                    ham.off('doubletap', el.doubletapHandler);
                }
                if (typeof binding.value !== 'function') {
                    el.doubletapHandler = null;
                    console.warn('[v-doubletap] invalid value, expected a function:', binding.arg);
                } else {
                    el.doubletapHandler = binding.value;
                    ham.on('doubletap', binding.value);
                }
            },
            unbind(el, binding) {
                const ham = el.hammer;
                if (el.doubletapHandler) {
                    ham.off('doubletap', el.doubletapHandler);
                }
            }
        })
    }
}

export default new VueHammer();
