import MarkdownIt from 'markdown-it';
import mila from 'markdown-it-link-attributes';
import center from 'markdown-it-center-text';
import anchor from 'markdown-it-anchor';
import toc from 'markdown-it-toc-done-right';
import slug from 'slug';
import markdownItAttrs from 'markdown-it-attrs';

const md = MarkdownIt({linkify: true});

const pattern = /!([a-z][a-z0-9]*)$/i;

md.use((md) => {
    const defaultRender = md.renderer.rules.link_open || function (tokens, idx, options, env, self) {
        return self.renderToken(tokens, idx, options);
    };

    md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
        let link = tokens[idx];
        let href;
        let hrefIndex = link.attrIndex('href')
        if (hrefIndex >= 0) {
            href = link.attrs[hrefIndex][1];

            let match = pattern.exec(href);
            if (match) {
                let type = match[1].toLowerCase();
                let addClass;
                switch (type) {
                    case 'button':
                        addClass = ' btn btn-sm btn-primary';
                        break;
                }

                if (addClass) {
                    link.attrs[hrefIndex][1] = href.replace(pattern, '');

                    let classIndex = link.attrIndex('class');

                    if (classIndex < 0) { // attr doesn't exist, add new attribute
                        link.attrPush(['class', addClass])
                    } else {
                        link.attrs[classIndex][1] += addClass;
                    }
                }
            }
        }

        // pass token to default renderer.
        return defaultRender(tokens, idx, options, env, self)
    }
})


md.use(mila, {
    pattern: /^https?:\/\//i,
    attrs: {
        target: '_blank',
        class: 'external-link'
    }
});

md.use(center);
md.use(anchor, {permalink: true, permalinkSymbol: '#', permalinkBefore: true, slugify: slug});
md.use(toc, {slugify: slug});
md.use(markdownItAttrs, {
    allowedAttributes: ['id', 'class', 'width', 'height']
});

/**
 *
 * @return {Promise<MarkdownIt>}
 */
export default async function init() {
    return md;
};
