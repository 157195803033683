export const cssVarDefaults = {
    "--wheel-inner-color": '#004FFF',
    "--wheel-ring-color": '#214474',
    "--wheel-dot-color": '#6191ff',
    "--wheel-arrow-1": '#af8b04',
    "--wheel-arrow-2": '#FDCA40',
    "--color-1": '#004FFF',
    "--color-2": '#3BB0A0',
    "--color-3": '#6a3aba',
    "--color-4": '#CB04A5',
    "--color-5": '#da9a10',
    "--color-6": '#4e9d3c',
    "--color-7": '#4C2A85',
    "--color-8": '#abc725',
    "--color-9": '#267e72',
    "--color-none": '#212121',
    "--whitelabeled-heading": '#020A1E',
    "--large-heading-background": '#020A1E',
    "--large-heading-text-color": '#FAFAFF',
    "--wallet-heading-background": '#004FFF',
    "--wallet-heading-border": 'transparent',
    "--wallet-heading-text": '#fff',
    "--info-heading-background": 'rgba(0, 79, 255, 0.09)',
    "--info-heading-bottom-border": 'transparent',
    "--info-heading-text-color": '#214474',
    "--body": '#fff',
    "--primary": '#004FFF',
    "--primary-muted": '#214474',
    "--primary-highlight": '#6191ff',
    "--secondary": '#686868',
    "--success": '#4e9d3c',
    "--info": '#004FFF',
    "--warning": '#FDCA40',
    "--danger": '#B80C09',
    "--light": '#FAFAFF',
    "--code": '#13315C',
    "--grey": '#f2f4f6',
    "--body-text": '#020A1E',
    "--primary-text": '#fff',
    "--secondary-text": '#686868',
    "--warning-text": '#af8b04',
    "--text-muted": '#8f8f8f',
    "--label-text": '#020A1E',
    "--link": '#004FFF',
    "--link-hover": '#0037b3',
    "--font-family-body-text": 'Source Sans Pro',
    "--font-family-button": 'Source Sans Pro',
    "--font-family-heading": 'Source Sans Pro',
    "--button-radius": '1px',
    "--small-button-radius": '1px',
    "--button-font-weight": '600',
    "--primary-button": '#004FFF',
    "--primary-button-text": '#fff',
    "--primary-muted-button": '#214474',
    "--primary-muted-button-text": '#fff',
    "--secondary-button": '#686868',
    "--secondary-button-text": '#fff',
    "--success-button": '#4e9d3c',
    "--success-button-text": '#fff',
    "--info-button": '#004FFF',
    "--info-button-text": '#fff',
    "--warning-button": '#FDCA40',
    "--warning-button-text": '#fff',
    "--danger-button": '#B80C09',
    "--danger-button-text": '#fff',
    "--dark-button": '#020A1E',
    "--dark-button-text": '#fff',
    "--metamask-button": '#f7861d',
    "--metamask-button-text": '#FAFAFF',
    "--config-button": '#4C2A85',
    "--config-button-text": '#fff',
    "--config2-button": '#267e72',
    "--config2-button-text": '#fff',
};
export const cssVarSections = [
    {
        "name": "Lotto Wheel ",
        "vars": [
            {
                "key": "style.--wheel-inner-color",
                "type": "color",
                "title": "Wheel Inner Color"
            },
            {
                "key": "style.--wheel-ring-color",
                "type": "color",
                "title": "Wheel Ring Color"
            },
            {
                "key": "style.--wheel-dot-color",
                "type": "color",
                "title": "Wheel Dot Color"
            },
            {
                "key": "style.--wheel-arrow-1",
                "type": "color",
                "title": "Wheel Arrow 1"
            },
            {
                "key": "style.--wheel-arrow-2",
                "type": "color",
                "title": "Wheel Arrow 2"
            }
        ]
    },
    {
        "name": "Lotto Win Rate Colors ",
        "vars": [
            {
                "key": "style.--color-1",
                "type": "color",
                "title": "Color 1"
            },
            {
                "key": "style.--color-2",
                "type": "color",
                "title": "Color 2"
            },
            {
                "key": "style.--color-3",
                "type": "color",
                "title": "Color 3"
            },
            {
                "key": "style.--color-4",
                "type": "color",
                "title": "Color 4"
            },
            {
                "key": "style.--color-5",
                "type": "color",
                "title": "Color 5"
            },
            {
                "key": "style.--color-6",
                "type": "color",
                "title": "Color 6"
            },
            {
                "key": "style.--color-7",
                "type": "color",
                "title": "Color 7"
            },
            {
                "key": "style.--color-8",
                "type": "color",
                "title": "Color 8"
            },
            {
                "key": "style.--color-9",
                "type": "color",
                "title": "Color 9"
            },
            {
                "key": "style.--color-none",
                "type": "color",
                "title": "Color None"
            }
        ]
    },
    {
        "name": "Components ",
        "vars": [
            {
                "key": "style.--whitelabeled-heading",
                "type": "color",
                "title": "Whitelabeled Heading"
            },
            {
                "key": "style.--large-heading-background",
                "type": "background",
                "title": "Large Heading Background"
            },
            {
                "key": "style.--large-heading-text-color",
                "type": "color",
                "title": "Large Heading Text Color"
            },
            {
                "key": "style.--wallet-heading-background",
                "type": "background",
                "title": "Wallet Heading Background"
            },
            {
                "key": "style.--wallet-heading-border",
                "type": "color",
                "title": "Wallet Heading Border"
            },
            {
                "key": "style.--wallet-heading-text",
                "type": "color",
                "title": "Wallet Heading Text"
            },
            {
                "key": "style.--info-heading-background",
                "type": "background",
                "title": "Info Heading Background"
            },
            {
                "key": "style.--info-heading-bottom-border",
                "type": "color",
                "title": "Info Heading Bottom Border"
            },
            {
                "key": "style.--info-heading-text-color",
                "type": "color",
                "title": "Info Heading Text Color"
            }
        ]
    },
    {
        "name": "Base Colors ",
        "vars": [
            {
                "key": "style.--body",
                "type": "color",
                "title": "Body"
            },
            {
                "key": "style.--primary",
                "type": "color",
                "title": "Primary"
            },
            {
                "key": "style.--primary-muted",
                "type": "color",
                "title": "Primary Muted"
            },
            {
                "key": "style.--primary-highlight",
                "type": "color",
                "title": "Primary Highlight"
            },
            {
                "key": "style.--secondary",
                "type": "color",
                "title": "Secondary"
            },
            {
                "key": "style.--success",
                "type": "color",
                "title": "Success"
            },
            {
                "key": "style.--info",
                "type": "color",
                "title": "Info"
            },
            {
                "key": "style.--warning",
                "type": "color",
                "title": "Warning"
            },
            {
                "key": "style.--danger",
                "type": "color",
                "title": "Danger"
            },
            {
                "key": "style.--light",
                "type": "color",
                "title": "Light"
            },
            {
                "key": "style.--code",
                "type": "color",
                "title": "Code"
            },
            {
                "key": "style.--grey",
                "type": "color",
                "title": "Grey"
            }
        ]
    },
    {
        "name": "Text colors ",
        "vars": [
            {
                "key": "style.--body-text",
                "type": "color",
                "title": "Body Text"
            },
            {
                "key": "style.--primary-text",
                "type": "color",
                "title": "Primary Text"
            },
            {
                "key": "style.--secondary-text",
                "type": "color",
                "title": "Secondary Text"
            },
            {
                "key": "style.--warning-text",
                "type": "color",
                "title": "Warning Text"
            },
            {
                "key": "style.--text-muted",
                "type": "color",
                "title": "Text Muted"
            },
            {
                "key": "style.--label-text",
                "type": "color",
                "title": "Label Text"
            },
            {
                "key": "style.--link",
                "type": "color",
                "title": "Link"
            },
            {
                "key": "style.--link-hover",
                "type": "color",
                "title": "Link Hover"
            }
        ]
    },
    {
        "name": "Fonts ",
        "vars": [
            {
                "key": "style.--font-family-body-text",
                "type": "font",
                "title": "Font Family Body Text"
            },
            {
                "key": "style.--font-family-button",
                "type": "font",
                "title": "Font Family Button"
            },
            {
                "key": "style.--font-family-heading",
                "type": "font",
                "title": "Font Family Heading"
            }
        ]
    },
    {
        "name": "Buttons ",
        "vars": [
            {
                "key": "style.--button-radius",
                "type": "size",
                "title": "Button Radius"
            },
            {
                "key": "style.--small-button-radius",
                "type": "size",
                "title": "Small Button Radius"
            },
            {
                "key": "style.--button-font-weight",
                "type": "font-weight",
                "title": "Button Font Weight"
            },
            {
                "key": "style.--primary-button",
                "type": "color",
                "title": "Primary Button"
            },
            {
                "key": "style.--primary-button-text",
                "type": "color",
                "title": "Primary Button Text"
            },
            {
                "key": "style.--primary-muted-button",
                "type": "color",
                "title": "Primary Muted Button"
            },
            {
                "key": "style.--primary-muted-button-text",
                "type": "color",
                "title": "Primary Muted Button Text"
            },
            {
                "key": "style.--secondary-button",
                "type": "color",
                "title": "Secondary Button"
            },
            {
                "key": "style.--secondary-button-text",
                "type": "color",
                "title": "Secondary Button Text"
            },
            {
                "key": "style.--success-button",
                "type": "color",
                "title": "Success Button"
            },
            {
                "key": "style.--success-button-text",
                "type": "color",
                "title": "Success Button Text"
            },
            {
                "key": "style.--info-button",
                "type": "color",
                "title": "Info Button"
            },
            {
                "key": "style.--info-button-text",
                "type": "color",
                "title": "Info Button Text"
            },
            {
                "key": "style.--warning-button",
                "type": "color",
                "title": "Warning Button"
            },
            {
                "key": "style.--warning-button-text",
                "type": "color",
                "title": "Warning Button Text"
            },
            {
                "key": "style.--danger-button",
                "type": "color",
                "title": "Danger Button"
            },
            {
                "key": "style.--danger-button-text",
                "type": "color",
                "title": "Danger Button Text"
            },
            {
                "key": "style.--dark-button",
                "type": "color",
                "title": "Dark Button"
            },
            {
                "key": "style.--dark-button-text",
                "type": "color",
                "title": "Dark Button Text"
            },
            {
                "key": "style.--metamask-button",
                "type": "color",
                "title": "Metamask Button"
            },
            {
                "key": "style.--metamask-button-text",
                "type": "color",
                "title": "Metamask Button Text"
            },
            {
                "key": "style.--config-button",
                "type": "color",
                "title": "Config Button"
            },
            {
                "key": "style.--config-button-text",
                "type": "color",
                "title": "Config Button Text"
            },
            {
                "key": "style.--config2-button",
                "type": "color",
                "title": "Config2 Button"
            },
            {
                "key": "style.--config2-button-text",
                "type": "color",
                "title": "Config2 Button Text"
            }
        ]
    }
];
