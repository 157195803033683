export default class RolodexApi {
    constructor(client) {
        this.client = client;
    }
    getAll() {
        return this.client.get(`api/rolodex`).then((res) => {
            return res.data;
        });
    }
    applyWal(logs) {
        return this.client.post("api/rolodex", { logs });
    }
}
