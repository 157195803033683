import MessagesForSubmission from "@/components/dumbapp/confirmation/MessagesForSubmission.vue";
import DumbappPreviewStep from "@/components/dumbapp/DumbappPreviewStep.vue";
import SubmissionCompactStep from "@/components/dumbapp/SubmissionCompactStep.vue";
import SubmissionHeader from "@/components/dumbapp/SubmissionHeader.vue";
import SubmissionMixin from "@/components/dumbapp/SubmissionMixin";
import FadeSlide from "@/components/FadeSlide.vue";
import ProgressCircle from "@/components/ProgressCircle.vue";
import { addExtras, } from "@blockwell/dumbapp";
import ApprovalSubmissionHeader from "@/components/dumbapp/ApprovalSubmissionHeader.vue";
export default {
    name: "Submission",
    components: {
        ApprovalSubmissionHeader,
        MessagesForSubmission,
        FadeSlide,
        SubmissionHeader,
        DumbappPreviewStep,
        SubmissionCompactStep,
        ProgressCircle,
    },
    mixins: [SubmissionMixin],
    data() {
        return {
            expanded: this.expand,
        };
    },
    methods: {
        expandClick() {
            this.expanded = !this.expanded;
            if (this.expanded) {
                let api = this.api;
                let params = {
                    api,
                    contracts: api.contracts,
                    walletApi: api
                };
                let sub = this.loadedSubmission;
                addExtras(params, sub).then((extras) => {
                    if (Object.keys(extras).length > 0) {
                        extras = Object.assign({}, sub.data.extras || {}, extras);
                        this.$store.dispatch("dumbapp/updateSubmission", {
                            id: sub.id,
                            updates: { extras },
                        });
                    }
                });
            }
        },
    },
};
