<template>
    <div>
        <div class="dumbapp-confirmation-message lotto-messages">
            <div class="material-icons message-icon">star</div>
            <div class="message">
                <div>You've won some tokens!</div>
                <ul class="lotto-wins">
                    <li v-for="win in wins">
                        <TokenAmount :value="win.value" :decimals="win.token.decimals"/>
                        <Clipboard :address="win.token.address" :network="win.token.network">
                            {{ win.token.name }} ({{ win.token.symbol }})
                        </Clipboard>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import Clipboard from "@/components/Clipboard";
import { DumbappSubmission } from "@blockwell/dumbapp";
import TokenAmount from "@/components/TokenAmount";

export default {
    name: "LottoMessages",
    components: {TokenAmount, Clipboard},
    props: {
        submission: DumbappSubmission
    },
    data() {
        return {};
    },
    computed: {
        wins() {
            return this.submission?.data?.extras?.lotto;
        }
    }
}
</script>

<style scoped lang="scss">
.lotto-messages {
    .lotto-wins {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .message-icon {
        color: #fdbf1a;
    }

    .win-value {
        font-weight: 600;
    }
}
</style>
