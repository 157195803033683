<template>
    <div class="dumbapp-input dumbapp-input-editable" :class="{preset}">

        <EditableText
            v-if="!preset"
            element="label"
            class="editable-label"
            :value="field.dynamicLabel"
            @input="labelInput"/>
        <label v-else class="editable-label">{{ field.dynamicLabel }}</label>

        <div class="existing-fields" v-if="this.value === '' && existingFields.length > 0">
            <div class="use-existing-field">Use an existing value</div>
            <div v-for="ex in existingFields" :key="ex.value" class="custom-control custom-checkbox"
                 :class="{checked: existing === ex.value}">
                <input class="custom-control-input" type="checkbox" :id="$id(ex.value)" :value="ex.value"
                       :checked="existing === ex.value"
                       @change="change">
                <div class="custom-box"></div>
                <label class="custom-control-label" :for="$id(ex.value)">{{ ex.label }}</label>
            </div>
        </div>
        <div v-if="this.value !== ''" class="no-help">
            This input will not be shown to users, it will be prefilled
            <a href="javascript: void 0;" @click="$emit('clear')"> clear</a>
        </div>

        <component
            v-if="!existing"
            :is="useComponent"
            :field="field"
            @input="input"
            @updated="updated"
            :id="uid">
            <template #label>
            </template>
            <template #help>
                <EditableText
                    v-if="!preset"
                    element="div"
                    :value="field.help"
                    placeholder="Click to add a help text"
                    @input="helpInput"
                    class="formulate-input-help"/>
            </template>
        </component>

    </div>
</template>

<script>
import EditableText from "@/components/creator/EditableText";
import ArrayInput from "@/components/dumbapp/input/ArrayInput";
import InputMixin from "@/components/dumbapp/input/InputMixin";
import { createDynamicSource, createSenderSource } from "@blockwell/dumbapp";

export default {
    name: "EditableInput",
    mixins: [InputMixin],
    inject: ['editor', 'provider'],
    provide() {
        return {
            field: this.field
        }
    },
    components: {EditableText, ArrayInput},
    props: {
        allFields: Array
    },
    data() {
        let existing = null;
        if (this.field.existing) {
            if (this.field.existing === 'dynamic') {
                existing = this.field.value.parameters.name;
            } else {
                existing = this.field.existing;
            }
        }

        return {
            value: "",
            existing
        };
    },
    watch: {
        existing(val) {
            if (!val) {
                this.$set(this.field, 'existing', null);
                this.$set(this.field, 'value', createDynamicSource(this.field.name));
            } else if (/^\d/.test(val)) {
                // If it starts with a number, it's a reference to another dynamic field
                this.$set(this.field, 'existing', 'dynamic');
                this.$set(this.field, 'value', createDynamicSource(val));
                this.value = "";
            } else if (val === 'sender') {
                this.$set(this.field, 'existing', val);
                this.$set(this.field, 'value', createSenderSource());
                this.value = "";
            } else {
                this.$set(this.field, 'existing', val);
                this.$set(this.field, 'value', createDynamicSource(this.field.name));
                this.value = "";
            }
            this.provider.trigger();
        },
        existingFields(fields) {
            if (/^\d/.test(this.existing)) {
                let field = fields.find(it => it.value === this.existing);
                if (!field) {
                    this.existing = null;
                }
            }
        }
    },
    computed: {
        isArray() {
            return !!this.field?.typing?.array;
        },
        useComponent() {
            if (this.isArray) {
                return ArrayInput;
            }
            return this.component;
        },
        preset() {
            return this.existing !== null || !(this.value === null || this.value === undefined || this.value === "");
        },
        existingFields() {
            let fields = [];
            if (this.field.typing.name === "address") {
                fields.push({
                    value: "sender",
                    label: "User Wallet"
                })
            }

            if (this.allFields) {
                for (let field of this.allFields) {
                    if (field.type === this.field.type
                        && field.name !== this.field.name
                        && field.value?.parameters?.name !== this.field.name
                        && field.value?.type !== "other"
                    ) {
                        fields.push({
                            value: field.name,
                            label: field.dynamicLabel
                        });
                    }
                }
            }
            return fields;
        }
    },
    methods: {
        labelInput(val) {
            if (this.field) {
                this.$set(this.field, 'dynamicLabel', val);
            }
        },
        helpInput(val) {
            if (this.field) {
                this.$set(this.field, 'help', val);
            }
        },
        input(val) {
            this.value = val;
        },
        updated(val) {
            this.$emit("updated", val);
        },
        change(event) {
            if (event.target.checked) {
                this.existing = event.target.value;
            } else {
                this.existing = null;
            }
        }
    }
}
</script>

<style lang="scss">
@import "~@/assets/css/custom.scss";

.dumbapp-input-editable {
    border: 1px solid transparent;
    margin-bottom: 30px;

    .formulate-input {
        margin-bottom: 0;
    }

    div.formulate-input-help, .no-help {
        margin: 0;
        line-height: 25px;
        border: 1px solid transparent;
    }

    .array-input-help {
        margin-bottom: 5px;
    }

    .editable-label {
        border: 1px solid transparent;
    }

    &.preset {

        .editable-label {
            opacity: 0.5;
        }
    }

    .no-help {
        margin-left: 11px;
        font-size: 14px;
        font-style: italic;


        a {
            margin-left: 5px;
        }
    }

    .formulate-input[data-classification=text][data-is-showing-errors=true] input {
        border-color: #d3d3d3;
    }

    .formulate-input-errors {
        display: none;
    }
}

.existing-fields {
    display: flex;
    margin-bottom: 3px;
    align-items: center;

    .use-existing-field {
        font-size: 14px;
        font-style: italic;
        color: #666;
    }

    .custom-checkbox {
        padding: 0;
        position: relative;
        display: flex;
        align-items: stretch;
        border: 2px solid rgba($config, 0.5);
        border-radius: 2px;
        margin-left: 5px;
        transition: all .3s;

        .custom-box {
            width: 20px;
            transition: all .3s;
            background-color: rgba($config, 0.5);
        }

        .custom-control-label {
            font-size: 12px;
            font-weight: normal;
            position: static;
            line-height: 20px;
            color: rgba($config, .6);
            padding: 0 5px;

            &::before {
                border-color: rgba($config, 0.5);
                top: 4px;
                left: 3px;
                width: 12px;
                height: 12px;
            }

            &::after {
                top: 2px;
                left: 1px;
                width: 16px;
                height: 16px;
            }
        }

        &:hover {
            border-color: rgba($config, 0.7);

            .custom-box {
                background-color: rgba($config, 0.7);
            }
        }

        &.checked {
            border-color: $config;

            .custom-control-label {
                color: $config;

                &::before {
                    background-color: rgba($config, 0.5);
                }
            }

            .custom-box {
                background-color: $config;
            }
        }
    }
}

.existing-fields-wrap {
    min-height: 27px;
}
</style>
