<template>
    <div>
        <div v-if="showValue" class="number-argument">
            <span class="value">{{ renderValue }}</span>
            <span v-if="renderSuffix" class="suffix"> {{ suffix }}</span>
        </div>
        <slot v-else />
    </div>
</template>

<script>
import { resolveStringSourcable, resolveStringValue } from "@blockwell/dumbapp";
import { BigNumber } from "bignumber.js";

export default {
    name: "NumberArgument",
    inject: ["provider"],
    props: {
        value: [String, BigNumber, Number],
        arg: Object,
        step: Object,
    },
    data() {
        return {
            suffix: ""
        }
    },
    computed: {
        renderValue() {
            if (!this.value) {
                return null;
            }
            return new BigNumber(this.value).toFormat({
                prefix: "",
                decimalSeparator: ".",
                groupSeparator: "\u00A0",
                groupSize: 3,
                secondaryGroupSize: 0,
                fractionGroupSeparator: " ",
                fractionGroupSize: 0,
                suffix: "",
            });
        },
        renderSuffix() {
            return this.arg?.symbol;
        },
        showValue() {
            return !!this.value;
        }
    },
    methods: {
        onProviderInput(values) {
            resolveStringSourcable(values, [this.arg?.symbol], this.step)
                .then(symbol => {
                    this.suffix = symbol;
                })
                .catch(() => {
                    this.suffix = "";
                })
        }
    },
    mounted() {
        this.provider.on("change", this.onProviderInput);
        this.onProviderInput(this.provider.values);
    },
    beforeDestroy() {
        this.provider.off("change", this.onProviderInput);
    },
};
</script>

<style scoped></style>
