<template>
    <div
        :class="`formulate-input-element formulate-input-element--${context.type}`"
        :data-type="context.type"
    >
        <input type="hidden" v-model="context.model" />
        <div class="formulate-tags" ref="wrap">
            <div v-for="(value, index) in values" class="formulate-tags--tag">
                <span class="formulate-tags--tag-value">{{ value }}</span>
                <button class="formulate-tags--tag-remove" @click="remove(index)">×</button>
            </div>
            <textarea
                v-bind="context.attributes"
                ref="textarea"
                @input="input"
                @blur="blur"
                @keydown="keydown"
                class="formulate-tags--input"
                rows="1"
                spellcheck="false"
                autocapitalize="off"
                autocorrect="off"
                role="combobox"
            ></textarea>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormulateTags",
    props: {
        context: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            values: [],
        };
    },
    watch: {
        values(val) {
            this.context.model = val.join(" ");
        },
        "context.model": {
            immediate: true,
            handler(val) {
                if (val?.trim().length > 0) {
                    this.values = val?.split(" ");
                } else {
                    this.values = [];
                }
            },
        },
    },
    methods: {
        remove(index) {
            this.$nextTick(() => {
                this.values.splice(index, 1);
                this.$refs.textarea.focus();
            });
        },
        input(event) {
            let el = event.target;
            if (event.inputType === "insertText") {
                if (event.data.includes(" ")) {
                    let values = el.value.split(" ");
                    this.values = this.values.concat(values.slice(0, -1).filter((it) => !!it));

                    if (values[values.length - 1]) {
                        el.value = values[values.length - 1];
                    } else {
                        el.value = "";
                    }
                }
            }
            el.style.width = "auto";
            if (el.clientWidth < el.scrollWidth) {
                el.style.width = "100%";
            }
        },
        blur(event) {
            let values = event.target.value.split(" ").filter((it) => !!it?.trim());
            this.values = this.values.concat(values);
            event.target.value = "";
            this.$emit("blur", event, this.values.join(" "));
        },
        keydown(event) {
            if (event.key === "Backspace" && !event.target.value && this.values.length > 0) {
                this.values.pop();
            }
            if (event.key === "Enter" && event.target.value) {
                if (event.target.value.trim()) {
                    this.values.push(event.target.value.trim());
                }
                event.target.value = "";
                return false;
            }
        },
    },
};
</script>

<style>
.formulate-tags {
    display: flex;
    flex-wrap: wrap;
    padding: 4px;
    font-weight: 400;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d3d3d3;
    border-radius: 1px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formulate-tags--tag {
    padding: 0 0 0 0.5rem;
    line-height: 22px;
    background-color: #eee;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    margin: 3px;
    white-space: nowrap;
}

.formulate-tags--tag-remove {
    background: none;
    border: none;
    line-height: 20px;
    padding: 2px 10px 2px 5px;
    margin: 0 0 0 4px;
    outline: none;
}

.formulate-tags--tag-remove:focus {
    outline: none;
}

.formulate-tags--input {
    width: auto;
    flex: 1 0 auto;
    border: none !important;
    background: none !important;
    resize: none;
    overflow: hidden;
    line-height: 24px;
    height: 26px;
    margin: 3px;
    outline: none !important;
    word-wrap: normal;
    white-space: nowrap;
}
</style>
