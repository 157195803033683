import { clone } from "rambdax";
import { Chain } from "@blockwell/chain-client";
import BigNumber from "bignumber.js";
export function mergeWhitelabelConfig(deps) {
    let base = clone(deps.prop);
    let draft = deps.draft;
    let style = base.style || {};
    if (deps.cssVars) {
        Object.assign(style, deps.cssVars);
    }
    if (deps.settings && deps.settings.settings) {
        Object.assign(base, deps.settings.settings);
        Object.assign(style, deps.settings.settings.style);
    }
    if (draft?.style) {
        Object.assign(style, draft.style);
    }
    let config = {
        ...base,
        ...draft,
        style,
    };
    return config;
}
export function fontStylesheet(val) {
    if (val.length > 0) {
        let fonts = val
            .map((it) => `family=${encodeURIComponent(it)}:wght@400;700`)
            .join("&");
        return `@import url("https://fonts.googleapis.com/css2?${fonts}&display=swap");`;
    }
    else {
        return "";
    }
}
export async function hasWhitelabelAccess(account, settings) {
    if (!account || !settings || !settings.accessToken || settings.accessToken === Chain.ADDRESS_ZERO) {
        return false;
    }
    let bal = await Chain.getTokenBalance(settings.bookChain, settings.accessToken, account);
    return new BigNumber(bal).gt(0);
}
