import axios from "axios";
import rateLimit from "axios-rate-limit";
export default class Bwns {
    constructor() {
        let client = axios.create({
            baseURL: process.env.VUE_APP_API_URL,
            paramsSerializer: {
                indexes: null,
            },
            timeout: 20000
        });
        client = rateLimit(client, {
            maxRPS: 2
        });
        client.defaults.headers.common['Client-Name'] = "Blockwell Wallet Web bwqrweb " + navigator.userAgent;
        this.client = client;
    }
    resolve(name, namespace) {
        let query = "";
        if (namespace) {
            query = "?namespace=" + encodeURIComponent(namespace);
        }
        return this.client
            .get(`api/bwns/resolve/${name}${query}`)
            .then((res) => res.data)
            .catch((err) => {
            if (err.response && err.response.status === 404) {
                return null;
            }
            else {
                throw err;
            }
        });
    }
    forContract(namespace, contractId) {
        return this.client
            .get(`api/bwns/find/${namespace}/${contractId}`)
            .then((res) => res.data)
            .catch((err) => {
            return null;
        });
    }
    forContractAddress(namespace, address, network) {
        return this.client
            .get(`api/bwns/find/${namespace}`, {
            params: {
                address,
                network,
            },
        })
            .then((res) => res.data)
            .catch((err) => {
            return null;
        });
    }
    listAll() {
        return this.client.get("api/bwns/list").then((res) => res.data);
    }
    list(namespace) {
        return this.client.get(`api/bwns/list/${namespace}`).then((res) => res.data);
    }
    create(name, namespace, address, network, title, pub = true) {
        return this.client
            .post("api/bwns", {
            name,
            namespace,
            address,
            network,
            title,
            public: pub,
        })
            .then((res) => res.data);
    }
    createLink(name, link) {
        return this.client
            .post("api/bwns/link", {
            name,
            link,
        })
            .then((res) => res.data);
    }
}
