<template>
    <div class="fuel-select">
        <h5>Buy Fuel With</h5>
        <div class="uniswap-pick">
            <div class="uniswap-populars">
                <div v-for="it in highlighted" class="uniswap-popular" @click="select(it.address)">
                    <div class="popular-logo">
                        <img :src="logo(it.address)" alt=""/>
                    </div>
                    <span>{{ it.name }}</span>
                </div>
            </div>
        </div>
        <div class="uniswap-search">
            <h5>Or you can search for a token</h5>
            <ContractLoader
                no-label
                :load-click="tokenLoaded"
                features="erc20"
                network="1"
                hide-recents
            />
        </div>
    </div>
</template>

<script>
import {tokenLogo} from "@/lib/api/WalletApi";
import ContractLoader from "@/views/happs/components/ContractLoader.vue";

export default {
    name: "FuelTokenSelect",
    components: {ContractLoader},
    data() {
        return {
        };
    },
    computed: {
        highlighted() {
            if (this.$route.query.testnet) {
                return [
                    {
                        name: "Ether",
                        address: "ether"
                    },
                    {
                        name: "Food Coin",
                        address: "0xFf255f8A7Cf00D68a123a2553a7d0cdCA63f61c3",
                        network: 5
                    }
                ]
            } else {
                return [
                    {
                        name: "Ether",
                        address: "ether"
                    },
                    {
                        name: "Tether",
                        address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
                        network: 1
                    },
                    {
                        name: "Blockwell PRIDE",
                        address: "pride"
                    },
                    {
                        name: "USDC",
                        address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
                        network: 1
                    },
                ]
            }
        }
    },
    methods: {
        tokenLoaded(data) {
            this.select(data.address);
        },
        select(address) {
            this.$emit("token", address);
        },
        logo(address) {
            return tokenLogo(address)
        },
    }
}
</script>

<style scoped lang=scss>
@import "~@/assets/css/custom";

.uniswap-search {
    max-width: 500px;
    margin: 10px auto 0;
}

.fuel-select {
    margin-top: 30px;
    ::v-deep( h5 ) {
        text-align: center;
        margin: 0 auto 10px;
    }
}

.uniswap-populars {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.uniswap-popular {
    cursor: pointer;
    border: 1px solid rgba($primary, .1);
    padding: 5px 10px;
    margin: 5px;
    font-size: 14px;
    font-weight: 600;
    color: #555;
    display: flex;
    align-items: center;
    border-radius: 10px;

    .popular-logo {
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;

        ::v-deep( img ) {
            max-width: 20px;
            max-height: 20px;
            display: block;
        }
    }

    &:hover {
        box-shadow: 0 0 2px rgba($primary, .5);
    }
}
</style>
