<template>
    <div class="contrib-method">
        <h3>
            <img src="../../assets/images/icons/token-creator.svg" alt="" width="45"
                 style="margin-right: 5px;"/>
            Any Wallet
        </h3>
        <p>
            Contribute using any wallet.
        </p>
        <Clipboard :address="contrib" class="contrib-address"/>
        <div class="any-wallet-description">
            Any ETH or tokens you send to the above address will be credited to you and you'll
            receive {{multiplier}}x PRIDE based on the USD price at the time of sending (pending KYC passing).
        </div>
    </div>
</template>

<script>
import Clipboard from "@/components/Clipboard";
export default {
    name: "AnyWalletOffer",
    components: {Clipboard},
    props: {
        contrib: String,
        multiplier: Number
    },
    data() {
        return {};
    }
}
</script>

<style scoped lang=scss>
@import "~@/assets/css/custom.scss";

.contrib-address {
    border: 1px solid rgba($primary, .3);
    @include mdElevation(1);
    padding: 10px;
    margin-top: 4px;

    @media screen and (max-width: 880px) {
        font-size: 14px;
    }
    @media screen and (max-width: 374px) {
        font-size: 12px;
    }
    @media screen and (max-width: 330px) {
        font-size: 11px;
    }
}

.any-wallet-description {
    font-size: 14px;
    margin-top: 8px;
    color: $secondary;
}
</style>
