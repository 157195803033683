<template>
    <component
        :is="element"
        contenteditable="true"
        class="dumbapp-editable"
        :class="{placeholder: showPlaceholder}"
        @focusin="focused"
        @focusout="unfocused"
        @input="input"
    >{{currentValue || placeholder}}</component>
</template>

<script>
export default {
    name: "EditableText",
    props: {
        value: String,
        element: {
            type: String,
            default: 'div'
        },
        placeholder: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            currentValue: this.value,
            hasFocus: false
        }
    },
    computed: {
        displayValue() {
            if (this.showPlaceholder) {
                return this.placeholder;
            }
            return this.currentValue;
        },
        showPlaceholder() {
            return !this.hasFocus && !this.currentValue && !!this.placeholder;
        }
    },
    watch: {
        value(val) {
            if (!this.hasFocus) {
                this.currentValue = val;
            }
        }
    },
    methods: {
        focus() {
            this.$el.focus();
        },
        focused(event) {
            if (!this.currentValue) {
                this.$el.innerText = "";
            }
            this.hasFocus = true;
            event.target.classList.add('editable-focused');
        },
        unfocused(event) {
            this.currentValue = event.target.innerText || "";
            if (!this.currentValue) {
                this.$el.innerText = this.placeholder;
            }
            this.hasFocus = false;
            event.target.classList.remove('editable-focused');

            this.$emit('blur', this.currentValue);
        },
        input(event) {
            this.$emit('input', event.target.innerText);
        }
    }
}
</script>

<style lang="scss">

@import "~@/assets/css/icons.scss";
@import "~@/assets/css/custom.scss";
@import "~@/assets/css/bootstrap/mixins/_forms.scss";

.dumbapp-editable {
    position: relative;
    border: 1px solid transparent;
    border-radius: 2px;

    &.placeholder {
        font-style: italic;
        transition: color .3s;
        color: rgba($config, .5);
    }

    &:hover {
        background: rgba($config, .05);

        &:after {
            color: $config;
        }
    }
    &:after {
        content: "edit";
        @include material-icons();
        color: rgba($config, .5);
        font-size: 14px;
        line-height: 14px;
        top: calc(50% - 7px);
        position: absolute;
        left: -15px;
        transition: color .3s;
    }
}

.editable-focused:focus {
    box-shadow: 0 0 0 .2rem rgba($config, .25);
    background: #fff;
    border: 1px solid rgba($config, .5);

    &:after {
        color: $config;
    }
}
</style>
