import { clone, omit } from "rambdax";
export function mergeRolodexLog(a, b) {
    let left;
    let right;
    if (a.timestamp > b.timestamp) {
        left = b;
        right = a;
    }
    else {
        left = a;
        right = b;
    }
    switch (left.op) {
        case "set":
            switch (right.op) {
                case "set":
                    let created = Math.min(...[left.data.created, right.data.created].filter((it) => !!it));
                    if (!created || created === Infinity) {
                        created = left.data.timestamp;
                    }
                    Object.assign(left.data, right.data, { created });
                    return left;
                case "delete":
                    return right;
            }
            break;
        case "delete":
            switch (right.op) {
                case "set":
                    return right;
                case "delete":
                    return right;
            }
            break;
    }
}
export function walApplySet(cards, log) {
    let existing = cards.findIndex((it) => it.id === log.id);
    if (existing > -1) {
        let entry = cards[existing];
        let created = Math.min(...[entry.created, log.data.created].filter((it) => !!it));
        if (!created || created === Infinity) {
            created = entry.timestamp;
        }
        let updated = clone(omit(["contract"], entry));
        Object.assign(updated, log.data);
        updated.created = created;
        cards.splice(existing, 1, updated);
    }
    else {
        cards.push({
            ...clone(log.data),
            created: Date.now()
        });
    }
}
export function walApplyDelete(cards, log) {
    let existing = cards.findIndex((it) => it.id === log.id);
    if (existing > -1) {
        cards.splice(existing, 1);
    }
}
export default class RolodexWal {
    constructor(startIndex) {
        this.startIndex = startIndex;
        this.log = [];
    }
    static getId(data) {
        if (data.id) {
            return data.id;
        }
        let id = data.address.toLowerCase();
        if (data.type === "contract") {
            id = data.chainId + "/" + id;
        }
        return id;
    }
    set(data) {
        this.log.push(Object.freeze({
            id: RolodexWal.getId(data),
            timestamp: data.timestamp,
            op: "set",
            data: clone(data),
        }));
    }
    delete(data) {
        this.log.push(Object.freeze({
            id: RolodexWal.getId(data),
            timestamp: data.timestamp,
            op: "delete",
        }));
    }
    getLogs(index) {
        let slice;
        let startIndex = index || this.startIndex;
        if (index !== undefined) {
            let internalIndex = index - this.startIndex;
            if (internalIndex < 0) {
                throw new Error(`Cannot get logs at ${index}, it is below startIndex ${this.startIndex}`);
            }
            if (internalIndex === this.log.length) {
                // If requesting the next index, return an empty list instead of throwing
                return [];
            }
            if (internalIndex >= this.log.length) {
                throw new Error(`Cannot get logs at ${index}, exceeds highest available index ${startIndex + this.log.length - 1}`);
            }
            slice = this.log.slice(internalIndex);
        }
        else {
            slice = this.log;
        }
        return slice.map((it, i) => {
            return {
                ...it,
                index: startIndex + i,
            };
        });
    }
    flush(upToIndex) {
        let internalIndex = upToIndex - this.startIndex;
        if (internalIndex < 0) {
            throw new Error(`Cannot flush logs to ${upToIndex}, it is below startIndex`);
        }
        if (internalIndex >= this.log.length) {
            throw new Error(`Cannot flush logs to ${upToIndex}, exceeds highest available index ${this.startIndex + this.log.length - 1}`);
        }
        this.log = this.log.slice(internalIndex + 1);
        this.startIndex = upToIndex + 1;
    }
    resetIndex() {
        this.startIndex = 0;
    }
}
