import Vue from "vue";
import MobileNav from "@/layouts/components/MobileNav.vue";
import MainHeader from "@/layouts/components/MainHeader.vue";
import MainFooter from "@/layouts/components/MainFooter.vue";
import PageMessages from "@/components/message/PageMessages.vue";
import WhitelabelMobileNav from "@/layouts/components/WhitelabelMobileNav.vue";
import WhitelabelMainHeader from "@/layouts/components/WhitelabelMainHeader.vue";
import WhitelabelFooter from "@/layouts/components/WhitelabelFooter.vue";
import { uniq } from "rambdax";
import { capitalCase } from "change-case";
import { fontStylesheet, hasWhitelabelAccess, mergeWhitelabelConfig, } from "@/components/whitelabel/whitelabel-layout";
export default Vue.extend({
    name: "StyleableLayout",
    components: {
        WhitelabelFooter,
        WhitelabelMainHeader,
        WhitelabelMobileNav,
        PageMessages,
        MainFooter,
        MainHeader,
        MobileNav,
    },
    props: {
        heading: {
            type: Boolean,
        },
        title: {
            type: Boolean,
        },
        messages: {
            type: Boolean,
        },
        disableStyling: {
            type: Boolean,
        },
        size: {
            type: String,
        },
        logo: {
            type: String,
        },
        icon: {
            type: String,
        },
        external: {
            type: String,
        },
        cssVars: {
            type: Object,
        },
        whitelabelLayout: {
            type: Boolean,
        },
        settings: {
            type: null,
        },
        override: {
            type: Object,
        },
        hideNav: Boolean,
    },
    data() {
        return {
            destroyed: false,
            styleElement: document.createElement("style"),
            config: { style: {} },
            fontSheet: null,
        };
    },
    computed: {
        whitelabelId() {
            return this.$route.meta?.whitelabelId;
        },
        sizeClass() {
            if (this.size) {
                return "content-width-" + this.size;
            }
            else {
                return "";
            }
        },
        configDeps() {
            let prop = {
                heading: this.heading,
                title: this.title,
                whitelabelLayout: this.whitelabelLayout,
                logo: this.logo,
                icon: this.icon,
                mainButtonOutline: false,
                secondaryButtonOutline: true,
            };
            if (this.override) {
                prop = this.override;
            }
            if (this.disableStyling) {
                return {
                    prop,
                    draft: { style: {} },
                    cssVars: {},
                    settings: null,
                };
            }
            return {
                draft: this.$store.state.whitelabel.config,
                prop,
                cssVars: this.cssVars,
                settings: this.$store.state.whitelabel.pages[this.whitelabelId],
            };
        },
        fontNames() {
            if (this.config.style) {
                let fonts = Object.entries(this.config.style)
                    .filter(([key]) => key.includes("font-family"))
                    .map(([key, val]) => capitalCase(val));
                return uniq(fonts.filter((it) => it !== "" && it !== "Source Code Pro" && it !== "Source Sans Pro"));
            }
            return [];
        },
        logoUrl() {
            return this.createImageUrl(this.config.logo);
        },
        iconUrl() {
            return this.createImageUrl(this.config.icon);
        },
        styleClasses() {
            let classes = [];
            if (this.config.mainButtonOutline) {
                classes.push("button-solid-to-outline");
            }
            if (!this.config.secondaryButtonOutline) {
                classes.push("button-outline-to-solid");
            }
            if (this.config.whitelabelLayout) {
                classes.push("whitelabel-layout");
            }
            return classes;
        },
        whitelabelAccessDeps() {
            return {
                account: this.$store.state.user.account,
                settings: this.settings,
            };
        },
    },
    watch: {
        configDeps: {
            deep: true,
            immediate: true,
            handler(val) {
                this.config = mergeWhitelabelConfig(val);
            },
        },
        fontNames: {
            immediate: true,
            deep: true,
            handler(val) {
                let fontSheet = fontStylesheet(val);
                if (!this.fontSheet || this.fontSheet !== fontSheet) {
                    this.fontSheet = fontSheet;
                    this.styleElement.innerHTML = this.fontSheet;
                }
            },
        },
        iconUrl(val) {
            let element = document.querySelector("link[rel~='icon']");
            if (val) {
                element.href = val;
            }
            else {
                element.href = "/favicon.ico";
            }
        },
        settings: {
            immediate: true,
            handler(settings) {
                if (settings) {
                    this.$store.restored.then(() => {
                        this.$store.dispatch("whitelabel/addPage", {
                            id: this.whitelabelId,
                            settings,
                        });
                    });
                }
            },
        },
        whitelabelAccessDeps: {
            immediate: true,
            deep: true,
            handler(val) {
                hasWhitelabelAccess(val.account, val.settings).then((res) => {
                    if (!this.destroyed && res) {
                        this.$store.dispatch("whitelabel/showWhitelabel", {
                            id: this.whitelabelId,
                            show: true,
                        });
                    }
                });
            },
        },
    },
    methods: {
        createImageUrl(url) {
            if (url && /^https:\/\//i.test(url)) {
                return process.env.VUE_APP_API_URL + "img?url=" + encodeURIComponent(url);
            }
        },
    },
    created() {
        document.head.appendChild(this.styleElement);
        let config = {
            title: !!this.title,
            heading: !!this.heading,
            whitelabelLayout: !!this.whitelabelLayout,
            style: this.cssVars || {},
            mainButtonOutline: false,
            secondaryButtonOutline: true,
        };
        this.$store.dispatch("whitelabel/updateDefaults", config);
    },
    destroyed() {
        this.destroyed = true;
        this.$store.dispatch("whitelabel/removeShowWhitelabel", this.whitelabelId);
        this.styleElement.remove();
    },
});
