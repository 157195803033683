import { render, staticRenderFns } from "./FooterSocial.vue?vue&type=template&id=285cb798&scoped=true&functional=true&"
import script from "./FooterSocial.vue?vue&type=script&lang=ts&"
export * from "./FooterSocial.vue?vue&type=script&lang=ts&"
import style0 from "./FooterSocial.vue?vue&type=style&index=0&id=285cb798&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "285cb798",
  null
  
)

export default component.exports