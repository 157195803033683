<template>
    <div class="mobile-nav-wrapper" :class="{ 'menu-open': show }">
        <div class="mobile-nav-button">
            <i class="material-icons" @click="open">menu</i>
            <portal-target name="title" class="title">
                <img src="../../assets/images/icon-QR.svg" height="24" alt="" />
                <span>{{ title }}</span>
            </portal-target>
        </div>
        <transition name="fade" appear>
            <div v-if="show" class="mobile-nav-mask" @click.self="close"></div>
        </transition>
        <div class="mobile-nav">
            <div class="mobile-nav-content">
                <div class="mobile-nav-top">
                    <div class="mobile-logo">
                        <img src="../../assets/images/lion.png" alt="Blockwell" />
                        <h1>Blockwell Wallet</h1>
                    </div>
                    <div v-if="loggedIn" class="user-wrap">
                        <div class="user-email">{{ email }}</div>
                        <div class="user-wallet">
                            <Clipboard ellipsize :address="account" />
                        </div>
                        <div class="account-links">
                            <router-link to="/app/account">My Account</router-link>
                            <router-link to="/app/account/contracts">My Contracts</router-link>
                            <a href="javascript: void 0;" @click="logout">Log Out</a>
                            <a href="javascript: void 0;" @click="openHistory">Contract History</a>
                        </div>
                        <FuelWidget />
                    </div>
                    <div v-else>
                        <router-link
                            to="/app/login"
                            class="btn btn-sm btn-primary"
                            @click.native="close"
                            >Sign In</router-link
                        >
                    </div>

                </div>

                <div class="mobile-nav-menu">
                    <MainMenu />
                </div>
            </div>
        </div>

        <Modal :show.sync="showHistory">
            <div class="dropdown-section recent-contracts">
                <h5 class="recent-header">Recent Contracts</h5>

                <ContractHistory v-if="history" :history="history" @link="route" />
                <ProgressCircle v-else />
            </div>
        </Modal>
    </div>
</template>
<script>
import Clipboard from "@/components/Clipboard";
import ContractHistory from "@/components/ContractHistory";
import MainMenu from "@/components/MainMenu";
import Modal from "@/components/Modal";
import ProgressCircle from "@/components/ProgressCircle";
import { mapActions, mapGetters, mapState } from "vuex";
import FuelWidget from "@/components/fuel/FuelWidget.vue";

export default {
    name: "MobileNav",
    components: { FuelWidget, ProgressCircle, ContractHistory, Modal, Clipboard, MainMenu },
    data() {
        return {
            show: false,
            showHistory: false,
            history: null,
        };
    },
    computed: {
        ...mapGetters("user", ["api", "loggedIn"]),
        ...mapState("user", ["email", "account"]),
        title() {
            return this.$route.meta?.title || this.$route.name || "";
        },
    },
    methods: {
        ...mapActions("user", ["logout"]),
        open() {
            this.show = true;
        },
        close() {
            this.show = false;
            this.showHistory = false;
        },
        openHistory() {
            this.updateHistory();
            this.showHistory = true;
        },
        updateHistory() {
            if (this.loggedIn) {
                this.api
                    .getContractHistory()
                    .then((res) => {
                        if (res) {
                            this.history = res || [];
                        }
                    })
                    .catch(console.error);
            }
        },
        route(link) {
            this.$router.push(link);
        },
        onRoute() {
            this.close();
        },
    },
    created() {
        this.$bus.on("route", this.onRoute);
    },
    destroyed() {
        this.$bus.off("route", this.onRoute);
    },
};
</script>
<style lang="scss">
@import "../../assets/css/main";
@import "../../assets/css/forms";

div.user-wallet {
    margin-bottom: 8px;

    .ellipsize {
        max-width: 200px;
        font-size: 14px;
    }
}

.mobile-nav-button {
    line-height: 32px;
    position: fixed;
    top: 9px;
    left: 0;
    padding-left: 8px;
    background: var(--whitelabeled-heading);
    color: #fff;
    z-index: 3;
    display: flex;
    align-items: center;

    .material-icons {
        display: block;
        color: #fff;
        font-size: 28px;
    }

    .title {
        line-height: 32px;
        font-size: 20px;
        margin-left: 8px;
        font-weight: 600;
        display: flex;
        align-items: center;
        max-width: calc(100vw - 150px);
        overflow-x: hidden;
        white-space: nowrap;

        a {
            color: #fff;
            text-decoration: none;
            display: flex;
            align-items: center;
        }

        img {
            margin-right: 8px;
            display: block;
            height: 24px;
        }

        span {
            display: block;
        }

        @media screen and (max-width: 390px) {
            font-size: 18px;
        }
    }
}

.mobile-nav-mask {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(black, 0.1);
    z-index: 5;
}

.mobile-nav {
    width: 280px;
    height: 100vh;
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 6;
    background: $dark url("~@/assets/images/bg1.png") center bottom;
    border-right: 2px solid rgba($primary, 0.8);
    box-shadow: 2px 0 2px rgba(black, 0.5);
    transform: translateX(-105%);
    transition: transform 0.3s;

    .mobile-nav-content {
        color: #fff;
    }

    .mobile-logo {
        text-align: center;
        padding: 10px;

        h1 {
            font-size: 16px;
            margin: 5px 0 10px;
        }

        img {
            display: block;
            margin: 0 auto;
            width: 52px;
        }
    }

    .mobile-nav-top {
        text-align: center;

        .account-links {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            a {
                font-size: 14px;
                font-weight: 600;
                display: block;
                padding: 5px 10px;
            }
        }
    }

    .mobile-nav-menu {
        margin-top: 20px;
        padding: 0 10px 0 25px;

        .main-menu {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                a {
                    font-size: 14px;
                    font-weight: 600;
                    color: #fff;
                    line-height: 40px;
                    text-decoration: none;

                    &.router-link-active {
                        border-bottom: 1px solid $primary;
                    }
                }
            }
        }
    }
}

.recent-contracts {
    .recent-header {
        margin-top: 0;
    }
}

.mobile-nav-wrapper {
    z-index: 4;

    @media screen and (min-width: 641px) {
        display: none;
    }

    &.menu-open {
        .mobile-nav {
            transform: translateX(0);
        }
    }
}

.mobile-nav-top {
    .fuel-widget {
        div.fuel-logo {

            margin-left: 15px;
            width: 40px;
            height: 40px;
        }
        div.fuel-detail {
            font-size: 18px;

            .decimals {
                color: #b4b4b4;
            }
        }
    }
}

</style>
