import Vue from "vue";
import { cleanWhitelabelDraft, whitelabelSettingsGroups } from "@/components/whitelabel/whitelabel";
import WhitelabelSettingsGroup from "@/components/whitelabel/WhitelabelSettingsGroup.vue";
import { cssVarDefaults } from "@/components/whitelabel/css";
import { clone } from "rambdax";
import bootbox from "bootbox";
import DumbappPopover from "@/components/DumbappPopover.vue";
import { mergeWhitelabelConfig } from "@/components/whitelabel/whitelabel-layout";
import { mapGetters } from "vuex";
import ProgressCircle from "@/components/ProgressCircle.vue";
import { debounce } from "@/lib/vutil";
export default Vue.extend({
    name: "WhitelabelSettings",
    components: { ProgressCircle, DumbappPopover, WhitelabelSettingsGroup },
    props: {
        whitelabelId: {
            type: String,
        },
    },
    data() {
        return {
            show: false,
            sections: whitelabelSettingsGroups,
            saveDumbappContractId: null,
            saveDumbapp: null,
            pendingReload: false
        };
    },
    computed: {
        ...mapGetters("dumbapp", ["tracking"]),
        api() {
            return this.$store.getters["user/api"];
        },
        currentConfig() {
            let draft = this.$store.state.whitelabel.config;
            let defaults = this.defaults;
            let style = clone(defaults.style);
            if (draft.style) {
                Object.assign(style, draft.style);
            }
            return {
                ...defaults,
                ...draft,
                style,
            };
        },
        defaults() {
            let prop = this.$store.state.whitelabel.defaults;
            let settings = this.settings?.settings || {};
            let style = clone(cssVarDefaults);
            if (prop.style) {
                Object.assign(style, clone(prop.style));
            }
            if (settings.style) {
                Object.assign(style, settings.style);
            }
            return {
                ...prop,
                ...settings,
                style,
            };
        },
        supportedPage() {
            let pages = Object.values(this.$store.state.whitelabel.pages).length;
            return pages > 0;
        },
        settings() {
            if (this.whitelabelId) {
                return this.$store.state.whitelabel.pages[this.whitelabelId];
            }
            return null;
        },
        dumbappQuery() {
            if (this.saveDumbapp && this.settings) {
                return {
                    data: JSON.stringify(mergeWhitelabelConfig({
                        draft: this.$store.state.whitelabel.config,
                        prop: this.settings.settings,
                    })),
                    folderName: this.settings.folder,
                    fileName: this.settings.file,
                };
            }
            return null;
        },
        pending() {
            let trackingId = ["whitelabel-settings", this.whitelabelId].join("-");
            let tracks = this.$store.getters["dumbapp/tracking"][trackingId];
            return tracks?.find(it => it.status === "pending");
        }
    },
    watch: {
        "settings.bookAddress": {
            immediate: true,
            handler(val) {
                if (this.settings) {
                    this.api.contracts
                        .getOne({ address: val, chainId: this.settings.bookChain })
                        .then(async (contract) => {
                        if (!this.saveDumbappContractId ||
                            this.saveDumbappContractId !== contract.id) {
                            let dumbapps = await this.api.getBookCodes(contract.id);
                            this.saveDumbapp = dumbapps.set;
                            this.saveDumbappContractId = contract.id;
                        }
                    });
                }
            },
        },
        settings: {
            deep: true,
            handler: debounce(function (val) {
                let settings = val.settings;
                let draft = this.$store.state.whitelabel.config;
                let update = cleanWhitelabelDraft(settings, draft);
                if (update) {
                    this.$store.dispatch("whitelabel/replaceWhitelabelConfig", update);
                }
            }, 100)
        },
        pending: {
            handler(val, old) {
                if (old && !val) {
                    this.pendingReload = true;
                    // Give it time to propagate
                    setTimeout(() => {
                        this.pendingReload = false;
                        this.$bus.emit("reload-settings");
                    }, 5000);
                }
            }
        }
    },
    methods: {
        reset() {
            bootbox.confirm({
                title: "Reset Changes?",
                message: `All unsaved changes will be lost.`,
                backdrop: true,
                callback: (result) => {
                    if (result) {
                        this.$store.dispatch("whitelabel/clearWhitelabelConfig");
                        this.$bus.emit("whitelabel-reset");
                    }
                },
            });
        },
        submitted(submission) {
            let data = {
                ...this.dumbappQuery,
                id: submission.id,
            };
            this.$store.dispatch("dumbapp/trackSubmission", {
                key: ["whitelabel-settings", this.whitelabelId].join("-"),
                data
            });
        },
    },
});
