import Cleave from "cleave.js";

class VueCleave {

    static inserted(el, binding) {
        console.log('cleave', binding);
        let element = el;
        if (el.__vue__) {
            let component = el.__vue__;
            if (component.component === "FormulateInputText") {
                element = $("input", el)[0];
            }
        }
        el.cleave = new Cleave(element, binding.value || {})
    }

    static update(el, binding) {
        const event = new Event('input', {bubbles: true});
        setTimeout(function () {
            el.value = el.cleave.properties.result
            el.dispatchEvent(event)
        }, 100);
    }

    static unbind(el, binding) {
        if (el.cleave) {
            el.cleave.destroy();
            delete el.cleave;
        }
    }

    install(Vue) {
        Vue.directive('cleave', {
            inserted: VueCleave.inserted,
            update: VueCleave.update,
            unbind: VueCleave.unbind
        });

        Vue.directive('cleave-number', {
            inserted(el, binding) {
                VueCleave.inserted(el, {
                    value: {
                        numeral: true,
                        numeralThousandsGroupStyle: 'thousand',
                        delimiter: ' ',
                        ...(binding.value || {})
                    }
                })
            },
            update: VueCleave.update,
            unbind: VueCleave.unbind
        })
    }
}

export default new VueCleave();
