<template>
    <FormulateInput
        :name="field.name"
        :validation-name="field.label"
        :id="id"
        type="text"
        error-behavior="blur"
        v-on="$listeners">
        <template #label><slot name="label"/></template>
        <template #help><slot name="help"/></template>
    </FormulateInput>
</template>

<script>
export default {
    name: "PlainInput",
    props: {
        id: {
            type: String,
            required: true
        },
        field: Object
    },
    data() {
        return {};
    }
}
</script>

<style scoped>

</style>
