import { cssVarSections } from "@/components/whitelabel/css";
import Vue from "vue";
import { path } from "rambdax/immutable";
import { debounce } from "@/lib/vutil";
import { applyDiff } from "rambdax";
export const whitelabelLayoutSettings = {
    name: "Layout",
    vars: [
        {
            key: "heading",
            type: "checkbox",
            title: "Show large heading",
            description: "Show a large hero-like heading section.",
        },
        {
            key: "title",
            type: "checkbox",
            title: "Show title",
            description: "Show a title on the page even without the large heading.",
        },
        {
            key: "whitelabelLayout",
            type: "checkbox",
            title: "Use whitelabel layout",
            description: "Use a layout without a Blockwell top menu and a more plain footer.",
        },
    ],
};
export const whitelabelImageSettings = {
    name: "Images",
    vars: [
        {
            key: "logo",
            type: "image",
            title: "Heading logo",
            description: "The image to show ahead of the title in the large heading."
        },
        {
            key: "logoLarge",
            type: "checkbox",
            title: "Large logo",
            description: "Show the logo image larger and without the text next to it.",
        },
        {
            key: "icon",
            type: "image",
            title: "Header icon",
            description: "A smaller version of a logo in the thin top header of the whitelabel layout."
        },
        {
            key: "iconLink",
            type: "link",
            title: "Icon link",
            description: "You can enter a web link for the header icon to click to."
        }
    ]
};
const buttonStyleOptions = [
    {
        key: "mainButtonOutline",
        type: "checkbox",
        title: "Main buttons outlined",
        description: "When checked the main button style will be outlined rather than solid."
    },
    {
        key: "secondaryButtonOutline",
        type: "checkbox",
        title: "Secondary outlined",
        description: "When checked the secondary button style will be outlined rather than solid."
    }
];
export const whitelabelSettingsGroups = [
    whitelabelLayoutSettings, whitelabelImageSettings, ...cssVarSections.map(it => {
        if (it.name.startsWith("Buttons")) {
            return {
                name: it.name,
                vars: [...buttonStyleOptions, ...it.vars]
            };
        }
        return it;
    })
];
export const WhitelabelField = Vue.extend({
    props: {
        setting: {
            type: Object,
        },
        defaults: {
            type: Object,
        },
        currentConfig: {
            type: Object,
        },
    },
    data() {
        return {
            val: path(this.setting.key, this.currentConfig),
            changed: false,
        };
    },
    computed: {
        modified() {
            return this.val !== path(this.setting.key, this.defaults);
        }
    },
    watch: {
        currentConfig: {
            deep: true,
            handler(val) {
                if (!this.changed) {
                    let conf = path(this.setting.key, val);
                    if (conf !== undefined) {
                        this.val = conf;
                    }
                }
            },
        },
    },
    methods: {
        reset() {
            this.changed = false;
            this.val = path(this.setting.key, this.currentConfig);
        }
    },
    created() {
        this.$bus.on("whitelabel-reset", this.reset);
    },
    destroyed() {
        this.$bus.off("whitelabel-reset", this.reset);
    }
});
export const WhitelabelTextFieldMixin = Vue.extend({
    methods: {
        input: debounce(function (ev) {
            let target = ev.target;
            let value = target.value;
            this.changed = true;
            let config = {};
            config = applyDiff([
                {
                    op: "add",
                    path: this.setting.key,
                    value,
                },
            ], config);
            this.$store.dispatch("whitelabel/saveWhitelabelConfig", config);
        }),
    }
});
export function cleanWhitelabelDraft(settings, draft) {
    let updateDraft = false;
    let newDraft = {};
    let settingsStyle = settings.style || {};
    for (let [key, val] of Object.entries(draft)) {
        if (typeof val === "string" || typeof val === "boolean") {
            // @ts-ignore
            if (settings[key] !== draft[key]) {
                updateDraft = true;
                // @ts-ignore
                newDraft[key] = val;
            }
        }
        else {
            let style = {};
            for (let [k, value] of Object.entries(val)) {
                let css = k;
                if (settingsStyle[css] !== value) {
                    updateDraft = true;
                    style[css] = value;
                }
            }
            newDraft.style = style;
        }
    }
    if (updateDraft) {
        return newDraft;
    }
    return null;
}
