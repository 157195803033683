import * as interval from "@/lib/interval";
import { DumbappSubmission } from "@blockwell/dumbapp";
import moment from "moment";
export default {
    name: "ApprovalSubmissionHeader",
    props: {
        submission: DumbappSubmission,
        approval: {
            type: Object
        },
        expanded: {
            type: Boolean,
            default: false
        },
        expandable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            now: moment()
        };
    },
    computed: {
        title() {
            return this.submission?.dumbapp?.getTitle();
        },
        timestamp() {
            return this.submission?.data?.meta?.created;
        },
        time() {
            if (this.timestamp) {
                let t = moment(this.timestamp);
                let yesterday = this.now.subtract(1, 'day');
                if (t.isBefore(yesterday)) {
                    return t.format('MMM D, h:mm a');
                }
                else {
                    return t.fromNow();
                }
            }
            return "";
        },
        link() {
            let shortcode = this.submission?.dumbapp?.shortcode;
            if (shortcode) {
                return `/${shortcode}`;
            }
            return "";
        }
    },
    methods: {
        clear() {
            if (this.interval !== null) {
                this.interval.clear();
                this.interval = null;
            }
        }
    },
    created() {
        this.interval = interval.every(() => {
            this.now = moment();
        }, 1000);
    },
    destroyed() {
        this.clear();
    }
};
