import { getNetwork } from "../../assets/lib/networks";
import RolodexTags from "./RolodexTags.vue";
export default {
    name: "FullRolodexEntry",
    components: { RolodexTags },
    props: {
        entry: {
            type: Object,
        },
    },
    data() {
        return {};
    },
    computed: {
        contract() {
            if (this.entry?.type === "contract") {
                return this.entry.contract;
            }
            return null;
        },
        wallet() {
            if (this.entry?.type === "wallet") {
                return this.entry;
            }
            return null;
        },
        contractName() {
            return this.contract?.name;
        },
        contractNetwork() {
            if (this.contract) {
                return getNetwork(this.contract.network).networkName;
            }
            return null;
        },
    },
    methods: {
        edit() {
            this.$emit("edit", this.entry);
        },
        pin() {
            this.$store.dispatch("rolodex/setPinned", {
                payload: this.entry,
                pinned: true,
            });
        },
        unpin() {
            this.$store.dispatch("rolodex/setPinned", {
                payload: this.entry,
                pinned: false,
            });
        },
        deleteEntry() {
            this.$store.dispatch("rolodex/delete", this.entry);
        },
        select() {
            this.$emit("click", this.entry);
        },
    },
};
