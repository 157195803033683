<template>
    <Messages class="in-page-messages"
              :messages="messages"
              @dismiss="dismiss"/>
</template>

<script>
import Messages from "@/components/message/Messages";
import {mapActions} from "vuex";

export default {
    name: "PageMessages",
    components: {Messages},
    computed: {
        messages() {
            if (!this.page) {
                return null;
            }
            return this.$store.state.message.messages.filter(it => it.page === this.page);
        },
        page() {
            switch (this.$route.path) {
                case '/app/wallet':
                    return 'wallet';
                case '/app/account/external':
                case '/app/account/kyc/external':
                    return 'external';
            }

            return null;
        }
    },
    methods: {
        ...mapActions('message', ['dismiss'])
    }
}
</script>
