<template>
    <div
        :class="[`formulate-input-element`, `formulate-input-element--${context.type}`, unit ? 'formulate-input-element--with-unit' : '']"
        :data-type="context.type">
        <input
            ref="input"
            type="text"
            v-bind="context.attributes"
            v-on="$listeners"
            @blur="blur"
            @input="input"
        >
        <span v-if="unit" class="currency-unit" ref="unit">{{unit}}</span>
    </div>
</template>

<script>
import Cleave from "cleave.js";
import ResizeObserver from "resize-observer-polyfill";

export default {
    name: "FormulateCurrency",
    props: {
        context: {
            type: Object,
            required: true
        },
        prefix: String,
        unit: String
    },
    data() {
        return {
            cleave: null,
            rawValue: "",
            onCleave: null
        };
    },
    watch: {
        "context.model": {
            immediate: true,
            handler(val) {
                if (val !== this.rawValue) {
                    this.rawValue = val;
                    if (this.cleave) {
                        this.cleave.setRawValue(val);
                    } else {
                        this.onCleave = val;
                    }
                }
            }
        },
        unit: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.$nextTick(() => {
                        this.observer.observe(this.$refs.unit);
                    })
                }
            }
        }
    },
    methods: {
        blur(event) {
            this.$emit("blur", event);
            this.context.blurHandler(event);
        },
        input(event) {
            this.rawValue = this.cleave.getRawValue();
            this.context.model = this.rawValue;
            this.$emit("input", this.rawValue);
            this.$emit("input-event", this.rawValue, event);
            this.$emit("value", this.rawValue);
        }
    },
    mounted() {
        let cleaveOptions = {
            numeral: true,
            numeralThousandsGroupStyle: "thousand",
            delimiter: '\u00A0',
            numeralDecimalScale: 18
        };

        if (this.prefix) {
            cleaveOptions.prefix = this.prefix;
            cleaveOptions.rawValueTrimPrefix = true;
        }
        this.cleave = new Cleave(this.$refs.input, cleaveOptions);

        if (this.onCleave) {
            this.cleave.setRawValue(this.onCleave);
            this.onCleave = null;
        }

        this.observer = new ResizeObserver(entries => {
            const {width} = entries[0].contentRect;

            if (this.$refs.input) {
                this.$refs.input.style = `padding-right: ${width + 10}px`;
            }
        });
    },
    destroyed() {
        this.cleave?.destroy();

        this.observer?.disconnect();
    }
};
</script>

<style lang="scss">
.formulate-input-element--with-unit[data-type='currency'] {
    position: relative;

    input[type=text] {
        padding-right: 50px;
    }
    .currency-unit {
        position: absolute;
        right: 10px;
        top: calc(50% - 12px);
    }
}
</style>
