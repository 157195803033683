import abi from "@/assets/data/BalanceToken.abi.json";
import { clone } from "rambdax";
import { getChain } from "@blockwell/chains";
import { BigNumber } from "bignumber.js";
export const fuelTokenAddress = "0xeeeeeeee8c82e57af492fe03dae4b3c26f199cc4";
export const fuelTokenNetwork = getChain(49111);
export class FuelData {
    constructor(data) {
        if (data) {
            Object.assign(this, clone(data));
        }
        else {
            this.balance = new BigNumber(0);
            this.stake = this.balance;
        }
    }
}
export async function loadFuel(api, batcher, account, skipCache = false) {
    const token = await api.contracts.getContract({
        chainId: fuelTokenNetwork,
        address: fuelTokenAddress,
    });
    const decimals = parseInt(token.parameters.decimals);
    let res = await batcher
        .batch(fuelTokenNetwork)
        .setContract(abi, fuelTokenAddress)
        .addBig("balanceOf", "balance", [account])
        .addBig("stakeOf", "stake", [account], null, skipCache ? 2 : 3000)
        .addBig("availableBalanceOf", "available", [account])
        .execute();
    let locked;
    if (res.available && res.available.lt(res.balance)) {
        locked = res.balance.minus(res.available).div(`1e${decimals}`);
    }
    else {
        locked = null;
    }
    return new FuelData({
        decimals,
        name: token.name,
        symbol: token.parameters.symbol,
        balance: res.balance.div(`1e${decimals}`),
        stake: res.stake.div(`1e${decimals}`),
        available: res.available.div(`1e${decimals}`),
        locked,
    });
}
