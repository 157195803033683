import { createPopper } from "@popperjs/core";
import ResizeObserver from "resize-observer-polyfill";
export default {
    props: {
        placement: {
            type: String,
            default: "auto",
        },
        reference: [Element, null],
        offset: String,
        preventClose: Boolean,
        boundaryOverride: [Element, null]
    },
    data() {
        return {
            showing: false,
            useFlip: true,
            placementOverride: null,
            referenceOverride: null,
            boundary: null,
        };
    },
    methods: {
        open() {
            if (!this.popper) {
                let ref;
                if (this.reference) {
                    if (this.reference instanceof Element) {
                        ref = this.reference;
                    }
                    else {
                        ref = $(this.reference)[0];
                    }
                }
                else if (this.referenceOverride) {
                    ref = this.referenceOverride;
                }
                let modifiers = [
                    {
                        name: "preventOverflow",
                        options: {
                            altAxis: true,
                            padding: 10,
                            boundary: this.boundaryOverride || this.boundary,
                        },
                    },
                ];
                if (this.useFlip) {
                    modifiers.push({
                        name: "flip",
                        options: {
                            fallbackPlacements: ["top", "right", "bottom", "left"],
                            boundary: this.boundaryOverride || this.boundary,
                        },
                    });
                }
                if (this.$refs.arrow) {
                    modifiers.unshift({
                        name: "offset",
                        options: {
                            offset: [0, 8],
                        },
                    });
                    modifiers.push({
                        name: "arrow",
                        options: {
                            element: this.$refs.arrow,
                        },
                    });
                }
                this.popper = createPopper(ref || this.$el, this.$refs.popover, {
                    strategy: "fixed",
                    modifiers,
                    placement: this.placementOverride || this.placement,
                });
            }
            this.$nextTick(() => {
                this.showing = true;
                window.addEventListener("mousedown", this.globalClick, true);
            });
        },
        close() {
            this.showing = false;
            window.removeEventListener("mousedown", this.globalClick, true);
        },
        enter() {
            this.$nextTick(() => {
                this.$refs.popover.setAttribute("data-show", "");
            });
        },
        leave() {
            this.$refs.popover?.removeAttribute("data-show");
        },
        beforeLeave() { },
        afterEnter() {
            this.popper?.update();
        },
        globalClick(event) {
            if (this.preventClose) {
                return;
            }
            if (!event.target || !this.$refs.content.contains(event.target)) {
                this.close();
            }
        },
        dismissClick() {
            this.close();
        },
    },
    watch: {
        showing(val) {
            this.$emit("update:show", val);
        },
        show(val) {
            if (!val) {
                this.close();
            }
            else {
                this.open();
            }
        },
    },
    mounted() {
        this.observer = new ResizeObserver((entries) => {
            this.popper?.update();
        });
        this.observer.observe(this.$refs.popover);
        if (this.show) {
            // This allows references to update first
            setTimeout(() => {
                this.open();
            });
        }
    },
    beforeDestroy() {
        if (this.popper) {
            this.popper.destroy();
            this.popper = null;
        }
        window.removeEventListener("mousedown", this.globalClick, true);
    },
};
