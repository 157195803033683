<template>
    <ul class="main-menu">
        <li>
            <router-link to="/app/wallet">WALLET</router-link>
        </li>
        <li>
            <router-link to="/creator">QR CREATOR</router-link>
        </li>
        <li>
            <router-link to="/palette2">PALETTES</router-link>
        </li>
        <li>
            <a href="https://docs.blockwell.ai">DOCS</a>
        </li>
        <li>
            <router-link to="/faq">FAQ</router-link>
        </li>
        <li>
            <a href="https://blockwell.ai/#team">TEAM</a>
        </li>
        <li>
            <a href="http://t.me/Blockwell" target="_blank">CONTACT</a>
        </li>
    </ul>
</template>
<script>
export default {
    name: 'MainMenu'
}
</script>
