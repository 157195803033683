<template>
    <div>
        <div v-if="showValue" class="suggestion-argument">
            <div class="suggestion-id-wrap">
                ID: <span>{{ value }}</span>
            </div>
            <ClampedText>
                {{ suggestionText }}
            </ClampedText>
        </div>
        <slot v-else/>
    </div>
</template>

<script>
import ClampedText from "@/components/ClampedText";
import * as voting from "@/lib/voting";

export default {
    name: "SuggestionArgument",
    inject: ['provider'],
    components: {ClampedText},
    props: {
        value: [String, Number],
        arg: Object,
        step: Object
    },
    data() {
        return {
            suggestion: null
        }
    },
    computed: {
        suggestionText() {
            if (this.suggestion) {
                return this.suggestion.text;
            }
            return "";
        },
        showValue() {
            return this.value !== undefined && this.value !== null;
        },
        suggestionDeps() {
            if (this.step) {
                return {
                    address: this.dumbappAddresses?.[this.step.index],
                    network: this.dumbappChainIds?.[this.step.index],
                    value: this.value
                }
            }
            return null;
        }
    },
    watch: {
        suggestionDeps: {
            immediate: true,
            handler(val) {
                if (val && val.value !== undefined && val.value !== null) {
                    voting.getSuggestion(val.network, val.address, val.value)
                        .then(res => this.suggestion = res);
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.suggestion-id-wrap {
    color: $text-muted;

    ::v-deep( span ) {
        color: $code-color;
    }
}

.suggestion-argument {
    ::v-deep( .toggle ) {
        padding: 0 15px;
        min-width: auto;
        margin-left: 5px;
        font-size: 12px;
        box-shadow: none !important;
    }
}
</style>
