<template>
    <div v-if="balance" class="token-balance-block dumbapp-block">
        <div class="token-balance-heading">
            Your Token Balance
        </div>
        <TokenAmount
            :value="balance"
            :symbol="context.contract.parameters.symbol"
            :decimals="context.contract.parameters.decimals"
            />
    </div>
</template>

<script>
import {BlockContext} from "@/components/dumbapp/block/Block";
import TokenAmount from "@/components/TokenAmount";
import { Chain } from "@blockwell/chain-client";
import BigNumber from "bignumber.js";

export default {
    name: "TokenBalanceBlock",
    components: {TokenAmount},
    props: {
        context: BlockContext
    },
    data() {
        return {
            balance: null
        };
    },
    computed: {
        balanceDeps() {
            return {
                account: this.context.account,
                contract: this.context.contract
            }
        }
    },
    watch: {
        balanceDeps: {
            deep: true,
            immediate: true,
            handler(val) {
                let {account, contract} = val;
                if (account) {
                    Chain.readString(contract.network, contract.address, contract.abi, "balanceOf", [
                        account
                    ]).then(result => {
                        if (this.balanceDeps.account === account) {
                            let balance = new BigNumber(result);
                            if (balance.isNaN()) {
                                this.balance = null;
                            } else {
                                this.balance = balance;
                            }
                        }
                    });
                }
            }
        }
    }
}
</script>

<style scoped lang=scss>
@import "~@/assets/css/custom.scss";

.token-balance-heading {
    color: var(--secondary);
    font-style: italic;
}

.token-balance-block {
    ::v-deep( .token-symbol ) {
        color: var(--text-muted);
    }
}

</style>
