import Vue from "vue";
import InjectProviderMixin from "../provider/InjectProviderMixin";
import { componentForType } from "@/components/dumbapp/argument/ArgumentComponentMixin";
import { DataStringFunctions, DataStringValues } from "@blockwell/dumbapp";
export default Vue.extend({
    name: "JsonArgument",
    mixins: [InjectProviderMixin],
    props: {
        value: [DataStringFunctions, DataStringValues],
        arg: Object,
        step: Object
    },
    data() {
        return {
            valueList: []
        };
    },
    watch: {
        value: {
            async handler(value) {
                let fields = [];
                let arg = this.arg;
                let parameters = arg.value.parameters;
                let jsonArguments = parameters.json;
                let values = value || new DataStringFunctions();
                for (let arg of jsonArguments) {
                    let resolvedValue;
                    if (values instanceof DataStringFunctions) {
                        let val = values[arg.name];
                        if (val !== undefined && val !== null) {
                            let res = await val.value();
                            if (res instanceof DataStringFunctions) {
                                console.warn("Value function resolved to DataStringFunctions, but they are not allowed as nested values");
                            }
                            else {
                                resolvedValue = res;
                            }
                        }
                    }
                    else {
                        resolvedValue = values[arg.name];
                    }
                    fields.push({
                        type: arg.type,
                        name: arg.name,
                        value: resolvedValue
                    });
                }
                this.valueList = fields;
            }
        }
    },
    methods: {
        componentForType
    }
});
