<template>
    <div class="layout">
        <MobileNav />
        <MainHeader />
        <div class="super-wrapper" :class="sizeClass">
            <main role="main" class="main-wrapper">
                <div class="section-content main-content" :class="sizeClass">
                    <div class="container-fluid p-0">
                        <h2 v-if="!noTitle" class="default-title">
                            <portal-target name="title" tag="span"></portal-target>
                        </h2>
                        <PageMessages v-if="!noMessages" />
                        <slot />
                    </div>
                </div>
            </main>
        </div>
        <MainFooter />
    </div>
</template>

<script>
import PageMessages from "@/components/message/PageMessages";
import MainFooter from "@/layouts/components/MainFooter";
import MobileNav from "@/layouts/components/MobileNav";
import MainHeader from "@/layouts/components/MainHeader";

export default {
    components: { MainFooter, MainHeader, MobileNav, PageMessages },
    props: {
        size: String,
        noTitle: Boolean,
        noMessages: Boolean,
    },
    computed: {
        sizeClass() {
            if (this.size) {
                return "content-width-" + this.size;
            } else {
                return "";
            }
        },
    },
};
</script>

<style lang="scss">
.in-page-messages {
    margin-top: 20px;
    margin-bottom: 15px;

    &:empty {
        margin-top: 0;
    }
}

.main-content.content-width-full {
    max-width: 100vw;
    padding: 0;

    .container-fluid {
        margin: 0;
        width: auto;
        max-width: none;
    }
}

.main-content.content-width-scroll {
    max-width: 100vw;
    overflow-x: auto;
    padding: 0;

    .container-fluid {
        margin: 0;
        width: auto;
        max-width: none;
    }
}

h2.default-title {
    line-height: 1.5;
    font-size: 32px;
    margin-left: 8px;
    font-weight: 600;
    display: flex;
    align-items: center;

    .vue-portal-target {
        display: flex;
        align-items: center;
    }

    a {
        color: #fff;
        text-decoration: none;
        display: flex;
        align-items: center;
    }

    img {
        margin-right: 15px;
        display: block;
        height: 36px;
    }

    span {
        display: block;
    }
}

@media screen and (max-width: 640px) {
    h2.default-title {
        display: none;
    }
}
</style>
