<template>
    <Dropdown icon="settings" class="account-dropdown">
        <div class="account-menu">
            <FuelWidget />
            <router-link to="/app/account" class="dropdown-item" v-close-popover>My Account</router-link>
            <router-link to="/app/account/contracts" class="dropdown-item" v-close-popover>My Contracts</router-link>
            <div class="dropdown-divider"></div>
            <a href="javascript: void 0;" class="dropdown-item" v-close-popover @click="performLogout">Log Out</a>
        </div>
    </Dropdown>
</template>

<script>

import Dropdown from "@/components/Dropdown";
import {mapActions} from "vuex";
import FuelWidget from "@/components/fuel/FuelWidget.vue";

export default {
    name: "AccountDropdown",
    components: { FuelWidget, Dropdown},
    data() {
        return {
            popperOptions: {
                modifiers: {
                    preventOverflow: {
                        padding: 0
                    }
                }
            }
        };
    },
    created() {
    },
    methods: {
        ...mapActions('user', ['logout']),
        async performLogout() {
            await this.logout();
        }
    }
}
</script>

<style scoped lang="scss">
@import "../assets/css/custom";

.account-menu {
    padding: 10px 0;
}

</style>
