import abi from "@/assets/data/Daico.abi.json";
import {responseToProposal} from "@/assets/lib/daico";
import Dapp from "@/assets/lib/Dapp";
import {Batcher} from "@/lib/eth/Batcher";
import {getTokenData} from "@/lib/eth/Token";
import { Chain } from "@blockwell/chain-client";

export default class Daico {
    static async loadProposal(network, address, proposalId) {
        const net = Dapp.getNetwork(network);
        const web3 = net.web3;

        const batcher = new Batcher(web3);

        batcher.setContract(abi, address)
            .add('getProposalInts', 'ints', [proposalId])
            .add('getProposalText', 'text', [proposalId])
            .add('getProposalRecipient', 'recipient', [proposalId]);

        let res = await batcher.execute();

        let prop = responseToProposal(null, proposalId, res.ints, res.text, res.recipient);
        prop.network = network;
        prop.address = address;
        return prop;
    }

    static async loadAllProposals(network, address) {
        const net = Dapp.getNetwork(network);
        const web3 = net.web3;

        const contract = new web3.eth.Contract(abi, address);
        let count = parseInt(await contract.methods.getProposalCount().call());

        let batch = new web3.BatchRequest();

        for (let i = 0; i < count; i++) {
            batch.add(contract.methods.getProposalInts(i).call.request());
            batch.add(contract.methods.getProposalText(i).call.request());
            batch.add(contract.methods.getProposalRecipient(i).call.request());
        }
        let res = await batch.execute();
        let data = res.response;
        let j = 0;
        let proposals = [];

        for (let i = 0; i < count; i++) {
            let ints = data[j++];
            let text = data[j++];
            let recipient = data[j++];

            let prop = responseToProposal(null, i, ints, text, recipient);
            prop.network = network;
            prop.address = address;
            proposals.push(prop);
        }

        return proposals;
    }

    static async getToken(network, address) {
        let tokenAddress = await Chain.readString(network, address, abi, 'token');
        let res = await getTokenData(network, [tokenAddress]);
        return res[0];
    }
}
