import Clipboard from "@/components/Clipboard.vue";
import GasEstimate from "@/components/dumbapp/GasEstimate.vue";
import SubmitMixin from "@/components/dumbapp/submit/SubmitMixin";
import LoadingButton from "@/components/LoadingButton.vue";
import Modal from "@/components/Modal.vue";
import QuickExchange from "@/components/QuickExchange.vue";
import { getTokenData } from "@/lib/eth/Token";
import BigNumber from "bignumber.js";
import { mapGetters } from "vuex";
import { resolveBigNumberishSourcable, } from "@blockwell/dumbapp";
import { Chain } from "@blockwell/chain-client";
import { getChain } from "@blockwell/chains";
import Vue from "vue";
import { ETH_ADDRESS_ZERO } from "@blockwell/eth-types";
export default Vue.extend({
    name: "BlockwellWalletView",
    components: { GasEstimate, QuickExchange, Modal, Clipboard, LoadingButton },
    mixins: [SubmitMixin],
    data() {
        return {
            forceSubmit: false,
            showQex: false,
            qexTransaction: null,
            needAssetValue: null,
            needAsset: null,
        };
    },
    computed: {
        ...mapGetters("user", ["loggedIn", "api"]),
        needMoreAsset() {
            if (this.needAsset) {
                let diff = this.needAsset.balance.minus(this.needAsset.total);
                if (diff.lt(0)) {
                    return diff.abs().sd(3, 0);
                }
            }
            return null;
        },
        needMoreAssetFormatted() {
            return this.needMoreAsset?.toFormat();
        },
        network() {
            if (this.dumbappChainId) {
                return getChain(this.dumbappChainId);
            }
            return null;
        },
    },
    watch: {
        api: {
            immediate: true,
            handler(val, old) {
                old?.events?.off("login", this.login);
                val?.events?.on("login", this.login);
            }
        }
    },
    methods: {
        buyAndSend() {
            this.showQex = true;
        },
        login() {
            this.$bus.emit("login_request", {
                message: "Sign In to load your Blockwell Wallet.",
            });
        },
        qexComplete(transaction) {
            this.qexTransaction = transaction;
            this.showQex = false;
            this.send();
        },
        calculateNeedAssetValue(values) {
            let dumbapp = this.dumbapp;
            if (dumbapp && dumbapp.requiresAsset) {
                Promise.all([
                    resolveBigNumberishSourcable(values, [dumbapp.requiresAsset.value]).then((it) => new BigNumber(it)),
                    resolveBigNumberishSourcable(values, [dumbapp.requiresAsset.decimals]).then(parseInt),
                ])
                    .then(async ([value, decimals]) => {
                    const address = dumbapp.requiresAsset.address;
                    const network = dumbapp.requiresAsset.network;
                    let total;
                    let balance;
                    let name;
                    let symbol;
                    if (address === ETH_ADDRESS_ZERO) {
                        decimals = 18;
                        balance = new BigNumber(await Chain.getBalance(dumbapp.requiresAsset.network, this.account)).shiftedBy(decimals * -1);
                        total = new BigNumber(value).shiftedBy(decimals * -1);
                        if (dumbapp.requiresAsset.gas) {
                            let price;
                            let pricing = await Chain.gasPrice(dumbapp.requiresAsset.network);
                            if (pricing.type === 2) {
                                price = new BigNumber(pricing.maxFeePerGas);
                            }
                            else {
                                price = new BigNumber(pricing.gasPrice);
                            }
                            total = price
                                .times(dumbapp.requiresAsset.gas)
                                .shiftedBy(decimals * -1)
                                .plus(total);
                        }
                        name = "Ether";
                        symbol = "ETH";
                    }
                    else {
                        let res = await getTokenData(network, [address], this.account);
                        let token = res[0];
                        name = token.name;
                        symbol = token.symbol;
                        if (isNaN(decimals)) {
                            decimals = token.decimals;
                        }
                        total = new BigNumber(value).shiftedBy(decimals * -1);
                        balance = new BigNumber(token.balance).shiftedBy(decimals * -1);
                    }
                    let data = {
                        address,
                        network,
                        balance,
                        decimals,
                        name,
                        symbol,
                        total,
                        currencyCode: dumbapp.requiresAsset.currencyCode,
                    };
                    this.needAsset = data;
                })
                    .catch(console.error);
            }
        },
    },
    mounted() {
        let provider = this.provider;
        if (provider) {
            provider.on("change", this.calculateNeedAssetValue);
            this.calculateNeedAssetValue(provider.values);
            provider.execution.addHandler(this, "postInput", async (state) => {
                let qex = undefined;
                if (this.qexTransaction) {
                    qex = {
                        currency: this.qexTransaction.payout_settlement.currency,
                        quantity: this.qexTransaction.payout_settlement.quantity,
                        transactionId: this.qexTransaction.payout_settlement.transaction_id,
                    };
                    if (this.needAsset) {
                        qex.total = this.needAsset.total;
                    }
                }
                return {
                    success: true,
                    data: {
                        qex,
                    },
                };
            });
        }
    },
    beforeDestroy() {
        let provider = this.provider;
        if (provider) {
            provider.off("change", this.calculateNeedAssetValue);
            provider.execution.removeHandler(this);
        }
        this.api.events.off("login", this.login);
    },
});
