<template>
    <div>
        <div v-if="showValue" class="markdown-argument">
            <ClampedText>
                <div class="value">{{ value }}</div>
            </ClampedText>
        </div>
        <slot v-else/>
    </div>
</template>

<script>
import ClampedText from "@/components/ClampedText";
export default {
    name: "PlainArgument",
    components: { ClampedText },
    props: {
        value: [String, Object]
    },
    computed: {
        showValue() {
            return !!this.value;
        }
    }
}
</script>

<style scoped>
.value {
    white-space: pre-wrap;
    overflow-wrap: break-word;
}
</style>
