<template>
    <div class="payment-proposal-info">
        <span class="payment-proposal-recurring">{{ recurring }} </span>
        <TokenAmount
            v-if="token"
            :value="proposal.value"
            :decimals="token.decimals"
            :symbol="token.symbol"
            class="payment-proposal-amount"
        />
        <span class="payment-proposal-to"> to </span>
        <span class="payment-proposal-recipient">
            <Clipboard :address="proposal.recipient" :network="proposal.network" ellipsize/>
        </span>
    </div>
</template>

<script>
import Clipboard from "@/components/Clipboard";
import TokenAmount from "@/components/TokenAmount";
import Daico from "@/lib/eth/Daico";
export default {
    name: "ProposalInfo",
    components: {Clipboard, TokenAmount},
    inject: ['provider'],
    props: {
        proposal: Object
    },
    data() {
        return {};
    },
    computed: {
        value() {
            return this.proposal?.value || "0";
        },
        recurring() {
            if (!this.proposal) {
                return '';
            }
            if (this.proposal.recurrence > 0) {
                return 'recurring payment of';
            }
            return 'one-time payment of';
        }
    },
    asyncComputed: {
        async token() {
            if (this.provider) {
                let token = await Daico.getToken(this.proposal.network, this.proposal.address);
                console.log('token', token);
                return token;
            }
            return null;
        }
    },
    methods: {
    }
}
</script>

<style scoped>

</style>
