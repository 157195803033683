import Vue from "vue";
import { getChain } from "@blockwell/chains";
import BigNumber from "bignumber.js";
export default Vue.extend({
    data() {
        return {
            resolvedArguments: null,
        };
    },
    computed: {
        dumbappContracts() {
            return this.resolvedArguments?.map((it) => it.contract);
        },
        dumbappAddresses() {
            return this.resolvedArguments?.map((it) => it.address);
        },
        dumbappValues() {
            return this.resolvedArguments?.map((it) => it.value);
        },
        dumbappChainIds() {
            return this.resolvedArguments?.map((it) => it.chainId);
        },
        dumbappChainId() {
            return this.dumbappChainIds?.[0];
        },
        dumbappNetwork() {
            return getChain(this.dumbappChainId);
        },
        stepContract() {
            if (this.index === 0 || this.index > 0) {
                return this.dumbappContracts?.[this.index];
            }
            return null;
        },
        stepNetwork() {
            if (this.index === 0 || this.index > 0) {
                let chainId = this.dumbappChainIds?.[this.index];
                if (chainId) {
                    return getChain(chainId);
                }
            }
            return null;
        },
        stepAddress() {
            if (this.index === 0 || this.index > 0) {
                return this.dumbappAddresses?.[this.index];
            }
            return null;
        },
        stepValue() {
            if (this.index === 0 || this.index > 0) {
                let val = this.dumbappValues?.[this.index];
                if (val !== null && val !== undefined) {
                    return new BigNumber(val).shiftedBy(-18).toString(10);
                }
            }
            return null;
        }
    },
    methods: {
        resolved(params) {
            if (params?.success === true) {
                this.resolvedArguments = params.arguments;
            }
            else {
                this.resolvedArguments = null;
            }
        },
    },
    mounted() {
        const controller = this.provider;
        if (controller) {
            controller.on("resolve", this.resolved);
            this.resolved(controller.lastResolve);
        }
    },
    beforeDestroy() {
        this.provider?.off("resolved", this.resolved);
    },
});
