import BigNumber from "bignumber.js";
import Dapp from "./Dapp";
import * as util from "./util";
import {getNetwork} from './networks';

export function responseToProposal(contractId, id, ints, text, recipient) {
    let it = {
        id,
        contractId,
        votes: ints[0] ? ints[0].toString() : "0",
        value: new BigNumber(ints[1].toString()).toFixed(0),
        recurrence: parseInt(ints[2]),
        deadline: Dapp.parseTimestamp(ints[3]),
        status: parseInt(ints[4]),
        withdrawn: ints[5],
        firstPaymentTimestamp: Dapp.parseTimestamp(ints[6]),
        lastPaymentTimestamp: Dapp.parseTimestamp(ints[7]),
        payments: ints[8],
        pendingAmount: ints[9],
        nextPayment: Dapp.parseTimestamp(ints[10]),
        text,
        recipient
    };
    return it;
}

/**
 *
 * @param {Api} api
 * @param {string} contractId
 * @param {number} proposalId
 */
export async function loadProposal(api, contractId, proposalId) {
    let batch = [];
    batch.push({
        contractId,
        method: 'getProposalInts',
        arg: [proposalId]
    });
    batch.push({
        contractId,
        method: 'getProposalText',
        arg: [proposalId]
    });
    batch.push({
        contractId,
        method: 'getProposalRecipient',
        arg: [proposalId]
    });
    let res = await api.batchCall(batch);

    return responseToProposal(contractId, proposalId, res[0].data, res[1].data, res[2].data);
}

/**
 *
 * @param {Api} api
 * @param {string} contractId
 */
export async function loadProposals(api, contractId) {
    let count = parseInt(await api.contractCall(contractId, 'getProposalCount'));
    let batch = [];
    for (let i = 0; i < count; i++) {
        batch.push({
            contractId,
            method: 'getProposalInts',
            arg: [i]
        });
        batch.push({
            contractId,
            method: 'getProposalText',
            arg: [i]
        });
        batch.push({
            contractId,
            method: 'getProposalRecipient',
            arg: [i]
        });
    }
    let res = await api.batchCall(batch);

    let items = [];
    for (let i = 0; i < count; i++) {
        let ints = res.shift().data;
        let text = res.shift().data;
        let recipient = res.shift().data;

        items.push(responseToProposal(contractId, i, ints, text, recipient));
    }
    return items;
}

export function proposalTemplateData(proposal, network, token, data = null) {
    let it = {
        id: proposal.id,
        amount: util.renderAmount(proposal.value, token.decimals, token.symbol),
        text: proposal.text,
        recipient: proposal.recipient,
        recipientLink: getNetwork(network).explorerAddress(proposal.recipient),
        votes: proposal.votes,
        hasVotes: proposal.votes !== "0",
        payments: proposal.payments,
        deadline: proposal.deadline ? proposal.deadline.format('lll') : null,
        firstPayment: proposal.firstPaymentTimestamp ? proposal.firstPaymentTimestamp.format('lll') : null,
        lastPayment: proposal.lastPaymentTimestamp ? proposal.lastPaymentTimestamp.format('lll') : null,
        recurring: proposal.recurrence > 0
    };

    if (proposal.deadline && proposal.deadline.isBefore()) {
        // At this point it has failed, just not executed on-chain
        proposal.status = 2;
    }

    if (proposal.recurrence > 0) {
        if (proposal.recurrence > 60 * 60 * 24) {
            it.recurrence = Math.round(proposal.recurrence / (60 * 60 * 24)) + " days";
        } else if (proposal.recurrence > 60 * 60) {
            it.recurrence = Math.round(proposal.recurrence / (60 * 60)) + " hours";
        } else if (proposal.recurrence > 60) {
            it.recurrence = Math.round(proposal.recurrence / 60) + " minutes";
        } else {
            it.recurrence = proposal.recurrence + " seconds";
        }
    }

    if (proposal.nextPayment) {
        if (proposal.nextPayment.isAfter()) {
            it.nextPayment = proposal.nextPayment.format('lll');
        } else {
            it.paymentPending = util.renderAmount(proposal.pendingAmount, token.decimals, token.symbol);
        }
    }

    switch (proposal.status) {
        case 1:
            it.approved = true;
            break;
        case 2:
            it.rejected = true;
            break;
        case 3:
            it.active = true;
            break;
        case 4:
            it.stopped = true;
            break;
        default:
            it.pending = true;
            break;
    }

    if (data) {
        it.threshold = data.simpleApprovalThreshold;
        if (data.codes) {
            it.voteLink = `${data.codes.vote.url}?proposalId=${proposal.id}`;
            it.shareLink = `${data.codes.vote.url}/sharing?proposalId=${proposal.id}`;
            it.stopLink = `${data.codes.voteToStop.url}?proposalId=${proposal.id}`;
        }
    }

    return it;
}

export function md(markdown, data) {

}
