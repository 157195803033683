<template>
    <div class="nav-dropdown">
        <Dropdown @open="open">
            <template #icon>
                <div class="submissions-icon">
                    <i class="material-icons">receipt_long</i>
                    <div v-if="updateNeeded" class="progress-wrap">
                        <ProgressCircle medium />
                    </div>
                    <div v-if="newErrors" class="error-icon">
                        <i class="material-icons">error</i>
                    </div>
                </div>
            </template>
            <div class="full-history">
                <router-link to="/app/account/submissions" class="btn btn-primary btn-sm">
                    <i class="material-icons">history</i>
                    View Full History
                </router-link>
                <a href="javascript: void 0;" class="btn btn-sm btn-config2" @click="addressBook">
                    <i class="material-icons">import_contacts</i>
                    Open Address Book
                </a>
            </div>
            <div class="submissions-list-wrap">
                <SubmissionsList :submissions="recentSubmissions" />
            </div>
        </Dropdown>
        <SubmissionModal @closed="openSubmissionId = null" :submission-id="openSubmissionId" />
    </div>
</template>

<script>
import Dropdown from "@/components/Dropdown";
import Eip1193Mixin from "@/components/dumbapp/executor/Eip1193Mixin";
import SubmissionModal from "@/components/dumbapp/SubmissionModal";
import SubmissionsList from "@/components/dumbapp/SubmissionsList";
import Modal from "@/components/Modal";
import ProgressCircle from "@/components/ProgressCircle";
import * as interval from "@/lib/interval";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
    name: "SubmissionsDropdown",
    components: { SubmissionModal, Modal, ProgressCircle, SubmissionsList, Dropdown },
    mixins: [Eip1193Mixin],
    data() {
        return {
            openSubmission: null,
            openSubmissionId: null,
        };
    },
    computed: {
        ...mapGetters("dumbapp", ["recentSubmissions", "updateNeeded"]),
        ...mapGetters("user", ["loggedIn"]),
        ...mapState("dumbapp", ["newErrors"]),
    },
    created() {
        this.$store.restored.then(() => {
            this.interval = interval.every(() => {
                this.updateStatuses({
                    component: this
                }).catch(console.error);
            }, 3000);
        });

        this.$on("completed", this.submissionCompleted);
        this.$on("error", this.submissionFailed);
        this.$on("updated", this.submissionUpdated);

        this.$bus.on("dumbapp-submitted", this.submittedToast);
    },
    destroyed() {
        this.clear();
    },
    methods: {
        ...mapActions("dumbapp", ["updateStatuses", "loadRecent"]),
        ...mapMutations("dumbapp", ["clear_new_errors"]),
        open() {
            this.clear_new_errors();

            this.$store.restored.then(() => {
                if (this.loggedIn) {
                    this.loadRecent();
                }
            });
        },
        clear() {
            if (this.interval) {
                this.interval.clear();
                this.interval = null;
            }
            this.$bus.off("dumbapp-submitted", this.submittedToast);
        },
        submittedToast(submission) {
            this.$toasted.show(
                `<div><strong>${submission.dumbapp.getTitle()} </strong><span> submitted</span></div>`,
                {
                    duration: 5000,
                    icon: "done",
                    action: {
                        text: "OPEN",
                        onClick: () => {
                            this.openSubmissionId = submission.id;
                        },
                    },
                }
            );
        },
        /**
         * @param {DumbappSubmission} submission
         */
        submissionCompleted(submission) {
            this.$toasted.show(
                `<div><strong>${submission.dumbapp.getTitle()} </strong><span> confirmed</span></div>`,
                {
                    duration: 5000,
                    icon: "check_circle",
                    className: "confirmed",
                    action: {
                        text: "OPEN",
                        onClick: () => {
                            this.openSubmissionId = submission.id;
                        },
                    },
                }
            );
            this.$bus.emit("dumbapp-completed", submission);
        },
        submissionFailed(submission) {
            this.$toasted.show(
                `<div><strong>${submission.dumbapp.getTitle()} </strong><span> failed</span></div>`,
                {
                    duration: 0,
                    icon: "error",
                    className: "failed",
                    action: [
                        {
                            text: "OPEN",
                            onClick: (e, toast) => {
                                this.openSubmissionId = submission.id;
                                toast.goAway(1);
                            },
                        },
                        {
                            text: "DISMISS",
                            onClick(e, toast) {
                                toast.goAway(1);
                            },
                        },
                    ],
                }
            );
            this.$bus.emit("dumbapp-failed", submission);
        },
        submissionUpdated(submission) {
            this.$bus.emit("dumbapp-updated", submission);
        },
        addressBook() {
            this.$bus.emit("rolodex_request");
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.submissions-icon {
    position: relative;

    .progress-wrap {
        position: absolute;
        top: -4px;
        left: -6px;
    }
}

.submissions-list-wrap {
    padding: 0 15px 15px;
    background: $light;
    width: 500px;
    line-height: 1.2;

    @media (max-width: 768px) {
        width: 500px;
    }
    @media (max-width: 590px) {
        width: calc(100vw - 30px);
    }

    ::v-deep(.dumbapp-submission:first-child) {
        margin-top: 0;
    }
}

.submissions-icon div.error-icon {
    background: #fff;
    border-radius: 20px;
    position: absolute;
    top: 0;
    right: -5px;
    width: 16px;
    height: 16px;

    i.material-icons {
        position: absolute;
        top: -1px;
        left: -2px;
        font-size: 20px;
        line-height: 18px;
        color: $danger;
    }
}

.full-history {
    text-align: center;
    padding: 10px;

    .btn {
        margin: 0 10px 5px;
    }
}
</style>
