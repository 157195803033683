<template>
    <div>
        <strong>MetaMask has an incorrect network</strong>, change your network in MetaMask to <strong>{{networkName}}.</strong>
    </div>
</template>

<script>

import { getChain } from "@blockwell/chains";
import { DumbappSubmission } from "@blockwell/dumbapp";

export default {
    name: "MetamaskWrongNetwork",
    props: {
        submission: DumbappSubmission,
        network: [String, Number]
    },
    computed: {
        networkName() {
            let net = getChain(this.network);
            return net.networkName;
        }
    },
    code: "wrong_network",
    executor: "metamask"
}
</script>

<style scoped>

</style>
