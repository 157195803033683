import Clipboard from "@/components/Clipboard.vue";
import ContractHistory from "@/components/ContractHistory.vue";
import MainMenu from "@/components/MainMenu.vue";
import Modal from "@/components/Modal.vue";
import ProgressCircle from "@/components/ProgressCircle.vue";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
    name: "WhitelabelMobileNav",
    components: { ProgressCircle, ContractHistory, Modal, Clipboard, MainMenu },
    props: {
        config: {
            type: Object
        },
    },
    data() {
        return {
            show: false,
            showHistory: false,
            history: null,
        };
    },
    computed: {
        ...mapGetters("user", ["api", "loggedIn"]),
        ...mapState("user", ["email", "account"]),
        title() {
            return this.$route.meta?.title || this.$route.name || "";
        },
    },
    methods: {
        ...mapActions("user", ["logout"]),
        open() {
            this.show = true;
        },
        close() {
            this.show = false;
            this.showHistory = false;
        },
        openHistory() {
            this.updateHistory();
            this.showHistory = true;
        },
        updateHistory() {
            if (this.loggedIn) {
                this.api
                    .getContractHistory()
                    .then((res) => {
                    if (res) {
                        this.history = res || [];
                    }
                })
                    .catch(console.error);
            }
        },
        route(link) {
            this.$router.push(link);
        },
        onRoute() {
            this.close();
        },
    },
    created() {
        this.$bus.on("route", this.onRoute);
    },
    destroyed() {
        this.$bus.off("route", this.onRoute);
    },
};
