import { TutorialState } from "@/store/tutorial";
import { mapActions } from "vuex";

export default {
    methods: {
        /**
         *
         * @param id
         * @return {Promise<TutorialState>}
         */
        getTutorialState(id) {
            return this.$store.dispatch("tutorial/getTutorialState", id);
        },
        ...mapActions("tutorial", [
            "completeTutorial",
            "tutorialRewardShown",
            "skipTutorial",
            "triggerTutorial",
        ]),
    },
};
