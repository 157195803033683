<template>
    <div>
        <strong>Wallet unavailable!</strong> Did you uninstall or disable MetaMask?
    </div>
</template>

<script>

import { DumbappSubmission } from "@blockwell/dumbapp";

export default {
    name: "MetamaskWrongNetwork",
    props: {
        submission: DumbappSubmission
    },
    data() {
        return {};
    },
    code: "ethereum_unavailable"
}
</script>

<style scoped>

</style>
