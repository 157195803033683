import { Dumbapp } from "@blockwell/dumbapp";
export class DumbappLoader {
    constructor(client) {
        this.client = client;
    }
    getMap(path) {
        return this.client.get(path).then((res) => {
            return Object.fromEntries(Object.entries(res.data.data).map(([key, val]) => [key, new Dumbapp(val)]));
        });
    }
}
