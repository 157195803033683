<template>
    <div v-if="groups" class="user-groups-block dumbapp-block">
        <div class="dumbapp-block-heading">
            Your {{contractName}} Groups
            <Info>
                Groups give you special access to a contract. For example, Admin is a Group that lets
                you administer a contract.
            </Info>
        </div>
        <div class="user-groups">
            <div v-if="!groups || !groups.length" class="no-groups">
                No Groups
            </div>
            <span v-for="(it, index) in groups" class="user-group">
                <span v-if="index > 0" class="user-group-separator">, </span>
                <span class="user-group-name">{{it}}</span>
            </span>
        </div>
    </div>
</template>

<script>
import {BlockContext} from "@/components/dumbapp/block/Block";
import Info from "@/components/Info";
import {getUserGroups} from "@/lib/eth/Token";

export default {
    name: "UserGroupsBlock",
    components: {Info},
    props: {
        context: BlockContext
    },
    data() {
        return {
            groups: null
        };
    },
    computed: {
        deps() {
            return {
                account: this.context.account,
                contract: this.context.contract
            }
        },
        contractName() {
            return this.context?.contract?.name;
        }
    },
    watch: {
        deps: {
            deep: true,
            immediate: true,
            handler(val) {
                let {account, contract} = val;
                if (account) {
                    getUserGroups(contract.network, contract.address, account)
                        .then(groups => {
                            this.groups = Object.entries(groups).filter(it => it[1]).map(it => it[0]);
                        })
                        .catch(console.error);
                }
            }
        }
    }
}
</script>

<style scoped lang=scss>
@import "~@/assets/css/custom.scss";

.dumbapp-block-heading {
    color: $secondary;
    font-style: italic;
}

.user-group-separator {
    color: $text-muted;
}

.no-groups {
    color: $secondary;
}

</style>
