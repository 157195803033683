<template>
    <div class="dumbapp-time-input">
        <FormulateInput
            :name="field.name"
            :id="id"
            type="text"
            error-behavior="live"
            :validation-rules="{time: validate, min_time: minimumTime}"
            :validation-messages="{time: 'Could not be converted to a time.', min_time: 'Time must be an hour or more.'}"
            validation="time|min_time"
            v-on="$listeners">
            <template #label>
                <slot name="label"/>
            </template>
        </FormulateInput>
        <div class="time-result">
            <template v-if="options.period">
                <div class="time-result-label">Effective Value</div>
                <span v-if="parsed" class="time-result-parsed">{{ period }}</span>
                <span v-else class="time-result-none">None</span>
            </template>
            <template v-else>
                <div class="time-result-label">Effective Date</div>
                <span v-if="parsed" class="time-result-parsed">{{ result }}</span>
                <span v-else class="time-result-none">None</span>
            </template>
        </div>
        <div class="formulate-input-help">
            <slot name="help"/>
        </div>
        <div v-if="options.period" class="time-help">
            Examples: '<span>7 days</span>', '<span>1 month</span>'
        </div>
        <div v-else class="time-help">
            Examples: '<span>tomorrow</span>', '<span>next week</span>', '<span>2 months</span>'
        </div>
    </div>
</template>

<script>
import {preciseDiff} from "@/lib/moment-precise-range";
import { parseTime } from "@blockwell/dumbapp";
import moment from "moment";

export default {
    name: "PlainInput",
    inject: ["provider"],
    props: {
        id: {
            type: String,
            required: true
        },
        field: Object
    },
    data() {
        return {
            seconds: null,
            parsed: null
        };
    },
    computed: {
        result() {
            if (this.parsed) {
                return this.parsed.format('lll');
            }
            return '';
        },
        period() {
            if (this.seconds) {
                return preciseDiff(moment(), moment().add(this.seconds, 'seconds'));
            }
            return '';
        },
        options() {
            return this.field?.typeOptions || {};
        }
    },
    methods: {
        validate({value}) {
            let val = parseTime(value);

            if (val === 0) {
                this.parsed = null;
                return true;
            }

            if (val === false) {
                this.parsed = null;
                return false;
            }

            let parsed = moment();
            parsed.add(val, 'seconds');

            this.seconds = val;
            this.parsed = parsed;
            return true;
        },
        minimumTime({value}) {
            if (this.field.name === "periodLength") {
                let method = this.provider?.dumbapp?.steps?.[0]?.method;
                if (method === "multiTransferAndLock" || method === "transferAndLock") {

                    let val = parseTime(value);

                    if (val && val < 3600) {
                        return false;
                    }
                }
            }
            return true;
        }
    }
}
</script>

<style lang="scss">
@import '~@/assets/css/custom.scss';

div.dumbapp-time-input {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;

    .formulate-input {
        margin-bottom: 0;
        flex: 0 0 50%;
    }

    .formulate-input-help {
        margin-bottom: 0;
    }

    .time-help {
        flex: 1 1 100%;
        margin-top: 5px;
        color: $text-muted;
        font-size: 0.8em;

        span {
            color: $dark;
        }
    }

    .time-result {
        box-sizing: border-box;
        flex: 0 0 50%;
        padding-left: 8px;
        margin-top: 26px;
        font-size: 0.8em;

        .time-result-label {
            color: $text-muted;
        }
    }
}
</style>
