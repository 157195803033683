import PopperMixin from "@/components/PopperMixin";
import ColorPicker from "@/components/color/ColorPicker.vue";
import Checkboard from "@/components/color/Checkboard.vue";
export default {
    name: "ColorField",
    components: { Checkboard, ColorPicker },
    inject: {
        manager: {
            default: null,
        },
    },
    mixins: [PopperMixin],
    props: {
        value: String,
    },
    data() {
        return {
            val: this.value,
            recent: []
        };
    },
    computed: {
        style() {
            let value = this.val;
            if (value) {
                return {
                    "background-color": this.resolveValue(value),
                };
            }
            return {};
        },
        theme() {
            return this.manager?.color?.colors;
        }
    },
    watch: {
        value(val) {
            this.val = val;
        },
        val(val) {
            this.$emit("input", val);
        },
        showing(val, old) {
            if (old && !val) {
                let value = this.val;
                this.manager?.addRecentColor(value);
            }
            if (!old && val) {
                this.recent = this.manager?.getRecentColors();
            }
        }
    },
    methods: {
        input(ev) {
            let color;
            if (typeof ev === "string") {
                color = ev;
            }
            else {
                if (ev.rgba.a === 1) {
                    color = ev.hex;
                }
                else {
                    color = `rgba(${ev.rgba.r}, ${ev.rgba.g}, ${ev.rgba.b}, ${ev.rgba.a})`;
                }
            }
            this.val = color;
            this.$emit("input", color);
        },
        resolveValue(value) {
            if (this.manager) {
                const colors = this.manager.color;
                return colors.resolveColor(value);
            }
            return value;
        }
    },
    mounted() {
        this.referenceOverride = this.$refs.placement;
        this.boundary = document.querySelector("body");
    },
};
