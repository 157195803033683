import Vue from "vue";
import { addressRegex } from "@blockwell/chain-client";
function filterAddress(filter) {
    let lowFilter = filter.toLowerCase();
    return (data) => {
        let lower = data.address.toLowerCase();
        if (lower.startsWith(lowFilter) || lower.slice(2).startsWith(lowFilter)) {
            return true;
        }
        if (data.type === "wallet") {
            if (data.alias?.toLowerCase()?.includes(lowFilter)) {
                return true;
            }
        }
        else {
            if (data.contract?.name?.toLowerCase()?.includes(lowFilter)) {
                return true;
            }
            let symbol = data.contract?.parameters?.symbol;
            if (typeof symbol === "string" && symbol.toLowerCase().startsWith(lowFilter)) {
                return true;
            }
        }
        if (data.tags && data.tags.length > 0) {
            for (let tag of data.tags) {
                if (tag.startsWith(filter)) {
                    return true;
                }
            }
        }
        return false;
    };
}
export default Vue.extend({
    props: {
        search: String,
    },
    data() {
        return {
            contracts: [],
            wallets: [],
            filter: null,
        };
    },
    computed: {
        contractsList() {
            return this.$store.getters["rolodex/contractAddresses"];
        },
        walletsList() {
            return this.$store.getters["rolodex/walletAddresses"];
        },
        filteredContracts() {
            if (!this.filter || addressRegex.test(this.filter)) {
                return this.contracts;
            }
            return this.contracts.filter(filterAddress(this.filter));
        },
        filteredWallets() {
            if (!this.filter || addressRegex.test(this.filter)) {
                return this.wallets;
            }
            return this.wallets.filter(filterAddress(this.filter));
        },
        api() {
            return this.$store.getters["user/api"];
        },
        recentWallets() {
            return this.filteredWallets.slice(0, 5);
        },
        recentContracts() {
            return this.filteredContracts.slice(0, 5);
        },
    },
    watch: {
        contractsList: {
            immediate: true,
            deep: true,
            async handler(list) {
                let query = list.map((it) => {
                    return {
                        address: it.address,
                        chainId: it.chainId,
                    };
                });
                let remaining = [...query];
                let contracts = [];
                while (remaining.length > 0) {
                    let batch = remaining.splice(0, 8);
                    contracts = contracts.concat(await this.api.contracts.getAll(batch));
                    this.contracts = list.map((it, index) => {
                        return {
                            ...it,
                            contract: contracts[index],
                        };
                    });
                }
            },
        },
        walletsList: {
            immediate: true,
            deep: true,
            async handler(list) {
                this.wallets = list;
            },
        },
        search: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.filter = val;
                }
                else {
                    this.filter = null;
                }
            },
        },
    },
});
