<template>
    <div class="wallet-address">
        <AddressBlockie
            :address="address"
            :scale="2"
            />
        <AddressArgument :value="address" ellipsize/>
    </div>
</template>

<script>
import AddressBlockie from "@/components/AddressBlockie";
import Clipboard from "@/components/Clipboard";
import AddressArgument from "@/components/dumbapp/argument/AddressArgument";
export default {
    name: "Address",
    components: { AddressArgument, AddressBlockie, Clipboard},
    props: {
        address: String,
        network: [String, Number]
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.wallet-address {
    display: inline-flex;
    align-items: center;
    background: rgba($dark, 0.06);
    border: 1px solid rgba($dark, 0.1);
    border-radius: 3px;
    padding: 4px 4px 4px 8px;

    ::v-deep( .address-blockie ) {
        margin-right: 5px;
    }

    ::v-deep( .clipboard-text ) {
        font-size: 14px;
        color: $secondary;
    }

    ::v-deep( .vue-clipboard-image ) {
        opacity: 0.8;
    }
}
</style>
