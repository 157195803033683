import * as Token from "@/lib/eth/Token";
import axios from "axios";
import BigNumber from 'bignumber.js';
import * as R from "rambdax";
import * as filter from "secure-filters";
import {getAddress} from "@blockwell/chain-client";
import {getNetwork, networkList} from './networks';

export const addressRegex = /^0x[a-fA-F0-9]{40}$/;

export function getFormData(form) {
    let data = {};
    const formData = new FormData(form);

    Array.from(formData.keys()).forEach(function (k) {
        if (k.includes('[]')) {
            data[k.replace(/\[]/g, '')] = formData.getAll(k);
        } else {
            data[k] = formData.get(k);
        }
    });

    return data;
}

export function toDecimals(value, decimals) {
    return new BigNumber(value).dividedBy(new BigNumber(`1e${decimals}`)).toString();
}

export function toInteger(value, decimals) {
    return new BigNumber(value).multipliedBy(new BigNumber(`1e${decimals}`)).toFixed(0).toString();
}

export function renderAmount(value, decimals, symbol, places = null) {
    return new BigNumber(value).dividedBy(new BigNumber(`1e${decimals}`)).toFormat(places) + ` <span class="token-symbol">${filter.html(symbol)}</span>`
}

export function renderAddress(address, network = 1, link = null, text = null) {
    let html = '';

    let content;
    if (text) {
        content = filter.html(text);
    } else {
        content = filter.html(address);
    }

    let url;
    if (link) {
        url = link;
    } else {
        let net = getNetwork(network);
        if (net) {
            url = net.explorerAddress(address);
        }
    }

    if (url) {
        html += `<a href="${url}" target="_blank">${content}</a>`;
    } else {
        html += `<span class="nolink-address">${content}</span>`;
    }

    html += `
        <img src="/images/clipboard.svg" width="11" class="clipboard account-clipboard" data-clipboard-text="${filter.html(address)}" alt="Copy">
    `;

    return html;
}

export function renderTokenName(address, network, name, symbol) {
    let net = getNetwork(network);

    let html = '';
    let url;
    if (net) {
        url = net.explorerToken(address);
    }
    html += `<a href="${url}" target="_blank">${filter.html(name)} (${filter.html(symbol)})</a>`;

    html += `
        <img src="/images/clipboard.svg" width="11" class="clipboard account-clipboard" data-clipboard-text="${filter.html(address)}" alt="Copy">
    `;

    return html;
}

export function parseSuggestion(text, truncate = true) {
    let suggestion = text;
    let colon = suggestion.indexOf(':');
    let tag = "";
    if (colon !== -1 && colon <= 12) {
        tag = suggestion.slice(0, colon).trim();
        suggestion = suggestion.slice(colon + 1).trim();
    }

    if (truncate && suggestion.length > 200) {
        suggestion = suggestion.slice(0, 200) + "...";
    }

    return {tag, suggestion};
}

export function fadeOut(element, duration = 250) {
    return new Promise(resolve => {
        element.fadeOut(duration, function () {
            resolve();
        });
    });
}

export function fadeIn(element, duration = 250) {
    return new Promise(resolve => {
        element.fadeIn(duration, function () {
            resolve();
        });
    });
}

export function slideDown(element, duration = 250) {
    return new Promise(resolve => {
        element.slideDown(duration, function () {
            resolve();
        });
    });
}

export function slideUp(element, duration = 250) {
    return new Promise(resolve => {
        element.slideUp(duration, function () {
            resolve();
        });
    });
}

export async function loadContract(address) {
    let data = (await axios.get(process.env.VUE_APP_API_URL + `api/qr/contract/${address}`)).data;
    if (!data.list || data.list.length === 0) {
        if (!data.address) {
            throw new Error('Contract not found');
        } else {
            return data;
        }
    }
    return data.list;
}

/**
 *
 * @param {jQuery} field
 * @param {Boolean} allowEther
 * @return {Promise<Array<Object> | "ether">}
 */
export function getContractFromField(field, allowEther = false) {
    return new Promise((resolve, reject) => {

        field.on('input', function () {
            let val = $(this).val();
            let $feedback = $(this).parents('.form-group').find('.invalid-feedback');
            if (addressRegex.test(val)) {
                $(this).addClass('is-valid');
                $(this).removeClass('is-invalid');
                loadContract(val)
                    .then(it => {
                        $feedback.hide();
                        resolve(it);
                    })
                    .catch(err => {
                        console.error(err);
                        $feedback.text('There was a problem loading the contract: ' + errorText(err.message));
                        $feedback.show();
                        $(this).addClass('is-invalid');
                        $(this).removeClass('is-valid');
                    });
            } else if (allowEther && val === "ether") {
                resolve("ether");
            }
        }).trigger('input');
    });
}

export function systemStatus() {
    if (window.status && window.status.status) {
        return window.status.status;
    }
    return "ok";
}

export function systemMessage() {
    if (window.status && window.status.message) {
        return window.status.message;
    }
    return "";
}

export function systemError() {
    if (window.status && window.status.error) {
        return window.status.error;
    }
    return "";
}

/**
 *
 * @param {string | AxiosError} message
 * @return {string}
 */
export function errorText(message) {
    if (systemStatus() === "maintenance" && systemError()) {
        return systemError();
    }
    if (message.response && message.response.data) {
        try {
            if (message.response.data.error.message) {
                return message.response.data.error.message;
            }
        } catch (err) {
            console.error(err);
        }
    } else if (message.message) {
        return message.message;
    }
    return message;
}

export function getTokenBalances(tokenList, wallet) {
    return R.mapFastAsync(async it => {
        const tokens = tokenList.filter(token => token.network === it.networkId);
        let balances;
        let availables;

        try {
            balances = await Token.getTokenBalances(it.networkId, tokens.map(it => it.address), wallet).catch(err => console.error('foo', err));
            availables = await Token.getAvailableBalances(it.networkId, tokens.map(it => it.address), wallet);
        } catch (err) {
            console.error("caught", err);
            if (!balances) {
                return {
                    network: it,
                    tokens: []
                }
            }
            if (!availables) {
                availables = [];
            }
        }
        let i = 0;
        for (let token of tokens) {
            let balance = balances[i];
            let available = availables[i];
            if (balance.gt(0)) {
                token.balance = balance.shiftedBy((token.decimals || 18) * -1);
            } else {
                token.balance = null;
            }
            if (available && available.lt(balance)) {
                token.available = available.shiftedBy((token.decimals || 18) * -1);
                token.locked = balance.minus(available).shiftedBy((token.decimals || 18) * -1);
            }

            // Some have a specific sendLink already set
            if (!token.sendLink) {
                token.sendLink = `/app/wallet/send?net=${token.network}&contract=${token.address}`;
            }
            token.link = getNetwork(token.network).explorerToken(token.address);

            if (token.bwtype === 19) {
                token.happLink = `/core?contract=${token.address}&net=${token.network}`;
            }

            if (token.address.toLowerCase() === "0x80edc5ae6de1415b878d234b24568250a95cc9fb") {
                token.happLink = {
                    link: '/2n8llj',
                    label: "Unstake"
                }
            }
            if (token.address.toLowerCase() === "0x019b9b5f9fad299196ef02f4acb53f6e8b3bebb9") {
                token.happLink = {
                    link: '/l0x5gp',
                    label: "Stake"
                }
            }
            if (token.network === 1 && token.address.toLowerCase() === "0xdef1da03061ddd2a5ef6c59220c135dec623116d") {
                token.happLink = {
                    link: '/m5a1gf',
                    label: "Stake"
                }
            }
            if (token.network === 49777 && token.address.toLowerCase() === "0x9fedef2647a356f545c13b27f9e0e9d7f08c87e9") {
                token.happLink = {
                    link: '/lrd5xn',
                    label: "Unstake"
                }
            }
            if (token.network === 4 && token.address.toLowerCase() === "0x3e21826b3791ffc8ea3d7430d25a2fad354ab53f") {
                token.happLink = {
                    link: '/xe5j62',
                    label: "Stake"
                }
            }
            if (token.network === 49111 && token.address.toLowerCase() === "0x93a9112fed4e401329afde91fbd9644c62b073a4") {
                token.happLink = {
                    link: '/yifrkw',
                    label: "Unstake"
                }
            }
            if (token.network === 5 && token.address.toLowerCase() === "0x58443a905f4c74f648262284defe8d6da2272095") {
                token.happLink = {
                    link: '/suggestions?net=5&contract=0x58443a905f4c74f648262284defe8d6da2272095',
                    label: "Voting"
                }
            }

            if (token.address === "0x0" || token.address === "0x0000000000000000000000000000000000000000") {
                if (token.network === 56 || token.network === 97) {
                    token.logo = require("@/assets/images/logos/bnb.png");
                } else if (token.network === 2020) {
                    token.logo = require("@/assets/images/logos/ronin.png");
                } else {
                    token.logo = require("@/assets/images/ethereum.png");
                }
                token.ether = true;
            } else if (it.networkId === 5 && token.address.toLowerCase() === "0x555555555130790a502c3c95e9c26633c10ae000") {
                token.logo = require("@/assets/images/tutor.png");
            } else if (it.networkId === 1) {
                if (token.address.toLowerCase() === "0xdef1da03061ddd2a5ef6c59220c135dec623116d") {
                    token.logo = "https://s2.coinmarketcap.com/static/img/coins/64x64/8845.png";
                } else {
                    let checksum = getAddress(token.address);
                    token.logo = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${checksum}/logo.png`
                }
            }

            ++i;
        }

        return {
            network: it,
            tokens: tokens.filter(token => {
                return (token.address.toLowerCase() === "0xff255f8a7cf00d68a123a2553a7d0cdca63f61c3" && token.network === 5) ||
                    (!token.standard || !!token.balance);
            })
        }
    }, networkList());
}
