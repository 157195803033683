import Vue from "vue";
export default Vue.extend({
    props: {
        arg: {
            type: Object
        }
    },
    data() {
        return {
            loading: false,
            value: { value: () => null },
            error: null,
            realValue: null,
            annotation: null,
        };
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.loading = true;
                    Promise.resolve(val.value())
                        .then((it) => {
                        this.error = null;
                        this.realValue = it;
                    })
                        .catch((err) => {
                        console.error(err);
                        this.error = err;
                    })
                        .finally(() => this.loading = false);
                }
                else {
                    this.realvalue = null;
                    this.error = null;
                }
                if (val && val.annotation) {
                    Promise.resolve(this.value.annotation())
                        .then((it) => {
                        this.annotation = it;
                    })
                        .catch(console.error);
                }
                else {
                    this.annotation = null;
                }
            },
        },
    }
});
