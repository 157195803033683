<template>
    <div class="dumbapp-confirmation-message">
        <div class="material-icons message-icon">comment</div>
        <div class="message">{{message}}</div>
    </div>
</template>

<script>
export default {
    name: "ConfirmationMessage",
    props: {
        message: String
    }
}
</script>

<style lang="scss">
@import "~@/assets/css/custom.scss";

.dumbapp-confirmation-message {
    display: flex;
    .message-icon {
        flex: 0 0 20px;
        font-size: 18px;
        color: #6c8cba;
        margin-right: 8px;
    }
    .message {
        flex: 1 1 auto;
    }
}
</style>
