<template>
    <div
        :class="`formulate-input-element formulate-input-element--${context.type}`"
        :data-type="context.type">
        <input
            type="hidden"
            v-model="context.model"
            v-bind="context.attributes">
        <div class="formulate-suggestion-field">
            <div v-if="!hasSuggestion" class="select-suggestion">
                <button type="button" class="btn btn-link" @click="select">Click Here to select</button>
            </div>
            <div v-else>
                <div class="suggestion-id-wrap">
                    ID: <span>{{ suggestionId }}</span>
                </div>
                <ClampedText class="suggestion-text">{{ suggestionText }}</ClampedText>
                <div class="change-suggestion">
                    <a href="javascript: void 0;" @click="select">Change selection</a>
                </div>
            </div>
        </div>

        <Modal :show.sync="showSelection" modal-class="select-suggestion-modal">
            <template #header>
                <h5>Select Suggestion</h5>
            </template>
            <SuggestionSelection
                :provider="provider"
                :field="field"
                @selected="selected"
            />
        </Modal>
    </div>
</template>

<script>
import ClampedText from "@/components/ClampedText";
import Modal from "@/components/Modal";
import SuggestionSelection from "@/components/SuggestionSelection";
import * as voting from "@/lib/voting";

export default {
    name: "FormulateSuggestion",
    components: {ClampedText, SuggestionSelection, Modal},
    inject: ['provider', 'field'],
    props: {
        context: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showSelection: false,
            suggestionId: null,
            selectedSuggestion: null
        };
    },
    watch: {
        suggestionId(val) {
            this.context.model = val;
        }
    },
    computed: {
        suggestionText() {
            if (this.suggestion) {
                return this.suggestion.text;
            }
            return "";
        },
        hasSuggestion() {
            return this.suggestionId === 0 || this.suggestionId;
        }
    },
    asyncComputed: {
        async suggestion() {
            if (this.selectedSuggestion) {
                return this.selectedSuggestion;
            }
            if (this.field && this.field.address && this.suggestionId) {
                return await voting.getSuggestion(this.field.network, this.field.address, this.suggestionId);
            }
            return null;
        }
    },
    methods: {
        select() {
            this.showSelection = true;
        },
        selected(suggestion) {
            this.suggestionId = suggestion.id.toString();
            this.selectedSuggestion = suggestion;
            this.showSelection = false;
        }
    }
}
</script>

<style lang="scss">
@import "~@/assets/css/custom.scss";

.suggestion-id-wrap {
    color: $text-muted;

    ::v-deep( span ) {
        color: $code-color;
    }
}

.formulate-suggestion-field {
    .change-suggestion {
        margin-top: 5px;
    }
}

.select-suggestion-modal {
    .v-modal-body {
        padding: 0;
    }
}
</style>
