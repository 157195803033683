export default {
    name: "Saturation",
    props: {
        value: Object,
    },
    computed: {
        colors() {
            return this.value;
        },
        bgColor() {
            return `hsl(${this.colors.hsv.h}, 100%, 50%)`;
        },
        pointerTop() {
            return -(this.colors.hsv.v * 100) + 1 + 100 + "%";
        },
        pointerLeft() {
            return this.colors.hsv.s * 100 + "%";
        },
    },
    methods: {
        handleChange(e, skip) {
            !skip && e.preventDefault();
            const container = this.$refs.container;
            if (!container) {
                // for some edge cases, container may not exist. see #220
                return;
            }
            const containerWidth = container.clientWidth;
            const containerHeight = container.clientHeight;
            const xOffset = container.getBoundingClientRect().left + window.pageXOffset;
            const yOffset = container.getBoundingClientRect().top + window.pageYOffset;
            const pageX = "pageX" in e ? e.pageX : e.touches ? e.touches[0].pageX : 0;
            const pageY = "pageY" in e ? e.pageY : e.touches ? e.touches[0].pageY : 0;
            const left = clamp(pageX - xOffset, 0, containerWidth);
            const top = clamp(pageY - yOffset, 0, containerHeight);
            const saturation = left / containerWidth;
            const bright = clamp(-(top / containerHeight) + 1, 0, 1);
            this.$emit("change", {
                h: this.colors.hsv.h,
                s: saturation,
                v: bright,
                a: this.colors.hsv.a,
                source: "hsva",
            });
        },
        handleMouseDown(e) {
            // this.handleChange(e, true)
            window.addEventListener("mousemove", this.handleChange);
            window.addEventListener("mouseup", this.handleChange);
            window.addEventListener("mouseup", this.handleMouseUp);
        },
        handleMouseUp(e) {
            this.unbindEventListeners();
        },
        unbindEventListeners() {
            window.removeEventListener("mousemove", this.handleChange);
            window.removeEventListener("mouseup", this.handleChange);
            window.removeEventListener("mouseup", this.handleMouseUp);
        },
    },
};
function clamp(val, min, max) {
    return Math.max(Math.min(val, max), 0);
}
