export default class MediaApi {
    constructor(client, loggedIn) {
        this.client = client;
        this.loggedIn = loggedIn;
    }
    upload(formData) {
        // This is using the URL directly because in dev mode the proxy can't handle file uploads
        return this.client.post(process.env.VUE_APP_API_V2_URL + "v2/media/upload", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => res.data.data);
    }
    getUserMedia() {
        return this.client.get("v2/media")
            .then(res => res.data.data);
    }
    findMedia(uri) {
        return this.client.get("v2/media/find?search=" + encodeURIComponent(uri))
            .then(res => res.data.data);
    }
    deleteMedia(id) {
        return this.client.delete(`v2/media/${id}`);
    }
}
