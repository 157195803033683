import Vue from "vue";
import { WhitelabelField } from "@/components/whitelabel/whitelabel";
import { applyDiff } from "rambdax";
import Info from "@/components/Info.vue";
export default Vue.extend({
    name: "WhitelabelCheckbox",
    components: { Info },
    mixins: [WhitelabelField],
    methods: {
        input(ev) {
            let target = ev.target;
            let value = target.checked;
            this.changed = true;
            let config = {};
            config = applyDiff([
                {
                    op: "add",
                    path: this.setting.key,
                    value,
                },
            ], config);
            this.$store.dispatch("whitelabel/saveWhitelabelConfig", config);
        },
    },
});
