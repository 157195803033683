<template>
    <div>
        <transition
            v-on:before-enter="beforeEnter"
            v-on:enter="enter"
            v-on:leave="leave"
            v-bind:css="false">
            <div v-if="show" class="maintenance-banner">
                <h2><i class="material-icons">warning</i> {{statusText}}</h2>
                <p>
                    {{message}}
                </p>
            </div>
        </transition>
    </div>
</template>

<script>
    import Velocity from 'velocity-animate';
    import {mapState} from "vuex";

    export default {
        name: "StatusBar",
        computed: {
            ...mapState('status', ["status", "message"]),
            statusText() {
                if (this.status === 'api_error') {
                    return "SERVICE OFFLINE";
                }
                return "MAINTENANCE MODE";
            },
            show() {
                return this.status && this.status !== "ok";
            }
        },
        methods: {
            beforeEnter: function (el) {
            },
            enter: function (el, done) {
                Velocity(el, "slideDown", { complete: done, display: 'flex' });
            },
            leave: function (el, done) {
                Velocity(el, {opacity: 0}, { duration: 600});
                setTimeout(() => {
                    Velocity(el, "slideUp", { complete: done, duration: 500, queue: false  });
                }, 300);
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/css/custom";

    .maintenance-banner {
        background-color: #FDCA40;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 0;

        ::v-deep( h2 ) {
            font-size: 16px;
            margin: 0 10px;
            white-space: nowrap;
        }

        ::v-deep( p ) {
            font-size: 14px;
            margin: 0;
        }

        ::v-deep( i ) {
            color: $dark;
        }

        @media screen and (max-width: 700px) {
            padding: 16px;
            flex-direction: column;
            align-items: center;
        }
    }

</style>
