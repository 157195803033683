import PageMessages from "@/components/message/PageMessages";
import MobileNav from "@/layouts/components/MobileNav";
import MainFooter from "@/layouts/components/MainFooter";
import MainHeader from "@/layouts/components/MainHeader";
import Vue from "vue";
export default Vue.extend({
    components: { MainFooter, MainHeader, MobileNav, PageMessages },
    props: {
        size: String,
        noMessages: Boolean,
    },
    computed: {
        sizeClass() {
            if (this.size) {
                return "content-width-" + this.size;
            }
            else {
                return "";
            }
        },
    },
});
