<template>
    <div class="dumbapp-preview" :class="{card}">
        <template v-if="dumbapp">
            <DumbappPreviewStep
                v-for="(step, i) in dumbapp.steps"
                :step="step"
                :key="dumbapp.shortcode + '-' + i"
                :index="i"
                class="step" />
        </template>
    </div>
</template>

<script>
import { Dumbapp } from "@blockwell/dumbapp";
import DumbappPreviewStep from "./DumbappPreviewStep";

export default {
    name: "DumbappPreview",
    components: { DumbappPreviewStep },
    props: {
        dumbapp: Dumbapp,
        card: Boolean
    },
    data() {
        return {};
    }
};
</script>

<style scoped lang="scss">
.dumbapp-preview {
    padding: 0;

    .step {
        border-bottom: 1px solid #eee;

        &:last-child {
            border-bottom: none;
        }
    }
}
</style>
