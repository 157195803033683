<template>
    <FormulateInput
        :name="field.name"
        :label="field.label"
        :help="field.help"
        :id="id"
        :validation-name="field.label"
        type="suggestion"
        validation="required">
        <template #label><slot name="label"></slot></template>
        <template #help><slot name="help"></slot></template>
    </FormulateInput>
</template>

<script>
import FormulateSuggestion from "@/components/formulate/FormulateSuggestion";
export default {
    name: "SuggestionInput",
    components: {FormulateSuggestion},
    props: {
        id: {
            type: String,
            required: true
        },
        field: Object
    },
    data() {
        return {};
    }
}
</script>

<style scoped>

</style>
