var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.linkOnly)?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: 'Copied to Clipboard',
        trigger: 'manual',
        show: _vm.showTooltip,
        container: this.containerElement || _vm.$refs.wrap,
    }),expression:"{\n        content: 'Copied to Clipboard',\n        trigger: 'manual',\n        show: showTooltip,\n        container: this.containerElement || $refs.wrap,\n    }"}],ref:"button",attrs:{"href":"javascript: void 0;","data-clipboard-text":_vm.textValue}},[_vm._v(_vm._s(_vm.displayText || "Copy To Clipboard"))]):_c('span',{ref:"wrap",staticClass:"clipboard-wrap"},[(_vm.href)?_c('a',{staticClass:"clipboard-text",class:{ ellipsize: _vm.ellipsize },attrs:{"href":_vm.href,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[(_vm.hasDefaultSlot)?_vm._t("default"):_c('span',[_vm._v(_vm._s(_vm.displayText))])],2):(!_vm.linkOnly && _vm.displayText)?_c(_vm.element,{tag:"component",staticClass:"clipboard-text",class:{ ellipsize: _vm.ellipsize }},[(_vm.hasDefaultSlot)?_vm._t("default"):_c('span',[_vm._v(_vm._s(_vm.displayText))])],2):_vm._e(),(_vm.button)?_c('button',{ref:"button",staticClass:"btn btn-primary btn-sm",attrs:{"data-clipboard-text":_vm.textValue,"type":"button"}},[_vm._v(" Copy To Clipboard ")]):_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: 'Copied to Clipboard',
            trigger: 'manual',
            show: _vm.showTooltip,
            container: this.containerElement || _vm.$refs.wrap,
        }),expression:"{\n            content: 'Copied to Clipboard',\n            trigger: 'manual',\n            show: showTooltip,\n            container: this.containerElement || $refs.wrap,\n        }"}],ref:"button",staticClass:"vue-clipboard-image",attrs:{"data-clipboard-text":_vm.textValue},on:{"click":function($event){$event.stopPropagation();}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 11.12 12.93"}},[_c('title',[_vm._v("Copy To Clipboard")]),_c('path',{staticClass:"clipboard-icon",class:{ 'white-icon': _vm.white },attrs:{"d":"M193.57,80.28v-2.2h-8.41v11h2.71V91h8.41V83Zm0,1.3,1.2,1.2h-1.2Zm-7.5,6.58V79h6.58v1h-4.78v8.14Zm2.72,1.93V80.93h3.86v2.76h2.72v6.4Z","transform":"translate(-185.16 -78.08)"}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }