<template>
    <div class="array-argument-wrapper">
        <ol class="array-argument-list">
            <li v-for="val in realValues" class="array-argument">
                <template v-if="val">
                    <component :is="component" :value="val" :arg="arg" :step="step" class="argument-value"/>
                </template>
                <div v-else class="placeholder">{{ provider.placeholderText() }}</div>
            </li>
        </ol>
        <div v-if="sum" class="array-argument-sum">
            Total: <span>{{sum}}</span>
        </div>
    </div>
</template>

<script>

import ArgumentComponentMixin from "@/components/dumbapp/argument/ArgumentComponentMixin";
import BigNumber from "bignumber.js";

export default {
    name: "ArrayArgument",
    mixins: [ArgumentComponentMixin],
    inject: ['provider'],
    props: {
        value: [Array],
        arg: Object,
        step: Object
    },
    computed: {
        realValues() {
            return this.value?.map(it => {
                if (it) {
                    if (typeof it.value === "function") {
                        return it.value();
                    }
                    return it;
                }
                return "";
            }) || [];
        },
        sum() {
            if (this.arg.typing.name === "uint") {
                let val = this.realValues.reduce((acc, it) => {
                    if (it !== "") {
                        return acc.plus(it);
                    } else {
                        return acc;
                    }
                }, new BigNumber(0));
                if (val.isNaN()) {
                    return "-"
                }
                return val.toFormat();
            }
            return null;
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.array-argument-wrapper {
    margin-bottom: 1rem;
}

.array-argument-list {
    list-style: none;
    counter-reset: array-argument;
    padding-left: 0;
    margin-bottom: 0;

    ::v-deep( li ) {
        counter-increment: array-argument;
        display: flex;

        ::v-deep( .value ) {
            overflow-wrap: break-word;
        }
    }

    ::v-deep(li::before) {
        color: var(--text-muted);
        content: counter(array-argument) ".";
        float: left;
        margin-right: 5px;
        text-align: right;
        flex: 0 0 auto;
    }
}

.array-argument-sum {
    color: var(--secondary);
    font-size: 0.9em;
}

.placeholder {
    font-style: italic;
    color: var(--text-muted);
}
</style>
