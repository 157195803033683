<template>
    <div v-if="swappers">
        <div v-for="it in swappers">
            <div class="dumbapp-confirmation-message deployment-message">
                <div class="material-icons message-icon">comment</div>
                <div class="message">
                    <span>
                        <router-link :to="'/' + it.shortcode">Click here to open Buy bApp</router-link>.
                        Don't forget to send {{it.mainTokenName}} tokens to the swapper!
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Clipboard from "@/components/Clipboard";
import { DumbappSubmission } from "@blockwell/dumbapp";

export default {
    name: "SwapperMessages",
    components: {Clipboard},
    props: {
        submission: DumbappSubmission
    },
    data() {
        return {};
    },
    computed: {
        swappers() {
            return this.submission?.data?.extras?.swapper;
        }
    }
}
</script>

<style scoped>

</style>
