<template>

    <div class="data">
        <div class="data-label">{{ label }}</div>
        <div class="data-value">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import BigNumber from "bignumber.js";
import moment from "moment";

export default {
    name: "DataValue",
    props: {
        label: String,
    }
}
</script>

<style scoped lang=scss>
@import "~@/assets/css/custom.scss";

.data {
    display: flex;
    margin-bottom: 25px;
    align-items: center;
}

.data-label {
    font-weight: 600;
    min-width: 120px;
    margin-right: 8px;
}

.data-value {
    display: flex;
    align-items: center;

    ::v-deep( .dumbapp-popover ) {
        margin-left: 10px;
    }
}

</style>
