import { getBlocks } from "@/components/dumbapp/block/index";
import { mapGetters } from "vuex";
import equal from "fast-deep-equal";
export default {
    name: "DumbappBlocks",
    inject: ["provider"],
    data() {
        return {
            blocks: null,
            renderedReq: null,
        };
    },
    computed: {
        ...mapGetters("user", ["api"]),
    },
    methods: {
        loadBlocks(api, req) {
            let renderedReq = {
                account: req.account,
                dumbapp: req.dumbapp?.shortcode,
                contracts: req.contracts.map((it) => it?.id),
            };
            if (!equal(renderedReq, this.renderedReq)) {
                this.renderedReq = renderedReq;
                getBlocks(api, req).then((blocks) => {
                    this.blocks = blocks;
                });
            }
        },
        async resolved(params) {
            if (params.success === true) {
                let reqs = {
                    account: params.values.getAccount(),
                    dumbapp: params.values.dumbapp,
                    contracts: params.arguments.map(it => it.contract),
                    approvalContract: params.state.approvalContract
                };
                this.loadBlocks(this.api, reqs);
            }
        },
    },
    mounted() {
        this.provider.on("resolve", this.resolved);
    },
    beforeDestroy() {
        this.provider.off("resolve", this.resolved);
    },
};
