<template>
    <Dropdown icon="hourglass_full" @open="updateHistory" class="history-dropdown">
        <div class="dropdown-box">
            <div class="contracts-menu">
                <div class="dropdown-section">
                    <h5>Your Wallet Address</h5>
                    <div class="account-dropdown-address">
                        <Clipboard :address="account"/>
                    </div>
                </div>
                <div class="dropdown-divider"></div>
                <div class="dropdown-section recent-contracts">
                    <h5>Recent Contracts</h5>

                    <ContractHistory v-if="history" :history="history" @link="route"/>
                    <ProgressCircle v-else/>
                </div>
            </div>
        </div>
    </Dropdown>
</template>

<script>

import Clipboard from "@/components/Clipboard";
import ContractHistory from "@/components/ContractHistory";
import Dropdown from "@/components/Dropdown";
import ProgressCircle from "@/components/ProgressCircle";
import {mapGetters, mapState} from "vuex";

export default {
    name: "ContractHistoryDropdown",
    components: {Dropdown, ProgressCircle, ContractHistory, Clipboard},
    data() {
        return {
            open: false,
            history: null
        };
    },
    computed: {
        ...mapGetters('user', ['api', 'loggedIn']),
        ...mapState("user", ['account'])
    },
    methods: {
        updateHistory() {
            if (this.loggedIn) {
                this.api.getContractHistory()
                    .then(res => {
                        if (res) {
                            this.history = res || [];
                        }
                    }).catch(console.error);
            }
        },
        route(link) {
            this.$router.push(link);
        }
    }
}
</script>

<style scoped lang="scss">
@import "../assets/css/custom";

.contracts-menu {
    padding: 15px 0;
    font-size: 1rem;

    & ::v-deep( h5 ) {
        font-size: 16px;
        margin: 0;
    }
}

.dropdown-section {
    padding: 0 25px;
}

.account-dropdown-address {
    white-space: nowrap;

    @media (max-width: 440px) {
        font-size: 0.9em;
    }

    @media (max-width: 400px) {
        font-size: 0.8em;
    }

    @media (max-width: 360px) {
        font-size: 0.7em;
    }
}

.recent-contracts {
    font-size: 14px;
    overflow-wrap: break-word;
}

.your-wallet-address {
    font-size: 16px;
    margin: 0;
}
</style>
