import Vue from "vue";
export default Vue.extend({
    name: "FooterSocial",
    props: {
        title: {
            type: String,
            default: "CONNECT WITH US",
        },
    },
});
