export default {
    inject: {
        _provider: {
            from: "provider",
            default: {}
        }
    },
    computed: {
        dumbappData() {
            return this._provider;
        }
    }
};
