import Vue from "vue";
import BigNumber from "bignumber.js";
import Info from "../Info.vue";
import { getEtherPrice } from "../../lib/prices";
import { every } from "../../lib/interval";
import { ExecutionStateValueProvider, } from "@blockwell/dumbapp";
import { Chain } from "@blockwell/chain-client";
import { getChain } from "@blockwell/chains";
export default Vue.extend({
    name: "GasEstimate",
    components: { Info },
    inject: ["provider", "walletHolder"],
    data() {
        return {
            estimate: null,
            balance: null,
            gasUsd: null,
            interval: null,
            net: null,
            approve: null,
        };
    },
    computed: {
        gasPrice() {
            if (this.estimate?.gasPrice) {
                return this.estimate.gasPrice.shiftedBy(-9).dp(1, BigNumber.ROUND_UP).toString(10);
            }
            return null;
        },
        gasCost() {
            if (this.estimate?.gasCost) {
                return this.estimate.gasCost.sd(4, BigNumber.ROUND_UP).toString(10);
            }
            return null;
        },
        notEnough() {
            if (this.estimate?.gasCost && this.balance) {
                let diff = this.balance.minus(this.estimate.gasCost);
                if (this.estimate.value) {
                    diff = diff.minus(this.estimate.value);
                }
                if (diff.lt(0)) {
                    return diff.times(-1).sd(3, BigNumber.ROUND_UP);
                }
            }
        },
        wallet() {
            return this.walletHolder?.wallet;
        }
    },
    watch: {
        "estimate.gasCost": {
            immediate: true,
            handler(val) {
                if (this.net?.chainId === 1) {
                    getEtherPrice()
                        .then((res) => {
                        this.gasUsd = res.times(val).dp(2, BigNumber.ROUND_UP);
                    })
                        .catch((err) => {
                        console.error(err);
                        this.gasUsd = null;
                    });
                }
            },
        }
    },
    methods: {
        loadEstimate(params) {
            const wallet = this.wallet;
            if (!wallet) {
                console.warn("No wallet in load estimate.");
                this.approve = null;
                this.estimate = null;
            }
            else if (params.success === true) {
                this.loadBalance(wallet.getAccount());
                this.approve = params.approve;
                if (params.arguments[0].chainId) {
                    this.net = getChain(params.arguments[0].chainId);
                }
                if (params.values instanceof ExecutionStateValueProvider) {
                    const values = params.values;
                    if (values.dumbapp && !params.approve) {
                        let step = values.dumbapp.steps[0];
                        let args = params.arguments[0];
                        wallet
                            .estimateGas(values.state, step, args)
                            .then((it) => {
                            if (values.dumbapp.requiresApproval &&
                                !this.approve &&
                                (it.code === "error" || it.code === "execution_error")) {
                                //this.provider?.resolve();
                            }
                            this.estimate = it;
                        });
                    }
                }
            }
            else {
                this.approve = null;
                this.estimate = null;
            }
        },
        loadBalance(account) {
            if (!account || !this.net) {
                this.balance = null;
            }
            else {
                Chain.getBalance(this.net.chainId, account)
                    .then((res) => {
                    let wallet = this.wallet;
                    if (wallet.getAccount() === account) {
                        this.balance = new BigNumber(res).div(`1e${this.net.decimals}`);
                    }
                })
                    .catch(console.error);
            }
        },
    },
    created() {
        this.interval = every(() => {
            let wallet = this.wallet;
            if (this.provider?.lastResolve) {
                this.loadEstimate(this.provider?.lastResolve);
            }
            else if (wallet) {
                this.loadBalance(wallet.getAccount());
            }
        }, 8000);
        this.provider.on("resolve", this.loadEstimate);
    },
    beforeDestroy() {
        if (this.interval) {
            this.interval.clear();
        }
        this.interval = null;
        this.provider.off("resolve", this.loadEstimate);
    },
});
