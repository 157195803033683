import Vue from "vue";
import ProgressCircle from "../ProgressCircle.vue";
import { tokenLogo } from "../../lib/api/WalletApi";
import BigNumber from "bignumber.js";
import { FuelData } from "../../lib/fuel";
import Info from "../Info.vue";
import DumbappPopover from "../DumbappPopover.vue";
import { Chain } from "../../../../../jslib/packages/chain-client/src";
import LoginForm from "@/components/LoginForm.vue";
import DumbappWallet from "@/components/dumbapp/submit/DumbappWallet.vue";
import { mapGetters } from "vuex";
export default Vue.extend({
    name: "BuyFuelWith",
    components: { DumbappWallet, LoginForm, DumbappPopover, Info, ProgressCircle },
    props: {
        token: String,
        fuel: FuelData,
        account: String,
    },
    data() {
        return {
            walletType: "metamask",
            dumbappAccount: null,
            tokenDumbapp: null,
            ethDumbapp: null,
            contrib: null,
            tokenBalance: null,
            amount: "",
            fuelAmount: "",
        };
    },
    computed: {
        ...mapGetters("dumbapp", ["tracking"]),
        api() {
            return this.$store.getters["user/api"];
        },
        loggedIn() {
            return this.$store.getters["user/loggedIn"];
        },
        symbol() {
            if (this.contrib?.symbol === "PRIDEHOLD") {
                return "PRIDE";
            }
            return this.contrib?.symbol;
        },
        logo() {
            return tokenLogo(this.contrib?.address, this.contrib?.network);
        },
        fuelLogo() {
            return tokenLogo("fuel");
        },
        fuelPerToken() {
            if (this.contrib?.accepted) {
                let bonus = 0;
                if (Array.isArray(this.contrib.bonus)) {
                    bonus = this.contrib.bonus[1];
                }
                else {
                    bonus = this.contrib.bonus || 0;
                }
                return new BigNumber(bonus)
                    .times(this.contrib.value)
                    .plus(this.contrib.value)
                    .sd(5);
            }
            return new BigNumber(0);
        },
        fuelPerTokenFormatted() {
            let fpt = this.fuelPerToken;
            if (!fpt || fpt.eq(0)) {
                return "-";
            }
            if (fpt.gt(10)) {
                return fpt.toFormat(0);
            }
            return fpt.toFormat();
        },
        bonus() {
            let bonus = this.contrib?.bonus;
            if (bonus) {
                if (Array.isArray(bonus)) {
                    return Math.floor(bonus[1] * 100);
                }
                return bonus * 100;
            }
            return 0;
        },
        tokenUsd() {
            if (this.contrib?.value) {
                let amount = new BigNumber(this.amount);
                if (amount.gt(0)) {
                    return amount.times(this.contrib.value).toFormat(2);
                }
            }
            return null;
        },
        dumbappQuery() {
            return {
                net: this.contrib?.network,
                data: "fuel:" + this.contrib?.reference,
                value: this.amount,
                token: this.contrib?.address,
            };
        },
        disableDumbapp() {
            return !this.contrib?.accepted || !new BigNumber(this.fuelAmount).gt(0);
        },
        dumbapp() {
            if (this.contrib?.address === "ether") {
                return this.ethDumbapp;
            }
            return this.tokenDumbapp;
        },
        balanceDeps() {
            return {
                account: this.dumbappAccount,
                network: this.contrib?.network,
                address: this.contrib?.address,
                decimals: this.contrib?.decimals,
            };
        },
        contribDeps() {
            return {
                token: this.token,
                loggedIn: this.loggedIn
            };
        }
    },
    watch: {
        contribDeps: {
            immediate: true,
            deep: true,
            handler({ token, loggedIn }) {
                this.tokenBalance = null;
                if (!loggedIn) {
                    this.contrib = null;
                    return;
                }
                let api = this.api;
                if (this.$route.query.testnet) {
                    api.contribData("ether").then(async (res) => {
                        this.contrib = {
                            accepted: true,
                            address: token,
                            network: "goerli",
                            bonus: [0.1, 0.1],
                            decimals: 18,
                            value: 100,
                            name: "Goerli Food Coin",
                            symbol: "GoFC",
                            reference: res.reference,
                        };
                    });
                }
                else {
                    api.contribData(token)
                        .then(async (res) => {
                        this.contrib = res;
                    })
                        .catch(console.error);
                }
            },
        },
        balanceDeps: {
            immediate: true,
            deep: true,
            async handler(res) {
                if (res.account && res.address) {
                    let rawBalance;
                    if (res.address === "ether") {
                        rawBalance = await Chain.getBalance(res.network, res.account);
                    }
                    else {
                        rawBalance = await Chain.getTokenBalance(res.network, res.address, res.account);
                    }
                    this.tokenBalance = new BigNumber(rawBalance)
                        .div(`1e${res.decimals}`)
                        .toFormat();
                }
                else {
                    this.tokenBalance = null;
                }
            },
        },
    },
    methods: {
        fromInput(val) {
            if (this.fuelPerToken?.gt(0)) {
                this.fuelAmount = this.fuelPerToken.times(val).dp(2).toString(10);
            }
            else {
                this.fuelAmount = "";
            }
        },
        toInput(val) {
            if (this.fuelPerToken?.gt(0)) {
                this.amount = new BigNumber(val).div(this.fuelPerToken).sd(7).toString(10);
            }
        },
        addSubmitted(submission) {
            this.amount = "";
            this.fuelAmount = "";
            let payload = {
                key: "fuel",
                data: {
                    id: submission.id,
                    stake: this.fuel.stake.toString(10),
                },
            };
            this.$store.dispatch("dumbapp/trackSubmission", payload);
        },
    },
    created() {
        const api = this.api.fuel;
        api.getDumbapps().then((res) => {
            this.tokenDumbapp = res.token;
            this.ethDumbapp = res.eth;
        });
    },
});
