<template>
    <div class="dumbapp-modal-wrapper">
        <div class="dumbapp-modal-trigger" ref="trigger">
            <slot v-if="hasTrigger" name="trigger" :open="open" />
            <button v-else type="button" @click="open" class="btn btn-primary btn-sm">
                {{ label || "Open" }}
            </button>
        </div>

        <Modal :show.sync="showing" large>
            <template #header>
                <h3>{{ title }}</h3>
            </template>

            <div v-if="description" class="description">
                <Markdown :content="description" class="dumbapp-description" />
            </div>
            <DumbappForm
                v-show="!!fields"
                ref="form"
                :dynamic="fields"
                :form-data.sync="formData"
                class="dumbapp-modal-form"
            />

            <DumbappBlocks />
            <div class="dumbapp-modal-buttons">
                <DumbappSubmit
                    show-cancel
                    :loading="submitting"
                    :disabled="submitting"
                    :network="network"
                    :strategy="strategy"
                    @cancel="close"
                />
                <div class="text-muted send-notice">
                    Pressing Send will submit the transaction on the blockchain.
                </div>
            </div>
            <DumbappPreview class="compact-dumbapp-preview" :dumbapp="dumbapp" />
        </Modal>
    </div>
</template>

<script>
import DumbappBlocks from "@/components/dumbapp/block/DumbappBlocks";
import DumbappForm from "@/components/dumbapp/DumbappForm";
import DumbappMixin from "@/components/dumbapp/DumbappMixin";
import DumbappPreview from "@/components/dumbapp/DumbappPreview";
import DumbappSubmit from "@/components/dumbapp/submit/DumbappSubmit";
import FadeSlide from "@/components/FadeSlide";
import LoadingButton from "@/components/LoadingButton";
import Markdown from "@/components/Markdown";
import Modal from "@/components/Modal";
import { mapGetters } from "vuex";

export default {
    name: "DumbappModal",
    mixins: [DumbappMixin],
    components: {
        DumbappBlocks,
        Markdown,
        DumbappSubmit,
        DumbappForm,
        LoadingButton,
        Modal,
        DumbappPreview,
        FadeSlide,
    },
    props: {
        values: Object,
        show: Boolean,
        reference: [Element, null],
        label: String,
    },
    data() {
        return {
            showing: false,
        };
    },
    computed: {
        ...mapGetters("user", ["api"]),
        hasTrigger() {
            return !!this.$scopedSlots.trigger || !!this.$slots.trigger;
        },
    },
    methods: {
        open() {
            this.showing = true;
        },
        close() {
            this.showing = false;
        },
    },
    watch: {
        showing(val) {
            this.$emit("update:show", val);
            this.hidden = !val;
        },
        show(val) {
            if (!val) {
                this.close();
            } else {
                this.open();
            }
        },
    },
    mounted() {
        this.provider.execution.onResult(this, result => {
            if (result.success === true) {
                this.close();
            }
        });
    },
    beforeDestroy() {
        this.provider.execution.removeHandler(this);
    },
};
</script>

<style scoped lang="scss">
.description {
    padding: 20px 16px 0;
}

.dumbapp-modal-form {
    padding: 0 16px;
}

.dumbapp-modal-wrapper {
    ::v-deep( section.v-modal-body ) {
        padding: 0;
    }
}

.dumbapp-modal-buttons {
    text-align: center;
    padding: 0 10px;
}
</style>
