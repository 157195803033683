<template>
    <div>
        <RolodexModal
            :show.sync="rolodexShow"
            :request-id="rolodexRequestId"
            @select="rolodexSelect"
        />
        <RolodexAddressModal
            v-if="rolodexAddressModal"
            :show.sync="rolodexAddressModal"
            :entry-id="rolodexAddressId"
        />
    </div>
</template>

<script>
import RolodexAddressModal from "@/components/rolodex/RolodexAddressModal";
import RolodexModal from "@/components/rolodex/RolodexModal";

export default {
    name: "RolodexModals",
    components: { RolodexAddressModal, RolodexModal },
    data() {
        return {
            rolodexRequestId: null,
            rolodexShow: false,
            rolodexAddressModal: false,
            rolodexAddressId: null,
        };
    },
    methods: {
        openAddress(entry) {
            this.rolodexAddressId = entry.id;
            this.rolodexAddressModal = true;
        },
        requestRolodex(requestId) {
            this.rolodexRequestId = requestId;
            this.rolodexShow = true;
        },
        rolodexSelect(data) {
            this.$bus.emit("rolodex_select", data);
            this.rolodexShow = false;
        },
        clear() {
            this.$bus.off("rolodex_request", this.requestRolodex);
            this.$bus.off("rolodex_address", this.openAddress);
        },
    },
    created() {
        this.$bus.on("rolodex_request", this.requestRolodex);
        this.$bus.on("rolodex_address", this.openAddress);
    },
    mounted() {
        this.$store.dispatch("rolodex/syncExternal");
    },
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";
</style>
