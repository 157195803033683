<template>
    <div class="dumbapp-embed" ref="embed">
        <h3>{{ title }}</h3>
        <Markdown v-if="description" :content="description" class="dumbapp-description"/>

        <DumbappBlocks
        />
        <DumbappForm
            v-show="!!fields"
            ref="form"
            :dynamic="fields"
            :form-data.sync="formData"
            class="dumbapp-modal-form"
        />

        <div class="dumbapp-embed-buttons">
            <DumbappSubmit
                show-cancel
                :loading="submitting"
                :disabled="submitting"
                :network="network"
            />
            <div class="text-muted send-notice">Pressing Send will submit the transaction on the blockchain.</div>
        </div>
        <DumbappPreview
            class="dumbapp-embed-preview"
            :dumbapp="dumbapp"
        />
        <div class="powered-by-blockwell">
            <span class="powered">Powered By</span>
            <img src="../../assets/images/lion.png" width="18" alt="">
            <a class="blockwell" href="https://blockwell.ai" target="_blank">Blockwell</a>
        </div>
    </div>
</template>

<script>
import DumbappBlocks from "@/components/dumbapp/block/DumbappBlocks";
import DumbappForm from "@/components/dumbapp/DumbappForm";
import DumbappMixin from "@/components/dumbapp/DumbappMixin";
import DumbappPreview from "@/components/dumbapp/DumbappPreview";
import SubmissionsList from "@/components/dumbapp/SubmissionsList";
import DumbappSubmit from "@/components/dumbapp/submit/DumbappSubmit";
import LoadingButton from "@/components/LoadingButton";
import Markdown from "@/components/Markdown";
import Modal from "@/components/Modal";
import ResizeObserver from "resize-observer-polyfill";

export default {
    components: {
        DumbappBlocks,
        DumbappSubmit, LoadingButton, Modal, SubmissionsList, Markdown, DumbappForm, DumbappPreview},
    mixins: [DumbappMixin],
    props: {
        query: Object,
    },
    mounted() {
        this.hidden = false;
        this.observer = new ResizeObserver(entries => {
            if (this.$refs.embed) {
                window.parent?.postMessage(JSON.stringify({
                    src: window.location.toString(),
                    context: 'iframe.resize',
                    height: this.$refs.embed.clientHeight + 1
                }), '*')
            }
        });

        this.observer.observe(this.$refs.embed);
        this.provider.execution.onResult(this, result => {
            if (result.success === true) {
                this.$router.push({
                    name: "Dumbapp Submissions",
                    params: {
                        submissionId: result.data.submission.id,
                    },
                });
            }
        });
    },
    beforeDestroy() {
        this.provider.execution.removeHandler(this);
    },
}
</script>

<style scoped lang="scss">
.dumbapp-form {
    margin-top: 15px;
}

.transaction-preview-title {
    margin-top: 0;
}

.button-wrap {
    margin-top: 10px;
}

.dumbapp-description {
    ::v-deep( a.header-anchor ) {
        display: none;
    }
}

.dumbapp-embed-buttons {
    text-align: center;
    padding: 0 10px;
}

.powered-by-blockwell {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9em;
    font-style: italic;
    opacity: 0.5;

    .powered {
        color: #555;
        margin-right: 7px;
    }
    .blockwell {
        margin-left: 3px;
    }
}
</style>
