import Vue from "vue";
import WhitelabelCheckbox from "@/components/whitelabel/WhitelabelCheckbox.vue";
import WhitelabelColor from "@/components/whitelabel/WhitelabelColor.vue";
import WhitelabelSize from "@/components/whitelabel/WhitelabelSize.vue";
import WhitelabelFont from "@/components/whitelabel/WhitelabelFont.vue";
import WhitelabelImage from "@/components/whitelabel/WhitelabelImage.vue";
import WhitelabelLink from "@/components/whitelabel/WhitelabelLink.vue";
export default Vue.extend({
    name: "WhitelabelSettingsGroup",
    components: {
        WhitelabelLink,
        WhitelabelImage,
        WhitelabelFont,
        WhitelabelSize,
        WhitelabelColor,
        WhitelabelCheckbox,
    },
    props: {
        settings: {
            type: Array,
        },
        defaults: {
            type: Object,
        },
        currentConfig: {
            type: Object,
        },
        title: String,
    },
    data() {
        return {};
    },
    methods: {
        settingComponent(it) {
            switch (it.type) {
                case "checkbox":
                    return WhitelabelCheckbox;
                case "color":
                case "background":
                    return WhitelabelColor;
                case "size":
                    return WhitelabelSize;
                case "font":
                    return WhitelabelFont;
                case "image":
                    return WhitelabelImage;
                case "link":
                    return WhitelabelLink;
            }
        },
    },
});
