<template>
    <div v-if="swaps">
        <div v-for="it in swaps">
            <div class="dumbapp-confirmation-message deployment-message">
                <div class="message-icon">
                    <img src="../../../assets/images/logos/uniswap.svg" alt="Uniswap" height="20" />
                </div>
                <div class="message">
                    Swapped {{it.fromAmount}} {{it.from.symbol}} to {{it.toAmount}} {{it.to.symbol}}!
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Clipboard from "@/components/Clipboard";
import { DumbappSubmission } from "@blockwell/dumbapp";

export default {
    name: "UniswapMessages",
    components: {Clipboard},
    props: {
        submission: DumbappSubmission
    },
    data() {
        return {};
    },
    computed: {
        swaps() {
            return this.submission?.data?.extras?.uniswap;
        }
    }
}
</script>

<style scoped>
.message-icon ::v-deep( img ) {
    display: block;
}
</style>
