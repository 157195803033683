<template>
    <div class="submission-messages" @click="$emit('click')">
        <fade-slide>
            <div v-if="confirmationMessage">
                <ConfirmationMessage :message="confirmationMessage"/>
            </div>
        </fade-slide>
        <fade-slide-list>
            <component v-for="notice in notices"
                       :key="notice.code"
                       :is="notice.component"
                       :submission="submission"
                       :network="notice.network"
                       />
            <component v-for="extra in extrasWithComponents"
                       v-if="showExtra(extra)"
                       :key="extra.key"
                       :is="extra.component"
                       :submission="submission" />
        </fade-slide-list>
    </div>
</template>

<script>
import Clipboard from "@/components/Clipboard";
import ConfirmationMessage from "@/components/dumbapp/confirmation/ConfirmationMessage";
import DeploymentMessages from "@/components/dumbapp/confirmation/DeploymentMessages";
import LottoMessages from "@/components/dumbapp/confirmation/LottoMessages";
import {getNoticeComponent} from "@/components/dumbapp/confirmation/notice";
import SwapperMessages from "@/components/dumbapp/confirmation/SwapperMessages";
import UniswapMessages from "@/components/dumbapp/confirmation/UniswapMessages";
import { defaultExtrasProvider, DumbappSubmission } from "@blockwell/dumbapp";
import FadeSlideList from "@/components/FadeSlideList";
import FadeSlide from "@/components/FadeSlide";
import {mapGetters} from "vuex";

export default {
    // The name is a bit odd because I wanted it to be distinct from the SomethingMessages components
    name: "MessagesForSubmission",
    components: {DeploymentMessages, FadeSlideList, Clipboard, FadeSlide, ConfirmationMessage},
    props: {
        submission: DumbappSubmission
    },
    data() {
        return {
            extras: defaultExtrasProvider.get()
        }
    },
    computed: {
        ...mapGetters('user', ['api']),
        confirmationMessage() {
            if (this.submission?.data?.completed()) {
                return this.submission?.dumbapp?.confirmationMessage;
            }
            return null;
        },
        notices() {
            if (this.submission?.data?.steps) {
                let notices = [];
                for (let step of this.submission.data.steps) {
                    if (step.notice) {
                        let component = getNoticeComponent(this.submission.data.executor, step.notice.code);
                        if (component) {
                            notices.push({
                                component,
                                network: step.notice.network,
                                code: step.notice.code
                            })
                        }
                    }
                }
                if (notices.length > 0) {
                    return notices;
                }
            }
            return null;
        },
        extrasWithComponents() {
            return this.extras.map(it => {
                let component;
                switch (it.key) {
                    case "lotto":
                        component = LottoMessages;
                        break;
                    case "deployed":
                        component = DeploymentMessages;
                        break;
                    case "swapper":
                        component = SwapperMessages;
                        break;
                    case "uniswap":
                        component = UniswapMessages;
                        break;
                }

                return {
                    ...it,
                    component
                }
            }).filter(it => !!it.component);
        }
    },
    methods: {
        showExtra(extra) {
            return !!this.submission?.data?.extras?.[extra.key];
        }
    }
}
</script>

<style lang="scss">
.deployment-message {
    .clipboard-text span {
        display: inline-block;
        vertical-align: middle;
        max-width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>
