import Vue from "vue";
export default Vue.extend({
    name: "RolodexTags",
    props: {
        tags: Array
    },
    data() {
        return {};
    },
    computed: {
        allTags() {
            return this.$store.getters["rolodex/allTags"];
        },
        coloredTags() {
            return this.tags.map(tag => {
                return {
                    tag,
                    color: this.allTags.findIndex(it => it === tag) % 4
                };
            });
        }
    }
});
