<template>
    <div class="contract-history-list">
        <template v-if="history.length > 0">
            <div v-for="it in history" class="recent-contract">
                <div class="recent-contract-name">
                    <a v-if="it.typeLink" :href="it.typeLink" @click.prevent="click(it.typeLink)">{{ it.name }}</a>
                    <span v-else>{{ it.name }}</span>
                    <span class="recent-contract-network">{{ networkName(it.network) }}</span>
                </div>
                <div class="recent-contract-address">
                    <Clipboard :address="it.address" />
                </div>
                <div class="recent-contract-links">
                    Recent links:
                    <span class="recent-contract-link" v-for="link in it.links">
                        <a :href="link.link" @click.prevent="click(link.link)"
                           :class="linkStyle(link)">{{ link.title }}</a>
                    </span>
                </div>
            </div>
        </template>
        <p v-else class="no-contracts">No contracts in history yet.</p>
    </div>
</template>

<script>
import { getNetwork } from "@/assets/lib/networks";
import Clipboard from "@/components/Clipboard";

export default {
    name: "ContractHistory",
    components: { Clipboard },
    props: {
        history: {
            type: Array,
            default: []
        }
    },
    data() {
        return {};
    },
    methods: {
        click(link) {
            this.$emit("link", link);
        },
        networkName(net) {
            return getNetwork(net).networkName;
        },
        linkStyle(link) {
            if (link?.title?.endsWith("happ")) {
                return "recent-link-happ";
            } else if (link?.title?.endsWith("bApp")) {
                return "recent-link-bapp";
            } else {
                return "recent-link-other";
            }
        }
    }
};
</script>

<style scoped lang="scss">
@import "../assets/css/custom";

.recent-contract-link:after {
    content: " • ";
}

.recent-contract-link:last-child:after {
    content: "";
}

.no-contracts {
    margin-top: 15px;
}


.recent-contract {
    margin: 15px 0;
    line-height: 24px;

    .recent-contract-address {
        color: #4e4e4e;
    }
}

.recent-contract-name {
    font-weight: bold;
    color: $code-color;
    font-size: 16px;
}

.recent-contract-network {
    margin-left: 5px;
    font-weight: normal;
    color: $secondary;
}

.recent-link-happ {

}

</style>
