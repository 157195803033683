import Vue from "vue";
import InjectProviderMixin from "../provider/InjectProviderMixin";
import { componentForType } from "@/components/dumbapp/argument/ArgumentComponentMixin";
import { DataStringFunctions, DataStringValues, solidityTypeToArgumentType, } from "@blockwell/dumbapp";
export default Vue.extend({
    name: "TupleArgument",
    mixins: [InjectProviderMixin],
    props: {
        value: [DataStringFunctions, DataStringValues],
        arg: Object,
        step: Object,
    },
    data() {
        return {
            valueList: [],
        };
    },
    watch: {
        value: {
            async handler(value) {
                if (!value) {
                    this.valueList = [];
                    return;
                }
                let fields = [];
                let arg = this.arg;
                let components = arg.typing.components;
                let i = 0;
                for (let it of components) {
                    let val = value[i];
                    if (it.array) {
                        fields.push({
                            type: "string",
                            name: it.parameterName,
                            value: Array.isArray(val) ? val.join(", ") : "",
                        });
                    }
                    else {
                        fields.push({
                            type: solidityTypeToArgumentType(it.name),
                            name: it.parameterName,
                            value: val,
                        });
                    }
                    ++i;
                }
                this.valueList = fields;
            },
        },
    },
    methods: {
        componentForType,
    },
});
