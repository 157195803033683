<template>
    <div class="dumbapp-popover">
        <div class="dumbapp-popover-trigger" ref="trigger">
            <slot v-if="hasTriggerSlot" name="trigger" :open="open" />
            <button
                v-else-if="label"
                type="button"
                @click="open"
                :disabled="disabled"
                :class="[buttonClass, { 'btn-sm': !large }]"
                class="btn"
            >
                {{ label }}
            </button>
        </div>

        <div class="dumbapp-popover-dumbapp" ref="popover">
            <transition name="fade" v-on:before-enter="enter" v-on:after-leave="leave">
                <div v-if="showing" class="dumbapp-popover-inner">
                    <div class="compact-dumbapp">
                        <DumbappForm
                            v-show="!!fields"
                            ref="form"
                            :dynamic="fields"
                            :form-data.sync="formData"
                        />
                        <div class="compact-dumbapp-buttons">
                            <DumbappSubmit
                                show-cancel
                                :loading="submitting"
                                :disabled="submitting"
                                :network="network"
                                :require-wallet-address="requireWalletAddress"
                                :strategy="strategy"
                                v-model="account"
                                @cancel="close"
                            />
                            <div class="text-muted send-notice">
                                Pressing Send will submit the transaction on the blockchain.
                            </div>
                        </div>

                        <DumbappBlocks />
                        <div class="preview-container">
                            <DumbappPreview class="compact-dumbapp-preview" :dumbapp="dumbapp" />
                        </div>

                        <div class="view-full">
                            <a href="javascript: void 0;" @click="showFull = true"
                                >Show Full Preview</a
                            >
                        </div>
                    </div>
                </div>
            </transition>
            <div ref="arrow" class="dumbapp-popover-arrow-wrapper">
                <transition name="fade">
                    <div v-if="showing" class="dumbapp-popover-arrow"></div>
                </transition>
            </div>
        </div>
        <Modal :show.sync="showFull" class="dumbapp-full-modal">
            <template #header>
                <button
                    type="button"
                    class="btn btn-sm btn-outline-danger"
                    @click="close"
                    :disabled="submitting"
                >
                    Cancel
                </button>
                <LoadingButton @click="submit" small :loading="submitting" :disabled="submitting"
                    >Send</LoadingButton
                >
            </template>
            <DumbappPreview :dumbapp="dumbapp" />
        </Modal>
    </div>
</template>

<script>
import DumbappBlocks from "@/components/dumbapp/block/DumbappBlocks";
import DumbappForm from "@/components/dumbapp/DumbappForm";
import DumbappMixin from "@/components/dumbapp/DumbappMixin";
import DumbappPreview from "@/components/dumbapp/DumbappPreview";
import DumbappSubmit from "@/components/dumbapp/submit/DumbappSubmit";
import FadeSlide from "@/components/FadeSlide";
import LoadingButton from "@/components/LoadingButton";
import Modal from "@/components/Modal";
import { createPopper } from "@popperjs/core";
import ResizeObserver from "resize-observer-polyfill";

export default {
    name: "DumbappPopover",
    mixins: [DumbappMixin],
    components: {
        DumbappBlocks,
        DumbappSubmit,
        DumbappForm,
        LoadingButton,
        Modal,
        DumbappPreview,
        FadeSlide,
    },
    props: {
        values: Object,
        prefilled: Object,
        show: false,
        reference: [Element, null],
        label: String,
        disabled: Boolean,
        buttonClass: {
            type: String,
            default: "btn-primary",
        },
        large: Boolean,
        requireWalletAddress: String,
        forceWalletType: String
    },
    provide() {
        return {
            forceWalletType: this.forceType
        }
    },
    data() {
        return {
            showFull: false,
            showing: false,
            account: null,
            forceType: {
                type: this.forceWalletType
            }
        };
    },
    computed: {
        hasTriggerSlot() {
            return !!this.$slots.trigger || !!this.$scopedSlots.trigger;
        },
        contribDeps() {
            return {
                ref: this.values?.i,
                account: this.account,
                loggedIn: this.$store.getters["user/loggedIn"],
            };
        },
    },
    methods: {
        open() {
            if (this.popper) {
                if (this.showing) {
                    this.close();
                } else {
                    this.showing = true;
                }
            } else {
                this.popper = createPopper(
                    this.reference || this.$refs.trigger,
                    this.$refs.popover,
                    {
                        strategy: "fixed",
                        modifiers: [
                            {
                                name: "offset",
                                options: {
                                    offset: [0, 8],
                                },
                            },
                            {
                                name: "arrow",
                                options: {
                                    element: this.$refs.arrow,
                                },
                            },
                            {
                                name: "flip",
                                options: {
                                    fallbackPlacements: ["top", "right", "bottom", "left"],
                                },
                            },
                            {
                                name: "preventOverflow",
                                options: {
                                    altAxis: true,
                                },
                            },
                        ],
                    }
                );

                this.$nextTick(() => {
                    this.showing = true;
                });
            }
        },
        close() {
            this.showFull = false;
            this.showing = false;
        },
        enter() {
            this.$nextTick(() => {
                this.$refs.popover?.setAttribute("data-show", "");
            });
        },
        leave() {
            this.$refs.popover?.removeAttribute("data-show");
            if (this.popper) {
                this.popper.destroy();
                this.popper = null;
            }
        },
        full() {
            this.showFull = true;
        },
    },
    watch: {
        forceWalletType(val) {
            this.forceType.type = val;
        },
        contribDeps: {
            deep: true,
            handler(contrib) {
                if (contrib.ref && contrib.account && !contrib.loggedIn) {
                    this.api.contribWalletLoaded(contrib.ref, contrib.account).catch(console.error);
                }
            },
        },
        showing(val) {
            this.$emit("update:show", val);
            this.hidden = !val;
        },
        show(val) {
            if (!val) {
                this.close();
            } else {
                this.open();
            }
        },
        prefilled: {
            immediate: true,
            deep: true,
            handler(val) {
                if (val) {
                    for (let [key, value] of Object.entries(val)) {
                        if (this.formData[key] === null || this.formData[key] === undefined || this.formData[key] === "") {
                            this.$set(this.formData, key, value);
                        }
                    }
                }
            },
        },
    },
    mounted() {
        this.observer = new ResizeObserver((entries) => {
            this.popper?.update();
        });

        try {
            this.observer.observe(this.$refs.popover);
        } catch (err) {
            console.warn(err);
            console.log(this.$el);
            console.log(this.$refs.popover);
        }

        if (this.show) {
            this.open();
        }

        this.provider.execution.onResult(this, (result) => {
            if (result.success === true) {
                this.close();
            }
        });
    },
    beforeDestroy() {
        if (this.popper) {
            this.popper.destroy();
            this.popper = null;
        }
        this.provider.execution.removeHandler(this);
    },
};
</script>

<style>
.whitelabel-layout .dumbapp-popover-dumbapp .not-signed-in {
    display: none;
}
</style>

<style scoped lang="scss">
@import "~@/assets/css/custom";

.dumbapp-popover-trigger {
    display: inline-block;
    margin-top: 5px;
}

.dumbapp-popover-dumbapp {
    display: none;
    z-index: 10;
    max-width: 100vw;

    &[data-show] {
        display: block;
    }

    $placements: (
        "bottom": "top",
        "top": "bottom",
        "left": "right",
        "right": "left",
    );

    @each $pos, $side in $placements {
        &[data-popper-placement^="#{$pos}"] .dumbapp-popover-arrow-wrapper {
            #{$side}: -4px;
        }
    }
}

.dumbapp-popover-arrow-wrapper,
.dumbapp-popover-arrow,
.dumbapp-popover-arrow::before {
    position: absolute;
    width: 8px;
    height: 9px;
}

.dumbapp-popover-arrow {
    z-index: -1;
}

.dumbapp-popover-inner {
    background: var(--background);
    color: var(--background-text);
    box-shadow: 0 0 7px var(--highlight-muted);
    border-radius: 3px;

    max-width: 400px;
    padding-top: 10px;
    max-height: 100vh;
    overflow-y: auto;

    ::v-deep(.formulate-form) {
        padding: 0 10px 2px;
    }
}

.dumbapp-popover-arrow::before {
    z-index: -1;
    content: "";
    transform: rotate(45deg);
    background: var(--background);
}

.dumbapp-full-modal {
    ::v-deep(section.v-modal-body) {
        padding: 0;
    }
}

.dumbapp-popover-inner {
    :deep(.compact-dumbapp) {
        font-size: 0.85em;

        .compact-dumbapp-preview .argument-value .value {
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
        }

        .need-more-asset {
            p {
                display: inline;
            }

            .buy-and-send {
                margin: 5px 0;
            }

            .force-send {
                display: block;
                margin: 10px 0;
            }
        }

        .wallet-wrap {
            display: block;
            text-align: left;
        }

        .dumbapp-submit-metamask {
            justify-content: center;
        }
    }
}

.compact-dumbapp-buttons {
    text-align: center;
    padding: 0 10px;
}

.send-notice {
    margin: 8px 0;
}

.preview-container {
    max-height: 300px;
    overflow-y: auto;
}

.view-full {
    text-align: center;
    padding: 5px;
}
</style>
