<template functional>
    <transition
        v-if="props.direction === 'horizontal'"
        :appear="props.appear"
        v-on:enter="$options.methods.enterWidth"
        v-on:leave="$options.methods.leaveWidth"
        v-bind:css="false"
    >
        <slot />
    </transition>
    <transition
        v-else
        :appear="props.appear"
        v-on:enter="$options.methods.enter"
        v-on:leave="$options.methods.leave"
        v-bind:css="false"
    >
        <slot />
    </transition>
</template>

<script>
import Velocity from "velocity-animate";
const CSS = Velocity.CSS;

$.each(["In", "Out"], function(i, direction) {
    Velocity.Redirects["slideWidth" + direction] = function(element, options, elementsIndex, elementsSize, elements, promiseData) {
        var opts = $.extend({}, options),
            begin = opts.begin,
            complete = opts.complete,
            inlineValues = {},
            computedValues = {width: "", marginLeft: "", marginRight: "", paddingLeft: "", paddingRight: ""};

        if (opts.display === undefined) {
            /* Show the element before slideIn begins and hide the element after slideOut completes. */
            /* Note: Inline elements cannot have dimensions animated, so they're reverted to inline-block. */
            opts.display = (direction === "In" ? (Velocity.CSS.Values.getDisplayType(element) === "inline" ? "inline-block" : "block") : "none");
        }

        opts.begin = function() {
            /* If the user passed in a begin callback, fire it now. */
            if (elementsIndex === 0 && begin) {
                begin.call(elements, elements);
            }

            /* Cache the elements' original vertical dimensional property values so that we can animate back to them. */
            for (var property in computedValues) {
                if (!computedValues.hasOwnProperty(property)) {
                    continue;
                }
                inlineValues[property] = element.style[property];

                /* For slideIn, use forcefeeding to animate all vertical properties from 0. For slideOut,
                 use forcefeeding to start from computed values and animate down to 0. */
                var propertyValue = CSS.getPropertyValue(element, property);
                computedValues[property] = (direction === "In") ? [propertyValue, 0] : [0, propertyValue];
            }

            /* Force vertical overflow content to clip so that sliding works as expected. */
            inlineValues.overflow = element.style.overflow;
            element.style.overflow = "hidden";
            element.children[0].style.width = (direction === "In") ? computedValues.width[0] : computedValues.width[1];
        };

        opts.complete = function() {
            /* Reset element to its pre-slide inline values once its slide animation is complete. */
            for (var property in inlineValues) {
                if (inlineValues.hasOwnProperty(property)) {
                    element.style[property] = inlineValues[property];
                }
            }

            element.children[0].style.width = "";

            /* If the user passed in a complete callback, fire it now. */
            if (elementsIndex === elementsSize - 1) {
                if (complete) {
                    complete.call(elements, elements);
                }
                if (promiseData) {
                    promiseData.resolver(elements);
                }
            }
        };

        Velocity(element, computedValues, opts);
    };
});

export default {
    name: "slide",
    props: {
        appear: Boolean,
        direction: String,
    },
    methods: {
        enter(el, done) {
            Velocity(el, "slideDown", {
                complete: done,
                duration: 400,
                queue: false,
            });
        },
        leave(el, done) {
            Velocity(el, "slideUp", { complete: done, duration: 400, queue: false});
        },
        enterWidth(el, done) {
            Velocity(el, "slideWidthIn", {
                complete: done,
                duration: 400,
                queue: false,
            });
        },
        leaveWidth(el, done) {
            Velocity(el, "slideWidthOut", { complete: done, duration: 400, queue: false });
        },
    },
};
</script>
