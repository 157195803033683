import bwTypeMap from "@/assets/data/TypeMap";
import abi from "@/assets/data/Type.abi.json";
import { Typing } from "@blockwell/eth-types";
import { Chain } from "@blockwell/chain-client";
export default class Contract {
    /**
     * Attempts to detect and add information on contract features.
     *
     * For example, an ERC-20 token would have:
     * ```
     * contract.features = ['erc20']
     * ```
     */
    static async addFeatures(contract) {
        let bwType = await Contract.getBwType(contract.network, contract.address);
        let features = [];
        if (bwType) {
            if (bwType.features) {
                features = features.concat(bwType.features);
            }
        }
        else {
            let funcs = contract.abi.filter((it) => it.type === "function");
            if (hasFunction(funcs, "safeTransferFrom", "address", "address", "uint256") &&
                hasFunction(funcs, "safeTransferFrom", "address", "address", "uint256", "bytes") &&
                hasFunction(funcs, "balanceOf", "address") &&
                hasFunction(funcs, "ownerOf", "uint256") &&
                hasFunction(funcs, "approve", "address", "uint256") &&
                hasFunction(funcs, "setApprovalForAll", "address", "bool")) {
                features.push("erc721");
            }
            else if (hasFunction(funcs, "transfer", "address", "uint256") &&
                hasFunction(funcs, "approve", "address", "uint256") &&
                hasFunction(funcs, "transferFrom", "address", "address", "uint256")) {
                features.push("erc20");
            }
        }
        console.log("replacing contract features", contract.features, features);
        contract.features = features;
    }
    static async getBwType(network, address) {
        try {
            let bwtype = await Chain.readString(network, address, abi, "bwtype");
            if (bwtype) {
                let type = bwTypeMap[bwtype];
                return type;
            }
        }
        catch (err) {
            console.warn(`Failed to read bwtype for ${network} ${address}: ${err.message}`);
        }
        return null;
    }
}
function hasFunction(abi, name, ...params) {
    return !!abi.find((it) => {
        if (it.name !== name) {
            return false;
        }
        if (it.inputs?.length !== params.length) {
            return false;
        }
        let i = 0;
        for (let input of it.inputs) {
            let paramType = Typing.parse(params[i]);
            let inputType = Typing.parse(input.type);
            if (!paramType.equals(inputType)) {
                return false;
            }
            ++i;
        }
        return true;
    });
}
let supportsSensitivity = "A".localeCompare && "A".localeCompare("a", undefined, { sensitivity: "accent" });
export function addressEqual(a, b) {
    if (!a || !b) {
        return false;
    }
    if (supportsSensitivity) {
        return a.localeCompare(b, undefined, { sensitivity: "accent" });
    }
    return a.toLowerCase() === b.toLowerCase();
}
