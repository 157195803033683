import { getErrorComponent } from "@/components/dumbapp/error/components";
import { ErrorContext } from "@/components/dumbapp/error/ErrorContext";
import { mapActions, mapGetters } from "vuex";
import { DumbappSubmission, SubmissionController } from "@blockwell/dumbapp";
import Vue from "vue";
export default Vue.extend({
    provide() {
        return {
            provider: this.provider
        };
    },
    props: {
        submission: DumbappSubmission,
        submissionId: String
    },
    data() {
        return {
            provider: new SubmissionController(this.submission)
        };
    },
    computed: {
        ...mapGetters("user", ["api"]),
        pending() {
            return this.loadedSubmission?.data?.pending();
        },
        error() {
            return this.loadedSubmission?.data?.error();
        },
        errorContext() {
            if (this.error) {
                return ErrorContext.forSubmission(this.submission);
            }
            return null;
        },
        errorComponent() {
            if (this.errorContext) {
                return getErrorComponent(this.errorContext);
            }
            else {
                return null;
            }
        },
        steps() {
            let steps = this.loadedSubmission?.data?.steps;
            let dumbapps = this.loadedSubmission?.dumbapp?.steps;
            if (!steps) {
                return [];
            }
            let list = [];
            for (let i = 0; i < steps.length; i++) {
                list.push({
                    submission: steps[i],
                    dumbapp: dumbapps[i]
                });
            }
            return list;
        },
        loadedSubmission() {
            if (this.submission) {
                return this.submission;
            }
            if (this.submissionId) {
                return this.$store.getters["dumbapp/submissions"][this.submissionId];
            }
            return null;
        },
        approval() {
            return this.submission?.data?.extras?.approval;
        },
        approvalForDumbapp() {
            return this.approval?.dumbappName;
        }
    },
    watch: {
        api: {
            immediate: true,
            handler(api) {
                if (api) {
                    this.provider.setApi(api.contracts);
                }
            }
        },
        loadedSubmission: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.provider.setSubmission(val);
                }
            }
        },
        submissionId: {
            immediate: true,
            async handler(val) {
                if (val) {
                    await this.$store.restored;
                    this.loadSubmission(val).catch(console.error);
                }
            }
        }
    },
    methods: {
        ...mapActions("dumbapp", ["loadSubmission"])
    }
});
