<template>
    <Dropdown @open="markAsRead" class="messages-dropdown">
        <template #icon>
            <div class="notifications-icon-wrap">
                <i class="material-icons notifications-icon" :class="count < 1 ? 'empty' : ''">notifications</i>
                <div v-if="count > 0" class="notifications-count"
                     :class="unreadCount > 0 ? 'unread' : ''">{{ unreadCount || count }}
                </div>
            </div>
        </template>

        <div class="messages-menu">
            <div class="messages-menu-inner">
                <h3>Latest Messages</h3>
                <Messages ref="msg" show-empty-message show-new show-time
                          :messages="messages"
                          @dismiss="dismiss"/>
            </div>
        </div>
    </Dropdown>
</template>

<script>
import Dropdown from "@/components/Dropdown";
import Messages from "@/components/message/Messages";
import {mapActions, mapState} from "vuex";

export default {
    name: "MessagesDropdown",
    components: {Dropdown, Messages},
    data() {
        return {
            popperOptions: {
                modifiers: {
                    preventOverflow: {
                        padding: 0
                    }
                }
            }
        };
    },
    computed: {
        ...mapState("message", ['unreadCount', 'count', 'messages'])
    },
    methods: {
        ...mapActions('message', ['markAsRead', 'dismiss'])
    }
}
</script>

<style lang="scss">
@import "~@/assets/css/custom";

@keyframes notification-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(214, 49, 69, 0.8);
    }
    25% {
        box-shadow: 0 0 0 15px rgba(214, 49, 69, 0.4);
    }
    50% {
        box-shadow: 0 0 0 20px rgba(214, 49, 69, 0.0);
    }
    100% {

    }
}

@keyframes message-in {
    0% {
        max-height: 0;
        opacity: 0;
        visibility: hidden;
    }
    99% {
        max-height: 200px;
        opacity: 1;
    }
    100% {
        opacity: 1;
        max-height: none;
    }
}

.notifications-icon-wrap {
    position: relative;

    .notifications-icon {
        position: relative;

        &.empty {
            opacity: 0.5;
        }
    }

    .notifications-count {
        color: #fff;
        position: absolute;
        top: 0;
        right: 0;
        line-height: 1.2;
        background: #666;
        padding: 0 3px;
        border-radius: 3px;
        font-weight: bold;

        &.empty {
            display: none;
        }

        &.unread {
            background: $unread;
            animation: notification-pulse 2s infinite;
        }
    }
}

.messages-dropdown {
    .messages-menu {
        width: 600px;

        @media (max-width: 768px) {
            width: 500px;
        }
        @media (max-width: 590px) {
            width: 400px;
        }
        @media (max-width: 480px) {
            width: calc(100vw - 30px);
        }

        .messages-menu-inner {
            padding: 15px 25px 5px;
        }
    }
}

</style>
