<template>
    <div>
        <div v-if="renderValue" class="time-argument">
            <span class="value">{{ renderValue }}</span>
        </div>
        <slot v-else />
    </div>
</template>

<script>
import { parseTime } from "@blockwell/dumbapp";
import dayjs from "dayjs";

export default {
    name: "TimeArgument",
    props: {
        value: [String, Number],
        arg: Object,
        submissionStep: Object,
    },
    computed: {
        typeOptions() {
            return this.arg?.typeOptions || {};
        },
        reference() {
            if (this.submissionStep?.ended) {
                return dayjs(this.submissionStep.ended);
            }
            return null;
        },
        seconds() {
            if (!this.value) {
                return null;
            }

            let reference = this.reference || dayjs();

            let val = parseTime(this.value, reference);

            if (val === false) {
                return null;
            }
            return val;
        },
        renderValue() {
            if (this.seconds === null) {
                return null;
            }
            if (this.seconds === 0) {
                return "None";
            }

            if (this.typeOptions.timestamp && this.submissionStep) {
                return dayjs.unix(this.seconds).format("MMM D, YYYY h:mm A");
            }

            let reference = this.reference || dayjs();
            let time = reference.clone().add(this.seconds, "seconds");

            return dayjs.preciseDiff(reference, time);
        },
        showValue() {
            return this.renderValue !== null;
        },
    },
};
</script>

<style scoped></style>
