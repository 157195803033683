import ApprovalDumbapp from "@/components/dumbapp/ApprovalDumbapp.vue";
import Vue from "vue";
import DumbappWallet from "@/components/dumbapp/submit/DumbappWallet.vue";
import { allSubmitProps } from "@/components/dumbapp/submit/SubmitMixin";
export default Vue.extend({
    name: "DumbappSubmit",
    components: { DumbappWallet, ApprovalDumbapp },
    inject: ["provider"],
    props: allSubmitProps,
    data() {
        return {
            restored: false,
            approve: null,
        };
    },
    computed: {
        dumbapp() {
            return this.provider?.dumbapp;
        },
    },
    methods: {
        resolved(params) {
            if (params.success === true) {
                this.approve = params.approve;
            }
            else {
                this.approve = null;
            }
        },
        resolve() {
            this.provider?.resolve();
        },
    },
    created() {
        this.provider?.on("resolve", this.resolved);
        this.$store.restored.then(() => {
            this.restored = true;
        });
    },
    beforeDestroy() {
        this.provider?.off("resolve", this.resolved);
    },
});
