<template>
    <div class="token-value-wrap">
        <p>You will receive</p>
        <div class="token-value-inner">
            <div class="token-value">
                <div class="token-value-value"> ${{ valueFormatted }}</div>
                <div class="token-value-amount">{{ amountFormatted }} {{symbol}}</div>
            </div>
            <div class="multiplier">
                <span class="material-icons">double_arrow</span>
                <span class="xfour">×{{multiplier}}</span>
            </div>
            <div class="pride-value">
                ${{ prideValue }}
                <span>in PRIDE</span>
            </div>
        </div>
    </div>
</template>

<script>
import BigNumber from "bignumber.js";

export default {
    name: "OfferMultiplier",
    props: {
        amount: String,
        value: String,
        symbol: String,
        multiplier: Number
    },
    data() {
        return {};
    },
    computed: {
        amountFormatted() {
            return new BigNumber(this.amount).toFormat(3);
        },
        valueFormatted() {
            return new BigNumber(this.value).toFormat(2);
        },
        prideValue() {
            let value = new BigNumber(this.value);
            if (value && !value.isNaN()) {
                return value.times(this.multiplier).toFormat(2);
            }
            return '';
        }
    }
}
</script>

<style scoped lang=scss>
@use "sass:color";
@import "~@/assets/css/custom.scss";

.token-value-wrap {
    > ::v-deep(p ){
        margin: 0 0 10px;
        color: $text-muted;
        font-size: 22px;
    }
    margin: 20px 0 30px 0;
}

.token-value-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;

    .token-value-amount {
        font-size: 12px;
        color: $secondary;
        height: 0;
    }
}

.token-value {
    width: calc(50% - 35px);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.multiplier {
    position: relative;
    width: 70px;
    height: 86px;

    .material-icons {
        text-align: center;
        position: absolute;
        left: 0;
        top: 12px;
        right: 0;
        bottom: 0;
        color: $text-inverted;
        text-shadow:   0 0 1px $primary,  0 0 2px $primary;
        font-size: 52px;
    }

    .xfour {
        text-align: center;
        position: absolute;
        left: 0;
        top: 3px;
        right: 0;
        bottom: 0;
        color: $primary;
        font-weight: 600;
    }
}

.pride-value {
    width: calc(50% - 35px);
    font-weight: 600;
    color: $primary;

    ::v-deep( span ) {
        display: block;
        font-size: 12px;
        font-weight: normal;
        color: color.scale($primary, $lightness: 20%);
        height: 0;
    }
}
</style>
