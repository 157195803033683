<template>
    <FormulateInput
        class="dumbapp-array-input"
        type="group"
        :repeatable="true"
        :name="field.name"
        v-on="$listeners"
        v-model="input">
        <template #label><slot name="label"></slot></template>
        <template #help><slot name="help"></slot></template>
        <template #remove="context">
            <button type="button" class="array-remove" @click="context.removeItem" tabindex="2">
                <i class="material-icons">close</i>
            </button>
        </template>
        <template #addmore="context">
            <div>
                <small>
                    This can have multiple values, use the link to add more. You can also press Enter to move to
                    the next one, or paste in a list and the fields will be added automatically.
                </small>
            </div>
            <button type="button" class="array-add btn btn-sm btn-link" @click="context.addMore">
                + {{ field.label }}
            </button>
        </template>
        <template #default="context">
            <component
                :is="component"
                :field="field"
                :id="context.id + '-' + context.index"
                @keypress.enter="enterPress(context, $event)"
                @paste="onPaste(context, $event)"
                @updated="updated(context, $event)"
            />
        </template>
    </FormulateInput>
</template>

<script>
import InputMixin from "@/components/dumbapp/input/InputMixin";

export default {
    name: "ArrayInput",
    mixins: [InputMixin],
    props: {
        field: Object,
        value: {
            type: Array,
            default() {
                return [""]
            }
        }
    },
    data() {
        return {
            input: this.value.map(it => {
                let val = {};
                val[this.field.name] = it;
                return val;
            })
        }
    },
    watch: {
        input: {
            immediate: true,
            deep: true,
            handler(val) {
                this.$emit('updated', {val, field: this.field});
            }
        }
    },
    methods: {
        enterPress(context, event) {
            let inputs = $('input', this.$el);
            let index = context.index;
            let last = index === inputs.length - 1;
            event.preventDefault();

            if (last) {
                this.input.push({});
            }

            this.$nextTick(() => {
                let inputs = $('input', this.$el);
                let input = inputs[index + 1];
                input.focus();
            });
        },
        onPaste(context, event) {
            let paste = (event.clipboardData || window.clipboardData).getData('text');
            if (/\n/.test(paste)) {
                event.preventDefault();
                this.$nextTick(() => {
                    let index = context.index;
                    let values = paste.split(/\n/).map(it => {
                        let val = {};
                        val[this.field.name] = it;
                        return val;
                    });
                    let replaced = this.input.slice(0, index).concat(values);
                    if (replaced.length < this.input.length) {
                        replaced = replaced.concat(this.input.slice(replaced.length));
                    }
                    this.input = replaced;
                });
            }
        },
        updated(context, {val, field}) {
            if (!this.input[context.index]) {
                let entry = {};
                entry[field.name] = val;
                this.$set(this.input, context.index, entry);
            } else {
                this.$set(this.input[context.index], field.name, val);
            }
        }
    }
}
</script>

<style lang="scss">
.dumbapp-array-input .formulate-input-group {
    label.formulate-input-label, div.formulate-input-help {
        display: none;
    }

    .formulate-input {
        margin-bottom: 0;
    }

    .formulate-input-group-repeatable {
        position: relative;

        input {
            padding-right: 2em;
        }

        .array-remove {
            position: absolute;
            width: 2em;
            height: 2em;
            background: none;
            border: none;
            right: 0;
            top: 6px;
            margin: 0;

            z-index: 8;
        }
    }
}
</style>
