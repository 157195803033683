import EthereumUnavailable from "@/components/dumbapp/confirmation/notice/EthereumUnavailable";
import MetamaskConfirm from "@/components/dumbapp/confirmation/notice/MetamaskConfirm";
import MetamaskNotConnected from "@/components/dumbapp/confirmation/notice/MetamaskNotConnected";
import MetamaskWrongNetwork from "@/components/dumbapp/confirmation/notice/MetamaskWrongNetwork";

const components = [
    MetamaskWrongNetwork,
    EthereumUnavailable,
    MetamaskConfirm,
    MetamaskNotConnected
];

export function getNoticeComponent(type, code) {
    for (let comp of components) {
        if ((!comp.executor || comp.executor === type) && comp.code === code) {
            return comp;
        }
    }

    return null;
}
