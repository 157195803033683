import EditableInput from "@/components/creator/EditableInput.vue";
import DumbappInput from "@/components/dumbapp/input/DumbappInput.vue";
import equal from "fast-deep-equal";
import * as R from "rambdax";
import Vue from "vue";
import FormSubmission from "@braid/vue-formulate/src/FormSubmission";
export default Vue.extend({
    name: "DumbappForm",
    components: { EditableInput, DumbappInput },
    inject: ["provider"],
    props: {
        dynamic: {
            type: Array,
        },
        formData: Object,
        creator: Boolean,
    },
    data() {
        return {
            form: this.formData,
            oldData: {},
        };
    },
    computed: {
        fields() {
            if (!this.dynamic) {
                return [];
            }
            return this.dynamic;
        },
        displayForm() {
            return this.fields.length > 0;
        },
    },
    watch: {
        form: {
            deep: true,
            handler(val) {
                // This gets changed twice for some reason, so avoid emitting if the old matches
                if (!equal(val, this.oldData)) {
                    this.oldData = R.clone(val);
                    this.$emit("update:form-data", this.form);
                }
            },
        },
        formData() {
            this.form = this.formData;
        },
    },
    methods: {
        submit() {
            this.provider?.execution?.execute();
        },
        clear(field) {
            this.$set(this.form, field.name, "");
            this.$emit("update:form-data", this.form);
        },
        updated({ val, field }) {
            let current = this.form[field.name];
            if (!equal(current, val)) {
                this.$set(this.form, field.name, val);
            }
        },
    },
    mounted() {
        let provider = this.provider;
        if (provider?.execution) {
            provider.execution.addHandler(this, "input", async (state) => {
                try {
                    if (state.simulation) {
                        return {
                            success: true,
                            data: {
                                type: "form",
                                data: this.form
                            }
                        };
                    }
                    let form = this.$refs.form;
                    let submission = new FormSubmission(form);
                    let errors = await submission.hasValidationErrors();
                    if (errors) {
                        return {
                            success: false,
                            code: "validation_error"
                        };
                    }
                    let data = await submission.values();
                    return {
                        success: true,
                        data: {
                            type: "form",
                            data
                        },
                    };
                }
                catch (err) {
                    return {
                        success: false,
                        code: "validation_error",
                    };
                }
            });
        }
    },
    destroyed() {
        this.provider?.execution?.removeHandler(this);
    },
});
