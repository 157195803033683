<template functional>
    <transition :appear="props.appear"
        v-on:enter="$options.methods.enter"
        v-on:leave="$options.methods.leave"
        v-bind:css="false">
        <slot/>
    </transition>
</template>

<script>
import Velocity from "velocity-animate";

export default {
    name: "fade-slide",
    props: {
        appear: Boolean
    },
    methods: {
        enter: function (el, done) {
            el.style.opacity = 0;
            Velocity(el, {opacity: 1}, {duration: 400});
            Velocity(el, "slideDown", {complete: done, duration: 400, queue: false});
        },
        leave: function (el, done) {
            Velocity(el, {opacity: 0}, {duration: 400});
            Velocity(el, "slideUp", {complete: done, duration: 400, queue: false});
        }
    }
}
</script>
