<template>
    <div>
        <div v-if="showValue" class="address-argument" ref="wrap">
            <div class="value" @mouseover="hover = true" @mouseout="hover = false" :class="{ellipsize}">
                {{ value }}
            </div>
            <div v-if="showRolodex" class="tiny-rolodex-wrap">
                <div class="tiny-rolodex">
                    <div v-if="showAlias" class="rolodex-alias">
                        {{ rolodexEntry.alias }}
                    </div>
                    <div class="material-icons" :class="{'in-rolodex': !!rolodexEntry}"
                         @click="rolodexClick">import_contacts
                    </div>
                </div>
                <ProgressCircle v-if="loading" tiny color="rolodex" class="rolodex-progress" />
            </div>
            <div :data-clipboard-text="value" ref="button"
                 @click.stop
                 v-tooltip="{
                content: 'Copied to Clipboard',
                trigger: 'manual',
                show: showTooltip,
                container: $refs.wrap
             }"
                 class="vue-clipboard-image">
                <svg
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.12 12.93">
                    <title>Blockwell_CopyClipboard</title>
                    <path class="clipboard-icon" :class="{'white-icon': white}"
                          d="M193.57,80.28v-2.2h-8.41v11h2.71V91h8.41V83Zm0,1.3,1.2,1.2h-1.2Zm-7.5,6.58V79h6.58v1h-4.78v8.14Zm2.72,1.93V80.93h3.86v2.76h2.72v6.4Z"
                          transform="translate(-185.16 -78.08)" />
                </svg>
            </div>
        </div>
        <slot v-else />

    </div>
</template>

<script>
import Clipboard from "@/components/Clipboard";
import ProgressCircle from "@/components/ProgressCircle";
import Slide from "@/components/Slide";
import { addressEqual } from "@/lib/eth/Contract";
import { addressRegex } from "@blockwell/chain-client";
import ClipboardJS from "clipboard";

export default {
    name: "AddressArgument",
    components: { ProgressCircle, Slide, Clipboard },
    props: {
        value: [String, Object],
        white: Boolean,
        container: String,
        ellipsize: Boolean
    },
    data() {
        return {
            showTooltip: false,
            hover: false,
            showRolodexModal: false,
            loading: false
        };
    },
    computed: {
        showValue() {
            return !!this.value;
        },
        showRolodex() {
            return this.value
                && addressRegex.test(this.value)
                && !addressEqual(this.value, this.$store.state.user.account);
        },
        rolodexEntry() {
            let entries = this.$store.getters["rolodex/addressCards"];
            return entries.find(it => addressEqual(it.address, this.value));
        },
        showAlias() {
            return this.rolodexEntry?.alias && !this.hover;
        }
    },
    watch: {
        showValue(show) {
            if (show) {
                this.initClipboard();
            }
        }
    },
    methods: {
        rolodexClick() {
            if (!this.rolodexEntry && this.showRolodex) {
                this.loading = true;
                this.$store.dispatch("rolodex/saveUnknownAddress", {
                    address: this.value
                })
                    .then(() => {
                        this.$bus.emit("rolodex_address", this.rolodexEntry);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                this.$bus.emit("rolodex_address", this.rolodexEntry);
            }
        },
        initClipboard() {
            if (!this.clip) {
                this.$nextTick(() => {
                    let opt = {};
                    if (this.container) {
                        opt.container = document.getElementById(this.container);
                    }
                    this.clip = new ClipboardJS(this.$refs.button, opt);
                    this.clip.on("success", (e) => {
                        e.clearSelection();
                        this.showTooltip = true;
                        setTimeout(() => {
                            this.showTooltip = false;
                        }, 3000);
                    });
                });
            }
        }
    },
    mounted() {
        if (this.showValue) {
            this.initClipboard();
        }
    }
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.value {
    font-family: $font-address;
    font-size: 13px;
    line-height: 20px;

    @media (max-width: 768px) {
        font-size: 12px;
    }
    @media (max-width: 590px) {
        font-size: 11px;
    }
    @media (max-width: 390px) {
        font-size: 9px;
    }

    &.ellipsize {
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.tiny-rolodex-wrap {
    width: 30px;
    height: 22px;
    position: relative;
}

.tiny-rolodex {
    font-size: 13px;
    line-height: 16px;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1px 2px 2px;
    border: 1px solid $rolodex;
    border-radius: 3px;
    height: 19px;
    min-width: 26px;
    box-shadow: -2px 0 3px rgba($rolodex, 0.4);

    .material-icons {
        font-size: 14px;
        color: $rolodex;
        padding: 0 5px 1px;
        cursor: pointer;

        &.in-rolodex {
            background: $rolodex;
            color: #fff;
        }
    }
}

.rolodex-alias {
    background: #fff;
    color: $rolodex;
    font-size: 13px;
    font-weight: bold;
    padding: 0 5px;
    white-space: nowrap;
    box-sizing: border-box;
}

.address-argument {
    display: flex;
    align-items: center;
}

.vue-clipboard-image {
    width: 26px;
    padding: 0 6px;
    box-sizing: border-box;
    cursor: pointer;
    display: block;

    ::v-deep( svg ) {
        display: block;
    }
}

.rolodex-progress {
    margin-top: 1px;
}

</style>
