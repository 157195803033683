import { every } from "@/lib/interval";
import mortice from "mortice";
export class DataLoaderOptions {
}
export default class DataLoader extends DataLoaderOptions {
    constructor(api, bookHelper, happ, options) {
        super();
        this.api = api;
        this.bookHelper = bookHelper;
        this.happ = happ;
        this.dumbapps = {};
        this.loadingData = false;
        this.mutex = mortice("dataloader");
        this.cache = {};
        Object.assign(this, options);
    }
    async load() {
        let release = await this.mutex.writeLock();
        try {
            this.onData(null);
            this.loadingData = true;
            let contract = await this.api.contracts.getOne(this.happ.contractId);
            this.error = undefined;
            if ("wallet" in contract) {
                throw new Error("The address was not for a contract.");
            }
            this.contract = contract;
            let data = await this.loader({
                web3: this.happ.web3,
                contract,
                firstLoad: true,
                account: this.account,
                cache: this.cache,
                api: this.api,
                bookHelper: this.bookHelper
            });
            Promise.resolve(this.onData(data)).catch((err) => console.error("Error in onData callback", err));
            if (this.dumbappsUrl) {
                let url = this.dumbappsUrl.replace(":contractId", contract.id);
                let dumbapps = await this.api.getDumbapps(url);
                this.onDumbapps(dumbapps);
            }
            return contract;
        }
        finally {
            this.loadingData = false;
            release();
        }
    }
    startUpdates() {
        this.interval?.clear();
        this.interval = every(() => {
            this.updateData();
        }, 15000, false);
    }
    destroy() {
        this.interval?.clear();
        this.interval = undefined;
    }
    updateData() {
        this.mutex
            .writeLock()
            .then(async (release) => {
            try {
                let data = await this.loader({
                    web3: this.happ.web3,
                    contract: this.contract,
                    firstLoad: false,
                    account: this.account,
                    cache: this.cache,
                    api: this.api,
                    bookHelper: this.bookHelper
                });
                await this.onData(data);
            }
            finally {
                release();
            }
        })
            .catch((err) => {
            console.error(err);
            this.error = "Failed to update contract: " + err.message;
        });
    }
}
