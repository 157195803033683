<template>
    <div>
        <div v-if="showValue" class="proposal-argument">
            <ProposalInfo v-if="proposal" :proposal="proposal" />
            <ClampedText>
                {{ proposalText }}
            </ClampedText>
        </div>
        <slot v-else/>
    </div>
</template>

<script>
import ClampedText from "@/components/ClampedText";
import ResolveStepsMixin from "@/components/dumbapp/ResolveStepsMixin";
import ProposalInfo from "@/components/ProposalInfo";
import Daico from "@/lib/eth/Daico";
import * as voting from "@/lib/voting";

export default {
    name: "ProposalArgument",
    inject: ['provider'],
    mixins: [ResolveStepsMixin],
    components: {ProposalInfo, ClampedText},
    props: {
        value: [String, Number],
        arg: Object,
        step: Object
    },
    data() {
        return {
            proposal: null
        }
    },
    computed: {
        proposalText() {
            if (this.proposal) {
                return this.proposal.text;
            }
            return "";
        },
        showValue() {
            return this.value !== undefined && this.value !== null;
        },
        proposalDeps() {
            if (this.step) {
                return {
                    address: this.dumbappAddresses?.[this.step.index],
                    network: this.dumbappChainIds?.[this.step.index],
                    value: this.value
                }
            }
            return null;
        }
    },
    watch: {
        proposalDeps: {
            handler(val) {
                if (val?.value) {
                    Daico.loadProposal(val.network, val.address, val.value)
                        .then(res => this.proposal = res);
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.proposal-id-wrap {
    color: $text-muted;

    ::v-deep( span ) {
        color: $code-color;
    }
}

.proposal-argument {
    ::v-deep( .toggle ) {
        padding: 0 15px;
        min-width: auto;
        margin-left: 5px;
        font-size: 12px;
        box-shadow: none !important;
    }
}
</style>
