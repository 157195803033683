// This file is used by both frontend and backend
import BigNumber from "bignumber.js";
const addressRegex = /^0x[a-f0-9]{40}$/i;
export class InfuraNode {
    constructor(net, projectId) {
        this.net = net;
        this.projectId = projectId;
    }
    getUrl() {
        return `https://${this.net || "mainnet"}.infura.io/v3/${this.projectId}`;
    }
}
/**
 * @property
 */
export class EthNetwork {
    constructor(config) {
        this.decimals = 18;
        Object.assign(this, config);
        if (!this.symbol) {
            this.symbol = "ETH";
        }
    }
    get networkId() {
        return this.chainId;
    }
    get alias() {
        return this.aliases[0];
    }
    clone() {
        // @ts-ignore
        return new this.constructor(this);
    }
    getNodeUrl() {
        if (typeof this.nodeUrl === "string") {
            return this.nodeUrl;
        }
        if (this.nodeUrl?.getUrl) {
            return this.nodeUrl.getUrl();
        }
        return null;
    }
    explorerAddress(address) {
        if (this.explorer) {
            if (addressRegex.test(address)) {
                return `${this.explorer}/address/${address}`;
            }
        }
    }
    explorerTx(hash) {
        if (this.explorer) {
            if (/^0x[a-f0-9]{64}$/i.test(hash)) {
                return `${this.explorer}/tx/${hash}`;
            }
        }
    }
    explorerToken(address, wallet) {
        if (this.explorer) {
            if (addressRegex.test(address)) {
                let url = `${this.explorer}/token/${address}`;
                if (addressRegex.test(wallet)) {
                    url += `?a=${wallet}`;
                }
                return url;
            }
        }
    }
    explorerNft(address, tokenId) {
        if (this.nftExplorer) {
            if (typeof this.nftExplorer === "string") {
                return `${this.nftExplorer}/assets/${address}/${tokenId}`;
            }
            else {
                return this.nftExplorer(address, tokenId);
            }
        }
        else {
            return this.explorerToken(address) + "?a=" + tokenId;
        }
    }
    hexChainId() {
        return "0x" + new BigNumber(this.chainId).toString(16);
    }
}
class AlethioNetwork extends EthNetwork {
    explorerAddress(address) {
        if (this.explorer) {
            if (addressRegex.test(address)) {
                return `${this.explorer}/account/${address}`;
            }
        }
    }
    explorerTx(hash) {
        if (this.explorer) {
            if (/^0x[a-f0-9]{64}$/i.test(hash)) {
                return `${this.explorer}/tx/${hash}`;
            }
        }
    }
    explorerToken(address, wallet) {
        if (this.explorer) {
            if (addressRegex.test(address)) {
                return `${this.explorer}/account/${address}`;
            }
        }
    }
}
class BlockscoutNetwork extends EthNetwork {
    explorerAddress(address) {
        if (this.explorer) {
            if (addressRegex.test(address)) {
                return `${this.explorer}/address/${address}`;
            }
        }
    }
    explorerTx(hash) {
        if (this.explorer) {
            if (/^0x[a-f0-9]{64}$/i.test(hash)) {
                return `${this.explorer}/tx/${hash}`;
            }
        }
    }
    explorerToken(address, wallet) {
        if (this.explorer) {
            if (addressRegex.test(address)) {
                if (wallet && addressRegex.test(wallet)) {
                    return `${this.explorer}/address/${wallet}/tokens/${address}/token-transfers`;
                }
                return `${this.explorer}/tokens/${address}`;
            }
        }
    }
    explorerNft(address, tokenId) {
        return `${this.explorer}/token/${address}/instance/${tokenId}`;
    }
}
const unknownNetwork = new EthNetwork({
    chainId: 0,
    aliases: [],
    networkName: "Unknown Network",
    nodeUrl: "",
});
const networks = [
    new EthNetwork({
        chainId: 1,
        networkName: "Mainnet",
        aliases: ["mainnet", "main", "eth"],
        nodeUrl: "https://ethereum-rpc.publicnode.com",
        explorer: "https://etherscan.io",
        nftExplorer: "https://opensea.io",
        description: "The Main Ethereum Network",
        eip1559: true,
        priorityFee: "4000000000",
    }),
    // new EthNetwork({
    //     chainId: 3,
    //     networkName: "Ropsten",
    //     aliases: ["ropsten", "rop"],
    //     nodeUrl: new InfuraNode("ropsten", "25c0a8904f7c460086f8afb839e102d5"),
    //     explorer: "https://ropsten.etherscan.io",
    //     description: "Ropsten Test Network",
    //     gasPrice: "2000000000",
    // }),
    // new EthNetwork({
    //     chainId: 4,
    //     networkName: "Rinkeby",
    //     aliases: ["rinkeby", "rin"],
    //     nodeUrl: "https://rinkeby.blockwell.ai/geth/http",
    //     description: "Rinkeby Test Network",
    //     eip1559: true,
    //     priorityFee: "1000000000",
    // }),
    new BlockscoutNetwork({
        chainId: 49111,
        networkName: "Firechain",
        aliases: ["firechain", "fire"],
        nodeUrl: "https://node.firechain.blockwell.ai",
        explorer: "https://explorer.firechain.blockwell.ai",
        description: "Blockwell's sidechain",
        gasPrice: "0",
    }),
    // new BlockscoutNetwork({
    //     chainId: 49112,
    //     networkName: "Defactor Chain",
    //     aliases: ["defactor"],
    //     nodeUrl: "https://node.defactor.com",
    //     explorer: "https://explorer.defactor.com",
    //     description: "Defactor's Blockchain",
    //     gasPrice: "0",
    // }),
    new BlockscoutNetwork({
        chainId: 49113,
        networkName: "Stripchain",
        aliases: ["stripchain", "strip"],
        nodeUrl: "https://node.stripchain.blockwell.ai",
        explorer: "https://explorer.stripchain.blockwell.ai",
        description: "Stripchain",
        gasPrice: "0",
    }),
    new EthNetwork({
        chainId: 56,
        networkName: "Binance Smart Chain",
        aliases: ["bsc", "binance"],
        nodeUrl: "https://bsc-dataseed.binance.org",
        explorer: "https://bscscan.com",
        symbol: "BNB",
        description: "Ethereum-compatible Binance Smart Chain",
        gasPrice: "6000000000",
    }),
    new EthNetwork({
        chainId: 97,
        networkName: "Binance Testchain",
        aliases: ["prebsc", "bsc-testnet"],
        nodeUrl: "https://data-seed-prebsc-2-s3.binance.org:8545",
        explorer: "https://testnet.bscscan.com",
        symbol: "BNB",
        description: "Binance Smart Chain Test Network",
        gasPrice: "10000000000",
    }),
    new EthNetwork({
        chainId: 43114,
        networkName: "Avalanche C-Chain",
        aliases: ["avalanche"],
        nodeUrl: "https://nd-511-450-656.p2pify.com/a4f520d9eeaa99016a5d63d9548777f4/ext/bc/C/rpc",
        explorer: "https://snowtrace.io",
        symbol: "AVAX",
        description: "Avalanche C-Chain",
        eip1559: true,
        priorityFee: "1000000000",
    }),
    // new EthNetwork({
    //     chainId: 2020,
    //     networkName: "Ronin",
    //     aliases: ["ronin", "roninchain"],
    //     nodeUrl: "https://api.roninchain.com/rpc",
    //     explorer: "https://explorer.roninchain.com/",
    //     coinName: "RON",
    //     symbol: "RON",
    //     description: "Axie Infinity's Ronin Blockchain",
    // }),
];
/**
 * Parses the input into an Ethereum chain ID.
 */
export function toChainId(input) {
    if (input === undefined || input === null) {
        return 0;
    }
    if (typeof input === "string") {
        if (Number.isInteger(parseInt(input))) {
            return parseInt(input);
        }
        if (input.startsWith && input.startsWith("0x")) {
            return new BigNumber(input).toNumber();
        }
        let alias = input.toLowerCase();
        for (let net of Object.values(networks)) {
            if (net.aliases.includes(alias)) {
                return net.chainId;
            }
        }
    }
    else if (Number.isInteger(input)) {
        return input;
    }
    return 0;
}
/**
 * Parses the input into an Ethereum network and returns information on it.
 */
export function getNetwork(network) {
    let chainId;
    if (typeof network === "string" || typeof network === "number") {
        chainId = toChainId(network);
    }
    else if (network && network.chainId) {
        chainId = network.chainId;
    }
    else {
        throw new Error("Unable to parse network in getNetwork: " + network);
    }
    let net = networks.find((it) => it.chainId === chainId);
    if (net) {
        return net.clone();
    }
    else {
        return unknownNetwork;
    }
}
/**
 * List all supported networks.
 */
export function networkList() {
    return networks;
}
