<template>
        <div class="error">{{errorMessage}}</div>
</template>

<script>

import {ErrorContext} from "@/components/dumbapp/error/ErrorContext";

export default {
    name: "DefaultError",
    props: {
        context: ErrorContext
    },
    computed: {
        errorMessage() {
            if (this.context && this.context.error) {
                if (this.context.error.message) {
                    return this.context.error.message;
                }
                return this.context.error;
            }
            return null;
        }
    }
}
</script>

<style scoped>

</style>
