<template>
    <div class="markdown-body" ref="markdown"></div>
</template>

<script>
    import Md from '@/assets/lib/md';
    import Mustache from 'mustache';

    export default {
        name: 'Markdown',
        props: {
            content: String,
            data: Object
        },
        watch: {
            content: {
                immediate: true,
                handler(val) {
                    this.$nextTick(async () => {
                        let md = await Md();
                        let html = md.render(val || "");
                        if (this.data) {
                            html = Mustache.render(html, this.data);
                        }
                        if (this.$refs.markdown) {
                            this.$refs.markdown.innerHTML = html;
                        }
                    });
                }
            }
        }
    }
</script>
