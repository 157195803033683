<template>
    <div
        :class="`formulate-input-element formulate-input-element--${context.type}`"
        :data-type="context.type">
        <input
            type="hidden"
            v-model="context.model"
            v-bind="context.attributes">
        <div class="formulate-proposal-field">
            <div v-if="!hasProposal" class="select-proposal">
                <button type="button" class="btn btn-link" @click="select">Click Here to select</button>
            </div>
            <div v-else>
                <ProposalInfo v-if="proposal" :proposal="proposal"/>
                <ClampedText class="proposal-text">{{ proposalText }}</ClampedText>
                <div class="change-proposal">
                    <a href="javascript: void 0;" @click="select">Change selection</a>
                </div>
            </div>
        </div>

        <Modal :show.sync="showSelection" modal-class="select-proposal-modal">
            <template #header>
                <h5>Select Proposal</h5>
            </template>
            <ProposalSelection
                :field="field"
                @selected="selected"
            />
        </Modal>
    </div>
</template>

<script>
import ClampedText from "@/components/ClampedText";
import Modal from "@/components/Modal";
import ProposalInfo from "@/components/ProposalInfo";
import ProposalSelection from "@/components/ProposalSelection";
import Daico from "@/lib/eth/Daico";

export default {
    name: "FormulateProposal",
    components: {ProposalInfo, ProposalSelection, ClampedText, Modal},
    inject: ['provider', 'field'],
    props: {
        context: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showSelection: false,
            proposalId: null,
            selectedProposal: null
        };
    },
    watch: {
        proposalId(val) {
            this.context.model = val;
        }
    },
    computed: {
        proposalText() {
            if (this.proposal) {
                return this.proposal.text;
            }
            return "";
        },
        hasProposal() {
            return this.proposalId === 0 || this.proposalId;
        }
    },
    asyncComputed: {
        async proposal() {
            if (this.selectedProposal) {
                return this.selectedProposal;
            }
            if (this.field && this.field.address && this.proposalId) {
                return await Daico.loadProposal(this.field.network, this.field.address, this.proposalId);
            }
            return null;
        }
    },
    methods: {
        select() {
            this.showSelection = true;
        },
        selected(proposal) {
            this.proposalId = proposal.id.toString();
            this.selectedProposal = proposal;
            this.showSelection = false;
        }
    }
}
</script>

<style lang="scss">
@import "~@/assets/css/custom.scss";

.proposal-id-wrap {
    color: $text-muted;

    ::v-deep( span ) {
        color: $code-color;
    }
}

.formulate-proposal-field {
    .change-proposal {
        margin-top: 5px;
    }
}

.select-proposal-modal {
    .v-modal-body {
        padding: 0;
    }
}
</style>
