import { createUrlBuilder } from "@/lib/api/wallet/util";
const url = createUrlBuilder("v2/palette");
export default class PaletteApi {
    constructor(client, loggedIn) {
        this.client = client;
        this.loggedIn = loggedIn;
    }
    get(name) {
        return this.client
            .get(url("palettes", name))
            .then((res) => {
            return res.data;
        })
            .catch((err) => {
            if (err?.response?.status === 403) {
                return {
                    data: null,
                    subscription: "none",
                    version: null,
                };
            }
        });
    }
    getPalettes() {
        if (!this.loggedIn) {
            return null;
        }
        return this.client
            .get(url("palettes"))
            .then((res) => {
            return res.data;
        });
    }
    async create(name, palette) {
        await this.client.post(url("palettes"), {
            ...palette,
            name,
        });
    }
    getDraft(name, since) {
        return this.client
            .get(url("drafts", name), since
            ? {
                params: {
                    since,
                },
                validateStatus(status) {
                    return status >= 200 && (status === 304 || status < 300);
                },
            }
            : undefined)
            .then((res) => res.data)
            .catch((err) => {
            if (err?.response?.status === 403) {
                return {
                    name,
                    data: null,
                    subscriptionActive: false,
                    status: "unauthorized",
                    version: 0,
                };
            }
        });
    }
    lockDraft(name) {
        return this.client
            .post(url("drafts", name, "lock"))
            .then((res) => res.data);
    }
    unlockDraft(name, palette) {
        return this.client
            .post(url("drafts", name, "unlock"), palette || undefined)
            .then((res) => res.data.data);
    }
    async refreshLock(name) {
        await this.client.post(url("drafts", name, "lock", "refresh"));
    }
    saveDraft(name, palette) {
        return this.client
            .put(url("drafts", name), palette)
            .then((res) => res.data.data);
    }
    discardDraft(name) {
        return this.client
            .delete(url("drafts", name))
            .then((res) => res.data);
    }
    async publish(name) {
        return this.client
            .post(url("drafts", name, "publish"))
            .then((res) => res.data);
    }
    availability(name) {
        return this.client
            .get(url("names", name))
            .then((res) => res.data.data.status);
    }
    getPaletteVersions(name) {
        return this.client
            .get(url("palettes", name, "versions"))
            .then((res) => res.data.data);
    }
    getVersion(name, version) {
        return this.client
            .get(url("palettes", name, "versions", version))
            .then((res) => res.data.data);
    }
    getSharing(name) {
        return this.client
            .get(url("palettes", name, "sharing"))
            .then((res) => res.data.data);
    }
    shareWith(name, email, role, notify) {
        return this.client.post(url("palettes", name, "sharing"), {
            email,
            role,
            notify,
        });
    }
    removeShare(name, email, role) {
        return this.client.post(url("palettes", name, "sharing", "remove"), {
            email,
            role,
        });
    }
    updateShare(name, email, role, newRole) {
        return this.client.post(url("palettes", name, "sharing", "update"), {
            email,
            role,
            newRole,
        });
    }
    subscribe(name, ticketId) {
        return this.client
            .post(url("palettes", name, "subscription"), {
            ticket: ticketId,
        })
            .then((res) => res.data.data);
    }
    async getSubscription(name) {
        let res = await this.client.get(url("palettes", name, "subscription"));
        const sub = res.data.data;
        if (!sub) {
            return {
                status: null,
                expires: null,
                active: false,
            };
        }
        return sub;
    }
}
