import Vue from "vue";
import { WhitelabelField } from "@/components/whitelabel/whitelabel";
import { applyDiff } from "rambdax";
import Info from "@/components/Info.vue";
import PopperMixin from "@/components/PopperMixin";
import ColorPicker from "@/components/color/ColorPicker.vue";
export default Vue.extend({
    name: "WhitelabelColor",
    mixins: [PopperMixin, WhitelabelField],
    components: { ColorPicker, Info },
    computed: {
        style() {
            if (this.val) {
                return {
                    "background-color": this.val,
                };
            }
            return {};
        },
        swatches() {
            return this.$store.getters["whitelabel/swatches"];
        }
    },
    methods: {
        input(ev) {
            let color;
            if (ev.rgba.a === 1) {
                color = ev.hex;
            }
            else {
                color = `rgba(${ev.rgba.r}, ${ev.rgba.g}, ${ev.rgba.b}, ${ev.rgba.a})`;
            }
            this.val = color;
            this.changed = true;
            let config = {};
            config = applyDiff([
                {
                    op: "add",
                    path: this.setting.key,
                    value: color,
                },
            ], config);
            this.$store.dispatch("whitelabel/saveWhitelabelConfig", config);
        },
    },
    mounted() {
        this.referenceOverride = this.$refs.placement;
        this.boundary = document.querySelector("body");
        this.placementOverride = "right";
        this.useFlip = false;
    },
});
