<template functional>
    <div class="progress-circular-wrap" :class="[{ tiny: props.tiny, small: props.small, medium: props.medium, auto: props.auto }, `color-${props.color}`]">
        <div :class="props.still ? 'still-spinner' : 'paper-spinner'">
            <div class="spinner-container active">
                <div class="spinner-layer layer-1">
                    <div class="circle-clipper left">
                        <div class="circle"></div>
                    </div>
                    <div class="gap-patch">
                        <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                        <div class="circle"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProgressCircle",
    components: {},
    props: {
        tiny: Boolean,
        small: Boolean,
        medium: Boolean,
        auto: Boolean,
        color: {
            type: String,
            default: "primary",
        },
        still: Boolean
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss">
@import "~@/assets/css/custom.scss";

/*
 * Adapted from Polymer paper spinner
 * https://github.com/PolymerElements/paper-spinner
 */
$strokewidth: 3px;
$arcsize: 270; // degrees (amount of circle the arc takes up)
$arctime: 1333ms; // (time it takes to expand and contract arc)
$arcstartrot: 216; // degrees (how much the start location of the arc should rotate each time, 216 gives us a 5 pointed star shape (it's 360/5 * 3). For a 7 pointed star, we might do 360/7 * 3 = 154.286)
$containerwidth: 28px;
$shrinkTime: 400ms;

.progress-circular-wrap {
    width: 52px;
    height: 52px;
}

.still-spinner {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    .spinner-container {
        width: 100%;
        height: 100%;
    }

    .circle {
        box-sizing: border-box;
        height: 100%;
        border-width: $strokewidth;
        border-style: solid;
        border-color: inherit;
        border-bottom-color: transparent !important;
        border-radius: 50%;
        -webkit-animation: none;
        animation: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: rotate(30deg);
    }
}

.paper-spinner {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;

    .spinner-container {
        width: 100%;
        height: 100%;
    }

    .spinner-container.active {
        /* duration: 360 * ARCTIME / (ARCSTARTROT + (360-ARCSIZE)) */
        -webkit-animation: container-rotate (360 * $arctime / ($arcstartrot + (360-$arcsize)))
            linear infinite;
        animation: container-rotate (360 * $arctime / ($arcstartrot + (360-$arcsize))) linear
            infinite;
    }

    @-webkit-keyframes container-rotate {
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes container-rotate {
        to {
            transform: rotate(360deg);
        }
    }

    .spinner-layer {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    .layer-1 {
        border-color: currentColor;
    }

    .active .spinner-layer.layer-1 {
        animation: fill-unfill-rotate (4 * $arctime) cubic-bezier(0.4, 0, 0.2, 1) infinite both,
            layer-1-fade-in-out (4 * $arctime) cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    }

    @keyframes fill-unfill-rotate {
        12.5% {
            transform: rotate(135deg);
        }
        25% {
            transform: rotate(270deg);
        }
        37.5% {
            transform: rotate(405deg);
        }
        50% {
            transform: rotate(540deg);
        }
        62.5% {
            transform: rotate(675deg);
        }
        75% {
            transform: rotate(810deg);
        }
        87.5% {
            transform: rotate(945deg);
        }
        to {
            transform: rotate(1080deg);
        }
    }

    @keyframes layer-1-fade-in-out {
        from {
            opacity: 0.99;
        }
        100% {
            opacity: 0.99;
        }
    }

    /**
     * Patch the gap that appear between the two adjacent div.circle-clipper while the
     * spinner is rotating (appears on Chrome 38, Safari 7.1, and IE 11).
     *
     * Update: the gap no longer appears on Chrome when .spinner-layer's opacity is 0.99,
     * but still does on Safari and IE.
     */
    .gap-patch {
        position: absolute;
        box-sizing: border-box;
        top: 0;
        left: 45%;
        width: 10%;
        height: 100%;
        overflow: hidden;
        border-color: inherit;

    }

    .gap-patch .circle {
        width: 1000%;
        left: -450%;
    }

    .circle-clipper {
        display: inline-block;
        position: relative;
        width: 50%;
        height: 100%;
        overflow: hidden;
        border-color: inherit;
    }

    .circle-clipper .circle {
        width: 200%;
    }

    .circle {
        box-sizing: border-box;
        height: 100%;
        border-width: $strokewidth;
        border-style: solid;
        border-color: inherit;
        border-bottom-color: transparent !important;
        border-radius: 50%;
        -webkit-animation: none;
        animation: none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .circle-clipper.left .circle {
        border-right-color: transparent !important;
        transform: rotate(129deg);
    }

    .circle-clipper.right .circle {
        left: -100%;
        border-left-color: transparent !important;
        transform: rotate(-129deg);
    }

    .active .circle-clipper.left .circle {
        -webkit-animation: left-spin $arctime cubic-bezier(0.4, 0, 0.2, 1) infinite both;
        animation: left-spin $arctime cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    }

    .active .circle-clipper.right .circle {
        -webkit-animation: right-spin $arctime cubic-bezier(0.4, 0, 0.2, 1) infinite both;
        animation: right-spin $arctime cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    }

    @-webkit-keyframes left-spin {
        from {
            transform: rotate(130deg);
        }

        50% {
            transform: rotate(-5deg);
        }

        to {
            transform: rotate(130deg);
        }
    }

    @keyframes left-spin {
        from {
            transform: rotate(130deg);
        }

        50% {
            transform: rotate(-5deg);
        }

        to {
            transform: rotate(130deg);
        }
    }

    @-webkit-keyframes right-spin {
        from {
            transform: rotate(-130deg);
        }

        50% {
            transform: rotate(5deg);
        }

        to {
            transform: rotate(-130deg);
        }
    }

    @keyframes right-spin {
        from {
            transform: rotate(-130deg);
        }

        50% {
            transform: rotate(5deg);
        }

        to {
            transform: rotate(-130deg);
        }
    }

    #spinnerContainer.cooldown {
        animation: container-rotate $shrinkTime linear infinite,
            fade-out 400ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    @-webkit-keyframes fade-out {
        from {
            opacity: 0.99;
        }

        to {
            opacity: 0;
        }
    }

    @keyframes fade-out {
        from {
            opacity: 0.99;
        }

        to {
            opacity: 0;
        }
    }
}

.progress-circular-wrap {
    &.color-primary {
        color: var(--primary);
    }

    &.color-light {
        color: var(--white);
    }

    &.color-rolodex {
        color: var(--config2);
    }

    &.tiny {
        width: 20px;
        height: 20px;
    }

    &.small {
        width: 26px;
        height: 26px;
    }

    &.medium {
        width: 40px;
        height: 40px;
    }

    &.auto {
        width: 100%;
        height: 100%;
    }
}
</style>
