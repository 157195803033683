import Vue from "vue";
import AddressArgument from "@/components/dumbapp/argument/AddressArgument.vue";
import Clipboard from "@/components/Clipboard.vue";
import { getNetwork } from "@/assets/lib/networks";
export default Vue.extend({
    name: "TokenContract",
    components: { Clipboard, AddressArgument },
    props: {
        contract: {
            type: Object
        }
    },
    data() {
        return {};
    },
    computed: {
        name() {
            if (this.contract) {
                let name = this.contract.name;
                if (this.contract.parameters.symbol) {
                    name += ` (${this.contract.parameters.symbol})`;
                }
                return name;
            }
            return "N/A";
        },
        explorerLink() {
            if (this.contract) {
                return getNetwork(this.contract.network).explorerToken(this.contract.address);
            }
            return "";
        }
    }
});
