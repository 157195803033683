<template>
    <portal-mounting v-if="rendered" :mount-to="portal" append>
        <div class="v-modal-wrapper">
            <transition
                name="modal-fade"
                appear
                @before-enter="modifyBody"
                @after-enter="fixZIndex"
                @after-leave="unmodifyBody"
            >
                <div
                    v-if="shown"
                    class="v-modal-backdrop"
                    :class="{ fullscreen }"
                    @mousedown.self="backdropClick"
                    :id="uid"
                >
                    <transition appear name="modal-slide">
                        <div
                            class="v-modal"
                            :class="modalClasses"
                            v-if="showContent"
                            role="dialog"
                            aria-modal="true"
                            :aria-labelledby="$id('title')"
                        >
                            <button
                                v-if="!$slots.header"
                                type="button"
                                class="v-modal-close-noheader"
                                aria-label="Close"
                                @click="close"
                            >
                                ×
                            </button>
                            <header v-if="$slots.header" class="v-modal-header" :id="$id('title')">
                                <div class="v-modal-header-content">
                                    <slot name="header"> </slot>
                                </div>
                                <button
                                    type="button"
                                    class="v-modal-close"
                                    aria-label="Close"
                                    @click="close"
                                >
                                    ×
                                </button>
                            </header>
                            <section class="v-modal-body">
                                <slot />
                            </section>

                            <footer v-if="!!this.$slots.footer" class="v-modal-footer">
                                <slot name="footer"> </slot>
                            </footer>
                        </div>
                    </transition>
                </div>
            </transition>
        </div>
    </portal-mounting>
</template>

<script>
export default {
    name: "Modal",
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        fullscreen: Boolean,
        large: Boolean,
        flexible: Boolean,
        modalClass: String,
        noDismiss: Boolean,
        portal: {
            type: String,
            default: "#modal-portal"
        }
    },
    data() {
        return {
            shown: this.show,
            showContent: this.show,
            rendering: false,
        };
    },
    watch: {
        show(val, prev) {
            if (val || prev !== true) {
                this.showContent = this.show;
                this.shown = this.show;
            } else {
                this.close();
            }
        },
    },
    computed: {
        rendered() {
            return this.shown || this.rendering;
        },
        modalClasses() {
            return [
                {
                    fullscreen: this.fullscreen,
                    "v-modal-flexible": this.flexible,
                    "v-modal-large": this.large,
                },
                this.modalClass,
            ];
        },
    },
    methods: {
        close() {
            this.showContent = false;
            this.$nextTick(() => {
                this.shown = false;
                this.$emit("update:show", false);
                this.$emit("closed");
            });
        },
        escape(event) {
            if (event.key === "Escape" || event.key === "Esc") {
                let attr = document.body.getAttribute("data-modals");
                if (attr) {
                    let modals = JSON.parse(attr);

                    if (modals[modals.length - 1] === this.uid) {
                        this.close();
                    }
                }
            }
        },
        modifyBody() {
            const body = document.body;
            const scroll = window.scrollY;
            const html = document.documentElement;
            this.rendering = true;
            let width = this.getScrollbarWidth();
            let attr = body.getAttribute("data-modals");
            let modals;
            if (attr) {
                modals = JSON.parse(attr);
            } else {
                modals = [];
            }

            if (modals.length === 0) {
                body.setAttribute("data-modal-scroll", scroll.toString());
                body.classList.add("v-modal-open");
                body.classList.add("no-touch-actions");
                html.classList.add("no-touch-actions");
                body.style = `right: ${width}px; background: #ddd; top: -${scroll}px`;
            }

            modals.push(this.uid);

            body.setAttribute("data-modals", JSON.stringify(modals));

            document.addEventListener("keydown", this.escape);

            this.$nextTick(() => {
                this.fixZIndex();
            });
        },
        fixZIndex() {
            let attr = document.body.getAttribute("data-modals");
            let modals;
            if (attr) {
                modals = JSON.parse(attr);
            } else {
                modals = [];
            }
            let zIndex = 120;
            for (let uid of modals) {
                document.getElementById(uid).style = `z-index: ${zIndex++};`;
            }
        },
        unmodifyBody() {
            const body = document.body;
            const html = document.documentElement;
            let attr = body.getAttribute("data-modals");
            if (attr) {
                let modals = JSON.parse(attr);
                modals = modals.filter((it) => it !== this.uid);

                if (modals.length === 0) {
                    const scroll = parseInt(body.getAttribute("data-modal-scroll"));
                    body.removeAttribute("data-modals");
                    body.classList.remove("v-modal-open");
                    body.classList.remove("no-touch-actions");
                    html.classList.remove("no-touch-actions");
                    body.style = "";
                    window.scrollTo({
                        top: scroll,
                        left: 0,
                        behavior: "auto",
                    });
                } else {
                    body.setAttribute("data-modals", JSON.stringify(modals));
                }
            }
            document.removeEventListener("keydown", this.escape);
            this.rendering = false;
        },
        getScrollbarWidth() {
            // From Bootstrap 4.0 Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
            const scrollDiv = document.createElement("div");
            scrollDiv.className = "scroll-bar-measurer";
            document.body.appendChild(scrollDiv);
            const scrollbarWidth = scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth;
            document.body.removeChild(scrollDiv);
            return scrollbarWidth;
        },
        backdropClick() {
            if (!this.noDismiss) {
                this.close();
            }
        },
    },
    beforeDestroy() {
        this.unmodifyBody();
    },
};
</script>

<style>
.no-touch-actions {
    touch-action: none;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: auto;
}

.v-modal-open {
    overflow: hidden;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
}

.scroll-bar-measurer {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}
</style>

<style lang="scss" scoped>
@use "~@/assets/css/custom.scss" as *;

.v-modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 10;
    overflow-y: auto;

    &.fullscreen {
        padding: 0;
        display: block;
    }
}

.v-modal {
    min-width: 300px;
    max-width: 700px;
    background: var(--background);
    @include mdElevation(5);
    margin: auto;
    outline: 0;

    &.v-modal-large {
        max-width: none;
        min-width: 80%;
    }

    &.v-modal-flexible {
        min-width: 0;
        max-width: none;
    }

    &.fullscreen {
        max-width: none;
        width: 100%;
        min-height: 100%;
        margin: 0;
    }
}

.v-modal-footer {
    padding: 15px;
    display: flex;
}

.v-modal-header {
    display: flex;
    border-bottom: 1px solid var(--text-muted);
    align-items: center;
    min-height: 60px;
    box-sizing: border-box;
    padding: 8px 15px;

    ::v-deep( h5 ) {
        margin: 0;
    }
}

.v-modal-footer {
    border-top: 1px solid var(--text-muted);
    justify-content: flex-end;
}

.v-modal-body {
    padding: 20px 15px;
    max-width: calc(100vw - 20px);
}

.v-modal-header-content {
    flex: 1 0 auto;
}

.v-modal-close,
.v-modal-close-noheader {
    border: none;
    padding: 5px;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: var(--text);
    opacity: 0.5;
    background: transparent;
}

.v-modal-close {
    flex: 0 1 auto;
}

.v-modal-close-noheader {
    float: right;
    z-index: 10;
    width: 34px;
    position: relative;
    top: -1px;
}

.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active {
    transition: all 0.3s ease;
}

.modal-fade-leave-active {
    transition: all 0.3s ease-in-out;
}

.modal-slide-enter,
.modal-slide-leave-to {
    transform: translateY(-20px);
}

.modal-slide-enter-active {
    transition: all 0.3s ease-in-out;
}

.modal-slide-leave-active {
    transition: all 0.3s ease-in-out;
}
</style>
