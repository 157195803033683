<template>
    <nav class="pagination-wrap" aria-label="Paging">
        <ul class="pagination">
            <li class="page-item" :class="page === 1 ? 'disabled' : ''">
                <a class="page-link" href="#" @click.prevent="previous">&lsaquo;</a>
            </li>
            <li v-for="n in showPages" class="page-item" :class="itemClasses(n)">
                <span v-if="n === 'ellipsis'" class="page-link">…</span>
                <a v-else class="page-link" href="#" @click.prevent="select(n)">{{n}}</a>
            </li>
            <li class="page-item" :class="page === pages ? 'disabled' : ''">
                <a class="page-link" href="#" @click.prevent="next()">&rsaquo;</a>
            </li>
        </ul>
    </nav>
</template>

<script>
    import * as R from 'rambdax';

    export default {
        name: "Pagination",
        props: {
            pages: {
                type: Number,
                default: 1
            },
            pageSize: {
                type: Number,
                default: 10
            },
            page: {
                type: Number,
                default: 1
            }
        },
        data() {
            return {
                currentPage: this.page
            }
        },
        watch: {
            page() {
                this.currentPage = this.page;
            },
            pages() {
                if (this.currentPage > this.pages) {
                    this.currentPage = 1;
                    this.pageChange();
                }
            }
        },
        computed: {
            showPages() {
                if (this.pages < 7) {
                    return R.range(1, this.pages + 1);
                }
                if (this.page < 4) {
                    return [...R.range(1, Math.max(this.page + 2, 6)), 'ellipsis', this.pages];
                }
                if (this.page > this.pages - 4) {
                    return [1, 'ellipsis', ...R.range(Math.min(this.page - 1, this.pages - 4), this.pages + 1)];
                }

                return [1, 'ellipsis', ...R.range(this.page - 1, this.page + 2), 'ellipsis', this.pages];
            }
        },
        methods: {
            itemClasses(n) {
                if (n === 'ellipsis') {
                    return 'disabled';
                }
                if (n === this.page) {
                    return 'active';
                }
            },
            previous() {
                this.currentPage = this.currentPage - 1;
                this.pageChange();
            },
            next() {
                this.currentPage = this.currentPage + 1;
                this.pageChange();
            },
            select(n) {
                this.currentPage = n;
                this.pageChange();
            },
            pageChange() {
                this.$emit('update:page', this.currentPage);
            }
        }
    }
</script>

<style scoped>
    .page-item {
        width: 2.6em;
        text-align: center;
    }
    .page-item .page-link {
        padding-left: 0;
        padding-right: 0;
    }
    .pagination {
        margin: 0;
    }
</style>
