import { ethResolveStringParameter } from "@blockwell/eth-types";
import { getNetwork } from "@ethersproject/providers";
import { Chain } from "@blockwell/chain-client";
import { getChain } from "@blockwell/chains";
import { findEvents } from "@blockwell/dumbapp/lib/extras/util";
export class SwapperExtraProcessor {
    constructor() {
        this.key = "swapper";
    }
    async process(parameters, submission) {
        let swappers = [];
        for (let { event, step } of findEvents(submission, /Deployed/)) {
            let address = ethResolveStringParameter(event.params, "contractAddress");
            if (address) {
                try {
                    let swapper = await parameters.contracts.getContract({
                        chainId: getChain(step.network).chainId,
                        address
                    });
                    if (swapper?.bwtype === 29) {
                        let chainId = getNetwork(swapper.network).chainId;
                        let contract = await parameters.contracts.getContract({
                            address: swapper.address,
                            chainId
                        });
                        let mainToken = await Chain.readString(contract.network, contract.address, contract.abi, "mainToken");
                        let tokens = await Chain.readStringArray(contract.network, contract.address, contract.abi, "getTokenList");
                        let token = await parameters.contracts.getContract({
                            address: mainToken,
                            chainId
                        });
                        let dumbapp = await parameters.api.swapperDumbapp(contract.id, mainToken, tokens[0]);
                        swappers.push({
                            shortcode: dumbapp.shortcode,
                            mainTokenName: token.name,
                        });
                    }
                }
                catch (err) {
                    console.error(err);
                }
            }
        }
        return swappers;
    }
}
